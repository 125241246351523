import React, { Component } from 'react';
import PaymentService from '../../../services/PaymentService'
import { Link } from 'react-router-dom'
import { Modal } from 'react-bootstrap';
import { store } from 'react-notifications-component';
var moment = require('moment');

class DirectDebitsTab extends Component {
    state = {
        goCardlessInfo: {
            info: {},
            bankAccounts: [],
            mandates: [],
            payments: []
        },
        loading: true,
        showBankAccountModal: false,
        createBankAccount: {
            accountHolder: "",
            accountNumber: "",
            sortCode: "",
            countryCode: "GB",
            customerId: ""
        }
    };

	componentDidMount() {
        this.updateGoCardlessInformation();    
    }

    /**
     * Get the Direct debit information from GoCardless.
     */
    updateGoCardlessInformation = () => {
        let memberId = this.props.memberId;

        this.setState({ loading: true });
        
        return new Promise((resolve, reject) => {
            // Load the GoCardless information for a member.
            PaymentService.getGoCardlessInfo(memberId).then((goCardlessInfo) => {
                let { createBankAccount } = this.state
                
                if (goCardlessInfo !== null && goCardlessInfo !== "" && typeof goCardlessInfo.info !== "undefined") {
                    this.setState({ 
                        goCardlessInfo: goCardlessInfo, 
                        loading: false,
                        createBankAccount: { ...createBankAccount, customerId: goCardlessInfo.info.id }
                    })
                    this.props.updateDirectDebitData(goCardlessInfo, true);
                } else {
                    this.setState({ loading: false })
                }

                resolve(true);
            });

        })
    }



    /**
     * Show and hide the modal.
     */
    handleBankAccountModal = (show) => {
        this.setState({ showBankAccountModal: (typeof show === "boolean") ? show : false })
    }


	/**
	 * On input change for the details tab.
	 */
	handleBankAccountChange = e => {
		let { createBankAccount } = this.state;
        createBankAccount[e.target.name] = e.target.value
        this.setState({ createBankAccount: createBankAccount })
	};

    /**
     * Handle the create bank account submit.
     */
    handleCreateBankAccount = (e) => {
		e.preventDefault(); // Prevent form from actually submitting.
        let { createBankAccount } = this.state;
        const submitButton = e.target.querySelector('button[type="submit"]');
        let thisRef = this;
		
		// Saving feedback.
		submitButton.innerHTML = "Creating..."; submitButton.disabled = true;
        
        // Send request to create bank account to GoCardless API.
        PaymentService.createGoCardlessBankAccount(createBankAccount).then((response) => {
            submitButton.innerHTML = "Create"; submitButton.disabled = false;

            // There were errors in the form.
            if (typeof response.errors != "undefined" && response.errors.length > 0) {
                response.errors.map(error => {
					store.addNotification({
						title: "Error!",
						message: error,
						type: "danger",
						container: "top-right",
						animationIn: ["animated", "slideInRight"],
						animationOut: ["animated", "slideOutRight"],
						dismiss: { duration: 10000 }
					});
					return true;
				})
            } else {
                // Was a sucessfull response from GoCardless.
                // Clear form.
                createBankAccount.accountHolder = ""
                createBankAccount.accountNumber = ""
                createBankAccount.sortCode = ""
                this.setState({ createBankAccount: createBankAccount })

                // Close modal.
                this.handleBankAccountModal(false)
                
                // Show success message.
                store.addNotification({
                    title: "Success!",
                    message: "An bank account has successfully been created for this user.",
                    type: "success",
                    container: "top-right",
                    animationIn: ["animated", "slideInRight"],
                    animationOut: ["animated", "slideOutRight"],
                    dismiss: { duration: 5000 }
                });
                
                this.setState({ loading: true });

                setTimeout(function() {
                    thisRef.updateGoCardlessInformation();
                }, 1000);

            }
        });
    }

    /**
     * Create new customer in GoCardless and save it on the individual.
     */
    createNewCustomerReference = async (e) => {
        e.preventDefault(); // Prevent form from actually submitting.
        let { memberDetails } = this.props;

		const submitButton = e.target;
		
		// Saving feedback.
        submitButton.innerHTML = "Creating Customer Reference..."; submitButton.disabled = true;
        
        // Send request to create customer on GoCardless API.
        PaymentService.createNewGoCardlessCustomerReference(memberDetails.contactId).then(async (response) => {
            
            if (response.success) {
                await this.updateGoCardlessInformation();
    
                // Show success message.
                store.addNotification({
                    title: "Success!",
                    message: "Customer reference was successfuly added.",
                    type: "success",
                    container: "top-right",
                    animationIn: ["animated", "slideInRight"],
                    animationOut: ["animated", "slideOutRight"],
                    dismiss: { duration: 5000 }
                });

            } else { 
                // Enable the button again.
                submitButton.innerHTML = "Create Customer Reference"; submitButton.disabled = false;

                // Show error messages.
                response.errors.map(error => {
                    return store.addNotification({
                        title: "Error!",
                        message: error.field + " " + error.message + ".",
                        type: "danger",
                        container: "top-right",
                        animationIn: ["animated", "slideInRight"],
                        animationOut: ["animated", "slideOutRight"],
                        dismiss: { duration: 5000 }
                    });
                })
            }

        })
        
    }

    
    render() {

        let { goCardlessInfo, loading, showBankAccountModal } = this.state
        let { memberDetails } = this.props

        if (typeof memberDetails.goCardlessId !== "undefined" && memberDetails.goCardlessId !== null && goCardlessInfo !== null) {

            return (
                <>
                    {/* 
                      * Bank Accounts
                      *
                      */}
                    <div>
                        <h4 className="float-left">Bank accounts</h4>
                        <button onClick={(e) => { this.handleBankAccountModal(true) }} className="float-right btn btn-primary">Create bank account</button>
                        <div className="clearfix"></div>
                    </div>
                    {(typeof goCardlessInfo.bankAccounts !== "undefined" && goCardlessInfo.bankAccounts.length > 0) &&
                        <div className={"table-responsive mt-3"}>
                            <table className="table mb-5">
                                <thead>
                                    <tr>
                                        <th>Reference</th>
                                        <th>Account Holder</th>
                                        <th>Account Number</th>
                                        <th>Bank Name</th>
                                        <th>Enabled</th>
                                        <th>Creation Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {goCardlessInfo.bankAccounts.map(bankAccount => {
                                        
                                        return (
                                            <tr key={"bank-account-" + bankAccount.id}>
                                                <td>{bankAccount.id}</td>
                                                <td>{bankAccount.account_holder_name}</td>
                                                <td>******{bankAccount.account_number_ending}</td>
                                                <td>{bankAccount.bank_name}</td>
                                                <td><span className={(bankAccount.enabled) ? "icon-enabled" : "icon-disabled"}></span></td>
                                                <td>{moment(bankAccount.created_at).format('DD/MM/YYYY')}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div> 
                    }

                    {(typeof goCardlessInfo.bankAccounts === "undefined" || goCardlessInfo.bankAccounts.length === 0) &&
                        <div className="mb-5">
                            <p>No bank accounts have been created on GoCardless with this account.</p>
                        </div>
                    }
    
                    {/* 
                      * Mandates
                      *
                      */}
                    <h4>Mandates</h4>
                    {(typeof goCardlessInfo.mandates !== "undefined" && goCardlessInfo.mandates.length > 0) && 
                        <div className={"table-responsive mt-3"}>
                            <table className="table mb-5">
                                <thead>
                                    <tr>
                                        <th>Reference</th>
                                        <th>Bank Account</th>
                                        <th>Status</th>
                                        <th>Creation Date</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {goCardlessInfo.mandates.map(mandate => {
                                        
                                        return (
                                            <tr key={"mandates-" + mandate.id}>
                                                <td>{mandate.id}</td>
                                                <td>{goCardlessInfo.bankAccounts.map(bankAccount => {
                                                    if (mandate.links.customer_bank_account === bankAccount.id) {
                                                        return bankAccount.bank_name
                                                    } else {
                                                        return null
                                                    }
                                                })}</td>
                                                <td>
                                                    <span 
                                                    className={"badge text-uppercase badge-secondary badge-" + 
                                                        (mandate.status === "pending_submission" ? "secondary":"") + 
                                                        (mandate.status === "submitted" ? "success":"") + 
                                                        (mandate.status === "active" ? "success":"") + 
                                                        (mandate.status === "cancelled" ? "danger":"")
                                                    }
                                                    >{mandate.status.replace(/_/gi, " ")}</span>
                                                </td>
                                                <td>{moment(mandate.created_at).format('DD/MM/YYYY')}</td>
                                                <td>
                                                    <Link to={"/direct-debits/mandates/" + mandate.id} className={"btn btn-secondary btn-sm btn-view float-right"}><span>View</span></Link>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    }
                    {(typeof goCardlessInfo.mandates === "undefined" || goCardlessInfo.mandates.length === 0) &&
                        <div className="mb-5">
                            <p>No mandates have been created on GoCardless with this account.</p>
                        </div>
                    }
    
    
                    {/* 
                      * Payments
                      *
                      */}
                    <h4 className="mb-3">Payments</h4>
                    <div className={"table-responsive" + ((typeof goCardlessInfo.payments === "undefined" || goCardlessInfo.payments.length === 0) ? " d-none" : "")}>
                        <table className="table mb-5">
                            <thead>
                                <tr>
                                    <th>Reference</th>
                                    <th>Details</th>
                                    <th>Amount</th>
                                    <th>Status</th>
                                    <th>Bank</th>
                                    {/* <th>Receipt/Invoice</th> */}
                                    <th>Payment Date</th>
                                    <th>&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                                {goCardlessInfo.payments.map(payment => {
                                    return (
                                        <tr key={"payments-" + payment.id}>
                                            <td>{payment.id}</td>
                                            <td>{payment.description}</td>
                                            <td>&pound;{(payment.amount/100).toFixed(2)}</td>
                                            <td>
                                                <span 
                                                    className={"badge text-uppercase badge-secondary badge-" + 
                                                        (payment.status === "pending_submission" ? "secondary":"") + 
                                                        (payment.status === "submitted" ? "success":"") + 
                                                        (payment.status === "cancelled" ? "danger":"")
                                                    }
                                                >{payment.status.replace(/_/gi, " ")}</span>
                                            </td>
                                            <td>
                                                {goCardlessInfo.mandates.map(mandate => {
                                                    let bankAccountName = "";
                                                    if (payment.links.mandate === mandate.id) {
                                                        goCardlessInfo.bankAccounts.map(bankAccount => {
                                                            if (mandate.links.customer_bank_account === bankAccount.id) {
                                                                bankAccountName = bankAccount.bank_name
                                                            }
                                                            return null
                                                        })
                                                    }
    
                                                    return bankAccountName;
                                                })}
                                            </td>
                                            {/* <td>?</td> */}
                                            <td>{moment(payment.charge_date).format('DD/MM/YYYY')}</td>
                                            <td>&nbsp;</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                    <div className={(typeof goCardlessInfo.payments !== "undefined" && goCardlessInfo.payments.length !== 0) ? "d-none" : ""}>
                        <p>No payments have been made on GoCardless with this account.</p>
                    </div>

                    {/* 
                      * Create bank account modal
                      */}
                    <Modal show={showBankAccountModal} onHide={this.handleBankAccountModal}>
                        <form onSubmit={this.handleCreateBankAccount}>
                            <Modal.Header>
                                <Modal.Title>Create bank account</Modal.Title>
                                <button type="button" className="close" onClick={this.handleBankAccountModal}><span aria-hidden="true">×</span><span className="sr-only">Close</span></button>
                            </Modal.Header>
                            <Modal.Body>
                                    <div className="form-group">
                                        <label>Account Holder</label>
                                        <input type="text" className="form-control" onChange={this.handleBankAccountChange} name="accountHolder" placeholder="MR J SMITH DOE" />
                                    </div>
                                    
                                    <div className="form-group">
                                        <label>Account Number</label>
                                        <input type="text" className="form-control" onChange={this.handleBankAccountChange} name="accountNumber" placeholder="12345678" />
                                    </div>

                                    <div className="form-group">
                                        <label>Sort Code</label>
                                        <input type="text" className="form-control" onChange={this.handleBankAccountChange} name="sortCode" placeholder="20-00-00" />
                                    </div>

                                </Modal.Body>
                                <Modal.Footer>
                                    <button className="btn btn-text" type="button" onClick={this.handleBankAccountModal}>Close</button>
                                    <button className="btn btn-primary" type="submit">Create</button>
                                </Modal.Footer>
                            </form>
                    </Modal>
                </>
            );
        } else if (loading) {
            return (
                <div>
                    <div className="alert alert-info" role="alert">
                        <h3>Loading</h3>
                        <span>Loading GoCardless information, please wait...</span>
                    </div>
                </div>
            ) 
        } else {
            return (
                <div>
                    <div className="alert alert-warning" role="alert">
                        <h3>Missing Reference</h3>
                        <span>This member does not have a GoCardless ID stored in their settings, to create one simply click the button below.</span>
                    </div>
                    <button className="btn btn-primary btn-plus" onClick={this.createNewCustomerReference.bind(this)}>Create Customer Reference</button>
                </div>

            )
        }
    }
}

export default DirectDebitsTab;