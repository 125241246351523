import React, { Component } from 'react';
import Layout from './../../bricks/Layout'
import { useTable, usePagination } from 'react-table'
import MemberService from '../../services/MemberService'
import { Link, withRouter } from 'react-router-dom'
import { AuthContext } from '../../components/AuthContext';
import AccessDenied from '../../components/AccessDenied';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
var moment = require('moment');


class Members extends Component {
	state = {
		response: '',
		allMembers: [],
		startDate: '',
		endDate: '',
		memberTable: {
			query: {
				searchQuery: "",
				startDate: null,
				endDate: null,
			},
			pageIndex: 0,
			pageCount: 0,
			perPage: 30,
			isLoading: true,
			sort: {
				field: null,
				descending: false
			}
		}
	};
    

	// Breadcrumbs.
	breadcrumbs = [
		{ title: 'Home', link: '/' }, 
		{ title: 'Members', link: '/members', active: true }
	];

	componentDidMount() {
		let { memberTable } = this.state

		// Load all Members.
		MemberService.getAllMembers(memberTable).then((response) => {
			this.setState({ 
				allMembers: this.shapeForTable(response.members),
				memberTable: {
					...memberTable,
					isLoading: false,
					pageCount: response.totalPages,
					pageIndex: response.pageIndex,
					perPage: response.perPage
				}
			})
		});
	}

	// Set the table columns.
	columns = [
		{ Header: 'Fullname', accessor: 'fullname', sortable: true },
		{ Header: 'Email', accessor: 'emailAddress', sortable: true },
		{ Header: 'Membership number', accessor: 'membershipNumber', sortable: true },
		{ Header: 'Type', accessor: 'membershipType', sortable: true },
		{ Header: 'Status', accessor: 'membershipStatusLabel', sortable: true },
		{ Header: 'Renewal Date', accessor: 'renewalDate', sortable: true },
		{ Header: '', accessor: 'view', sortable: false }
	];


	// Shape out member data in preperation for display in our table
	shapeForTable = (members) => {
		return members.map(m => {
			if(m.suffix) {
				if(m.suffix.includes('REnvP') && m.suffix.includes('PIEMA')) {
					m.membershipType = 'Practitioner Member REnvP';
				}
				
			}
			return m;
		});
	}

	// On the search input change, update the state.
	handleSearchInput = (e, fieldName) => {
		let { memberTable } = this.state

		// Get the changed field info.
		let value = (e !== null && typeof e.target !== "undefined") ? e.target.value.toString() : e;
		let name = (typeof fieldName !== "undefined") ? fieldName : e.target.getAttribute('name');

		// Set loading to true and update the page number.
		memberTable.isLoading = true;
		memberTable.query[name] = value;
		memberTable.pageIndex = 0;
		this.setState({ memberTable: memberTable })

		MemberService.getAllMembers(memberTable).then((response) => {
			if (typeof response.query !== "undefined") {
				if (this.state.memberTable.query.searchQuery === response.query.searchQuery || (this.state.memberTable.query.searchQuery === "" && response.query.searchQuery === null)) {
					memberTable.isLoading = false;
					this.setState({ allMembers: this.shapeForTable(response.members), memberTable: memberTable })
				}
			}
		});
	}

	// On page change for the table.
	handlePageChange = (pageIndex) => {
		let { memberTable } = this.state

		// Set loading to true and update the page number.
		memberTable.isLoading = true;
		memberTable.pageIndex = pageIndex;
		this.setState({ memberTable: memberTable })

		// Load all Members.
		MemberService.getAllMembers(memberTable).then((response) => {
			this.setState({ 
				allMembers: response.members,
				memberTable: {
					...memberTable,
					isLoading: false,
					pageCount: response.totalPages,
					pageIndex: response.pageIndex,
					perPage: response.perPage
				}
			})
		});
	}

	// On the sort change get new data.
	handleSortData = (sort) => {
		let { memberTable } = this.state


		// Set loading to true and update the page number.
		memberTable.isLoading = true;
		memberTable.sort = sort;
		this.setState({ memberTable: memberTable })

		// Load all Members.
		MemberService.getAllMembers(memberTable).then((response) => {
			this.setState({ 
				allMembers: response.members,
				memberTable: {
					...memberTable,
					isLoading: false,
					pageCount: response.totalPages,
					pageIndex: response.pageIndex,
					perPage: response.perPage
				}
			})
		});
	}



	/**
	 * On click of a row table.
	 * @param {*} e 
	 */
	handleTdClick = (e) => {
		// let to = e.target.parentElement.getAttribute('to')
		// this.props.history.push(to);
	}

	render() {
		let { startDate, endDate } = this.state;

		return (
			<Layout breadcrumbs={this.breadcrumbs} match={this.props.match}>
				<div>
					<h1 className="page-title float-left">Members</h1>
					<div className="float-right">
						<input name="searchQuery" className="form-control form-control-sm" placeholder="Search members" onChange={this.handleSearchInput} />
						<DatePicker
							className="form-control form-control-sm email-log-datepicker"
							selected={startDate}
							dateFormat="dd/MM/yyyy"
							onChange={date => { 
								this.setState({ startDate: date })
								if (date !== null && date !== "") {
									this.handleSearchInput(moment(date).format('YYYY-MM-DD')+"T00:00:00Z", 'startDate')
								} else {
									this.handleSearchInput(null, 'startDate')
								}
							}}
							placeholderText="Start Renewal Date"
						/>
						<DatePicker
							className="form-control form-control-sm"
							selected={endDate}
							dateFormat="dd/MM/yyyy"
							onChange={date => { 
								this.setState({ endDate: date })
								if (date !== null && date !== "") {
									this.handleSearchInput(moment(date).format('YYYY-MM-DD')+"T23:59:00Z", 'endDate')
								} else {
									this.handleSearchInput(null, 'endDate')
								}
							}}
							placeholderText="End Renewal Date"
						/>
					</div>
				</div>

				<div className="table-responsive">
					<MembersTable 
						tableData={this.state.allMembers}
						tableColumns={this.columns} 
						searchQuery={this.state.memberTable.searchQuery} 
						gotoPage={this.handlePageChange} 
						handleTdClick={this.handleTdClick}
						// gotoPage={this.handlePageChange} 
						pageIndex={this.state.memberTable.pageIndex} 
						pageCount={this.state.memberTable.pageCount}
						isLoading={this.state.memberTable.isLoading}
						sort={this.state.memberTable.sort}
						sortData={this.handleSortData}
					/>
				</div>
			</Layout>
		);
	}
}


/**
 * Table to display the members with pagination and filtering.
 * @param {}
 */
function MembersTable({ tableColumns, tableData, gotoPage, pageIndex, pageCount, isLoading, sort, sortData, handleTdClick }) {

	// Define the table columns.
	const columns = React.useMemo(() => tableColumns, [tableColumns])
	
	// Define the data.
	const data = React.useMemo(() => tableData, [tableData])
	
	// Use the state and functions returned from useTable to build your UI
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		page
	} = useTable({
		columns,
		data,
		manualPagination: true,
		initialState: { pageIndex: pageIndex, pageSize: 20 },
	}, usePagination)


	/**
	 * Calculate and show the correct pages.
	 * @param {*} pIndex 
	 * @param {*} pCount 
	 */
	const numberedPages = (pIndex, pCount) => {
		let gCount = 3;
		let showPagesArr = [];

		// Loop through all pages and calculate which ones to show.
		for (let i = 0; i < pCount; i++) {
			if ((pIndex - gCount) < i && (gCount + pIndex) > i) {
				showPagesArr.push(i);
			}            
		}
		return showPagesArr;
	}

	/**
	 * On click of the sort header.
	 * @param {*} e 
	 */
	const sortHeader = (e) => {
		let sortField = e.target.getAttribute("sortfield");

		if (sort.field === sortField && !sort.descending) {
			sort.descending = true;
		} else if (sort.field === sortField && sort.descending) {
			sort.field = null;
			sort.descending = false;
		} else {
			sort.field = sortField;
			sort.descending = false;
		}

		sortData(sort);
	}

    
	return (
		<div>
			<div className="table-responsive table-data position-relative">
				<table className={"table table-striped table-hover"} {...getTableProps()}>
					<thead>
						{headerGroups.map(headerGroup => (
							<tr {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map(column => (
									// Add the sorting props to control sorting. For this example
									// we can add them into the header props
									<th {...column.getHeaderProps()} onClick={sortHeader} sortfield={column.id}>
										{column.Header}
										{(column.sortable) ? 
											(<span className={"sort " + (sort.field === column.id ? (sort.descending ? 'sort-desc' : 'sort-asc') : 'sort-none')}></span>) 
										: ''}
									</th>
								))}
							</tr>
						))}
					</thead>
					<tbody {...getTableBodyProps()}>
						{page.map((row, i) => {
							prepareRow(row);
							//console.log(row)
							return (
								<tr {...row.getRowProps()} onClick={handleTdClick.bind(this)} to={"/members/" + row.original.memberId}>
									{row.cells.map(cell => {
										if (cell.column.id === 'view') {
											return (<td {...cell.getCellProps()}>
												<Link to={"/members/" + cell.row.original.memberId} className={"btn btn-primary btn-sm btn-view float-right"}><span>Edit</span></Link>
											</td>)
										} else if (cell.column.id === 'renewalDate') {
											return (<td {...cell.getCellProps()}>{moment(cell.row.original.renewalDate).format('DD/MM/YYYY')}</td>)
										} else if (cell.column.id === 'membershipStatusLabel') {
											let label = cell.row.original.membershipStatusLabel;
											let className;
											switch (label) {
												case 'Member': className = 'success'; break;
												case 'Cancelled': className = 'danger'; break;
												case 'Non-Member': className = 'secondary'; break;
												default: className = 'secondary'; break;
											}

											return (<td {...cell.getCellProps()}>
												<span className={"badge badge-" + className}>{cell.row.original.membershipStatusLabel}</span>
											</td>)
										} else if (cell.column.id === 'membershipNumber') {
											if (typeof cell.row.values.membershipNumber != "undefined") {
												return (<td {...cell.getCellProps()}>{cell.render('Cell')}</td>)
											} else {
												return (<td {...cell.getCellProps()}>N/A</td>)
											}
										} else {
											return (<td {...cell.getCellProps()}>{cell.render('Cell')}</td>)
										}
									})}
								</tr>
							)
						})}
					</tbody>
				</table>
				<div className={"loading-table-data" + ((isLoading) ? " active" : "")}><div className="loading-inner">Loading...</div></div>
			</div>

			<nav className="d-flex mt-4" aria-label="Page navigation">
				<ul className="pagination mx-auto">
					{/* <li className="page-item">
						<button className="page-link" onClick={() => gotoPage(0)} disabled={(pageIndex === 0)}>&lt;&lt; First</button>
					</li> */}
					<li className="page-item">
						<button className="page-link" onClick={() => gotoPage(pageIndex-1)} disabled={(pageIndex === 0)}>&lt; Previous</button>
					</li>
					{numberedPages(pageIndex, pageCount).map((pageNum) => {
						return (
							<li className={"page-item " + (pageIndex === pageNum ? 'active' : '')} key={pageNum+1}>
								<button className="page-link" onClick={() => gotoPage(pageNum)}>{pageNum+1}</button>
							</li>
						)
					})}
					<li className="page-item">
						<button className="page-link" onClick={() => gotoPage(pageIndex+1)} disabled={(pageIndex+1 === pageCount)}>Next &gt;</button>
					</li>
					{/* <li className="page-item">
						<button className="page-link" onClick={() => gotoPage(pageCount-1)} disabled={(pageIndex+1 === pageCount)}>Last &gt;&gt;</button>
					</li> */}
				</ul>
			</nav>
		</div>
	)
}

export default withRouter((props, ref) => (
	<AuthContext.Consumer>{context => {
		if (context.currentUser.permissions.members) {
			return (<Members {...props} authContext={context} />)
		} else {
			return (<AccessDenied {...props}/>)
		}
	}}</AuthContext.Consumer>
));