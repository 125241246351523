import React from 'react'

function FullPageSpinner(props) {
    return (
        
        <section id="fullpage-spinner">
            <div className="loader loader-3">
                <div className="dot dot1"></div>
                <div className="dot dot2"></div>
                <div className="dot dot3"></div>
            </div>
        </section>
    )
}
export { FullPageSpinner }