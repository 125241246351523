import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { AuthContext } from './../components/AuthContext'

class Header extends Component {    
    
    render() { 
        return (
            <AuthContext.Consumer>
                {context => (
                    <header>
                        <div className="header">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="user-section">
                                            <div className="d-flex justify-content-end">
                                                <Link 
                                                    to={"/users/edit-user/" + ((context.currentUser != null) ? context.currentUser.id : "")} 
                                                    className="myaccount-button">
                                                        {((context.currentUser !== null) ? context.currentUser.firstName + " " + context.currentUser.lastName : "")}
                                                </Link>
                                                <button className="logout-button" onClick={context.handleLogoutClick}></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>
                )}
            </AuthContext.Consumer>
        );
    }
}
 
export default Header;