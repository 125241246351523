import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { AuthContext } from './../components/AuthContext'

class Sidenav extends Component {

    navItems = [{
        title: "Home",
        class: "home",
        to: "/",
        permission: null
    }, {
        title: "Applications",
        class: "applications",
        to: "/applications",
        permission: "applications",
        children: [{
            title: "Completed Applications",
            to: "/applications/completed-applications"
        },{
            title: "Incomplete Applications",
            to: "/applications/incomplete-applications"
        },{
            title: "Export Applications",
            to: "/applications/export-applications"
        }, {
            title: "Process Applications",
            to: "/applications/process-applications"
        }, {
            title: "Duplicate Applications",
            to: "/applications/duplicate-applications"
        }, {
            title: "Discount Codes",
            to: "/applications/discount-codes"
        }, {
            title: "Country Discounts",
            to: "/applications/country-discounts"
        }, {
            title: "Stickerbook Discounts",
            to: "/applications/stickerbook-discounts"
        }, {
            title: "Configuration",
            to: "/applications/configuration"
        }]
    }, {
        title: "Members",  
        class: "members", 
        to: "/members", 
        permission: "members", 
    }, {
        title: "Invoices",  
        class: "invoices", 
        to: "/invoices", 
        permission: "invoices", 
    }, {
        title: "Payments",
        class: "payments",
        to: "/payments",
        permission: "payments",
        children: [{
            title: "Export Stripe",
            to: "/payments/export-stripe-sage"
        }, {
            title: "Export GoCardless",
            to: "/payments/export-gocardless-sage"
        }, {
            title: "Export BACs Payments",
            to: "/payments/export-bacs-payments"
        }]
    }, {
        title: "Direct Debits",
        class: "direct-debits",
        to: "/direct-debits",
        permission: "directDebits",
        children: [{
            title: "Payments",
            to: "/direct-debits/payments"
        }, {
            title: "Mandates",
            to: "/direct-debits/mandates"
        }, {
            title: "Bank Accounts",
            to: "/direct-debits/bank-accounts"
        }]
    }, {
        title: "Email Log",
        class: "emails",
        to: "/email-log",
        permission: "emailLog",
    }, {
        title: "Users",
        class: "users",
        to: "/users",
        permission: "admin",
    }, {
        title: "Settings",
        class: "settings",
        to: "/settings",
        permission: "settings",
    }
]

    render() {
        let { match } = this.props
        let path = (typeof match !== "undefined") ? match.path : "";


        return ( 
            <AuthContext.Consumer>
                {context => (
                    <div id="side-nav" className="side-nav">
                        <div className="logo">
                            <Link to={"/"}>
                                <img src="/assets/logo.svg" className="img-fluid" alt="IEMA logo" />
                            </Link>
                        </div>
            

                        <ul className="list-unstyled side-menu">
                            {this.navItems.map((navItem, key) => (
                                <MenuItem key={"menu-item-" + key} navItem={navItem} currentUser={context.currentUser} path={path} />
                            ))}
                        </ul>
                        <div className="contact-detail text-center">
                            <p>Need help?</p>
                            <ul className="list-unstyled list-inline">
                                <li className="list-inline-item contact-icon">
                                    <a className="phone" href={"tel:01522534344"}>&nbsp;</a>
                                </li>
                                <li className="list-inline-item contact-icon">
                                    <a className="email" href={"mailto:helpdesk@cursor.co.uk"}>&nbsp;</a>
                                </li>
                            </ul>
                            <p>Developed by <a href={"https://www.cursor.co.uk"}>Cursor</a><br/><span className="global-version">v{global.appVersion}</span></p>
                        </div>
                    </div>
                )}
            </AuthContext.Consumer>
        );
    }
}



/**
 * Menu Item component
 */
class MenuItem extends Component {

    render() {
        let { navItem, currentUser, path } = this.props;

        // Create the standard <li /> element.
        let liElem = (
            <li className={navItem.class + (
                (path.startsWith(navItem.to) && navItem.to !== "/") ||
                (navItem.to === "/" && path === "/")
            ? " active" : "")}>
                <Link to={navItem.to}>{navItem.title}</Link>
            </li>
        )
        
        // Add the children.
        if (typeof navItem.children !== "undefined" && navItem.children.length > 0) {
            liElem = (
                <li className={navItem.class + " submenu-toggle" + (path.startsWith(navItem.to) ? " active" : "")}>
                    <div className="submenu-item">
                        <Link to={navItem.to}>{navItem.title}</Link>
                        <button className="submenu-toggler" data-toggle="collapse" href={"#collapse-" + navItem.class}>+</button>
                    </div>

                    <div className={"collapse" + (path.startsWith(navItem.to) ? " show" : "")} id={"collapse-" + navItem.class}>
                        <ul className={"list-unstyled submenu"}>
                            {navItem.children.map((child, key) => (
                                <li key={"link-item" + key} className={(path.startsWith(child.to) ? " active" : "")}>
                                    <Link to={child.to}>{child.title}</Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                </li>
            )
        }


        // Check Permissions.
        if (navItem.permission === null || currentUser.permissions[navItem.permission] === true) {
            return (<>{liElem}</>)
        } else {
            return (<></>)
        }
    }
}
 
export default Sidenav;