import React, { Component } from 'react';
import Layout from './../../bricks/Layout'
import InvoiceService from '../../services/InvoiceService'
import MemberService from '../../services/MemberService'
import PaymentService from '../../services/PaymentService'
import { AuthContext } from '../../components/AuthContext';
import AccessDenied from '../../components/AccessDenied';
import { store } from 'react-notifications-component';

let closeModalButton = null;
let closePaymentModalButton = null;

class Details extends Component {
	state = {
        invoice: null,
        member: null
    };
    
	// Breadcrumbs.
	breadcrumbs = [
		{ title: 'Home', link: '/' }, 
		{ title: 'Invoices', link: '/invoices', active: true },
		{ title: 'Invoice Details', link: null, active: true }
    ];
    
	constructor(props) {
		super(props);
		this.downloadPdf = this.downloadPdf.bind(this);
	}

	componentDidMount() {
        let invoiceId = this.props.match.params.invoiceId
        
		// Load all Invoices.
		InvoiceService.getById(invoiceId).then((response) => {
            this.setState({ invoice: response.invoice, member: response.member })
		});
    }

    /**
     * Download PDF.
     */
    downloadPdf(e) {
        let invoiceId = this.state.invoice.id;
        let memberId = this.state.member.contactId;

		// Show feedback.
		const buttonClicked = e.target;
		buttonClicked.innerHTML = "Downloading Invoice..."; buttonClicked.disabled = true;
        
        InvoiceService.downloadInvoicePdf(memberId, invoiceId).then(url => {
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `invoice-${invoiceId}.pdf`);
            
            // Append to html page
            document.body.appendChild(link);
            
            // Click the link automatically then remove it.
            link.click();
            link.parentNode.removeChild(link);

            buttonClicked.innerHTML = "Download Invoice"; buttonClicked.disabled = false;
        })
    }

	render() {
        let { invoice, member } = this.state;
		
		if (invoice != null) {
			
			// Add the VAT percentage
			if (invoice.totalTax !== 0) {
				invoice.vatPercentage = (invoice.totalTax * 100) / invoice.lineItemAmount;
			} else {
				invoice.vatPercentage = 0;
			}

            let labelClass = "secondary";
            if (invoice.statusCode === "Paid" || invoice.statusCode === "Fully Paid") { labelClass = "success"; } 
            else if (invoice.statusCode === "Due") { labelClass = "warning"; }
            else if (invoice.statusCode === "Cancelled") { labelClass = "danger"; }

            return (
                <Layout breadcrumbs={this.breadcrumbs} match={this.props.match}>
                    <h1 className="page-title">#{invoice.invoiceNumber} Invoice Details</h1>
                    
                    <table className="table table-borderless table-sm mb-5">
                        <tbody>
                            <tr>
                                <td width="25%" className="pl-0"><strong>Name</strong></td>
                                <td>{member.fullname}</td>
                            </tr>
                            <tr>
                                <td width="25%" className="pl-0"><strong>Member</strong></td>
                                <td>{member.membershipType}</td>
                            </tr>
                            <tr>
                                <td width="25%" className="pl-0"><strong>Membership Number</strong></td>
                                <td>{member.membershipNumber}</td>
                            </tr>
                            <tr>
                                <td width="25%" className="pl-0"><strong>Payment Method</strong></td>
                                <td>{invoice.paymentMethod}</td>
                            </tr>
                            <tr>
                                <td width="25%" className="pl-0"><strong>Total Amount</strong></td>
                                <td>&pound;{invoice.totalAmount.toFixed(2)}</td>
                            </tr>
                            <tr>
                                <td width="25%" className="pl-0"><strong>Transaction Date</strong></td>
                                <td>{invoice.transactionDate}</td>
                            </tr>
                            <tr>
                                <td width="25%" className="pl-0"><strong>Due Date</strong></td>
                                <td>{invoice.dueDate}</td>
                            </tr>
							{typeof invoice.poNumber !== "undefined" && invoice.poNumber !== "" ? (
								<tr>
									<td width="25%" className="pl-0"><strong>PO Number</strong></td>
									<td>{invoice.poNumber}</td>
								</tr>
							) : ""}
                            <tr>
                                <td width="25%" className="pl-0"><strong>Status</strong></td>
                                <td><span className={"badge badge-"+labelClass}>{invoice.statusCode}</span></td>
                            </tr>
                        </tbody>
                    </table>

                    <h4>Items</h4>
					<table className="table table-borderless table-condensed">
						
								<thead>
									<tr>
										<th>Description</th>
										<th>Quantity</th>
										<th>Unit Price</th>
										<th>VAT</th>
										<th>Amount</th>
									</tr>
								</thead>
								<tbody>
									{invoice.products.map((product, index) => (
										<tr className="table-row-border" key={"product"+index}>
											<td>{product.description}</td>
											<td>{product.qty}</td>
											<td>&pound;{product.itemCost.toFixed(2)}</td>
											{/* Calculate the VAT percentage for this product */}
											<td>&pound;{product.taxAmount.toFixed(2)}</td>
											<td>&pound;{(product.total - product.taxAmount).toFixed(2)}</td>
										</tr>
									))}
									<tr>
										<td>&nbsp;</td>
										<td>&nbsp;</td>
										<td>&nbsp;</td>
										<td>Subtotal (Excl VAT): </td>
										<td>&pound;{invoice.lineItemAmount.toFixed(2)}</td>
									</tr>

									<tr className="no-border">
										<td>&nbsp;</td>
										<td>&nbsp;</td>
										<td>&nbsp;</td>
										<td>Total VAT: </td>
										<td>&pound;{invoice.totalTax.toFixed(2)}</td>
									</tr>

									<tr className="table-row-border-top">
										<td>&nbsp;</td>
										<td>&nbsp;</td>
										<td>&nbsp;</td>
										<td><strong>Total: </strong></td>
										<td><strong>&pound;{invoice.totalAmount.toFixed(2)}</strong></td>
									</tr>
								</tbody>
							</table>
                    
                    <div className="clearfix mt-4">
                        <div className="pull-right">
                            <button 
                                className="btn btn-outline-primary float-right ml-2"
                                data-toggle="modal" 
                                data-target="#email-invoice-modal">Email Invoice</button>

                            <button className="btn btn-outline-secondary float-right ml-2" onClick={this.downloadPdf}>Download Invoice</button>

                        </div>
                    </div>

                    {/* Show Email invoice modal. */}
                    <EmailInvoiceModal closeButton={this.closeModalButton} emailInvoice={invoice} defaultEmail={member.emailAddress} />
                </Layout>
            );
        } else {
            return (
                <Layout breadcrumbs={this.breadcrumbs} match={this.props.match}>
                    <h1 className="page-title">Invoice Details</h1>
                    <p>Loading...</p>
                </Layout>
            );

        }
	}
}

/**
 * Email Invoice modal.
 * 
 */
class EmailInvoiceModal extends Component {
	state = { errorMessage: '' };

	handleSubmit = (e) => {
		e.preventDefault();
		
		// Close the modal.
		closeModalButton.click();

		let { emailInvoice } = this.props;
		let emailVal = e.target.querySelector('[name="email-invoice"]').value

		if (typeof emailInvoice.invoiceNumber === "undefined") {
			// Show error
			store.addNotification({
				title: "Error!",
				message: "Invalid invoice, please try again.",
				type: "danger",
				container: "top-right", animationIn: ["animated", "slideInRight"], animationOut: ["animated", "slideOutRight"],
				dismiss: { duration: 3000 }
			});

		} else {
			
			// Show some feedback to the user and store the notification ID.
			const notificationId = store.addNotification({
				title: "Sending email",
				message: "Sending invoice #" + emailInvoice.invoiceNumber + " to " + emailVal + ".",
				type: "default",
				container: "top-right", animationIn: ["animated", "slideInRight"], animationOut: ["animated", "slideOutRight"],
				dismiss: { duration: 0, showIcon: true }
			});


			// Send email.
			MemberService.emailInvoice(emailInvoice.contactId, emailInvoice.id, emailVal).then(url => {		

				// Remove previous notification and show new one.
				store.removeNotification(notificationId)
				store.addNotification({
					title: "Email has been sent",
					message: "Sending invoice #" + emailInvoice.invoiceNumber + " to " + emailVal + ".",
					type: "success",
					container: "top-right", animationIn: ["animated", "slideInRight"], animationOut: ["animated", "slideOutRight"],
					dismiss: { duration: 3000, showIcon: true }
				});
			})
		}
	};


  	render() {
		let { emailInvoice, defaultEmail } = this.props;
		return (
	  		<form onSubmit={this.handleSubmit.bind(this)}>
				<div className="modal" role="dialog" id="email-invoice-modal">
					<div className="modal-dialog" role="document">
						<div className="modal-content">
							<div className="modal-header">
								{typeof emailInvoice.invoiceNumber != "undefined" ? 
								(<h5 className="modal-title">Email #{emailInvoice.invoiceNumber} invoice</h5>) :
								(<h5 className="modal-title">Email invoice</h5>)}
								
							</div>
							<div className="modal-body">
								<div className="form-group">
									{/* List Mandates */}
									<label htmlFor="mandateSelect">Email</label>
									<input type="email" name="email-invoice" defaultValue={defaultEmail} className="form-control" />
								</div>
							</div>
							<div className="modal-footer">
								<button type="button" ref={button => closeModalButton = button} className="btn btn-default float-left" data-dismiss="modal">Cancel</button>
								<button type="submit" className="btn btn-primary float-right px-5">Email invoice</button>
							</div>
						</div>
					</div>
				</div>
			</form>
		);
  	}
}

export default ((props, ref) => (
	<AuthContext.Consumer>{context => {
		if (context.currentUser.permissions.invoices) {
			return (<Details {...props} authContext={context} />)
		} else {
			return (<AccessDenied {...props}/>)
		}
	}}</AuthContext.Consumer>
));