import React, { Component } from 'react';
import Layout from '../../bricks/Layout'
import ApplicationService from '../../services/ApplicationService';
import { Link, withRouter } from 'react-router-dom'
import { useTable, usePagination } from 'react-table'
import { Modal } from 'react-bootstrap';
import { store } from 'react-notifications-component';
import { AuthContext } from '../../components/AuthContext';
import AccessDenied from '../../components/AccessDenied';
import { buildURI } from '../../components/CSVCore';
import { AlertHeading } from 'react-bootstrap/Alert';
var moment = require('moment');

class Applications extends Component {
	csvLink = React.createRef()
	csvLinkCheckedApplications = React.createRef()

	state = {
		response: '',
		applicationDisplayedModal: false,
		applicationDisplayed: {},
		completedApplications: [],
		checkedApplications: [],
		csvHeaderData: [],
		applicationsToExport: [],
		exportToCsvText: 'Export Applications',
		loadingModal: true,
		processingApplication: false,
        completedApplicationsTable: {
			query: {
				searchQuery: "",
				startDate: null,
				endDate: null,
			},
			pageIndex: 0,
			pageCount: 0,
			perPage: 20,
			isLoading: true,
			sort: {
				field: null,
				descending: false
			}
		}

	};
	
    // Breadcrumbs.
    breadcrumbs = [
        { title: 'Home', link: '/' }, 
        { title: 'Applications', link: '/applications', active: true },
    ];


    

	componentDidMount() {
        
	}



	



	pad = function(n, width, z) {
		z = z || '0';
		n = n + '';
		return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
	}


	render() {

		/* 
		*  We need access to the current user data
		*  this is so we can display content that
		*  the user has the correct permission to.
		*/
		let { authContext } = this.props;
		let permissions = (typeof authContext.currentUser.permissions !== "undefined") ? authContext.currentUser.permissions : {};
		// 
		return (
			<Layout match={this.props.match}>
				<div className="row">
					{permissions.applications && 
					<div className="col-12 col-sm-6 col-md-4 col-lg-4 mb-3">
						<div className="card">
							<div className="card-body bg-light test-blah-class">
								<h4 className="mb-4">Completed Applications</h4>
                                <p>All applications that have been completed.</p>
                                <br/>
								<Link to="/applications/completed-applications" className="btn btn-outline-secondary">View Completed Applications</Link>
							</div>
						</div>
					</div>
					}

					{permissions.applications && 
					<div className="col-12 col-sm-6 col-md-4 col-lg-4 mb-3">
						<div className="card">
							<div className="card-body bg-light">
								<h4 className="mb-4">Incomplete Applications</h4>
                                <p>Applications that have been started but not yet completed.</p>
                                <br/>
								<Link to="/applications/incomplete-applications" className="btn btn-outline-secondary">View Incomplete Applications</Link>
							</div>
						</div>
					</div>
					}

					{permissions.applications && 
					<div className="col-12 col-sm-6 col-md-4 col-lg-4 mb-3">
						<div className="card">
							<div className="card-body bg-light">
								<h4 className="mb-4">Export Applications</h4>
                                <p>Applications that are eligible to be exported. Applications that can be processed automatically will not appear here.</p>
								<Link to="/applications/export-applications" className="btn btn-outline-secondary">View Export Applications</Link>
							</div>
						</div>
					</div>
					}

					{permissions.applications && 
					<div className="col-12 col-sm-6 col-md-4 col-lg-4 mb-3">
						<div className="card">
							<div className="card-body bg-light">
								<h4 className="mb-4">Process Applications</h4>
                                <p>Applications that are eligible to be automatically processed. Exported applications will not appear here.</p>
								<Link to="/applications/process-applications" className="btn btn-outline-secondary">View Process Applications</Link>
							</div>
						</div>
					</div>
					}

					{permissions.applications && 
					<div className="col-12 col-sm-6 col-md-4 col-lg-4 mb-3">
						<div className="card">
							<div className="card-body bg-light">
								<h4 className="mb-4">Duplicate Applications</h4>
                                <p>Applications that have been attempted to process but the individual already exists within Dynamics.</p>
								<Link to="/applications/duplicate-applications" className="btn btn-outline-secondary">View Duplicate Applications</Link>
							</div>
						</div>
					</div>
					}

					{permissions.applications && 
					<div className="col-12 col-sm-6 col-md-4 col-lg-4 mb-3">
						<div className="card">
							<div className="card-body bg-light">
								<h4 className="mb-4">Discount Codes</h4>
                                <p>The discount codes that can be applied when applications are made.</p>
								<Link to="/applications/discount-codes" className="btn btn-outline-secondary">View Discount Codes</Link>
							</div>
						</div>
					</div>
					}

					{permissions.applications &&
						<div className="col-12 col-sm-6 col-md-4 col-lg-4 mb-3">
							<div className="card">
								<div className="card-body bg-light">
									<h4 className="mb-4">Country Discounts</h4>
									<p>Auto-applied discounts applied according to correspondence country.</p>
									<Link to="/applications/country-discounts" className="btn btn-outline-secondary">View Country Discounts</Link>
								</div>
							</div>
						</div>
					}

					{permissions.applications &&
						<div className="col-12 col-sm-6 col-md-4 col-lg-4 mb-3">
							<div className="card">
								<div className="card-body bg-light">
									<h4 className="mb-4">Stickerbook Discounts</h4>
									<p>Discount codes auto-applied from a Stickerbook referral</p>
									<Link to="/applications/stickerbook-discounts" className="btn btn-outline-secondary">View Stickerbook Discounts</Link>
								</div>
							</div>
						</div>
					}

					{permissions.applications && 
					<div className="col-12 col-sm-6 col-md-4 col-lg-4 mb-3">
						<div className="card">
							<div className="card-body bg-light">
								<h4 className="mb-4">Configuration</h4>
                                <p></p><br/>
								<Link to="/applications/configuration" className="btn btn-outline-secondary">View Configuration</Link>
							</div>
						</div>
					</div>
					}

				</div>
			</Layout>
		);
	}
}


/**
 * List all subjects covered.
 * 
 * @param {*} answers 
 */
function SubjectsCoveredList({ answers }) {
	if (typeof answers !== "undefined") {
		let getLabel = (key) => {
			let label = null;
			switch (key) {
				case "subject_covered_global_megatrends": label = "Global megatrends and their impact on sustainability"; break;
				case "subject_covered_sustainable_development": label = "Sustainable Development"; break;
				case "subject_covered_corporate_responsibility": label = "Corporate Responsibility"; break;
				case "subject_covered_natural_cycles": label = "Natural cycles, ecosystems, biodiversity and the impact of pollution."; break;
				case "subject_covered_tools_and_techniques": label = "Tools and techniques for assessing and managing environment, social, economic issues and improving sustainability performance of organisations, products and services, or new developments."; break;
				case "subject_covered_environment_and_sustainability": label = "Environment and sustainability principles and issues and associated Policy and Legislation (international, regional or national) suitably relevant."; break;
				default: label = null; break;
			}
			return label;
		}

		// Regexp to get all correct answers.
		let matches = Object.keys(answers).filter(function(pattern) {
			return (pattern.match(/^subject_/s) && answers[pattern] === "true") ? pattern : null;
		})

		// Check if have any valid subjects.
		if (matches.length > 0) {
			return (
				<div>
					<strong>Sustainability Criteria Selections:</strong><br />
					<ul className="pl-4">
						{matches.map(subject => (
							<li key={subject}>{getLabel(subject)}</li>
						))}
					</ul>
				</div>
			)
		}
	}

	return (<></>)
}


/**
 * Application table to display all applications.
 * 
 * @param {*}
 */
function ApplicationTable({ handleApplicationDisplayModal, propColumns, propData, checkedApplications, applicationsCheckboxHandler, gotoPage, pageIndex, pageCount, isLoading, sort, sortData, }) {

	// console.log('propData');
	// console.log(propData);
	// Define the table columns.
	const columns = React.useMemo(() => propColumns, [propColumns])
	
	// Define the data.
	const data = React.useMemo(() => propData, [propData])

	// Use the state and functions returned from useTable to build your UI
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow,
	} = useTable({
		columns,
		data,
		initialState: { pageIndex: pageIndex, pageSize: 20 },
	}, usePagination)
	

	/**
	 * Calculate and show the correct pages.
	 * @param {*} pIndex 
	 * @param {*} pCount 
	 */
	const numberedPages = (pIndex, pCount) => {
		let gCount = 3;
		let showPagesArr = [];

		// Loop through all pages and calculate which ones to show.
		for (let i = 0; i < pCount; i++) {
			if ((pIndex - gCount) < i && (gCount + pIndex) > i) {
				showPagesArr.push(i);
			}            
		}
		return showPagesArr;
	}
	
    
	return (
		<div className="table-responsive">
			<table className={"table table-striped"} {...getTableProps()}>
				<thead>
					{headerGroups.map(headerGroup => (
						<tr {...headerGroup.getHeaderGroupProps()}>
						{headerGroup.headers.map(column => (
							<th {...column.getHeaderProps()}>{column.render("Header")}</th>
						))}
					</tr>
					))}
				</thead>

				<tbody {...getTableBodyProps()}>
					{rows.map((row, i) => {
						prepareRow(row);
						return (
							<tr {...row.getRowProps()}>
								{row.cells.map(cell => {
									if (cell.column.id === 'price') {
										return (<td {...cell.getCellProps()}>
											&pound;{cell.render("Cell")}</td>)
									} else if (cell.column.id === "name") {
										return <td key={i} {...cell.getCellProps()}>{cell.row.original.firstName + ' ' + cell.row.original.lastName}</td>;
									} else if (cell.column.id === "exportedBy") {
										return <td key={i} {...cell.getCellProps()}>
											<Link to={"/users/edit-user/"+cell.row.original.exportedById}>{cell.row.original.exportedBy}</Link>
										</td>;
									} else if (cell.column.id === 'checkbox') {
										return (<td {...cell.getCellProps()}>
											<input type="checkbox" className="check-one" value={cell.row.original.id} checked={checkedApplications.includes(cell.row.original.id) ? true : false} onChange={applicationsCheckboxHandler}/></td>)
										} else if (cell.column.id === "view") {
										return (<td {...cell.getCellProps()}>
										<button key={i} onClick={handleApplicationDisplayModal} className="btn btn-primary btn-sm btn-view" application-id={cell.row.original.id}>View</button></td>)
									} else if (cell.column.id === "totalAmount") {
										return (<td {...cell.getCellProps()}>
										&pound;{cell.row.original.totalAmount.toFixed(2)}</td>)
									} else if (cell.column.id === 'exported') {
										let label = cell.row.original.exported;
										if (label === 1) {
										return (<td {...cell.getCellProps()}>
											<span className={"badge badge-success"}>Exported</span></td>)
										}
										return (<td {...cell.getCellProps()}>
											<span className={"badge badge-warning"}>Not Exported</span></td>)
									}
									else if (cell.column.id === 'processed') 
									{
										let label = cell.row.original.processed;
										if (label === 1) {
										return (<td {...cell.getCellProps()}>
											<span className={"badge badge-success"}>Processed</span></td>)
										}
										return (<td {...cell.getCellProps()}>
											<span className={"badge badge-warning"}>Not Processed</span></td>)
									} 
									else if (cell.column.id === 'applicationType') 
									{
										let applicationType = '(application)';
										if(typeof(cell.row.original.isUpgrade) != 'undefined' && cell.row.original.isUpgrade == 1)
										{
											applicationType = '(upgrade)';
										}

									return <td {...cell.getCellProps()}>{cell.render("Cell")} {applicationType}</td>;
									} else {
										return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
									}
								})}
							</tr>
						);
					})}
				</tbody>
			</table>
			<nav className="d-flex mt-4" aria-label="Page navigation">
				<ul className="pagination mx-auto">
					<li className="page-item">
						<button className="page-link" onClick={() => gotoPage(0)} disabled={(pageIndex === 0)}>&lt;&lt; First</button>
					</li>
					<li className="page-item">
						<button className="page-link" onClick={() => gotoPage(pageIndex-1)} disabled={(pageIndex === 0)}>&lt; Previous</button>
					</li>
					{numberedPages(pageIndex, pageCount).map((pageNum) => {
						return (
							<li className={"page-item " + (pageIndex === pageNum ? 'active' : '')} key={pageNum+1}>
								<button className="page-link" onClick={() => gotoPage(pageNum)}>{pageNum+1}</button>
							</li>
						)
					})}
					<li className="page-item">
						<button className="page-link" onClick={() => gotoPage(pageIndex+1)} disabled={(pageIndex+1 === pageCount)}>Next &gt;</button>
					</li>
					<li className="page-item">
						<button className="page-link" onClick={() => gotoPage(pageCount-1)} disabled={(pageIndex+1 === pageCount)}>Last &gt;&gt;</button>
					</li>
				</ul>
			</nav>
		</div>
	)
}

export default withRouter((props, ref) => (
	<AuthContext.Consumer>{context => {
		if (context.currentUser.permissions.applications) {
			return (<Applications {...props} authContext={context} />)
		} else {
			return (<AccessDenied {...props}/>)
		}
	}}</AuthContext.Consumer>
));