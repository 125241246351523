import React, { Component } from 'react';
import MemberService from '../../../services/MemberService'
import moment from "moment"
import { ViewContext } from "./ViewContext";

class MembershipTab extends Component {

	state = {
		// membershipDetails: {
		// 	cpdDeclaration: false,
		// 	codeOfConductDeclaration: false
		// }
	}

	handleMembershipFormSubmit = (e) => {
		e.preventDefault();
		let submitButton = e.target.querySelector('button[type="submit"]');
		let { memberId } = this.props;
		
		// Saving feedback.
		submitButton.innerHTML = "Saving..."; submitButton.disabled = true;
		
		// Save the data.
		MemberService.updateMembershipInfo(memberId, this.props.membershipDetails).then((response) => {
			submitButton.innerHTML = "Save"; submitButton.disabled = false;

		});
	}


  	render() {
		let { membershipDetails, memberDetails } = this.props;

		// Determine should show the declarations.
		let showDeclarations = false
		if (typeof memberDetails.membershipType !== "undefined" && memberDetails.membershipType !== "") {
			if (
				!(memberDetails.membershipType.toLowerCase()).includes("student") &&
				!(memberDetails.membershipType.toLowerCase()).includes("access") &&
				!(memberDetails.membershipType.toLowerCase()).includes("retired") &&
				!(memberDetails.membershipType.toLowerCase()).includes("affiliate")
			) {
				showDeclarations = true
			}
		}

		return (
			<ViewContext.Consumer>
			{context => { 
				let { memberDetails, loadingDetails } = context;

				if (loadingDetails) {
					return (
						<div>
							<div className="alert alert-info" role="alert">
								<h3>Loading</h3>
								<span>Loading membership data, please wait...</span>
							</div>
						</div>
					)
				}
				
				if (typeof memberDetails.membershipNumber !== "undefined" && memberDetails.membershipNumber != null) {
					return (
						<div>
							<table className="table table-sm mb-5">
								<tbody>
									<tr>	
										<td><strong>Membership Number</strong></td>
										<td>{memberDetails.membershipNumber}</td>
									</tr>
	
									<tr>
										<td><strong>Membership Type</strong></td>
										<td>{memberDetails.membershipType}</td>
									</tr>
	
									<tr>
										<td><strong>Start Date</strong></td>
										<td>{memberDetails.rawStartDate && moment(memberDetails.rawStartDate).format("DD/MM/YYYY")}</td>
									</tr>
	
									<tr>
										<td><strong>Renewal Date</strong></td>
										<td>{moment(memberDetails.rawRenewalDate).format("DD/MM/YYYY")}</td>
									</tr>
	
									<tr>
										<td><strong>Payment Status</strong></td>
										<td>{memberDetails.paymentStatus}</td>
									</tr>
								</tbody>
							</table>
	
							{/* Declaration input. */}
							{showDeclarations ? 
								<div>
									<h3>Declarations</h3>
	
									<form className="member-address" onSubmit={this.handleMembershipFormSubmit}>
										<div className="custom-control custom-switch mb-1">
											<input id="cpdDeclaration" name="cpdDeclaration" className="custom-control-input" type="checkbox"
												checked={ typeof membershipDetails.cpdDeclaration !== "undefined" && membershipDetails.cpdDeclaration ? true : false } 
												onChange={context.handleMembershipChanges} />
											<label className="custom-control-label" htmlFor="cpdDeclaration">CPD</label>
										</div>
																
										<div className="custom-control custom-switch mb-1">
											<input id="codeOfConductDeclaration" name="codeOfConductDeclaration" className="custom-control-input" type="checkbox"
												checked={ typeof membershipDetails.codeOfConductDeclaration !== "undefined" && membershipDetails.codeOfConductDeclaration ? true : false } 
												onChange={context.handleMembershipChanges} />
											<label className="custom-control-label" htmlFor="codeOfConductDeclaration">Code of Conduct</label>
										</div>
	
	
										<button type="submit" className="btn-outline-primary btn px-5 mt-3">Save</button>
									</form>				
								</div>
							: <></>}
						</div>
					)
				} else {
					return (
						<div>
							<div className="alert alert-warning" role="alert">
								<h3>Non-Member notice</h3>
								<span>This is a non-member individual, so there is no record of this individual's membership.</span>
							</div>
						</div>
					)
				}
				
			}}
			</ViewContext.Consumer>
		)
	}
}

export default MembershipTab;
