import React, { Component } from 'react';
import Header from './Header';
import Sidenav from './Sidenav';
import { Link } from 'react-router-dom'

class Layout extends Component {
    render() { 

        // If we have breadcrumbs show them.
        let breadcrumbs;
        if (typeof this.props.breadcrumbs != "undefined") {
            breadcrumbs = <nav aria-label="breadcrumb">
                <ul className="breadcrumb small">
                    {this.props.breadcrumbs.map((value, index) => {
                        if (typeof value.link !== "undefined" && value.link !== "" && value.link !== null ) {
                            return  <li key={index} className={"breadcrumb-item " + (value.active ? 'active' : null)}>
                                        <Link to={value.link}>{value.title}</Link>
                                    </li>
                        } else {
                            return <li key={index} className={"breadcrumb-item " + (value.active ? 'active' : null)}>
                                        <span>{value.title}</span>
                                    </li>
                        }
                    })}
                </ul>
            </nav>
        }

        return (
            <div>
                <div className="main-section">
                    <Sidenav match={this.props.match} />
                    <div className="main-content">
                        <Header/>

                        <div className="main" id="main">
                            {/* Show breadcrumbs */}
                            {breadcrumbs}

                            {this.props.children}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
 
export default Layout;