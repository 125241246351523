import React, { useState } from 'react';
import Routes from './Routes';
import AuthContextProvider from './components/AuthContext';
import ReactNotification from 'react-notifications-component'
import CacheBuster from './CacheBuster'
import Holding from './pages/Holding'
import { Provider, ErrorBoundary } from '@rollbar/react';

const rollbarConfig = {
	accessToken: `${process.env.REACT_APP_ROLLBAR_POST_CLIENT_ITEM}`,
	environment: `${process.env.REACT_APP_ROLLBAR_ENVIRONMENT}`,
};

class AppClass extends React.Component {
	render() {
		let { isAuthenticated, userHasAuthenticated } = this.props;
		let showHoldingPage = false;

		if (showHoldingPage) {
			return (<Holding />)
		}
		
		
		return (
			<CacheBuster>
				{({ loading, isLatestVersion, refreshCacheAndReload }) => {
					if (loading) return null;
					if (!loading && !isLatestVersion) {
						// You can decide how and when you want to force reload
						refreshCacheAndReload();
					}
					
					return (
						<AuthContextProvider>
							<div className="App">
								<ReactNotification />
								<Routes appProps={{ isAuthenticated, userHasAuthenticated }} />
							</div>
						</AuthContextProvider>
					);
				}}
			</CacheBuster>
		)			 
	}
}

function App() {
	// Create a state we can use throughout the application tree.
	const [isAuthenticated, userHasAuthenticated] = useState(false);
	
	return (
		<Provider config={rollbarConfig}>
			<ErrorBoundary>
				<AppClass isAuthenticated={isAuthenticated} userHasAuthenticated={userHasAuthenticated} />
			</ErrorBoundary>
		</Provider>
	)
}

export default App;
