import React, { Component } from 'react';
import Layout from '../../bricks/Layout'
import { Link, withRouter } from 'react-router-dom'
import { AuthContext } from '../../components/AuthContext';
import AccessDenied from '../../components/AccessDenied';
import PaymentService from '../../services/PaymentService';
import { buildURI } from '../../components/CSVCore';
import { store } from 'react-notifications-component';
import 'react-datepicker/dist/react-datepicker.css';
var moment = require('moment');

class ExportStripeToSage extends Component {
	state = {
		response: '',
        allPayments: [],
        allTotals: {},
		paymentTable: {
            paymentMethod: "stripe",
			query: '',
			pageIndex: 0,
			pageCount: 0,
			perPage: 30,
			isLoading: true,
			lastExported: null,
			sort: {
				field: null,
				descending: false
			},
			date: moment().subtract(1, 'days').toDate()
        },
	};

	// Breadcrumbs.
	breadcrumbs = [
		{ title: 'Home', link: '/' }, 
		{ title: 'Payments', link: '/payments' },
		{ title: 'Export Stripe Transactions', link: '/payments/export-stripe-sage', active: true }
	];

	componentDidMount() {
		let { paymentTable } = this.state

        // Load all emails.
        PaymentService.getAllPayments(paymentTable).then((response) => {
			this.setState({ 
				allPayments: response.allPayments,
				allTotals: response.allTotals,
				paymentTable: {
					...paymentTable,
					isLoading: false,
					pageCount: response.totalPages,
					lastExported: response.lastExported,
					endDate: response.endDate,
					pageIndex: response.pageIndex,
					perPage: response.perPage
				}
			})
		});
	}


	/**
	 * Export all payments recently made.
	 */
	exportAllPayments = async (e) => {
        let { paymentTable } = this.state
        
        // Stop the click propagation
        e.preventDefault();

		// Show feedback.
		const buttonClicked = e.target;
		buttonClicked.innerHTML = "Exporting payments to Sage..."; buttonClicked.disabled = true;

		let response = await PaymentService.exportAllPayments({
            paymentMethod: paymentTable.paymentMethod
        }); 

		// Check if we need to display an error message
		if (typeof response.errors !== "undefined" && response.errors.length !== 0) {

			// Yes, loop through and display all the errors.
			for (let i = 0; i < response.errors.length; i++) {
				store.addNotification({
					title: "Error!",
					message: response.errors[i],
					type: "danger",
					container: "top-right",
					animationIn: ["animated", "slideInRight"],
					animationOut: ["animated", "slideOutRight"],
					dismiss: { duration: 3000 }
				});
			}
			
		} else {
			
			// Export to CSV
			buildURI(response.allPayments, false, response.headerData, ",", "", "IEMA-Stripe-Payments-" + moment().format('YYYY-MM-DD') + ".csv");

			store.addNotification({
				title: "Success!",
				message: "Successfully exported Stripe payments from " + moment(this.state.paymentTable.lastExported).format("DD/MM/YYYY") + ".",
				type: "success",
				container: "top-right",
				animationIn: ["animated", "slideInRight"],
				animationOut: ["animated", "slideOutRight"],
				dismiss: { duration: 3000 }
			});
		}


		buttonClicked.innerHTML = "Export Transactions to Sage"; buttonClicked.disabled = false;

		
		return true;
	}

	render() {
        let { paymentTable, allPayments, allTotals } = this.state;

		return (
			<Layout breadcrumbs={this.breadcrumbs} match={this.props.match}>
				<div className="page-title mb-4 clearfix">
					<div className="float-left">
						<h1>Export Stripe Transactions to Sage</h1>
						<p>Showing Stripe transactions {moment(paymentTable.endDate).format("DD/MM/YYYY") === moment(paymentTable.lastExported).format("DD/MM/YYYY")
							? "on " + moment(paymentTable.endDate).format("DD/MM/YYYY")
							: (moment(paymentTable.endDate).format("DD/MM/YYYY") === moment().format("DD/MM/YYYY")
        					? "since last import on " + moment(paymentTable.lastExported).format("DD/MM/YYYY")
							: "between " + moment(paymentTable.lastExported).format("DD/MM/YYYY") + " and " + moment(paymentTable.endDate).format("DD/MM/YYYY")
      					)}.</p>
					</div>

					<div className="float-right">
						<button 
							className="btn btn-primary mt-4"
							onClick={this.exportAllPayments}
						>Export Transactions to Sage</button>
					</div>
				</div>

				<div>
					{Object.keys(allPayments).length > 0 ? 
						Object.keys(allPayments).map((key) => {
							let payments = allPayments[key]
							return (
								<div className="mb-5" key={"payments-group-" + key}>
									<h4 className="mb-3">Transactions for <a target="_blank" rel="noopener noreferrer" href={"https://dashboard.stripe.com/payouts/"+key}>{key}</a></h4>
									<div className="table-responsive position-relative">
										<table className="table table-striped">
											<thead>
												<tr>
													<th colSpan="1" style={{width: "10%"}}>Nominal code</th>
													<th colSpan="1" style={{width: "15%"}}>Payment Method</th>
													<th colSpan="1" style={{width: "45%"}}>Category</th>
													<th colSpan="1" style={{width: "45%"}}>Date</th>
													<th colSpan="1" className="text-right" style={{width: "10%"}}>NET</th>
													<th colSpan="1" className="text-right" style={{width: "10%"}}>VAT</th>
													<th colSpan="1" className="text-right" style={{width: "10%"}}>Total</th>
													<th colSpan="1" style={{width: "10%"}}></th>
												</tr>
											</thead>
											<tbody>
												{payments.map((payment, keyX) => (
													<tr key={"payments-table-" + key + "-" + keyX}>
														<td>{payment.nominalCode}</td>
														<td>{payment.method}</td>
														<td>{payment.category}</td>
														<td>{moment(payment.transactionDate).format('DD/MM/YYYY')}</td>
														<td className="text-right">&pound;{Number(payment.net).toFixed(2)}</td>
														<td className="text-right">&pound;{Number(payment.vat).toFixed(2)}</td>
														<td className="text-right">&pound;{Number(payment.total).toFixed(2)}</td>
														<td>
															{(payment.applicationId !== null) ? 
																(<Link to={"/applications/view/" + payment.applicationId} className={"btn btn-secondary btn-sm btn-view float-right"}><span>Application #{payment.applicationId}</span></Link>) : 
																(<Link to={"/invoices/" + payment.invoiceId} className={"btn btn-secondary btn-sm btn-view float-right"}><span>Invoice #{(typeof payment.invoiceInfo !== "undefined") ? payment.invoiceInfo.invoiceNumber : ""}</span></Link>)
															}
														</td>
													</tr>
												))}

                                                {/* Add the totals. */}
                                                <tr className="table-row-totals">
                                                    <td className="text-right" colSpan="4">Totals:</td>
                                                    <td className="text-right"><strong>&pound;{Number(allTotals[key].net).toFixed(2)}</strong></td>
                                                    <td className="text-right"><strong>&pound;{Number(allTotals[key].vat).toFixed(2)}</strong></td>
                                                    <td className="text-right"><strong>&pound;{Number(allTotals[key].total).toFixed(2)}</strong></td>
                                                    <td></td>
                                                </tr>
											</tbody>
										</table>
									</div>
								</div>
							)
	
						}) : (paymentTable.lastExported !== null) ? 
								(<div className="alert alert-warning" role="alert">
									<span>There are no payments available to export from {moment(paymentTable.lastExported).format("DD/MM/YYYY")}.</span>
								</div>) : 
								(<div className="alert alert-info" role="alert">
									<h3>Loading</h3>
									<span>Loading payments information, please wait...</span>
								</div>)
							
							
					}
					
				</div>
			</Layout>
		);
	}
}


export default withRouter((props, ref) => (
	<AuthContext.Consumer>{context => {
		if (context.currentUser.permissions.payments) {
			return (<ExportStripeToSage {...props} authContext={context} />)
		} else {
			return (<AccessDenied {...props}/>)
		}
	}}</AuthContext.Consumer>
));