import React, { Component } from 'react';
import Layout from './../../bricks/Layout'
import { withRouter } from 'react-router-dom'
import { AuthContext } from '../../components/AuthContext';
import AccessDenied from '../../components/AccessDenied';
import { store } from 'react-notifications-component';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import SettingsService from '../../services/SettingsService.js'
import ApplicationService from '../../services/ApplicationService.js'
// var moment = require('moment');

  

class Settings extends Component {
	state = {
        loading: true,
        settings: {
            sageLastExported: new Date(),
            bacsMinimumDate: new Date(),
			currentYear:''
		},
		priceLists: []
	};

	// Breadcrumbs.
	breadcrumbs = [
		{ title: 'Home', link: '/' }, 
		{ title: 'Settings', link: '/settings', active: true },
	];

	componentDidMount() {
        // Get the settings info.

		console.log('DID MOUNT: get settings');

        SettingsService.getInfo().then((response) => {
			
			console.log('response',response);
			// Transform the sage last export in to a date object.
			response.sageLastExported = (response.sageLastExported != null) ? new Date(response.sageLastExported) : new Date()
			response.sageEndDate = (response.sageEndDate != null) ? new Date(response.sageEndDate) : new Date()
			response.sageStripeLastExported = (response.sageStripeLastExported != null) ? new Date(response.sageStripeLastExported) : new Date()
			response.sageStripeEndDate = (response.sageStripeEndDate != null) ? new Date(response.sageStripeEndDate) : new Date()
			response.sageGoCardlessLastExported = (response.sageGoCardlessLastExported != null) ? new Date(response.sageGoCardlessLastExported) : new Date()
			response.sageGoCardlessEndDate = (response.sageGoCardlessEndDate != null) ? new Date(response.sageGoCardlessEndDate) : new Date()
			response.bacsMinimumDate = (response.bacsMinimumDate != null) ? new Date(response.bacsMinimumDate) : new Date()

			// Show the data.

			//console.log('setState',response);

			this.setState({ settings: response, loading: false })
		});
		

		// Get price lists.
		ApplicationService.getPriceLists().then((priceLists) => {
			this.setState({ priceLists: priceLists })
		});
	}

	/**
	 * On input change for the details.
	 * @param {*} e 
	 */
	handleChange = e => {
		let { settings } = this.state;
		settings[e.target.name] = e.target.value
		this.setState({ settings: settings })
	};

	handleYearChange = (event) => {
		const selectedYear = parseInt(event.target.value, 10);
		this.setState({
		  settings: {
			...this.state.settings,
			currentYear: selectedYear,
		  },
		});
	};



	/**
	 * Handle form submit.
	 * @param {*} e 
	 */
	handleFormSubmit = (e) => {
		const submitButton = e.target.querySelector('button[type="submit"]');
		
		e.preventDefault();
		
		let { settings } = this.state;
		
		// Saving feedback.
		submitButton.innerHTML = "Saving settings..."; submitButton.disabled = true;
		
		// Save the data.
		SettingsService.updateSettings(settings).then((response) => {
			submitButton.innerHTML = "Save settings"; submitButton.disabled = false;

			if (response.updated) {
				store.addNotification({
					title: "Success!",
					message: "Settings have been saved.",
					type: "success",
					container: "top-right",
					animationIn: ["animated", "slideInRight"],
					animationOut: ["animated", "slideOutRight"],
					dismiss: { duration: 5000 }
				});
	
				// Show the data.
				this.setState({ memberDetails: response.memberInfo })
			} else {
				// Display all the errors we have collected 
				for (let i = 0; i < response.errorMessage.length; i++) {
					const message = response.errorMessage[i];
					store.addNotification({
						title: "Error",
						message: message,
						type: "danger",
						container: "top-right",
						animationIn: ["animated", "slideInRight"],
						animationOut: ["animated", "slideOutRight"],
						dismiss: { duration: 7000 }
					});
					
				}
			}
					
		});
	}

	render() {
		let { settings, loading, priceLists } = this.state;

		// Generate an array of years from 2020 to 2025
		const startYear = 2020;
		const endYear = 2025;
		const years = Array.from({ length: endYear - startYear + 1 }, (_, index) => startYear + index);
		
		if (loading === false) {
			return (
				<Layout breadcrumbs={this.breadcrumbs} match={this.props.match}>
					<div className="page-title mb-4 clearfix">
						<div className="float-left">
							<h1>Settings</h1>
						</div>
					</div>
					
					<div>
						<form className="member-address" onSubmit={this.handleFormSubmit}>

							{/* Sage last exported. */}
							{/* <div className="row">
								<div className="col-md-6 col-lg-5">
									<div className="form-group">
										<label>Sage Last Exported</label><br />
										<DatePicker className="form-control" dateFormat="dd/MM/yyyy" value={(settings.sageLastExported !== null ) ?
											settings.sageLastExported : "" } selected={settings.sageLastExported}
											onChange={date => { this.setState({ settings: { ...settings, sageLastExported: date } }) }} />
										<small className="form-text text-muted">Change the Sage last exported date to export previous dates.</small>
									</div>
								</div>
							</div> */}

							{/* Stripe Sage last exported. */}
							<div className="row">
								<div className="col-md-6 col-lg-5">
									<div className="form-group">
										<label>Sage Stripe Last Exported</label><br />
										<DatePicker className="form-control" dateFormat="dd/MM/yyyy" value={(settings.sageStripeLastExported !== null ) ?
											settings.sageStripeLastExported : "" } selected={settings.sageStripeLastExported}
											onChange={date => { this.setState({ settings: { ...settings, sageStripeLastExported: date } }) }} />
										<small className="form-text text-muted">Change the Stripe last exported date to export previous dates.</small>
									</div>
								</div>
								<div className="col-md-6 col-lg-5">
									<div className="form-group">
										<label>Sage Stripe End Date</label><br />
										<DatePicker className="form-control" dateFormat="dd/MM/yyyy" value={(settings.sageStripeEndDate !== null ) ?
											settings.sageStripeEndDate : "" } selected={settings.sageStripeEndDate}
											onChange={date => { this.setState({ settings: { ...settings, sageStripeEndDate: date } }) }} />
										<small className="form-text text-muted">Payments after this date will NOT be included in the export.</small>
									</div>
								</div>
							</div>

							{/* GoCardless Sage last exported. */}
							<div className="row">
								<div className="col-md-6 col-lg-5">
									<div className="form-group">
										<label>Sage GoCardless Last Exported</label><br />
										<DatePicker className="form-control" dateFormat="dd/MM/yyyy" value={(settings.sageGoCardlessLastExported !== null ) ?
											settings.sageGoCardlessLastExported : "" } selected={settings.sageGoCardlessLastExported}
											onChange={date => { this.setState({ settings: { ...settings, sageGoCardlessLastExported: date } }) }} />
										<small className="form-text text-muted">Change the GoCardless last exported date to export previous dates.</small>
									</div>
								</div>
								<div className="col-md-6 col-lg-5">
									<div className="form-group">
										<label>Sage GoCardless End Date</label><br />
										<DatePicker className="form-control" dateFormat="dd/MM/yyyy" value={(settings.sageGoCardlessEndDate !== null ) ?
											settings.sageGoCardlessEndDate : "" } selected={settings.sageGoCardlessEndDate}
											onChange={date => { this.setState({ settings: { ...settings, sageGoCardlessEndDate: date } }) }} />
										<small className="form-text text-muted">Payments after this date will NOT be included in the export.</small>
									</div>
								</div>
							</div>

							{/* BACS minimum date. */}
							<div className="row">
								<div className="col-md-6 col-lg-5">
									<div className="form-group">
										<label>BACS minimum date</label><br />
										<DatePicker className="form-control" dateFormat="dd/MM/yyyy" value={(settings.bacsMinimumDate !== null ) ?
											settings.bacsMinimumDate : "" } selected={settings.bacsMinimumDate}
											onChange={date => { this.setState({ settings: { ...settings, bacsMinimumDate: date } }) }} />
									</div>
								</div>
								{/* <div className="col-md-6 col-lg-5">
									<div className="form-group">
										<label>BACS End Date</label><br />
										<DatePicker className="form-control" dateFormat="dd/MM/yyyy" value={(settings.bacsEndDate !== null ) ?
											settings.bacsEndDate : "" } selected={settings.bacsEndDate}
											onChange={date => { this.setState({ settings: { ...settings, bacsEndDate: date } }) }} />
										<small className="form-text text-muted">Payments after this date will NOT be included in the export.</small>
									</div>
								</div> */}
							</div>

							{/* Current year */}
							{/* <div className="row">
								<div className="col-md-6 col-lg-5">
									<div className="form-group">
										<label>Current year</label><br />
										<input type="text" className="form-control" defaultValue={(settings.currentYear !== null ) ?
											settings.currentYear : "" } />
									</div>
								</div>
							</div> */}

							<div className="row">
								<div className="col-md-6 col-lg-5">
									<div className="form-group">
										<label>Set 'current' price list year (for testing purposes)</label><br />
										<select
										className="form-control"
										value={settings.currentYear !== null ? settings.currentYear : ''}
										onChange={this.handleYearChange}
										>
										<option value="">Use Current Year</option>
										{years.map((year) => (
											<option key={year} value={year}>
											{year}
											</option>
										))}
										</select>
									</div>
								</div>
							</div>
						
							<button type="submit" className="btn btn-primary mt-2 px-5">Save settings</button>

						</form>
					</div>
				</Layout>
			);

		} else {
			return (
				<Layout breadcrumbs={this.breadcrumbs} match={this.props.match}>
					<h1 className="page-title">Settings</h1>
					<p>Loading...</p>
				</Layout>
			);

		}
	}
}


export default withRouter((props, ref) => (
	<AuthContext.Consumer>{context => {
		if (context.currentUser.permissions.settings) {
			return (<Settings {...props} authContext={context} />)
		} else {
			return (<AccessDenied {...props}/>)
		}
	}}</AuthContext.Consumer>
));