import React, { Component } from 'react';
import Layout from './../../bricks/Layout'
import { Link } from 'react-router-dom'
import { store } from 'react-notifications-component';
import PaymentService from '../../services/PaymentService'
import { AuthContext } from '../../components/AuthContext';
import AccessDenied from '../../components/AccessDenied';


class DirectDebits extends Component {
	state = {
		response: ''
	};

	componentDidMount() {
	}


	// Breadcrumbs.
	breadcrumbs = [
		{ title: 'Home', link: '/' }, 
		{ title: 'Direct Debits', link: '/direct-debits', active: true },
	];



	/**
	 * Direct debits - daily script
	 * Get any invoices due in 10 days and pay using GoCardless
	 */
	processDirectDebitInvoices(e) {
		let buttonClicked = e.target
		buttonClicked.innerHTML = "Processing..."; buttonClicked.disabled = true;

		PaymentService.processDirectDebitInvoices().then((response) => {
			buttonClicked.innerHTML = "Process"; buttonClicked.disabled = false;

			store.addNotification({
				title: "Success!",
				message: "Invoices due in 10 days have been processed.",
				type: "success",
				container: "top-right",
				animationIn: ["animated", "slideInRight"],
				animationOut: ["animated", "slideOutRight"],
				dismiss: { duration: 10000 }
			});
		});
	}



	/**
	 * Link all members to GoCardless.
	 */
	goCardlessCreateCustomerReference(e) {
		let buttonClicked = e.target
		buttonClicked.innerHTML = "Creating references..."; buttonClicked.disabled = true;

		PaymentService.goCardlessCreateCustomerReference().then((response) => {
			buttonClicked.innerHTML = "Create references"; buttonClicked.disabled = false;
		});
	}

	render() {
		// 
		return (
			<Layout match={this.props.match} breadcrumbs={this.breadcrumbs}>
				<h1 className="page-title">Direct Debits</h1>

				<div className="row">
					<div className="col-12 col-sm-6 col-md-4 col-lg-4">
						<div className="card">
							<div className="card-body bg-light">
								<h4 className="mb-4">Payments</h4>
								<Link className="btn btn-outline-secondary" to={"/direct-debits/payments"}>View Payments</Link>
							</div>
						</div>
					</div>

					<div className="col-12 col-sm-6 col-md-4 col-lg-4">
						<div className="card">
							<div className="card-body bg-light">
								<h4 className="mb-4">Mandates</h4>
								<Link className="btn btn-outline-secondary" to={"/direct-debits/mandates"}>View Mandates</Link>
							</div>
						</div>
					</div>

					<div className="col-12 col-sm-6 col-md-4 col-lg-4">
						<div className="card">
							<div className="card-body bg-light">
								<h4 className="mb-4">Bank Accounts</h4>
								<Link className="btn btn-outline-secondary" to={"/direct-debits/bank-accounts"}>View Bank Accounts</Link>
							</div>
						</div>
					</div>
				</div>

				<h3 className="mt-4 mb-3">Debugging</h3>
				<div className="row">				
					<div className="col-12 col-sm-6 col-md-4 col-lg-4">
						<div className="card">
							<div className="card-body bg-light">
								<h4 className="mb-4">Daily script</h4>
								<p>Process the due invoices for direct debits</p>
								<button className="btn btn-outline-secondary" onClick={this.processDirectDebitInvoices}>Process</button>
							</div>
						</div>
					</div>
							
					<div className="col-12 col-sm-6 col-md-4 col-lg-4">
						<div className="card">
							<div className="card-body bg-light">
								<h4 className="mb-4">GoCardless Reference</h4>
								<p>Link all members to GoCardless.</p>
								<button className="btn btn-outline-secondary" onClick={this.goCardlessCreateCustomerReference}>Create references</button>
							</div>
						</div>
					</div>
				</div>

			</Layout>
		);
	}
}

export default ((props, ref) => (
	<AuthContext.Consumer>{context => {
		if (context.currentUser.permissions.directDebits) {
			return (<DirectDebits {...props} authContext={context} />)
		} else {
			return (<AccessDenied {...props}/>)
		}
	}}</AuthContext.Consumer>
));