import React, { Component } from 'react';
import Layout from './../../bricks/Layout'
import { useTable } from 'react-table'
import UserService from '../../services/UserService'
import { Link } from 'react-router-dom'
import { AuthContext } from '../../components/AuthContext';
import AccessDenied from '../../components/AccessDenied';

class Users extends Component {
	state = {
		response: '',
		isPermitted: null,
		allUsers: []
	};

	// Breadcrumbs.
	breadcrumbs = [
		{ title: 'Home', link: '/' }, 
		{ title: 'Users', link: '/users', active: true }
	];

	componentDidMount() {
        // Load all users.
        UserService.getAllUsers().then((users) => {
            this.setState({ allUsers: users })
		});
    }

	// Set the table columns.
	columns = [
		{ Header: 'Name', accessor: 'name' },
		{ Header: 'Email', accessor: 'email' },
		{ Header: 'Group', accessor: 'permissions' },
		{ Header: '', accessor: 'view' }
	];
	
	
	render() {
		return (
			<div>
				<Layout breadcrumbs={this.breadcrumbs} match={this.props.match}>
					<div>
						<div className="form-group float-right">
							<AddUser currentUserId={this.props.authContext.currentUser} users={this.state.allUsers} className={"btn btn-primary"} />
						</div>
						<h1 className="page-title">Users</h1>
					</div>

					<div className="table-responsive">
						<UsersTable propData={this.state.allUsers} propColumns={this.columns} />
					</div>						
				</Layout>
			</div>
		);
	}
}

function UsersTable({ propColumns, propData }) {
	// Define the table columns.
	const columns = React.useMemo(() => propColumns, [propColumns])
	
	// Define the data.
	const data = React.useMemo(() => propData, [propData])

	// Use the state and functions returned from useTable to build your UI
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow,
	} = useTable({
		columns,
		data,
	})

	return (
		
		<div className="table-responsive">
			<table className={"table table-striped"} {...getTableProps()}>
				<thead>
					{headerGroups.map(headerGroup => (
					<tr {...headerGroup.getHeaderGroupProps()}>
						{headerGroup.headers.map(column => (
							<th {...column.getHeaderProps()}>{column.render("Header")}</th>
						))}
					</tr>
					))}
				</thead>
				<tbody {...getTableBodyProps()}>
					{rows.map((row, i) => {
						prepareRow(row);
						return (
							<tr {...row.getRowProps()}>
								{row.cells.map(cell => {
									if (cell.column.id === 'name') {
										return <td {...cell.getCellProps()}>{cell.row.original.firstname + ' ' + cell.row.original.lastname}</td>;
									} else if (cell.column.id === 'view') {
										return (<td {...cell.getCellProps()}>
											<Link to={"/users/edit-user/" + cell.row.original.id} className={"btn btn-primary btn-sm btn-view"}><span>Edit</span></Link></td>)
									} else if (cell.column.id === 'permissions') {
										if (JSON.parse(cell.row.original.permissions).admin === true) {
											return (<td {...cell.getCellProps()}>
											<span>Admin</span></td>)
										} else {
											return (<td {...cell.getCellProps()}>
											<span>User</span></td>)
										}
									} else {
										return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
									}
								})}
							</tr>
						);
					})}
				</tbody>
			</table>
		</div>
	)
}

function AddUser() {
			
	return (<Link to={"/users/add-user"} className={"btn btn-primary"}><span>Add User</span></Link>)

}

export default ((props, ref) => (
	<AuthContext.Consumer>{context => {
		if (context.currentUser.permissions.admin) {
			return (<Users {...props} authContext={context} />)
		} else {
			return (<AccessDenied {...props}/>)
		}
	}}</AuthContext.Consumer>
));