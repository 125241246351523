import React, { Component } from 'react'


class Holding extends Component {
    state = {
    }

    render() {
		return (
            <>
                <div className="login-wrapper">
                    <div className="login-section">
                        <div className="login-form">
                            <div className="text-left">
                                <div className="company-logo mb-4">
                                    <img src={'/assets/logo.svg'} alt="IEMA Logo"/>
                                </div>
                                <h3>Website Maintenance</h3>
                                <p>Our website is currently undergoing maintenance and will be available on Monday, 3rd of August.</p>
                                <p>We apologise for any incovenience this may have caused.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default Holding