import React, { Component } from 'react';
import Layout from '../../bricks/Layout'
import { withRouter } from 'react-router-dom'
import { AuthContext } from '../../components/AuthContext';
import ApplicationService from '../../services/ApplicationService';
import AccessDenied from '../../components/AccessDenied';
import Select from 'react-select'
import DatePicker from "react-datepicker";
import { store } from 'react-notifications-component';
import moment from "moment"
import { buildURI } from '../../components/CSVCore';
import { Typeahead, withAsync } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

const AsyncTypeahead = withAsync(Typeahead);

class EditDiscountCodes extends Component {
	state = {
        isLoadingTypeahead: false,
        overrideSearchProducts: [],

        discountId: null,
        loading: true,
        breadcrumbs: [],
        formTypes: [],
        data: {
            code: "",
            type: "discount",
            discountValue: 0,
            discountType: "fixed",
            fromDate: moment().toDate(),
            expiryDate: null, 
            usage: 0,
            enabled: 1,
            maxUses: 0,
            applications: []
        },
        analyticsData: {
            discount: null,
            usage: 0,
            applications: [],
            loading: true
        },
        openedTab: "details",
        defaultApplications: null,
        applicationPrices: {},
        applicationProducts: {}
    };

	constructor(props) {
		super(props);
		this.onClickTabButton = this.onClickTabButton.bind(this);
		this.downloadAllAnalyticData = this.downloadAllAnalyticData.bind(this);
        this.handleProductSearch = this.handleProductSearch.bind(this);
	}
    
	componentDidMount() {
        let discountId = this.props.match.params.discountId
		let tabSlug = this.props.match.params.tabSlug
        
        // Set default states
        this.setState({ 
            openedTab: (typeof tabSlug != "undefined") ? tabSlug : this.state.openedTab,
            discountId: discountId,
            breadcrumbs: [
                { title: 'Home', link: '/' }, 
                { title: 'Applications', link: '/applications'},
                { title: 'Discount Codes', link: '/applications/discount-codes'},
                { title: 'Edit Discount Code', link: '/applications/discount-codes/edit/' + discountId, active: true }
            ]
        })

        // Get the info.
        ApplicationService.getDiscountById(discountId).then((response) => {
            let data = {
                code: response.code,
                type: response.type,
                enabled: response.enabled,
                discountValue: response.discountValue,
                discountType: response.discountType,
                fromDate: (response.fromDate !== null) ? moment(response.fromDate).toDate() : moment().toDate(),
                expiryDate: (response.expiryDate !== null) ? moment(response.expiryDate).toDate() : null,
                maxUses: response.maxUses,
                applications: response.applications,
                usage: response.usage
            }
            
            this.setState({ 
                data: data, 
                applicationProducts: response.applicationProducts,
                applicationPrices: response.applicationPrices,
                defaultApplications: response.applications,
                loading: false 
            })
        });


        // Load the form types.
        ApplicationService.getAllFormTypes().then((response) => {
            let formTypes = [];

            // Sort out the form types.
            response.formTypes.map(type => {
                formTypes.push({ value: type.key, label: type.name }); return true;
            })
            
            
            this.setState({ formTypes: formTypes })
        });


        // Get the info.
        ApplicationService.getDiscountAnalyticData(discountId).then((response) => {
            this.setState({ analyticsData: { ...response, loading: false }})
        });
        
    }

	currentlUrl = ""
	componentDidUpdate (prevProps, prevState) {
		let activeTab = window.location.pathname.split("/").pop();

		if (this.currentlUrl !== activeTab && (activeTab === "details" || activeTab === "analytics")) {
			this.currentlUrl = activeTab
			this.setState({ openedTab: activeTab })
		}
	}

	/**
	 * On input change for the edit discount.
	 */
	handleInputChange = e => {
        let value = e.target.value
        let { data } = this.state;

        if (e.target.getAttribute('type') === "checkbox") {
            data[e.target.name] = (e.target.checked) ? 1 : 0
        } else {
            data[e.target.name] = value
        }

        if (e.target.name === "discountValue" || e.target.name === "discountType") {

            if (isNaN(Number(data["discountValue"]))) {
                data["discountValue"] = 0;
            } else if (data.discountType === "percentage") {
                if (Number(data["discountValue"]) > 100) {
                    data["discountValue"] = 100;
                } else if (Number(data["discountValue"]) < 0) {
                    data["discountValue"] = 0;
                }
            }
        }
        
        this.setState({ data: data })
    };
    

	/**
	 * Handle the input date cahnges.
	 */
	handleFromDateChange = date => {
		this.setState({ data: { ...this.state.data, fromDate: date } })
	};
	handleExpiryDateChange = date => {
		this.setState({ data: { ...this.state.data, expiryDate: date } })
    };

    /**
     * Handle the application inputs.
     */
    handleApplicationInputs = values => {
        let { applicationProducts } = this.state;
        let newApplicationProducts = {}

        for (let x in values) {
            let application = values[x];
            if (typeof applicationProducts[application.value] !== "undefined") {
                newApplicationProducts[application.value] = applicationProducts[application.value]
            }   
        }

        this.setState({ data: { ...this.state.data, applications: values }, applicationProducts: newApplicationProducts })
    }


    /**
     * Handle the form submit.
     */
    handleSubmit = e => {
		const submitButton = e.target.querySelector('button[type="submit"]');
		
		e.preventDefault();
		
		let { data, discountId, applicationProducts } = this.state;
		
		// Saving feedback.
        submitButton.innerHTML = "Saving..."; submitButton.disabled = true;

        // Validate form.
        let errorMessages = [];
        if (data.applications == null) {
            errorMessages.push("Please assign the discount code to an application.")
        } 
        
        if (data.type !== "access" && (data.discountValue === 0 || data.discountValue === "")) {
            errorMessages.push("Please provide a value for the discount code.")
        }         

        // Check for validation errors.
        if (errorMessages.length > 0) {
            submitButton.innerHTML = "Save"; submitButton.disabled = false;

            // Display the error messages.
            errorMessages.map(errorMessage => {
                store.addNotification({
                    title: "Error!",
                    message: errorMessage,
                    type: "danger",
                    container: "top-right",
                    animationIn: ["animated", "slideInRight"],
                    animationOut: ["animated", "slideOutRight"],
                    dismiss: { duration: 5000 }
                });

                return true;
            })
            
        } else {
            
            // Save the data.
            ApplicationService.saveDiscount(discountId, data, applicationProducts).then((response) => {
                submitButton.innerHTML = "Save"; submitButton.disabled = false;
                
                if (response.updated) {
                    store.addNotification({
                        title: "Success!",
                        message: "Discount code has been successfully saved.",
                        type: "success",
                        container: "top-right",
                        animationIn: ["animated", "slideInRight"],
                        animationOut: ["animated", "slideOutRight"],
                        dismiss: { duration: 5000 }
                    });
                    
                    this.props.history.push("/applications/discount-codes");
                }
            });
        }
    }
        
        
    /**
	 * On tab click
	 * 
	 */
	onClickTabButton(e) {
		let discountId = this.props.match.params.discountId
		this.props.history.push("/applications/discount-codes/edit/" + discountId + "/" + e.target.getAttribute('tab'));
		this.setState({ openedTab: e.target.getAttribute('tab') })
    }
    

    /**
     * Download all analytic data as an excel.
     * @param {*} e 
     */
    downloadAllAnalyticData(e) {
		let discountId = this.props.match.params.discountId
        let downloadButton = e.target;
        downloadButton.innerHTML = "Downloading..."; downloadButton.disabled = true;
        
        // Get the info.
        ApplicationService.downloadAllAnalyticDataById(discountId).then((response) => {
            downloadButton.innerHTML = "Download all analytic data"; downloadButton.disabled = false;


			// Export to CSV
			buildURI(response.applications, false, [
                "id",
                "name",
                "totalAmount",
                "dateSubmitted"
            ], ",", "", "IEMA-Analytics-" + response.discount.code + "-" + moment().format('YYYY-MM-DD') + ".csv");
        });
    }


    /**
     * Override product change.
     * @param {string} query 
     */
    handleProductSearch(query) {
        this.setState({ isLoadingTypeahead: true });

        ApplicationService.productSearch(query).then(products => {
            let options = products.map((i) => ({
                name: i.productName.toString() + " (" + i.productNumber.toString() + ")",
                productNumber: i.productNumber.toString(),
                productId: i.productId.toString(),
            }));
            
            this.setState({ 
                overrideSearchProducts: options,
                isLoadingTypeahead: false 
            });
        });
    };

	render() {
        let { formTypes, data, defaultApplications, loading, openedTab, analyticsData, applicationPrices, applicationProducts } = this.state

        if (loading) {
            return (
                <Layout breadcrumbs={this.state.breadcrumbs} match={this.props.match}>
                    <h1 className="page-title">Edit Discount Code</h1>
                    <div>
                        <div className="alert alert-info" role="alert">
                            <h3>Loading</h3>
                            <span>Loading discount information, please wait...</span>
                        </div>
                    </div>
                </Layout>
            ) 
        } else {

            return (
                <Layout breadcrumbs={this.state.breadcrumbs} match={this.props.match}>
                    <h1 className="page-title">Edit Discount Code</h1>

                    <ul className="nav nav-tabs mb-4">
                        <li className="nav-item">
                            <button
                                tab="details"
                                className={"nav-link" + (openedTab === 'details' ? " active" : "")}
                                onClick={this.onClickTabButton}
                            >Details</button>
                        </li>
                        
                        <li className="nav-item">
                            <button 
                                tab="analytics"
                                className={"nav-link" + (openedTab === 'analytics' ? " active" : "")}
                                onClick={this.onClickTabButton}
                            >Analytics</button>
                        </li>
                    </ul>
                    
                    <div className="row">
                        <div className="col-md-10 offset-md-1">

                            <div className={(openedTab !== "details" ? "d-none" : "")}>
                                <form onSubmit={this.handleSubmit} method="POST">
                                    <div className="custom-control custom-switch mb-3">
                                        <input type="checkbox" className="custom-control-input" name="enabled" id="enabled" checked={data.enabled === 1 ? true : false} onChange={this.handleInputChange} />
                                        <label className="custom-control-label" htmlFor="enabled">Enabled</label>
                                    </div>
    
                                    <div className="form-group">
                                        <label htmlFor="type">Type</label>
                                        <select className="custom-select" name="type" id="type" value={data.type} onChange={this.handleInputChange}>
                                            <option value="discount">Discount</option>
                                            <option value="access">Access</option>
                                            <option value="tracker">Tracker</option>
                                        </select>
                                    </div>
        
                                    <div className="form-group">
                                        <label htmlFor="code">Code *</label>
                                        <input type="text" className="form-control" id="code" name="code" required value={data.code} onChange={this.handleInputChange} />
                                    </div>

                                    <div className={"form-group" + (data.type === "access" ? " d-none" : "")}>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label htmlFor="discount">Discount Value *</label>
                                                <div className={"input-group" + (data.discountType !== "percentage" ? " d-none" : "")}>
                                                    <input type="text" name="discountValue" value={data.discountValue === null ? "" : data.discountValue} className="form-control" onChange={this.handleInputChange} />
                                                    <div className="input-group-append">
                                                        <span className="input-group-text">%</span>
                                                    </div>
                                                </div>
        
                                                <div className={"input-group" + (data.discountType !== "fixed" ? " d-none" : "")}>
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text">&pound;</span>
                                                    </div>
                                                    <input type="text" name="discountValue" value={data.discountValue === null ? "" : data.discountValue} className="form-control" onChange={this.handleInputChange} />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="discountType">Discount Type *</label>
                                                    <select className="custom-select" value={data.discountType} name="discountType" id="discountType" onChange={this.handleInputChange}>
                                                        <option value="fixed">Fixed</option>
                                                        <option value="percentage">Percentage</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
        
        
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="fromDate">From Date *</label>
                                                <DatePicker className="form-control" 
                                                    dateFormat="dd/MM/yyyy h:mm aa" 
                                                    id="fromDate"
                                                    name="fromDate"
                                                    showTimeSelect required
                                                    timeFormat="h:mm aa" timeIntervals={15}
                                                    value={(data.fromDate !== null ) ? data.fromDate : ""} selected={data.fromDate}
                                                    onChange={this.handleFromDateChange} 
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="expiryDate">Expiry Date</label>
                                                <DatePicker className="form-control" 
                                                    dateFormat="dd/MM/yyyy h:mmaa" 
                                                    id="expiryDate"
                                                    name="expiryDate"
                                                    showTimeSelect
                                                    timeFormat="h:mm aa" timeIntervals={15}
                                                    value={(data.expiryDate !== null ) ? data.expiryDate : ""} selected={data.expiryDate}
                                                    onChange={this.handleExpiryDateChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
        
                                    {/* Form attribution. */}
                                    {defaultApplications !== null  ? (
                                        <div className="form-group">
                                            <label htmlFor="">Available to which applications? *</label>
                                            <Select options={formTypes} placeholder="" isMulti={true} theme={theme => ({
                                                ...theme, borderRadius: "0.15rem", borderColour: "red",
                                                colors: { ...theme.colors, neutral0: "#f8f9fa", primary: "black", primary25: "#e9ecef" }
                                            })} onChange={this.handleApplicationInputs} required defaultValue={data.applications} />
                                        </div>
                                    ) : (<></>)}

                                    <hr />
                                    <h2 className="mt-4">Product overrides</h2>
    
                                    {/* Loop through every application group and applications. */}
                                    {data.applications !== null && data.applications.length > 0 ? data.applications.map(application => {
                                        if (typeof applicationPrices[application.value] != "undefined") {
                                            let products = applicationPrices[application.value].products        
                                            return (
                                                <div key={application.value} className="mt-4 mb-5">
                                                    <h4 className="mb-3">{application.label}</h4>
        
                                                    {Object.keys(products).map(key => (
                                                        <div className="form-group row" key={application.value + "-" + key}>
                                                            <label htmlFor="staticEmail" className="col-sm-2 col-form-label">{key}</label>
        
                                                            <div className="col-sm-10">
                                                                {/* Autocomplete for products. */}
                                                                <AsyncTypeahead
                                                                    isLoading={this.state.isLoadingTypeahead}
                                                                    defaultSelected={(typeof applicationProducts[application.value] !== "undefined" && typeof applicationProducts[application.value][key] !== "undefined" ? 
                                                                        [applicationProducts[application.value][key]] : [""])}
                                                                    labelKey="name"
                                                                    id={application.value + "-" + key + "-input"}
                                                                    onSearch={this.handleProductSearch}
                                                                    onChange={(value) => {
                                                                        // Set the application products variable.
                                                                        if (typeof applicationProducts[application.value] === "undefined") { applicationProducts[application.value] = {} }
                                                                        applicationProducts[application.value][key] = value[0];
                                                                        this.setState({ applicationProducts: applicationProducts })
                                                                    }}
                                                                    options={this.state.overrideSearchProducts}
                                                                    placeholder="Search for an override product..."
                                                                    renderMenuItemChildren={(option, props) => (<><span>{option.name}</span></>)}
                                                                />
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            )
                                        } else {
                                            return (<></>);
                                        }
                                    }) : (<p>Please add allowed applications to this discount in order to override their products.</p>)}

                                    <button type="submit" className="btn btn-primary float-right px-4">Save</button>
                                </form>
                            </div>   


                            <div className={(openedTab !== "analytics" ? "d-none" : "")}>
                                {(analyticsData.loading) ? (
                                    <div>
                                        <div className="alert alert-info" role="alert">
                                            <h3>Loading</h3>
                                            <span>Loading analytics data, please wait...</span>
                                        </div>
                                    </div>
                                ) : (
                                    <div>
                                        <div className="form-group row">
                                            <label htmlFor="analytics-usage" className="col-sm-2 col-form-label">Usage</label>
                                            <div className="col-sm-10">
                                                <input type="text" readOnly className="form-control-plaintext" id="analytics-usage" value={analyticsData.usage} />
                                            </div>
                                        </div>


                                        <div className="clearfix clear">
                                            <div className="float-left">
                                                <h3>Applications</h3>
                                                <p>Display the last 5 applications with this discount code.</p>
                                            </div>
                                            <button className="float-right btn btn-primary" onClick={this.downloadAllAnalyticData}>Download all analytic data</button>
                                        </div>
                                        <br />
                                        <div className="table-responsive table-data position-relative">
                                            <table className={"table table-striped table-hover"}>
                                                <thead>
                                                    <tr>
                                                        <th>ID</th>
                                                        <th>Membership Grade</th>
                                                        <th>Invoice No</th>
                                                        <th>Amount</th>
                                                        <th>Date/Time</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {analyticsData.applications.map((application) => (
                                                        <tr key={"application-"+application.id}>
                                                            <td>{application.id}</td>
                                                            <td>{application.name}</td>
                                                            <td>N/A</td>
                                                            <td>&pound;{application.totalAmount.toFixed(2)}</td>
                                                            <td>{moment(application.dateSubmitted).format("DD/MM/YYYY hh:mm")}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </Layout>
            );
        }
    }
}

export default withRouter((props, ref) => (
	<AuthContext.Consumer>{context => {
		if (context.currentUser.permissions.applications) {
			return (<EditDiscountCodes {...props} authContext={context} />)
		} else {
			return (<AccessDenied {...props}/>)
		}
	}}</AuthContext.Consumer>
));