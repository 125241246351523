import React, { Component } from 'react';
import Layout from './../../bricks/Layout'
import { useTable, usePagination } from 'react-table'
import InvoiceService from '../../services/InvoiceService'
import { AuthContext } from '../../components/AuthContext';
import AccessDenied from '../../components/AccessDenied';
import { Link } from 'react-router-dom'


class Invoices extends Component {
	state = {
		response: '',
		allInvoices: [],
		allStatus: {
			primary: { label: "All", key: "" },
			success: { label: "Fully Paid", key: "fully-paid" },
			info: { label: "Partial", key: "partial" },
			warning: { label: "Due", key: "due" },
			danger: { label: "Cancelled", key: "cancelled" },
		},
		invoiceTable: {
			query: '',
			filterStatus: "All",
			pageIndex: 0,
			pageCount: 0,
			perPage: 30,
			isLoading: true,
			sort: {
				field: null,
				descending: false
			}
		},

		// Set the table columns.
		allColumns: [
			{ Header: 'Invoice #', accessor: 'invoiceNumber', sortable: true },
			{ Header: 'Name', accessor: 'fullname', sortable: true },
			{ Header: 'Membership Number', accessor: 'membershipNumber', sortable: true },
			{ Header: 'Email', accessor: 'emailAddress', sortable: true },
			{ Header: 'Amount', accessor: 'totalAmount', sortable: true },
			{ Header: 'Status', accessor: 'statusLabel', sortable: true },
			{ Header: '', accessor: 'view', sortable: false }
		],
		dueColumns: [
			{ Header: 'Invoice #', accessor: 'invoiceNumber', sortable: true },
			{ Header: 'Name', accessor: 'fullname', sortable: true },
			{ Header: 'Amount', accessor: 'totalAmount', sortable: true },
			{ Header: 'Date', accessor: 'createdOn', sortable: true },
			{ Header: 'Due Date', accessor: 'dueDate', sortable: true },
			{ Header: 'Overdue By', accessor: 'dueDateDif', sortable: true },
			{ Header: 'Status', accessor: 'statusLabel', sortable: true },
			{ Header: '', accessor: 'view', sortable: false }
		],
		columns: [{ Header: 'Invoice #', accessor: 'invoiceNumber', sortable: true }]
	};

	tabButtons = { "": null, "fully-paid": null, "partial": null, "due": null, "cancelled": null }

	componentDidMount() {
		let { invoiceTable, allColumns } = this.state
		this.setState({ columns: allColumns });

		let activeTab = window.location.pathname.split("/").pop();
		if (activeTab !== "" && activeTab !== "invoice" && typeof this.tabButtons[activeTab] !== "undefined") {
			this.tabButtons[activeTab].click();
		}		
		
		// Load all Invoices.
		InvoiceService.getAll(this.state.invoiceTable).then((response) => {
			this.setState({ allInvoices: response.invoices, invoiceTable: {
				...invoiceTable,
				isLoading: false,
				pageCount: response.totalPages,
				pageIndex: response.pageIndex,
				perPage: response.perPage
			}})
		});
	}

	currentlUrl = ""
	componentDidUpdate (prevProps, prevState) {
		let activeTab = window.location.pathname.split("/").pop();

		if (this.currentlUrl !== activeTab) {
			this.currentlUrl = activeTab
			if (activeTab !== "" && activeTab !== "invoice" && typeof this.tabButtons[activeTab] !== "undefined") {
				this.tabButtons[activeTab].click();
			}
		}
	}

	// Breadcrumbs.
	breadcrumbs = [
		{ title: 'Home', link: '/' }, 
		{ title: 'Invoices', link: '/invoices', active: true }
	];


	// On the search input change, update the state.
	handleSearchInput = (e) => {
		let query = e.target.value
		let { invoiceTable } = this.state

		// Set loading to true and update the page number.
		invoiceTable.isLoading = true;
		invoiceTable.query = query;
		invoiceTable.pageIndex = 0;
		this.setState({ invoiceTable: invoiceTable })

		InvoiceService.getAll(invoiceTable).then((response) => {
			if (this.state.invoiceTable.query === response.query || 
				(this.state.invoiceTable.query === "" && response.query === null)) {
				invoiceTable.isLoading = false;
				this.setState({ allInvoices: response.invoices, invoiceTable: invoiceTable })
			}
		});
	}


	// On page change for the table.
	handlePageChange = (pageIndex) => {
		let { invoiceTable } = this.state

		// Set loading to true and update the page number.
		invoiceTable.isLoading = true;
		invoiceTable.pageIndex = pageIndex;
		this.setState({ invoiceTable: invoiceTable })

		// Load all Invoices.
		InvoiceService.getAll(invoiceTable).then((response) => {
			this.setState({ 
				allInvoices: response.invoices,
				invoiceTable: {
					...invoiceTable,
					isLoading: false,
					pageCount: response.totalPages,
					pageIndex: response.pageIndex,
					perPage: response.perPage
				}
			})
		});
	}

	// On the sort change get new data.
	handleSortData = (sort) => {
		let { invoiceTable } = this.state

		// Set loading to true and update the page number.
		invoiceTable.isLoading = true;
		invoiceTable.sort = sort;
		this.setState({ invoiceTable: invoiceTable })

		// Load all Invoices.
		InvoiceService.getAll(invoiceTable).then((response) => {
			this.setState({ 
				allInvoices: response.invoices,
				invoiceTable: {
					...invoiceTable,
					isLoading: false,
					pageCount: response.totalPages,
					pageIndex: response.pageIndex,
					perPage: response.perPage
				}
			})
		});
	}

	/**
	 * Filter the status of the invoices
	 */
	handleFilterStatus = (e) => {
		let { invoiceTable, allColumns, dueColumns } = this.state
		let columns = allColumns;
		let tabSlug = e.target.getAttribute('tab-slug');

		// Set loading to true and update the page number.
		invoiceTable.isLoading = true;
		invoiceTable.filterStatus = e.target.innerHTML;

		// Do we need to change the columns?
		if (invoiceTable.filterStatus === "Due") { columns = dueColumns }

		// Set the url.
		this.props.history.push("/invoices/" + tabSlug);

		this.setState({ invoiceTable: invoiceTable, columns: columns })

		// Load all Invoices.
		InvoiceService.getAll(invoiceTable).then((response) => {
			this.setState({ 
				allInvoices: response.invoices,
				invoiceTable: {
					...invoiceTable,
					isLoading: false,
					pageCount: response.totalPages,
					pageIndex: response.pageIndex,
					perPage: response.perPage
				}
			})
		});
	}


	render() {
		let filterStatus = this.state.invoiceTable.filterStatus;
		let allStatus = this.state.allStatus;

		return (
			<Layout breadcrumbs={this.breadcrumbs} match={this.props.match}>
				<div className="d-block clearfix">
					<h1 className="d-inline float-left">Invoices</h1><br />
					<div className="form-group float-right">
						<input name="search" className="form-control" placeholder="Search invoices" onChange={this.handleSearchInput} />
					</div>
				</div>

				<ul className="nav nav-tabs mb-4">
					{Object.keys(allStatus).map((key, i) => {
						return (
							<li className="nav-item" key={i}>
								<button
									tab={allStatus[key].label}
									tab-slug={allStatus[key].key}
									className={"nav-link" + ((filterStatus === allStatus[key].label) ? ' active' : '')}
									onClick={this.handleFilterStatus}
									ref={button => this.tabButtons[allStatus[key].key] = button}
								>{allStatus[key].label}</button>
							</li>
						)
					})}
				</ul>

				<div className="table-responsive">
					<InvoicesTable 
						tableData={this.state.allInvoices} 
						tableColumns={this.state.columns} 
						query={this.state.invoiceTable.query} 
						gotoPage={this.handlePageChange}
						pageIndex={this.state.invoiceTable.pageIndex} 
						pageCount={this.state.invoiceTable.pageCount}
						isLoading={this.state.invoiceTable.isLoading}
						sort={this.state.invoiceTable.sort}
						sortData={this.handleSortData}
					/>
				</div>
			</Layout>
		);
	}
}

/**
 * Table to display the invoices with pagination and filtering.
 * @param {}
 */
function InvoicesTable({ tableColumns, tableData, gotoPage, pageIndex, pageCount, isLoading, sort, sortData }) {

	// Define the table columns.
	const columns = React.useMemo(() => tableColumns, [tableColumns])
	
	// Define the data.
	const data = React.useMemo(() => tableData, [tableData])
	
	// Use the state and functions returned from useTable to build your UI
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		page
	} = useTable({
		columns,
		data,
		manualPagination: true,
		initialState: { pageIndex: pageIndex, pageSize: 20 },
	}, usePagination)


	/**
	 * Calculate and show the correct pages.
	 * @param {*} pIndex 
	 * @param {*} pCount 
	 */
	const numberedPages = (pIndex, pCount) => {
		let gCount = 3;
		let showPagesArr = [];

		// Loop through all pages and calculate which ones to show.
		for (let i = 0; i < pCount; i++) {
			if ((pIndex - gCount) < i && (gCount + pIndex) > i) {
				showPagesArr.push(i);
			}            
		}
		return showPagesArr;
	}


	/**
	 * On click of the sort header.
	 * @param {*} e 
	 */
	const sortHeader = (e) => {
		let sortField = e.target.getAttribute("sortfield");
		let sortable = e.target.getAttribute("sortable");

		if (sortable) {
			if (sort.field === sortField && !sort.descending) {
				sort.descending = true;
			} else if (sort.field === sortField && sort.descending) {
				sort.field = null;
				sort.descending = false;
			} else {
				sort.field = sortField;
				sort.descending = false;
			}
	
			sortData(sort);
		}
	}
	
	return (
		<div>
			<div className="table-responsive table-data position-relative">
				<table className={"table table-striped"} {...getTableProps()}>
					<thead>
						{headerGroups.map(headerGroup => (
							<tr {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map(column => (
									// Add the sorting props to control sorting. For this example
									// we can add them into the header props
									<th {...column.getHeaderProps()} onClick={sortHeader} sortfield={column.id} sortable={column.sortable.toString()}>
										{column.Header}
										{(column.sortable) ? 
											(<span className={"sort " + (sort.field === column.id ? (sort.descending ? 'sort-desc' : 'sort-asc') : 'sort-none')}></span>) 
										: ''}
									</th>
								))}
							</tr>
						))}
					</thead>
					<tbody {...getTableBodyProps()}>
						{page.map((row, i) => {
							prepareRow(row);
							
							return (
								<tr {...row.getRowProps()}>
									{row.cells.map(cell => {
										if (cell.column.id === 'totalAmount') {
											return (<td {...cell.getCellProps()}>&pound;{cell.row.original.totalAmount.toFixed(2)}</td>)
										} else if (cell.column.id === 'fullname') {
											return (<td {...cell.getCellProps()}><Link to={"/members/" + cell.row.original.contactId}>{cell.row.original.fullname}</Link></td>)
										} else if (cell.column.id === 'statusLabel') {
											let labelClass = "secondary";
											if (cell.row.original.statusLabel === "Fully Paid") { labelClass = "success"; } 
											else if (cell.row.original.statusLabel === "Partial") { labelClass = "info"; }
											else if (cell.row.original.statusLabel === "Cancelled") { labelClass = "danger"; }
											else if (cell.row.original.statusLabel === "Due") { labelClass = "warning"; }
											return (<td {...cell.getCellProps()} className="text-center">
												<span className={"badge badge-"+labelClass}>{cell.row.original.statusLabel}</span>
											</td>)
										} else if (cell.column.id === 'dueDate') {
											if (cell.row.original.dueDate === "Invalid date") {
												return (<td {...cell.getCellProps()} className="text-center">N/A</td>)
											} else {
												return (<td {...cell.getCellProps()} className="text-center">{cell.row.original.dueDate}</td>)
											}
										} else if (cell.column.id === 'dueDateDif') {
											if (isNaN(cell.row.original.dueDateDif) || cell.row.original.dueDateDif === null) {
												return (<td {...cell.getCellProps()} className="text-center">N/A</td>)
											} else {
												return (<td {...cell.getCellProps()} className={"text-center " + ((cell.row.original.dueDateDif < 0) ? "text-danger" : "")}>{cell.row.original.dueDateDif} days</td>)
											}

										} else if (cell.column.id === 'view') {
											return (<td {...cell.getCellProps()}>
												<Link to={"/invoices/" + cell.row.original.invoiceid} className={"btn btn-primary btn-sm btn-view"}><span>View</span></Link>
											</td>)
										} else {
											return (<td {...cell.getCellProps()}>{cell.render('Cell')}</td>)
										}
									})}
								</tr>
							)
						})}
					</tbody>
				</table>
				<div className={"loading-table-data" + ((isLoading) ? " active" : "")}><div className="loading-inner">Loading...</div></div>
			</div>

			<nav className="d-flex mt-4" aria-label="Page navigation">
			<ul className="pagination mx-auto">
					{/* <li className="page-item">
						<button className="page-link" onClick={() => gotoPage(0)} disabled={(pageIndex === 0)}>&lt;&lt; First</button>
					</li> */}
					<li className="page-item">
						<button className="page-link" onClick={() => gotoPage(pageIndex-1)} disabled={(pageIndex === 0)}>&lt; Previous</button>
					</li>
					{numberedPages(pageIndex, pageCount).map((pageNum) => {
						return (
							<li className={"page-item " + (pageIndex === pageNum ? 'active' : '')} key={pageNum+1}>
								<button className="page-link" onClick={() => gotoPage(pageNum)}>{pageNum+1}</button>
							</li>
						)
					})}
					<li className="page-item">
						<button className="page-link" onClick={() => gotoPage(pageIndex+1)} disabled={(pageIndex+1 === pageCount)}>Next &gt;</button>
					</li>
					{/* <li className="page-item">
						<button className="page-link" onClick={() => gotoPage(pageCount-1)} disabled={(pageIndex+1 === pageCount)}>Last &gt;&gt;</button>
					</li> */}
				</ul>
			</nav>
		</div>
	)
}


export default ((props, ref) => (
	<AuthContext.Consumer>{context => {
		if (context.currentUser.permissions.invoices) {
			return (<Invoices {...props} authContext={context} />)
		} else {
			return (<AccessDenied {...props}/>)
		}
	}}</AuthContext.Consumer>
));