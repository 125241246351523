import React, {Component} from 'react';
import Layout from '../../bricks/Layout'
import {withRouter} from 'react-router-dom'
import {AuthContext} from '../../components/AuthContext';
import ApplicationService from '../../services/ApplicationService';
import AccessDenied from '../../components/AccessDenied';
import Select from 'react-select'
import {store} from 'react-notifications-component';
import {Typeahead, withAsync} from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

const AsyncTypeahead = withAsync(Typeahead);

class EditCountryDiscountsLmi extends Component {
	state = {
		isLoadingTypeahead: false,
		overrideSearchProducts: [],
		loading: true,
		breadcrumbs: [],
		formTypes: [],
		discountId: "",
		data: {
			code: "",
			type: "country",
			startDate: null,
			expiryDate: null,
			discountValue: 0,
			discountType: "fixed",
			maxUses: 0,
			usage: 0,
			enabled: 1,
			applications: []
		},
		defaultApplications: null,
		applicationPrices: {},
		applicationProducts: {}
	};

	constructor(props) {
		super(props);
		this.handleProductSearch = this.handleProductSearch.bind(this);
	}

	componentDidMount() {
		// Set default states
		this.setState({
			breadcrumbs: [
				{ title: 'Home', link: '/' },
				{ title: 'Applications', link: '/applications'},
				{ title: 'Country Discounts', link: '/applications/country-discounts' },
				{ title: 'Edit Product Overrides (LMI)', link: '/applications/country-discounts-lmi/edit', active: true }
			]
		})

		// Get the info.
		ApplicationService.getSingleDiscountByType('country').then((response) => {
			let data = {
				code: response.code,
				type: response.type,
				enabled: response.enabled,
				discountValue: response.discountValue,
				discountType: response.discountType,
				maxUses: response.maxUses,
				fromDate: response.fromDate,
				expiryDate: response.expiryDate,
				applications: response.applications,
				usage: response.usage
			}

			this.setState({
				data: data,
				discountId: response.id,
				applicationProducts: response.applicationProducts,
				applicationPrices: response.applicationPrices,
				defaultApplications: response.applications,
				loading: false
			})

		});


		// Load the form types.
		ApplicationService.getAllFormTypes().then((response) => {
			let formTypes = [];

			// Sort out the form types.
			response.formTypes.map(type => {
				formTypes.push({ value: type.key, label: type.name }); return true;
			})


			this.setState({ formTypes: formTypes })
		});

	}

	/**
	 * On input change for the edit discount.
	 */
	handleInputChange = e => {
		let value = e.target.value
		let { data } = this.state;

		if (e.target.getAttribute('type') === "checkbox") {
			data[e.target.name] = (e.target.checked) ? 1 : 0
		} else {
			data[e.target.name] = value
		}

		if (e.target.name === "discountValue" || e.target.name === "discountType") {

			if (isNaN(Number(data["discountValue"]))) {
				data["discountValue"] = 0;
			} else if (data.discountType === "percentage") {
				if (Number(data["discountValue"]) > 100) {
					data["discountValue"] = 100;
				} else if (Number(data["discountValue"]) < 0) {
					data["discountValue"] = 0;
				}
			}
		}

		this.setState({ data: data })
	};

	/**
	 * Handle the application inputs.
	 */
	handleApplicationInputs = values => {
		let { applicationProducts } = this.state;
		let newApplicationProducts = {}

		for (let x in values) {
			let application = values[x];
			if (typeof applicationProducts[application.value] !== "undefined") {
				newApplicationProducts[application.value] = applicationProducts[application.value]
			}
		}

		this.setState({ data: { ...this.state.data, applications: values }, applicationProducts: newApplicationProducts })
	}


	/**
	 * Handle the form submit.
	 */
	handleSubmit = e => {
		const submitButton = e.target.querySelector('button[type="submit"]');

		e.preventDefault();

		let { data, discountId, applicationProducts } = this.state;

		// Saving feedback.
		submitButton.innerHTML = "Saving..."; submitButton.disabled = true;

		// Validate form.
		let errorMessages = [];
		if (data.applications == null) {
			errorMessages.push("Please assign the discount code to an application.")
		}

		if (data.type !== "access" && (data.discountValue === 0 || data.discountValue === "")) {
			errorMessages.push("Please provide a value for the discount code.")
		}

		// Check for validation errors.
		if (errorMessages.length > 0) {
			submitButton.innerHTML = "Save"; submitButton.disabled = false;

			// Display the error messages.
			errorMessages.map(errorMessage => {
				store.addNotification({
					title: "Error!",
					message: errorMessage,
					type: "danger",
					container: "top-right",
					animationIn: ["animated", "slideInRight"],
					animationOut: ["animated", "slideOutRight"],
					dismiss: { duration: 5000 }
				});

				return true;
			})

		} else {

			// Save the data.
			ApplicationService.saveDiscount(discountId, data, applicationProducts).then((response) => {
				submitButton.innerHTML = "Save"; submitButton.disabled = false;

				if (response.updated) {
					store.addNotification({
						title: "Success!",
						message: "Country discount has been successfully saved.",
						type: "success",
						container: "top-right",
						animationIn: ["animated", "slideInRight"],
						animationOut: ["animated", "slideOutRight"],
						dismiss: { duration: 5000 }
					});

					this.props.history.push("/applications/country-discounts");
				}
			});
		}
	}

	/**
	 * Override product change.
	 * @param {string} query
	 */
	handleProductSearch(query) {
		this.setState({ isLoadingTypeahead: true });

		ApplicationService.productSearch(query).then(products => {
			let options = products.map((i) => ({
				name: i.productName.toString() + " (" + i.productNumber.toString() + ")",
				productNumber: i.productNumber.toString(),
				productId: i.productId.toString(),
			}));

			this.setState({
				overrideSearchProducts: options,
				isLoadingTypeahead: false
			});
		});
	};

	render() {
		let { formTypes, data, defaultApplications, loading, applicationPrices, applicationProducts } = this.state

		if (loading) {
			return (
				<Layout breadcrumbs={this.state.breadcrumbs} match={this.props.match}>
					<h1 className="page-title">Edit Product Overrides (LMI)</h1>
					<div>
						<div className="alert alert-info" role="alert">
							<h3>Loading</h3>
							<span>Loading discount information, please wait...</span>
						</div>
					</div>
				</Layout>
			)
		} else {

			return (
				<Layout breadcrumbs={this.state.breadcrumbs} match={this.props.match}>
					<h1 className="page-title">Edit Product Overrides (LMI)</h1>

					<ul className="nav nav-tabs mb-4">
						<li className="nav-item">
							<button
								tab="details"
								className="nav-link active"
							>Details</button>
						</li>
					</ul>

					<div className="row">
						<div className="col-md-10 offset-md-1">

							<div>
								<form onSubmit={this.handleSubmit} method="POST">
									<div className="custom-control custom-switch mb-3">
										<input type="checkbox" className="custom-control-input" name="enabled" id="enabled" checked={data.enabled === 1 ? true : false} onChange={this.handleInputChange} />
										<label className="custom-control-label" htmlFor="enabled">Enabled</label>
									</div>

									<div className={"form-group" + (data.type === "access" ? " d-none" : "")}>
										<div className="row">
											<div className="col-md-6">
												<label htmlFor="discount">Discount Value *</label>
												<div className={"input-group" + (data.discountType !== "percentage" ? " d-none" : "")}>
													<input type="text" name="discountValue" value={data.discountValue === null ? "" : data.discountValue} className="form-control" onChange={this.handleInputChange} />
													<div className="input-group-append">
														<span className="input-group-text">%</span>
													</div>
												</div>

												<div className={"input-group" + (data.discountType !== "fixed" ? " d-none" : "")}>
													<div className="input-group-prepend">
														<span className="input-group-text">&pound;</span>
													</div>
													<input type="text" name="discountValue" value={data.discountValue === null ? "" : data.discountValue} className="form-control" onChange={this.handleInputChange} />
												</div>
											</div>
											<div className="col-md-6">
												<label htmlFor="discountType">Discount Type *</label>
												<select className="custom-select" value={data.discountType} name="discountType" id="discountType" onChange={this.handleInputChange}>
													<option value="fixed">Fixed</option>
													<option value="percentage">Percentage</option>
												</select>
											</div>
										</div>
									</div>

									{/* Form attribution. */}
									{defaultApplications !== null  ? (
										<div className="form-group">
											<label htmlFor="">Available to which applications? *</label>
											<Select options={formTypes} placeholder="" isMulti={true} theme={theme => ({
												...theme, borderRadius: "0.15rem", borderColour: "red",
												colors: { ...theme.colors, neutral0: "#f8f9fa", primary: "black", primary25: "#e9ecef" }
											})} onChange={this.handleApplicationInputs} required defaultValue={data.applications} />
										</div>
									) : (<></>)}

									<hr />
									<h2 className="mt-4">Product overrides</h2>

									{/* Loop through every application group and applications. */}
									{data.applications !== null && data.applications.length > 0 ? data.applications.map(application => {
										if (typeof applicationPrices[application.value] != "undefined") {
											let products = applicationPrices[application.value].products
											return (
												<div key={application.value} className="mt-4 mb-5">
													<h4 className="mb-3">{application.label}</h4>

													{Object.keys(products).map(key => (
														<div className="form-group row" key={application.value + "-" + key}>
															<label htmlFor="staticEmail" className="col-sm-2 col-form-label">{key}</label>

															<div className="col-sm-10">
																{/* Autocomplete for products. */}
																<AsyncTypeahead
																	isLoading={this.state.isLoadingTypeahead}
																	defaultSelected={(typeof applicationProducts[application.value] !== "undefined" && typeof applicationProducts[application.value][key] !== "undefined" ?
																		[applicationProducts[application.value][key]] : [""])}
																	labelKey="name"
																	id={application.value + "-" + key + "-input"}
																	onSearch={this.handleProductSearch}
																	onChange={(value) => {
																		// Set the application products variable.
																		if (typeof applicationProducts[application.value] === "undefined") { applicationProducts[application.value] = {} }
																		applicationProducts[application.value][key] = value[0];
																		this.setState({ applicationProducts: applicationProducts })
																	}}
																	options={this.state.overrideSearchProducts}
																	placeholder="Search for an override product..."
																	renderMenuItemChildren={(option, props) => (<><span>{option.name}</span></>)}
																/>
															</div>
														</div>
													))}
												</div>
											)
										} else {
											return (<></>);
										}
									}) : (<p>Please add allowed applications to this discount in order to override their products.</p>)}

									<button type="submit" className="btn btn-primary float-right px-4">Save</button>
								</form>
							</div>

						</div>
					</div>
				</Layout>
			);
		}
	}
}

export default withRouter((props, ref) => (
	<AuthContext.Consumer>{context => {
		if (context.currentUser.permissions.applications) {
			return (<EditCountryDiscountsLmi {...props} authContext={context} />)
		} else {
			return (<AccessDenied {...props}/>)
		}
	}}</AuthContext.Consumer>
));
