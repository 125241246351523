import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import { OverlayTrigger, Tooltip,} from 'react-bootstrap';
import { ViewContext } from "./ViewContext";
import { Prompt } from 'react-router'

import "react-datepicker/dist/react-datepicker.css";

const tooltip = (
	<Tooltip id="tooltip">
		Editing employer details is currently only available in Dynamics
	</Tooltip>
  );

class DetailsTab extends Component {

	/** Regions */
	// 	<fetch top="50" >
	//   <entity name="tiss_region" >
	//     <attribute name="tiss_regionid" />
	//     <attribute name="tiss_name" />
	//   </entity>
	// </fetch>
	regions = [
		{ id: '0bb51621-cefc-e611-8100-5065f38b95f1', name: 'East of England' }, 
		{ id: '0db51621-cefc-e611-8100-5065f38b95f1', name: 'East Scotland' }, 
		{ id: '0fb51621-cefc-e611-8100-5065f38b95f1', name: 'International' }, 
		{ id: '11b51621-cefc-e611-8100-5065f38b95f1', name: 'MENA' }, 
		{ id: '13b51621-cefc-e611-8100-5065f38b95f1', name: 'Midlands' }, 
		{ id: '15b51621-cefc-e611-8100-5065f38b95f1', name: 'Nigeria' }, 
		{ id: '17b51621-cefc-e611-8100-5065f38b95f1', name: 'North America' }, 
		{ id: '19b51621-cefc-e611-8100-5065f38b95f1', name: 'North East' }, 
		{ id: '1bb51621-cefc-e611-8100-5065f38b95f1', name: 'North Scotland' }, 
		{ id: '1db51621-cefc-e611-8100-5065f38b95f1', name: 'North West' }, 
		{ id: '1fb51621-cefc-e611-8100-5065f38b95f1', name: 'Northern Ireland' }, 
		{ id: '21b51621-cefc-e611-8100-5065f38b95f1', name: 'Republic of Ireland' }, 
		{ id: '38f989d7-24af-e711-811a-70106fa5fd31', name: 'Solent' }, 
		{ id: '23b51621-cefc-e611-8100-5065f38b95f1', name: 'South East' }, 
		{ id: '25b51621-cefc-e611-8100-5065f38b95f1', name: 'South West' }, 
		{ id: '550843d1-24af-e711-811a-70106fa5fd31', name: 'Surrey and Sussex' }, 
		{ id: '27b51621-cefc-e611-8100-5065f38b95f1', name: 'Wales' }, 
		{ id: '29b51621-cefc-e611-8100-5065f38b95f1', name: 'West Scotland' }, 
		{ id: '2bb51621-cefc-e611-8100-5065f38b95f1', name: 'Yorkshire and Humber' }, 
	]

	formHasChanged = false;

	render() {		

		return (
			<ViewContext.Consumer>
				{context => { 
					let { memberDetails, countriesList, memberId, loadingDetails } = context;


				if (loadingDetails) {
					return (
						<div>
							<div className="alert alert-info" role="alert">
								<h3>Loading</h3>
								<span>Loading details data, please wait...</span>
							</div>
						</div>
					)
				}

					return (
						<div>
							<form className="member-address" onSubmit={context.handleDetailsFormSubmit}>
								<Prompt
									when={this.formHasChanged}
									message={location =>
										location.pathname.startsWith("/members/" + memberId)
										  ? true
										  : "Changes that you made may not be saved. Are you sure you want to go to another page?"
									  }
								/>

								<h4 className="mt-4 mb-4">Personal details</h4>
								<div className="row">
									<div className="col-md-2 col-lg-2">
										<div className="form-group">
											<label>Title</label>
											<select className="form-control" id="memberTitle" name="title" value={(memberDetails.title===null) ? '' :
												memberDetails.title} onChange={(e) => { this.formHasChanged = true; context.handleDetailsChange(e); }}>
												<option disabled value="">Please Select a Title</option>
												<option value="454530000">Mr</option>
												<option value="454530001">Mrs</option>
												<option value="454530002">Miss</option>
												<option value="454530004">Dr</option>
												<option value="454530005">Prof</option>
												<option value="454530003">Ms</option>
											</select>
										</div>
									</div>

									<div className="col-md-4 col-lg-4">
										<div className="form-group">
											<label className="required">First Name</label>
											<input className="form-control" name="firstname" value={(typeof memberDetails.firstname !== "undefined" && memberDetails.firstname !== null ) ?
												memberDetails.firstname : "" } onChange={(e) => { this.formHasChanged = true; context.handleDetailsChange(e); }} />
										</div>
									</div>
							
							
									<div className="col-md-6">
										<div className="form-group">
											<label>Middle Name</label>
											<input name="middlename" className="form-control" value={(typeof memberDetails.middlename !== "undefined" && memberDetails.middlename !== null ) ?
												memberDetails.middlename : "" } onChange={(e) => { this.formHasChanged = true; context.handleDetailsChange(e); }} />
										</div>
									</div>

									<div className="col-md-6">
										<div className="form-group">
											<label className="required">Last Name</label>
											<input name="lastname" className="form-control" value={(typeof memberDetails.lastname !== "undefined" && memberDetails.lastname !== null ) ?
												memberDetails.lastname : "" } onChange={(e) => { this.formHasChanged = true; context.handleDetailsChange(e); }} />
										</div>
									</div>

									<div className="col-md-6">
										<div className="form-group">
											<label>Date of Birth</label>
											<DatePicker className="form-control" dateFormat="dd/MM/yyyy" value={(memberDetails.dateOfBirth !== null ) ?
												memberDetails.dateOfBirth : "" } selected={memberDetails.dateOfBirth}
												onChange={context.handleDetailsDateChange} />
										</div>
									</div>
			
									<div className="col-md-6">
										<div className="form-group">
											<label>Job Title</label>
											<input name="jobTitle"
											className="form-control"
											value={(typeof memberDetails.jobTitle !== "undefined" && memberDetails.jobTitle != null) ? memberDetails.jobTitle : ""  } 
											onChange={(e) => { this.formHasChanged = true; context.handleDetailsChange(e); }} />
										</div>
									</div>

									<div className="col-md-6">
										<div className="form-group">
											<label>Employer</label>
											<OverlayTrigger placement="top" overlay={tooltip}>
												<span className="tooltip-info"> </span>
											</OverlayTrigger>
											<input name="employer" disabled className="form-control" value="N/A" onChange={(e) => { this.formHasChanged = true; context.handleDetailsChange(e); }} />
										</div>
									</div>
									
									<div className="col-md-6">
										<div className="form-group">
											<label className="required">Email Address</label>
											<input className="form-control"
												name="emailAddress"
												value={(typeof memberDetails.emailAddress !== "undefined" && memberDetails.lastname !== null ) ? memberDetails.emailAddress : "" } 
												onChange={(e) => { this.formHasChanged = true; context.handleDetailsChange(e); }} />
										</div>
									</div>

									<div className="col-md-6">
										<div className="form-group">
											<label className="required">Telephone</label>
											<input className="form-control" 
												type="tel" 
												name="telephone"
												value={(typeof memberDetails.telephone !== "undefined" && memberDetails.telephone != null ) ? memberDetails.telephone : "" } 
												onChange={(e) => { this.formHasChanged = true; context.handleDetailsChange(e); }} />
										</div>
									</div>
									
									<div className="col-md-6">
										<div className="form-group">
											<label>Mobile</label>
											<input className="form-control"
												name="mobile"
												value={(typeof memberDetails.mobile !== "undefined" && memberDetails.mobile != null ) ? memberDetails.mobile : "" } 
												onChange={(e) => { this.formHasChanged = true; context.handleDetailsChange(e); }} />
										</div>
									</div>

									<div className="col-6">
										<div className="form-group">
											<label>Region</label>
											<select className="form-control" 
												name="region"
												value={memberDetails.region !== null ? memberDetails.region : ""}
												onChange={(e) => { this.formHasChanged = true; context.handleDetailsChange(e); }}>
													<option key={"regionnull"} value="" disabled>Please select a region</option>
													{this.regions.map(region => (
														<option key={"region" + region.id}  value={region.id}>{region.name}</option>
													))}
											</select>
										</div>
									</div>

									<div className="col-6">
										<div className="custom-control custom-switch mb-1">
											<input id="allowedWebLogin" name="allowedWebLogin" className="custom-control-input" type="checkbox"
												checked={ typeof memberDetails.allowedWebLogin !== "undefined" && memberDetails.allowedWebLogin ? true
												: false } onChange={(e) => { this.formHasChanged = true; context.handleDetailsChange(e); }} />
											<label className="custom-control-label" htmlFor="allowedWebLogin">Allowed Web Login</label>
										</div>
									</div>
								</div>

							
								<br />
								<hr />
								<EditAddress type="correspondence" title="Correspondence Address" thisRef={this} countriesList={countriesList} address={memberDetails.correspondence} context={context} hiddenInputs={false} />
								<br /> 
								<hr />
								<div className="custom-control custom-switch mb-1 float-right">
									<input id="sameAsCorrespondence" name="sameAsCorrespondence" className="custom-control-input" type="checkbox"
										checked={ typeof memberDetails.sameAsCorrespondence !== "undefined" &&
										memberDetails.sameAsCorrespondence ? true : false } onChange={(e) => { this.formHasChanged = true; context.handleDetailsChange(e); }} />
									<label className="custom-control-label" htmlFor="sameAsCorrespondence">Same as correspondence address</label>
								</div>
								<EditAddress type="invoice" title="Invoice Address" countriesList={countriesList} thisRef={this} address={memberDetails.invoice} context={context} hiddenInputs={memberDetails.sameAsCorrespondence} />
					
								
								
								<br />
								<hr />
								<h4>Interest Areas</h4>
								<p>Please select the topic areas you are interested in.</p>
								
								<div className="row">
									<div className="col-md-6">
										<h6>Personal Interest Areas</h6>
										
										<div className="custom-control custom-switch mb-1">
											<input id="climatechangeenergy" name="climatechangeenergy" className="custom-control-input" type="checkbox"
												checked={ typeof memberDetails.climatechangeenergy !== "undefined" &&
												memberDetails.climatechangeenergy ? true : false } onChange={(e) => { this.formHasChanged = true; context.handleDetailsChange(e); }} />
											<label className="custom-control-label" htmlFor="climatechangeenergy">Climate Change &amp; Energy</label>
										</div>
										
										<div className="custom-control custom-switch mb-1">
											<input id="impactassessment" name="impactassessment" className="custom-control-input" type="checkbox"
												checked={ typeof memberDetails.impactassessment !== "undefined" &&
												memberDetails.impactassessment ? true : false }
												onChange={(e) => { this.formHasChanged = true; context.handleDetailsChange(e); }} />
											<label className="custom-control-label" htmlFor="impactassessment">Impact Assessment</label>
										</div>
										
										<div className="custom-control custom-switch mb-1">
											<input id="naturalenvironment" name="naturalenvironment" className="custom-control-input" type="checkbox"
												checked={ typeof memberDetails.naturalenvironment !== "undefined" && memberDetails.naturalenvironment
												? true : false } onChange={(e) => { this.formHasChanged = true; context.handleDetailsChange(e); }} />
											<label className="custom-control-label" htmlFor="naturalenvironment">Natural Environment</label>
										</div>
										
										<div className="custom-control custom-switch mb-1">
											<input id="environmentalmanagementsystems" name="environmentalmanagementsystems" className="custom-control-input"
												type="checkbox" checked={ typeof memberDetails.environmentalmanagementsystems !== "undefined" &&
												memberDetails.environmentalmanagementsystems ? true : false }
												onChange={(e) => { this.formHasChanged = true; context.handleDetailsChange(e); }} />
											<label className="custom-control-label" htmlFor="environmentalmanagementsystems">Environmental Management</label>
										</div>
										
										<div className="custom-control custom-switch mb-1">
											<input id="pollution" name="pollution" className="custom-control-input" type="checkbox"
												checked={ typeof memberDetails.pollution !== "undefined" && memberDetails.pollution ? true : false }
												onChange={(e) => { this.formHasChanged = true; context.handleDetailsChange(e); }} />
											<label className="custom-control-label" htmlFor="pollution">Pollution</label>
										</div>
										
										<div className="custom-control custom-switch mb-1">
											<input id="corporatesustainability" name="corporatesustainability" className="custom-control-input" type="checkbox"
												checked={ typeof memberDetails.corporatesustainability !== "undefined" &&
												memberDetails.corporatesustainability ? true : false } onChange={(e) => { this.formHasChanged = true; context.handleDetailsChange(e); }} />
											<label className="custom-control-label" htmlFor="corporatesustainability">Corporate Sustainability</label>
										</div>
										
										<div className="custom-control custom-switch mb-1">
											<input id="circulareconomy" name="circulareconomy" className="custom-control-input" type="checkbox"
												checked={ typeof memberDetails.circulareconomy !== "undefined" && memberDetails.circulareconomy ? true
												: false } onChange={(e) => { this.formHasChanged = true; context.handleDetailsChange(e); }} />
											<label className="custom-control-label" htmlFor="circulareconomy">Circular Economy</label>
										</div>
									</div>

									<div className="col-md-6">
										<h6>Work Interest Areas</h6>
										
										<div className="custom-control custom-switch mb-1">
											<input id="workenvironmentalmanagementsystems" name="workenvironmentalmanagementsystems" className="custom-control-input"
												type="checkbox" checked={ typeof memberDetails.workenvironmentalmanagementsystems !== "undefined" &&
												memberDetails.workenvironmentalmanagementsystems ? true : false }
												onChange={(e) => { this.formHasChanged = true; context.handleDetailsChange(e); }} />
											<label className="custom-control-label" htmlFor="workenvironmentalmanagementsystems">Environmental Management</label>
										</div>
										
										<div className="custom-control custom-switch mb-1">
											<input id="workcorporatesustainability" name="workcorporatesustainability" className="custom-control-input"
												type="checkbox" checked={ typeof memberDetails.workcorporatesustainability !== "undefined" &&
												memberDetails.workcorporatesustainability ? true : false } onChange={(e) => { this.formHasChanged = true; context.handleDetailsChange(e); }} />
											<label className="custom-control-label" htmlFor="workcorporatesustainability">Corporate Sustainability</label>
										</div>
									</div>
								</div>
								<br />


								<br />
								{/* <h4>Debug</h4>

								<div className="row">
									<div className="col-md-6">
										<div className="form-group">
											<label>GoCardless ID</label>
											<input className="form-control"
												type="text"
												name="goCardlessId"
												value={(typeof memberDetails.goCardlessId !== "undefined" && memberDetails.goCardlessId !== null ) 
													? memberDetails.goCardlessId : "" } 
												onChange={(e) => { this.formHasChanged = true; context.handleDetailsChange(e); }} />
										</div>
									</div>
								</div> */}
								
								<button type="submit" className="float-right btn-outline-primary btn px-5">Save</button>
								<div className="clearfix"></div>
							</form>
						</div>
					)
					
				}}
			</ViewContext.Consumer>
		)
	}

}

// Instead of duplicating address fields.
const EditAddress = ({ type, title, address, context, countriesList, thisRef, hiddenInputs }) => {

	if (typeof address !== "undefined") {
		return (
			<div id={"address-form-"+type}>
				<h4 className="mb-4">{title}</h4>
				
				{hiddenInputs ? (<></>) : (
					<><div className="row">
						<div className="col-6">
							<div className="form-group">
								<label className="required">Name</label>
								<input className="form-control" address-type={type} name="name" value={(address !== null && typeof
									address.name !== "undefined" ) ? address.name : "" } onChange={(e) => { thisRef.formHasChanged = true; context.handleDetailsChange(e); }} />
							</div>
						</div>
						<div className="col-6">
							<div className="form-group">
								<label className="required">Address Line 1</label>
								<input className="form-control" address-type={type} name="line1" value={(address !== null && typeof
									address.line1 !== "undefined" ) ? address.line1 : "" } onChange={(e) => { thisRef.formHasChanged = true; context.handleDetailsChange(e); }} />
							</div>
						</div>
					</div>
					
					<div className="row">
						<div className="col-6">
							<div className="form-group">
								<label>Address Line 2</label>
								<input className="form-control" address-type={type} name="line2" value={(address !== null && typeof
									address.line2 !== "undefined" ) ? address.line2 : "" } onChange={(e) => { thisRef.formHasChanged = true; context.handleDetailsChange(e); }} />
							</div>
						</div>
						<div className="col-6">
							<div className="form-group">
								<label>Address Line 3</label>
								<input className="form-control" address-type={type} name="line3" value={(address !== null && typeof
									address.line3 !== "undefined" ) ? address.line3 : "" } onChange={(e) => { thisRef.formHasChanged = true; context.handleDetailsChange(e); }} />
							</div>
						</div>
					</div>
					
					<div className="row">
						<div className="col-6">
							<div className="form-group">
								<label className="required">City</label>
								<input className="form-control" address-type={type} name="city" value={(address !== null && typeof
									address.city !== "undefined" ) ? address.city : "" } onChange={(e) => { thisRef.formHasChanged = true; context.handleDetailsChange(e); }} />
							</div>
						</div>
						<div className="col-6">
							<div className="form-group">
								<label>County</label>
								<input className="form-control" address-type={type} name="county" 
									value={(address !== null && typeof address.county !== "undefined" ) ? address.county : "" } 
									onChange={(e) => { thisRef.formHasChanged = true; context.handleDetailsChange(e); }} />
							</div>
						</div>
						<div className="col-6">
							<div className="form-group">
								<label>Country</label>
								<select className="form-control" 
									address-type={type} name="country" 
									value={(address !== null && typeof address.country !== "undefined" ) ? address.country : "" }
									onChange={(e) => { thisRef.formHasChanged = true; context.handleDetailsChange(e); }}>
									{(address !== null && typeof address.country !== "undefined") ? <option value="notSelected">No Country Selected</option> : ""} 
										{countriesList.map(country => (
											<option 
												key={"countries" + country.id} 
												value={country.name}
											>{country.name}</option>
										))}
								</select>
	
							</div>
						</div>
						<div className="col-6">
							<div className="form-group">
								<label className="required">Postcode</label>
								<input className="form-control" address-type={type} name="postcode" value={(address !== null && typeof
									address.postcode !== "undefined" ) ? address.postcode : "" }
									onChange={(e) => { thisRef.formHasChanged = true; context.handleDetailsChange(e); }} />
							</div>
						</div>
					</div></>
				)}
			</div>
		)

	} else {
		return (<span></span>);
	}
}

export default DetailsTab;