/**
 * Email Log Service
 * =================
 * 
 * @author    Jamie Hunt
 * @since     1.0.2
 */

// Module dependencies.
import Cookies from 'universal-cookie';
const axios = require('axios').default;
const EmailLogService = {};

// Required configuration.
// const API_URL = process.env.REACT_APP_API_URL;
const API_URL_PREFIX = (typeof process.env.REACT_APP_API_URL_PREFIX_OVERRIDE != "undefined") ? 
    process.env.REACT_APP_API_URL_PREFIX_OVERRIDE : process.env.REACT_APP_API_URL_PREFIX;


/**
 * Get all the emails from the API database.
 * @return array
 */
const CancelToken = axios.CancelToken;
let cancelSearch = null;
EmailLogService.getAllEmails = async function(params) {

    
    // Get the api session cookie.
    const cookies = new Cookies();
    let apiSessionId = cookies.get('apiSessionId')

    // Cookie doesn't exist.
    if (typeof apiSessionId == "undefined") {
       return false; 
    }

    // If we've searched before, cancel that request.
    if (cancelSearch != null) {
        cancelSearch('Operation canceled by the user.');
    }

    try {
        // Check if the cookie is valid and call the service.
        let response = await axios(API_URL_PREFIX + "email-log/get-all-emails", {
            method: 'GET',
            params: params,
            cancelToken: new CancelToken(function executor(c) {
                cancelSearch = c; // Resave var.
            }),
            headers: {
                'Authorization': "Bearer " + apiSessionId
            },
        });
        // Get the result.
        let result = await response;
    
        if (typeof result.data.results != "undefined") {
            return result.data.results
        } else {
            return[];
        }
    } catch (error) {
        return []; 
    }

}

/**
 * Get one email from the database using an ID.
 * @return object
 */
EmailLogService.getEmailById = async function(emailId) {
    // Get the api session cookie.
    const cookies = new Cookies();
    let apiSessionId = cookies.get('apiSessionId')

    // Cookie doesn't exist.
    if (typeof apiSessionId == "undefined") {
        return false; 
    }

    // Check if the cookie is valid and call the service.
    let response = await axios(API_URL_PREFIX + "email-log/get-email-by-id", {
        method: 'GET',
        params: {
            emailId: emailId
        },
        headers: {
            'Authorization': "Bearer " + apiSessionId
        },
    });

    // Get the result.
    let result = await response;
    if (result) {
        return result.data.email
    }
    
    return null;

}

/**
 * Get all the emails are associated with membership number
 * @return array
 */
EmailLogService.getMemberEmails = async function(memberId, emailLogTable) {
    // Get the api session cookie.
    const cookies = new Cookies();
    let apiSessionId = cookies.get('apiSessionId')

    // Cookie doesn't exist.
    if (typeof apiSessionId == "undefined") {
        return false; 
    }

    // If we've searched before, cancel that request.
    if (cancelSearch != null) {
        cancelSearch('Operation canceled by the user.');
    }

    // Check if the cookie is valid and call the service.
    let response = await axios(API_URL_PREFIX + "email-log/get-emails-by-membership-number", {
        method: 'GET',
        params: {
            memberId: memberId,
            emailLogTable: emailLogTable
        },
        cancelToken: new CancelToken(function executor(c) {
            cancelSearch = c; // Resave var.
        }),
        headers: {
            'Authorization': "Bearer " + apiSessionId
        },
    });

    // Get the result.
    let result = await response;

    if (result) {
        return result.data.allMemberEmails
        
    }
    
    return null;
}

export default EmailLogService
