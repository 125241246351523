import React, { Component, createContext } from 'react';
import { store } from 'react-notifications-component';
import Layout from '../../../bricks/Layout'
import MemberService from '../../../services/MemberService'
import { AuthContext } from './../../../components/AuthContext';
import AccessDenied from '../../../components/AccessDenied';

// Get the tabs.
import DetailsTab from "./DetailsTab";
import InvoicesTab from "./InvoicesTab";
import PaymentsTab from "./PaymentsTab";
import MembershipTab from "./MembershipTab";
import DirectDebitsTab from "./DirectDebitsTab";
import EmailLogTab from "./EmailLogTab";

export const ViewContext = createContext()

let closeModalButton = null;

class ViewContextProvider extends Component {
	constructor(props) {
		super(props);
		this.onClickTabButton = this.onClickTabButton.bind(this);
		this.handleDetailsChange = this.handleDetailsChange.bind(this);
		this.handleDetailsDateChange = this.handleDetailsDateChange.bind(this);
	}
	// Setting default tab state
	state = {
		openedTab: 'details',
		memberId: '',
		countriesList: [],
		loadingDetails: true,
		membershipDetails: {
			cpdDeclaration: false,
			codeOfConductDeclaration: false
		},
		memberDetails: {
			dateOfBirth: new Date(),
			addressCity: "",
			addressCountry: "",
			addressCounty: "",
			addressLine1: "",
			addressLine2: "",
			addressLine3: "",
			addressPostalcode: "",
			emailAddress: "",
			firstname: "",
			middlename: "",
			lastname: "",
			fullname: "",
			sameAsCorrespondence: false,
			mobile: "",
			jobtitle: "",  
			correspondence: {
				name: "",
				city: "",
				composite: "",
				country: "",
				county: "",
				line: "",
				line1: "",
				line2: "",
				line3: "",
				postcode: "",
			},
			invoice: {
				name: "",
				city: "",
				composite: "",
				country: "",
				county: "",
				line: "",
				line1: "",
				line2: "",
				line3: "",
				postcode: "",
			},

		},
		directDebitDetails: null,
		formStatus: {
			details: { error: "", success: "" }
		}
	};

	breadcrumbs = [
		{ title: 'Home', link: '/' }, 
		{ title: 'Members', link: '/members' },
		{ title: 'Edit Member', link: null, active: true }
	];

	componentDidMount() {
		let memberId = this.props.match.params.memberId
		let tabSlug = this.props.match.params.tabSlug
		
		this.setState({ openedTab: (typeof tabSlug != "undefined") ? tabSlug : this.state.openedTab })

		// Load all Members.
		MemberService.getInfo(memberId).then((memberDetails) => {
			
			// Transform the date of birth in to a date object.
			if (memberDetails.dateOfBirth != null) {
				let dobArr = memberDetails.dateOfBirth.split('/')
				let dob = new Date(dobArr[2], dobArr[1]-1, dobArr[0])
				memberDetails.dateOfBirth = dob;
			} else {
				memberDetails.dateOfBirth = new Date();
			}

			// Membership details
			let membershipDetails = {
				cpdDeclaration: memberDetails.cpdDeclaration,
				codeOfConductDeclaration: memberDetails.codeOfConductDeclaration
			}

			// Show the data.
			this.setState({ memberDetails: memberDetails, memberId: memberId, membershipDetails: membershipDetails, loadingDetails: false })
		});

		// Get the list of countries
		MemberService.getAllCountries().then(countries => {
			if (countries) {
				this.setState({ countriesList: countries }) 
			}
		}); 

	}

	currentlUrl = ""
	componentDidUpdate (prevProps, prevState) {
		let activeTab = window.location.pathname.split("/").pop();

		if (this.currentlUrl !== activeTab && (activeTab === "details" || activeTab === "membership" || activeTab === "invoices" || activeTab === "direct-debits" || activeTab === "payments" || activeTab === "email-log")) {
			this.currentlUrl = activeTab
			this.setState({ openedTab: activeTab })
		}
	}


	/**
	 * On tab click
	 * 
	 */
	onClickTabButton(e) {
		let memberId = this.props.match.params.memberId
		this.props.history.push("/members/" + memberId + "/" + e.target.getAttribute('tab'));
		this.setState({ openedTab: e.target.getAttribute('tab') })
	}


	/**
	 * On input change for the details tab.
	 */
	handleDetailsChange = e => {
		let { memberDetails } = this.state;
		let addressType = e.target.getAttribute('address-type');
		let isAddress = (addressType === null || addressType === "") ? false : true;

		if (isAddress) {
			if (typeof memberDetails[addressType] == "undefined" || memberDetails[addressType] == null) { memberDetails[addressType] = {}; }
			memberDetails[addressType][e.target.name] = e.target.value
			
			this.setState({ memberDetails: memberDetails })
		} else {
			let value = e.target.value

			if (e.target.getAttribute('type') === "checkbox") {
				memberDetails[e.target.name] = e.target.checked
			} else {
				memberDetails[e.target.name] = value
			}

			this.setState({ memberDetails: memberDetails })

		}
	};


	/**
	 * On input change for the membership tab.
	 */
	handleMembershipChanges = e => {
		let { membershipDetails } = this.state;
		let value = e.target.value

		if (e.target.getAttribute('type') === "checkbox") {
			membershipDetails[e.target.name] = e.target.checked
		} else {
			membershipDetails[e.target.name] = value
		}

		this.setState({ membershipDetails: membershipDetails })
	};

	/**
	 * Update the data for the sibling components.
	 */
	updateDirectDebitData = (info, updateMemberInfo) => {
		updateMemberInfo = (typeof updateMemberInfo !== "undefined") ? updateMemberInfo : false;

		if (updateMemberInfo) {
			let memberId = this.props.match.params.memberId
			
			// Load all Members.
			MemberService.getInfo(memberId).then((memberDetails) => {
						
				// Transform the date of birth in to a date object.
				if (memberDetails.dateOfBirth != null) {
					let dobArr = memberDetails.dateOfBirth.split('/')
					let dob = new Date(dobArr[2], dobArr[1]-1, dobArr[0])
					memberDetails.dateOfBirth = dob;
				} else {
					memberDetails.dateOfBirth = new Date();
				}

				// Show the data.
				this.setState({ memberDetails: memberDetails, memberId: memberId })
			});
		}

		this.setState({ directDebitDetails: info });
	}


	/**
	 * On input date change for the details tab.
	 */
	handleDetailsDateChange = date => {
		let { memberDetails } = this.state;
		memberDetails['dateOfBirth'] = date

		this.setState({ memberDetails: memberDetails })
	};

	handleCopyCorrespondence = (e) => {
		
		e.preventDefault();
		let { memberDetails } = this.state;
		// Copy the deails over
		memberDetails.invoice = memberDetails.correspondence
		this.setState({ memberDetails: memberDetails })

	}



	/**
	 * Handle details form submit.
	 */
	handleDetailsFormSubmit = (e) => {
		const submitButton = e.target.querySelector('button[type="submit"]');
		
		e.preventDefault();
		
		let { memberId, memberDetails } = this.state;
		
		// Saving feedback.
		submitButton.innerHTML = "Saving..."; submitButton.disabled = true;
		
		// Save the data.
		MemberService.updateInfo(memberId, memberDetails).then((response) => {
			submitButton.innerHTML = "Save"; submitButton.disabled = false;

			if (response.updated) {
				store.addNotification({
					title: "Success!",
					message: "Member details have been saved.",
					type: "success",
					container: "top-right",
					animationIn: ["animated", "slideInRight"],
					animationOut: ["animated", "slideOutRight"],
					dismiss: { duration: 5000 }
				});

				// Update info.
				// Transform the date of birth in to a date object.
				if (response.memberInfo.dateOfBirth != null) {
					let dobArr = response.memberInfo.dateOfBirth.split('/')
					let dob = new Date(dobArr[2], dobArr[1]-1, dobArr[0])
					response.memberInfo.dateOfBirth = dob;
				} else {
					response.memberInfo.dateOfBirth = new Date();
				}
	
				// Show the data.
				this.setState({ memberDetails: response.memberInfo })
			} else {
				// Display all the errors we have collected 
				for (let i = 0; i < response.errorMessage.length; i++) {
					const message = response.errorMessage[i];
					store.addNotification({
						title: "Error - Details not saved",
						message: message,
						type: "danger",
						container: "top-right",
						animationIn: ["animated", "slideInRight"],
						animationOut: ["animated", "slideOutRight"],
						dismiss: { duration: 7000 }
					});
					
				}
			}
					
		});
	}


	render() {

		let { 
			memberDetails, 
			membershipDetails, 
			openedTab,
			countriesList,
			directDebitDetails
		} = this.state;

		let { authContext } = this.props;
		let permissions = (typeof authContext.currentUser.permissions !== "undefined") ? authContext.currentUser.permissions : {};
		console.log('111',authContext.currentUser.id)
		return (
			<Layout breadcrumbs={this.breadcrumbs} match={this.props.match}>
				<div className="row">
					<div className="col-12">
						<ViewContext.Provider value={{
							...this.state,
							handleDetailsChange: this.handleDetailsChange,
							handleDetailsDateChange: this.handleDetailsDateChange,
							handleDetailsFormSubmit: this.handleDetailsFormSubmit,
							handleMembershipChanges: this.handleMembershipChanges,
							handleCopyCorrespondence: this.handleCopyCorrespondence,
						}}>
							<div>
								<div className="row">
									<div className="col-md-8">
										<h1 className="page-title">{memberDetails.fullname}</h1>
									</div>
									<div className="col-md-4">
										<button 
										className="btn btn-secondary float-right" 																										
										data-toggle="modal" 
										data-target="#password-reset-modal"
										>Send Password Reset Email</button>
										<EmailPasswordModal closeButton={this.closeModalButton} defaultEmail={memberDetails.emailAddress} contactId={memberDetails.contactId}/>
									</div>
								</div>
							</div>
							<ul className="nav nav-tabs mb-4">
								{ permissions.members &&
								<li className="nav-item">
									<button
										tab="details"
										className={"nav-link" + (openedTab === 'details' ? " active" : "")}
										onClick={this.onClickTabButton}
									>Details</button>
								</li>
								}
								
								{ permissions.members &&
								<li className="nav-item">
									<button 
										tab="membership"
										className={"nav-link" + (openedTab === 'membership' ? " active" : "")}
										onClick={this.onClickTabButton}
									>Membership</button>
								</li>
								}

								{ permissions.invoices &&
									<li className="nav-item">
									<button 
										tab="invoices"
										className={"nav-link" + (openedTab === 'invoices' ? " active" : "")}
										onClick={this.onClickTabButton}
										>Invoices</button>
								</li>
								}

								{ permissions.directDebits &&
								<li className="nav-item">
									<button 
										tab="direct-debits"
										className={"nav-link" + (openedTab === 'direct-debits' ? " active" : "")}
										onClick={this.onClickTabButton}
									>Direct Debits</button>
								</li>
								}

								{/* {permissions.payments && 
									<li className="nav-item">
										<button 
											tab="payments"
											className={"nav-link" + (openedTab === 'payments' ? " active" : "")}
											onClick={this.onClickTabButton}
										>Payments</button>
									</li>
								} */}
								{permissions.emailLog && 
									<li className="nav-item">
										<button 
											tab="email-log"
											className={"nav-link" + (openedTab === 'email-log' ? " active" : "")}
											onClick={this.onClickTabButton}
										>Email Log</button>
									</li>
								}
							</ul>
							{ permissions.members &&
								<div className={(openedTab !== "details" ? "d-none" : "")}>
									<DetailsTab countriesList={countriesList} memberDetails={memberDetails} memberId={this.props.match.params.memberId} MemberService={MemberService} />
								</div>
							}
							{ permissions.members &&
								<div className={(openedTab !== "membership" ? "d-none" : "")}>
									<MembershipTab memberDetails={memberDetails} membershipDetails={membershipDetails} memberId={this.props.match.params.memberId} />
								</div>
							}
							{ permissions.invoices &&
								<div className={(openedTab !== "invoices" ? "d-none" : "")}>
									<InvoicesTab currentUserId={authContext.currentUser.id} memberDetails={memberDetails} memberId={this.props.match.params.memberId} onClickTabButton={this.onClickTabButton} directDebitDetails={directDebitDetails} />
								</div>
							}
							{ permissions.directDebits &&
								<div className={(openedTab !== "direct-debits" ? "d-none" : "")}>
									<DirectDebitsTab memberDetails={memberDetails} memberId={this.props.match.params.memberId} updateDirectDebitData={this.updateDirectDebitData} />
								</div>
							}
							{ permissions.payments && 
								<div className={(openedTab !== "payments" ? "d-none" : "")}>
									<PaymentsTab memberDetails={memberDetails} memberId={this.props.match.params.memberId} />
								</div>
							}
							{ permissions.emailLog && 
								<div className={(openedTab !== "email-log" ? "d-none" : "")}>
									<EmailLogTab memberDetails={memberDetails} memberId={this.props.match.params.memberId} />
								</div>
							}
							
						</ViewContext.Provider>
					</div>
				</div>
			</Layout>
		);
	}
}

/**
 * Email Invoice modal.
 * 
 */
class EmailPasswordModal extends Component {
	state = { errorMessage: '' };


	handleSubmit = (e) => {
		e.preventDefault();
		let { contactId } = this.props
		let emailVal = e.target.querySelector('[name="email-password"]').value
		if (emailVal.length === 0 ) {
			store.addNotification({
				title: "Error!",
				message: "Please provide an email address",
				type: "danger",
				container: "top-right",
				animationIn: ["animated", "slideInRight"],
				animationOut: ["animated", "slideOutRight"],
				dismiss: { duration: 3000 }
			});	
		} else {
			// Send a reset password email.
			MemberService.sendResetPasswordEmail(contactId, emailVal).then((response) => {
			
				if (response.success) {
					store.addNotification({
						title: "Success!",
						message: "Reset Password email has been sent.",
						type: "success",
						container: "top-right",
						animationIn: ["animated", "slideInRight"],
						animationOut: ["animated", "slideOutRight"],
						dismiss: { duration: 3000 }
					});
				} else {
					store.addNotification({
						title: "Error!",
						message: "There was a problem sending the reset password email, please check that this member has an emil address and then try again.",
						type: "danger",
						container: "top-right",
						animationIn: ["animated", "slideInRight"],
						animationOut: ["animated", "slideOutRight"],
						dismiss: { duration: 3000 }
					});
				}
				closeModalButton.click();
			});
		}
	}


  	render() {
		let { defaultEmail } = this.props;
		return (
	  		<form onSubmit={this.handleSubmit.bind(this)}>
				<div className="modal" role="dialog" id="password-reset-modal">
					<div className="modal-dialog" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">Send Password Reset Email</h5>
							</div>
							<div className="modal-body">
								<div className="form-group">
									{/* List Mandates */}
									<label htmlFor="mandateSelect">Email</label>
									<input type="email" name="email-password" defaultValue={defaultEmail} className="form-control" />
								</div>
							</div>
							<div className="modal-footer">
								<button type="button" ref={button => closeModalButton = button} className="btn btn-default float-left" data-dismiss="modal">Cancel</button>
								<button type="submit" name="submit-password-reset" className="btn btn-primary float-right px-5">Send</button>
							</div>
						</div>
					</div>
				</div>
			</form>
		);
  	}
}

export default ((props, ref) => (
	<AuthContext.Consumer>{context => {
		if (context.currentUser.permissions.members) {
			return (<ViewContextProvider {...props} authContext={context} />)
		} else {
			return (<AccessDenied {...props}/>)
		}
	}}</AuthContext.Consumer>
));

// export default ((props, ref) => (
// 	<AuthContext.Consumer>{context => {
// 		// Check if we have any permissions for the hub
// 		if (context.currentUser.permissions.members === false
// 			&& context.currentUser.permissions.applications === false
// 			&& context.currentUser.permissions.directDebits === false
// 			&& context.currentUser.permissions.emailLog === false
// 			&& context.currentUser.permissions.invoices === false
// 			&& context.currentUser.permissions.payments === false
// 			&& context.currentUser.permissions.admin === false) {
// 			// They don't have any permissions
// 			return (<AccessDenied {...props}/>)
// 			} else {
// 			return (<Home {...props} authContext={context} />)
// 		}
// 	}}</AuthContext.Consumer>
// ));