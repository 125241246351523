import React, { Component } from 'react';
import Layout from '../../bricks/Layout'
import { useTable, usePagination } from 'react-table'
import { AuthContext } from '../../components/AuthContext';
import AccessDenied from '../../components/AccessDenied';
import ApplicationService from '../../services/ApplicationService';
import { Link, withRouter } from 'react-router-dom'

const moment = require('moment');

class StickerbookDiscounts extends Component {
	state = {
		allDiscounts: [],
		discountsTable: {
			isLoading: true,
			sort: {
				field: null,
				descending: false
			},
			type: 'stickerbook'
		},
	};

    // Breadcrumbs.
    breadcrumbs = [
			{ title: 'Home', link: '/' },
			{ title: 'Applications', link: '/applications'},
			{ title: 'Stickerbook Discounts', link: '/applications/stickerbook-discounts', active: true }
	];


	// Set the table columns.
	columns = [
		{ Header: 'Code', accessor: 'code', sortable: true },
		{ Header: 'Value', accessor: 'discountValue', sortable: true },
		{ Header: 'Enabled', accessor: 'enabled', sortable: true },
		{ Header: '', accessor: 'view', sortable: false }
	];

	componentDidMount() {
		let { discountsTable } = this.state

		// Load all Members.
		ApplicationService.getAllDiscounts(discountsTable).then((response) => {
			this.setState({
				allDiscounts: response.discounts,
				discountsTable: {
					...discountsTable,
					isLoading: false
				}
			})
		});
	}

	// On the sort change get new data.
	handleSortData = (sort) => {

		let { discountsTable } = this.state

		// Set loading to true and update the page number.
		discountsTable.isLoading = true;
		discountsTable.sort = sort;
		this.setState({ discountsTable: discountsTable })

		// Load all Members.
		ApplicationService.getAllDiscounts(discountsTable).then((response) => {
			this.setState({
				allDiscounts: response.discounts,
				discountsTable: {
					...discountsTable,
					isLoading: false
				}
			})
		});
	}


	render() {
		return (
			<Layout breadcrumbs={this.breadcrumbs} match={this.props.match}>
				<div className="page-title mb-4 clearfix">
					<div className="float-left">
						<h1 className="page-title">Stickerbook Discounts</h1>
					</div>
				</div>

				<div className="table-responsive">
					<CountriesTable
						tableData={this.state.allDiscounts}
						tableColumns={this.columns}
						isLoading={this.state.discountsTable.isLoading}
						sort={this.state.discountsTable.sort}
						sortData={this.handleSortData}
					/>
				</div>
			</Layout>
		);
	}
}


/**
 * Table to display the countries with pagination and filtering.
 * @param {}
 */
function CountriesTable({ tableColumns, tableData, isLoading, sort, sortData }) {

	// Define the table columns.
	const columns = React.useMemo(() => tableColumns, [tableColumns])

	// Define the data.
	const data = React.useMemo(() => tableData, [tableData])

	// Use the state and functions returned from useTable to build your UI
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		page
	} = useTable({
		columns,
		data,
		manualPagination: true,
		initialState: { },
	}, usePagination)
	
	/**
	 * On click of the sort header.
	 * @param {*} e
	 */
	const sortHeader = (e) => {
		let sortField = e.target.getAttribute("sortfield");

		if (sort.field === sortField && !sort.descending) {
			sort.descending = true;
		} else if (sort.field === sortField && sort.descending) {
			sort.field = null;
			sort.descending = false;
		} else {
			sort.field = sortField;
			sort.descending = false;
		}

		sortData(sort);
	}

	return (
		<div>
			<div className="table-responsive table-data position-relative">
				<table className={"table table-striped table-hover"} {...getTableProps()}>
					<thead>
						{headerGroups.map(headerGroup => (
							<tr {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map(column => (
									// Add the sorting props to control sorting. For this example
									// we can add them into the header props
									<th {...column.getHeaderProps()} onClick={sortHeader} sortfield={column.id}>
										{column.Header}
										{(column.sortable) ?
											(<span className={"sort " + (sort.field === column.id ? (sort.descending ? 'sort-desc' : 'sort-asc') : 'sort-none')}></span>)
										: ''}
									</th>
								))}
							</tr>
						))}
					</thead>
					<tbody {...getTableBodyProps()}>
						{page.map((row, i) => {
							prepareRow(row);
							return (
								<tr {...row.getRowProps()} to={"/applications/stickerbook-discounts/edit/" + row.original.id}>
									{row.cells.map(cell => {
										if (cell.column.id === 'view') {
											return (<td {...cell.getCellProps()}>
												<Link to={"/applications/stickerbook-discounts/edit/" + cell.row.original.id} className={"btn btn-primary btn-sm btn-view float-right"}><span>Edit</span></Link>
											</td>)
										} else if (cell.column.id === 'discountValue') {
											if (cell.row.original.discountType === "fixed") {
												return (<td {...cell.getCellProps()}>&pound;{cell.row.values.discountValue.toFixed(2)}</td>)
											} else {
												return (<td {...cell.getCellProps()}>{cell.row.values.discountValue}%</td>)
											}
										} else if (cell.column.id === 'enabled') {
											return (<td {...cell.getCellProps()}>
												<span className={"badge" + (cell.row.original.enabled === 1 ? " badge-success" : " badge-danger")}>{cell.row.original.enabled === 1 ? "Enabled" : "Disabled"}</span>
											</td>)
										} else {
											return (<td {...cell.getCellProps()}>{cell.render('Cell')}</td>)
										}
									})}
								</tr>
							)
						})}
					</tbody>
				</table>
				<div className={"loading-table-data" + ((isLoading) ? " active" : "")}><div className="loading-inner">Loading...</div></div>
			</div>
			
		</div>
	)
}

export default withRouter((props, ref) => (
	<AuthContext.Consumer>{context => {
		if (context.currentUser.permissions.applications) {
			return (<StickerbookDiscounts {...props} authContext={context} />)
		} else {
			return (<AccessDenied {...props}/>)
		}
	}}</AuthContext.Consumer>
));
