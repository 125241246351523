import React, { Component } from 'react';
import Header from '../bricks/Header';
import Sidenav from '../bricks/Sidenav';

class AccessDenied extends Component {
    render() { 

        return (
            <div>
                <div className="main-section">
                    <Sidenav match={this.props.match} />
                    <div className="main-content">
                        <Header/>

                        <div className="main" id="main">
							<h1>Sorry, you don’t have permission to access this page</h1>
							<p>Please contact your manager or the hub administrator for assistance</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
 
export default AccessDenied;