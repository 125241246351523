import React, { Component } from 'react';
import Layout from './../../bricks/Layout'
import { Link, withRouter } from 'react-router-dom'
import { AuthContext } from '../../components/AuthContext';
import AccessDenied from '../../components/AccessDenied';
import PaymentService from '../../services/PaymentService';
import { buildURI } from '../../components/CSVCore';
import { store } from 'react-notifications-component';
import 'react-datepicker/dist/react-datepicker.css';
var moment = require('moment');


let closeModalButton = null;
class BACSPayments extends Component {
	state = {
		response: '',
		allPayments: [],
		paymentTable: {
			isLoading: true,
			minimumBacsDate: null,
			total: 0
        },
	};

	// Breadcrumbs.
	breadcrumbs = [
		{ title: 'Home', link: '/' }, 
		{ title: 'Payments', link: '/payments' },
		{ title: 'BACs Payments', link: '/payments/export-bacs-payments', active: true }
	];

	componentDidMount() {
		let { paymentTable } = this.state

        // Load all emails.
        PaymentService.getAllBacsPayments(paymentTable).then((response) => {
			this.setState({ 
				allPayments: response.allPayments,
				paymentTable: {
					...paymentTable,
					isLoading: false,
					total: response.total,
					minimumBacsDate: response.minimumBacsDate
				}
			})
		});
	}

	render() {
        let { paymentTable, allPayments } = this.state;

		return (
			<Layout breadcrumbs={this.breadcrumbs} match={this.props.match}>
				<div className="page-title mb-4 clearfix">
					<div className="float-left">
						<h1>Export BACs transactions to Sage</h1>
						{/* <p>Minimum {paymentTable.minimumBacsDate !== null ? (moment(paymentTable.minimumBacsDate).format("DD/MM/YYYY")) : ""}</p> */}
					</div>

					{paymentTable.total > 0 ? (
						<div className="float-right">
							<button 
								className="btn btn-primary mt-1"
								// onClick={this.exportAllBacsPayments}
								data-toggle="modal" 
								data-target="#email-payments-modal"
							>Process {paymentTable.total} BACs Transactions</button>
						</div>
					) : (<></>)}
				</div>

				<div>
					{Object.keys(allPayments).length > 0 ? 
						Object.keys(allPayments).map((key) => {
							let payments = allPayments[key]
							return (
								<div className="mb-5" key={"payments-group-" + key}>
									<h4 className="mb-3">Transactions that arrived on {key}</h4>
									<div className="table-responsive position-relative">
										<table className="table table-striped">
											<thead>
												<tr>
													<th colSpan="1" style={{width: "10%"}}>Nominal code</th>
													<th colSpan="1" style={{width: "15%"}}>Payment Method</th>
													<th colSpan="1" style={{width: "45%"}}>Category</th>
													<th colSpan="1" style={{width: "10%"}} className="text-right">NET</th>
													<th colSpan="1" style={{width: "10%"}} className="text-right">VAT</th>
													<th colSpan="1" style={{width: "10%"}} className="text-right">Total</th>
													<th colSpan="1" style={{width: "10%"}}></th>
												</tr>
											</thead>
											<tbody>
												{payments.map((payment, keyX) => (
													<tr key={"payments-table-" + key + "-" + keyX}>
														<td>{payment.nominalLedgerCode}</td>
														<td>Electronic Transfer</td>
														<td>{payment.name}</td>
														<td className="text-right">&pound;{Number(payment.pricePerUnit).toFixed(2)}</td>
														<td className="text-right">&pound;{Number(payment.taxAmount).toFixed(2)}</td>
														<td className="text-right">&pound;{Number(payment.extendedAmount).toFixed(2)}</td>
                                                        <td>
															<Link to={"/invoices/" + payment.invoiceid} className={"btn btn-secondary btn-sm btn-view float-right"}><span>Invoice #{payment.invoiceNumber}</span></Link>
                                                        </td>
													</tr>
												))}
											</tbody>
										</table>
									</div>
								</div>
							)
	
						}) : (paymentTable.minimumBacsDate !== null) ? 
							(<div className="alert alert-warning" role="alert">
								<span>There are no payments available to export.</span>
							</div>) : 
							(<div className="alert alert-info" role="alert">
								<h3>Loading</h3>
								<span>Loading payments information, please wait...</span>
							</div>)
					}
					
				</div>


				{/* Show Email invoice modal. */}
				<BACsModal closeButton={this.closeModalButton} />
			</Layout>
		);
	}
}



/**
 * Email Invoice modal.
 * 
 */
class BACsModal extends Component {
	state = { 
		errorMessage: '',
		sendEmailReceipt: false
	};


	/**
	 * On submit of the form, trigger this function.
	 * @param {*} e 
	 */
	handleSubmit = async (e) => {
		e.preventDefault();
		
		
		let { sendEmailReceipt } = this.state;
		
		if (sendEmailReceipt) {
			let response = await PaymentService.emailBacsReceipts();

			// Check if we need to display an error message
			if (typeof response.errors !== "undefined" && response.errors.length !== 0) {

				// Yes, loop through and display all the errors.
				for (let i = 0; i < response.errors.length; i++) {
					store.addNotification({
						title: "Error!",
						message: response.errors[i],
						type: "danger",
						container: "top-right",
						animationIn: ["animated", "slideInRight"],
						animationOut: ["animated", "slideOutRight"],
						dismiss: { duration: 3000 }
					});
				}
				
			} else {
				store.addNotification({
					title: "Success!",
					message: "Successfully email receipts for BACs transactions.",
					type: "success",
					container: "top-right",
					animationIn: ["animated", "slideInRight"],
					animationOut: ["animated", "slideOutRight"],
					dismiss: { duration: 3000 }
				});
				
			}

			// Request CSV file and don't send email receipts.
			await this.exportCSVData();

			// Close the modal.
			closeModalButton.click();

			
		} else {
			// Request CSV file and don't send email receipts.
			await this.exportCSVData();

			// Close the modal.
			closeModalButton.click();
		}
	};

	/**
	 * Export the CSV data and serve file.
	 */
	exportCSVData = async () => {
		let response = await PaymentService.exportAllBacsPayments(); 

		// Check if we need to display an error message
		if (typeof response.errors !== "undefined" && response.errors.length !== 0) {

			// Yes, loop through and display all the errors.
			for (let i = 0; i < response.errors.length; i++) {
				store.addNotification({
					title: "Error!",
					message: response.errors[i],
					type: "danger",
					container: "top-right",
					animationIn: ["animated", "slideInRight"],
					animationOut: ["animated", "slideOutRight"],
					dismiss: { duration: 3000 }
				});
			}
			
		} else {
			
			// Export to CSV
			buildURI(response.allPayments, false, response.headerData, ",", "", "IEMA-BACs-" + moment().format('YYYY-MM-DD') + ".csv");

			store.addNotification({
				title: "Success!",
				message: "Successfully exported the BACs transactions.",
				type: "success",
				container: "top-right",
				animationIn: ["animated", "slideInRight"],
				animationOut: ["animated", "slideOutRight"],
				dismiss: { duration: 3000 }
			});
		}
		
		return true;
	}


  	render() {
		// let { emailInvoice, defaultEmail } = this.props;

		return (
	  		<form onSubmit={this.handleSubmit.bind(this)}>
				<div className="modal" role="dialog" id="email-payments-modal">
					<div className="modal-dialog" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">Processing BACs transactions</h5>
							</div>

							<div className="modal-body">
								<div className="form-group">
									<label htmlFor="mandateSelect">Would you like to send email receipts?</label><br />

									<div className="form-check form-check-inline">
										<input className="form-check-input" type="radio" name="sendEmailReceiptsOptions" id="sendEmailReceipts0" value="false" defaultChecked 
											onChange={e => { this.setState({ sendEmailReceipt: (e.target.value === 'true') }) }} />
										<label className="form-check-label" htmlFor="sendEmailReceipts0">No</label>
									</div>
									
									<div className="form-check form-check-inline">
										<input className="form-check-input" type="radio" name="sendEmailReceiptsOptions" id="sendEmailReceipts1" value="true" 
											onChange={e => { this.setState({ sendEmailReceipt: (e.target.value === 'true') }) }} />
										<label className="form-check-label" htmlFor="sendEmailReceipts1">Yes</label>
									</div>
								</div>
							</div>
							<div className="modal-footer">
								<button type="button" ref={button => closeModalButton = button} className="btn btn-default float-left" data-dismiss="modal">Cancel</button>
								<button type="submit" className="btn btn-primary float-right">Process BACs transactions</button>
							</div>
						</div>
					</div>
				</div>
			</form>
		);
  	}
}


export default withRouter((props, ref) => (
	<AuthContext.Consumer>{context => {
		if (context.currentUser.permissions.payments) {
			return (<BACSPayments {...props} authContext={context} />)
		} else {
			return (<AccessDenied {...props}/>)
		}
	}}</AuthContext.Consumer>
));