import React, { Component } from 'react';
import Layout from '../../bricks/Layout';
import EmailLogService from '../../services/EmailLogService';
import { Modal } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import { useTable, usePagination } from 'react-table';
import { AuthContext } from '../../components/AuthContext';
import AccessDenied from '../../components/AccessDenied';
import 'react-datepicker/dist/react-datepicker.css';
var moment = require('moment');

class Emails extends Component {
	state = {
		response: '',
		allEmails: [],
		emailDisplayModal: false,
		emailDisplayed: {},
		startDate: '',
		setStartDate: '',
		allEmailsTable: {
			query: {
				searchQuery: "",
				startDate: null,
				endDate: null,
			},
			pageIndex: 0,
			pageCount: 0,
			perPage: 30,
			isLoading: true,
		}
	};

	// Breadcrumbs.
	breadcrumbs = [
		{ title: 'Home', link: '/' }, 
		{ title: 'Email Log', link: '/email-log', active: true }
	];

	componentDidMount() {
		let { allEmailsTable } = this.state

        // Load all emails.
        EmailLogService.getAllEmails(allEmailsTable).then((response) => {
			this.setState({ 
				allEmails: response.allEmails,
				allEmailsTable: {
					...allEmailsTable,
					isLoading: false,
					pageCount: response.totalPages,
					pageIndex: response.pageIndex,
					perPage: response.perPage
				}
			})
		});
    }

	// Set the table columns.
	columns = [
		{ Header: 'ID', accessor: 'id' },
		{ Header: 'To', accessor: 'recipientEmail' },
		{ Header: 'Membership Number', accessor: 'membershipNumber' },
		{ Header: 'Subject', accessor: 'subject' },
		{ Header: 'Date / Time', accessor: (data) => {
			return moment(data.dateSent).local().format("DD/MM/YYYY hh:mm:ss a")
		 } },
		{ Header: 'Status', accessor: 'statusName' },
		{ Header: '', accessor: 'view' },
	];


	/**
     * Show the modal and fetch email information.
     */
    handleEmailDisplayModal = (e) => {
		let emailId = e.target.getAttribute('email-id')
		this.setState({ emailDisplayModal: true })

        // Get this email
        EmailLogService.getEmailById(emailId).then((email) => {

			let hubUser = '';
			if(typeof(email.hubUserFirstname) != 'undefined' && email.hubUserFirstname != null && typeof(email.hubUserLastname) != 'undefined' && email.hubUserLastname != null)
			{
				hubUser = '(issued by: ' + email.hubUserFirstname + ' ' + email.hubUserLastname + ')';
			}

			email.hubUser = hubUser;
			
			this.setState({ emailDisplayed: email })
		});
		
	}

		// On the search input change, update the state.
		handleSearchInput = (e, fieldName) => {
			let { allEmailsTable } = this.state

			// Get the changed field info.
			let value = (e !== null && typeof e.target !== "undefined") ? e.target.value.toString() : e;
			let name = (typeof fieldName !== "undefined") ? fieldName : e.target.getAttribute('name');

			// Set loading to true and update the page number.
			allEmailsTable.isLoading = true;
			allEmailsTable.query[name] = value;
			allEmailsTable.pageIndex = 0;
			this.setState({ allEmailsTable: allEmailsTable })

			// Request.
			EmailLogService.getAllEmails(allEmailsTable).then((response) => {
				if (typeof response.query != "undefined" && (this.state.allEmailsTable.query.searchQuery === response.query.searchQuery || (this.state.allEmailsTable.query.searchQuery === "" && response.query.searchQuery === null))) {
					
					this.setState({ 
						allEmails: response.allEmails, 
						allEmailsTable:{
							...allEmailsTable,
							pageCount: response.totalPages,
							isLoading: false 
						} })
				}
			});
		}

		// On page change for the table.
		handlePageChange = (pageIndex) => {
			let { allEmailsTable } = this.state
			// Set loading to true and update the page number.
			allEmailsTable.isLoading = true;
			allEmailsTable.pageIndex = pageIndex;
			this.setState({ allEmailsTable: allEmailsTable })
	
			// Load all Members.
			EmailLogService.getAllEmails(allEmailsTable).then((response) => {
				this.setState({ 
					allEmails: response.allEmails,
					allEmailsTable: {
						...allEmailsTable,
						isLoading: false,
						pageCount: response.totalPages,
						pageIndex: response.pageIndex,
						perPage: response.perPage
					}
				})
			});
		}


		// On the sort change get new data.
		handleSortData = (sort) => {
			let { allEmailsTable } = this.state
	
	
			// Set loading to true and update the page number.
			allEmailsTable.isLoading = true;
			allEmailsTable.sort = sort;
			this.setState({ allEmailsTable: allEmailsTable })
	
			// Load all emails.
			EmailLogService.getAllEmails(allEmailsTable).then((response) => {
				this.setState({ 
					allMembers: response.members,
					allEmailsTable: {
						...allEmailsTable,
						isLoading: false,
						pageCount: response.totalPages,
						pageIndex: response.pageIndex,
						perPage: response.perPage
					}
				})
			});
		}


	/**
     * Hide the modal.
     */
	closeEmailDisplayModal = (e) => {
		this.setState({ emailDisplayModal: false })
	}
	
	render() {
		return (
			<AuthContext.Consumer>
			{context => {
				let { startDate, endDate } = this.state;
				let { statusName } = this.state.emailDisplayed

				// Message Status Colours
				let className;
				switch (statusName) {
					case 'Sent': className = 'success'; break;
					case 'Loaded': className = 'success'; break;
					case 'Link Clicked': className = 'success'; break;
					case 'Held': className = 'warning'; break;
					case 'Delayed': className = 'warning'; break;
					case 'Cancelled': className = 'danger'; break;
					default: className = 'warning'; break;
				}

					return (
					<div>
						<Layout breadcrumbs={this.breadcrumbs} match={this.props.match}>
						<h1 className="float-left page-title">Email Log</h1>
						
						<div className="float-right">
							<input name="searchQuery" className="form-control form-control-sm" placeholder="Search Email Log" onChange={this.handleSearchInput} />
							<DatePicker
								className="form-control form-control-sm email-log-datepicker"
								selected={startDate}
								dateFormat="dd/MM/yyyy"
								onChange={date => { 
									this.setState({ startDate: date })
									if (date !== null && date !== "") {
										this.handleSearchInput(moment(date).format('YYYY-MM-DD') + " 00:00:00", 'startDate')
									} else {
										this.handleSearchInput(null, 'startDate')
									}
								}}
								placeholderText="Start Date"
							/>
							<DatePicker
								className="form-control form-control-sm"
								selected={endDate}
								dateFormat="dd/MM/yyyy"
								onChange={date => { 
									this.setState({ endDate: date })
									if (date !== null && date !== "") {
										this.handleSearchInput(moment(date).format('YYYY-MM-DD') + " 23:59:00", 'endDate')
									} else {
										this.handleSearchInput(null, 'endDate')
									}
								}}
								placeholderText="End Date"
							/>
						</div>


						<div className="table-responsive">
							{this.state.allEmailsTable.pageCount !== 0 ? 
							<EmailsTable 
							handleEmailDisplayModal={this.handleEmailDisplayModal}
							propData={this.state.allEmails} 
							propColumns={this.columns}
							tableData={this.state.allMembers} 
							tableColumns={this.columns} 
							searchQuery={this.state.allEmailsTable.searchQuery} 
							gotoPage={this.handlePageChange} 
							handleTdClick={this.handleTdClick}
							pageIndex={this.state.allEmailsTable.pageIndex} 
							pageCount={this.state.allEmailsTable.pageCount}
							isLoading={this.state.allEmailsTable.isLoading}
							sort={this.state.allEmailsTable.sort}
							sortData={this.handleSortData} /> : 
							<h3>No results found, try another search.</h3>} 

							<Modal centered size="lg" show={this.state.emailDisplayModal} onHide={this.closeEmailDisplayModal}>	
									<Modal.Header>
										<Modal.Title>{this.state.emailDisplayed.subject} {this.state.emailDisplayed.hubUser}</Modal.Title>
											<button type="button" className="close" onClick={this.closeEmailDisplayModal}><span aria-hidden="true">×</span><span className="sr-only">Close</span></button>
									</Modal.Header>
									<Modal.Body>
										<div className="container">
											<div className="row">
												<div className="col-sm-6">
													<p>To: {this.state.emailDisplayed.recipientEmail}<br />
													{this.state.emailDisplayed.dateSent}<br/></p>
												</div>	
												<div className="col-sm-6">
													<p className={"float-right badge badge-" + className}>Status: {this.state.emailDisplayed.statusName}<br /></p>
												</div>
											</div>
											<div className="row">
												<div className="col-sm-12">
													<hr />
													<div className="email-log-message" dangerouslySetInnerHTML={{__html: this.state.emailDisplayed.body}} />
												</div>
											</div>
										</div>
											
									</Modal.Body>
									<Modal.Footer></Modal.Footer>

							</Modal>
						</div>
					</Layout>
				</div>
			)
			}}
		</AuthContext.Consumer>
		);
	}
}

function EmailsTable({ handleEmailDisplayModal, propColumns, propData, gotoPage, pageIndex, pageCount, isLoading, sort, sortData }) {
	// Define the table columns.
	const columns = React.useMemo(() => propColumns, [propColumns])
	
	// Define the data.
	const data = React.useMemo(() => propData, [propData])
	// Use the state and functions returned from useTable to build your UI
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow,
	} = useTable({
		columns,
		data,
		manualPagination: true, 
		initialState: { pageIndex: pageIndex, pageSize: 30 },
	}, usePagination)
	
	/**
	 * Calculate and show the correct pages.
	 * @param {*} pIndex 
	 * @param {*} pCount 
	 */
	const numberedPages = (pIndex, pCount) => {
		let gCount = 3; // Number of page numbers we would like to display
		let showPagesArr = [];

		// Loop through all pages and calculate which ones to show.
		for (let i = 0; i < pCount; i++) {
			if ((pIndex - gCount) < i && (gCount + pIndex) > i) {
				showPagesArr.push(i);
			}            
		}
		return showPagesArr;
	}

	/**
	 * On click of the sort header.
	 * @param {*} e 
	 */
	const sortHeader = (e) => {
		let sortField = e.target.getAttribute("sortfield");

		if (sort.field === sortField && !sort.descending) {
			sort.descending = true;
		} else if (sort.field === sortField && sort.descending) {
			sort.field = null;
			sort.descending = false;
		} else {
			sort.field = sortField;
			sort.descending = false;
		}

		sortData(sort);
	}
    
	return (
		<div>
			<div className="table-responsive table-data position-relative">
				<table className={"table table-striped"} {...getTableProps()}>
					<thead>
						{headerGroups.map(headerGroup => (
						<tr {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map(column => (
							<th {...column.getHeaderProps()} onClick={sortHeader} sortfield={column.id}>
								{column.Header}
								{(column.sortable) ? 
									(<span className={"sort " + (sort.field === column.id ? (sort.descending ? 'sort-desc' : 'sort-asc') : 'sort-none')}></span>) 
								: ''}
							</th>
							))}
						</tr>
						))}
					</thead>
					<tbody {...getTableBodyProps()}>
						{rows.map((row, i) => {
							prepareRow(row);
							return (
								<tr {...row.getRowProps()}>
									{row.cells.map(cell => {
										if (cell.column.id === 'name') {
											return <td key={i} {...cell.getCellProps()}>{cell.row.original.firstname + ' ' + cell.row.original.lastname}</td>;
										} else if (cell.column.id === 'view') {
											return (<td key={i}><button key={i} onClick={handleEmailDisplayModal} email-id={cell.row.original.id} className={"btn btn-primary btn-sm btn-view"}><span>View</span></button></td>)
										} else if (cell.column.id === 'statusName') {
												let label = cell.row.original.statusName;
												let className;
												switch (label) {
													case 'Sent': className = 'success'; break;
													case 'Loaded': className = 'success'; break;
													case 'Link Clicked': className = 'success'; break;
													case 'Held': className = 'warning'; break;
													case 'Delayed': className = 'warning'; break;
													case 'Cancelled': className = 'danger'; break;
													default: className = 'warning'; break;
												}
												return (<td {...cell.getCellProps()}>
													<span className={"badge badge-" + className}>{cell.row.original.statusName}</span>
												</td>)
										} else {
											return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
										}
									})}
								</tr>
							);
						})}
					</tbody>
				</table>
				<div className={"loading-table-data" + ((isLoading) ? " active" : "")}><div className="loading-inner">Loading...</div></div>
			</div>
			<nav className="d-flex mt-4" aria-label="Page navigation">
				<ul className="pagination mx-auto">
					<li className="page-item">
						<button className="page-link" onClick={() => gotoPage(0)} disabled={(pageIndex === 0)}>&lt;&lt; First</button>
					</li>
					<li className="page-item">
						<button className="page-link" onClick={() => gotoPage(pageIndex-1)} disabled={(pageIndex === 0)}>Previous</button>
					</li>
					{numberedPages(pageIndex, pageCount).map((pageNum) => {
						return (
							<li className={"page-item " + (pageIndex === pageNum ? 'active' : '')} key={pageNum+1}>
								<button className="page-link" onClick={() => gotoPage(pageNum)}>{pageNum+1}</button>
							</li>
						)
					})}
					<li className="page-item">
						<button className="page-link" onClick={() => gotoPage(pageIndex+1)} disabled={(pageIndex+1 === pageCount)}>Next &gt;</button>
					</li>
					<li className="page-item">
						<button className="page-link" onClick={() => gotoPage(pageCount-1)} disabled={(pageIndex+1 === pageCount)}>Last &gt;&gt;</button>
					</li>
				</ul>
			</nav>
		</div>

	)
}

export default ((props, ref) => (
	<AuthContext.Consumer>{context => {
		if (context.currentUser.permissions.emailLog) {
			return (<Emails {...props} authContext={context} />)
		} else {
			return (<AccessDenied {...props}/>)
		}
	}}</AuthContext.Consumer>
));