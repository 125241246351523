/**
 * User Service
 * =================
 * 
 * @author    José Veríssimo
 * @since     1.0.2
 */

// Module dependencies.
import Cookies from 'universal-cookie';
const axios = require('axios').default;

const PaymentService = {};

// Required configuration.
const API_URL_PREFIX = (typeof process.env.REACT_APP_API_URL_PREFIX_OVERRIDE != "undefined") ? 
    process.env.REACT_APP_API_URL_PREFIX_OVERRIDE : process.env.REACT_APP_API_URL_PREFIX;

/**
 * Get the basic info for a member.
 * 
 * @return {}
 */
PaymentService.getByMemberId = async function(memberId) {
    // Get the api session cookie.
    const cookies = new Cookies();
    let apiSessionId = cookies.get('apiSessionId')


    // Cookie doesn't exist.
    if (typeof apiSessionId == "undefined") {
        return null;
    }

    let response = await axios.get(API_URL_PREFIX + "payment/by-member-id", {
        params: { memberId: memberId },
        headers: {
            'Authorization': "Bearer " + apiSessionId
        }
    })

    if (typeof response.data.payments != "undefined") {
        return response.data.payments;
    } else {
        return null;
    }
}


/**
 * Get the go cardless info for a member.
 * 
 * @return {}
 */
PaymentService.getGoCardlessInfo = async function(memberId) {
    // Get the api session cookie.
    const cookies = new Cookies();
    let apiSessionId = cookies.get('apiSessionId')


    // Cookie doesn't exist.
    if (typeof apiSessionId == "undefined") {
        return null;
    }

    let response = await axios.get(API_URL_PREFIX + "payment/gocardless-info", {
        params: { memberId: memberId },
        headers: {
            'Authorization': "Bearer " + apiSessionId
        }
    })

    if (typeof response.data != "undefined") {
        return response.data;
    } else {
        return null;
    }
}


/**
 * Get the go cardless info for a member.
 * 
 * @return {}
 */
PaymentService.getMandateInfo = async function(mandateId) {
    // Get the api session cookie.
    const cookies = new Cookies();
    let apiSessionId = cookies.get('apiSessionId')


    // Cookie doesn't exist.
    if (typeof apiSessionId == "undefined") {
        return null;
    }

    let response = await axios.get(API_URL_PREFIX + "payment/gocardless-mandate-info", {
        params: { mandateId: mandateId },
        headers: {
            'Authorization': "Bearer " + apiSessionId
        }
    })

    if (typeof response.data != "undefined") {
        return response.data;
    } else {
        return null;
    }
}


/**
 * Get the go cardless info for a member.
 * 
 * @return {}
 */
PaymentService.getPaymentInfo = async function(paymentId) {
    // Get the api session cookie.
    const cookies = new Cookies();
    let apiSessionId = cookies.get('apiSessionId')


    // Cookie doesn't exist.
    if (typeof apiSessionId == "undefined") {
        return null;
    }

    let response = await axios.get(API_URL_PREFIX + "payment/gocardless-payment-info", {
        params: { paymentId: paymentId },
        headers: {
            'Authorization': "Bearer " + apiSessionId
        }
    })

    if (typeof response.data != "undefined") {
        return response.data;
    } else {
        return null;
    }
}


/**
 * Get the go cardless bank account info for a member.
 * 
 * @return {}
 */
PaymentService.getBankAccountInfo = async function(bankAccountId) {
    // Get the api session cookie.
    const cookies = new Cookies();
    let apiSessionId = cookies.get('apiSessionId')


    // Cookie doesn't exist.
    if (typeof apiSessionId == "undefined") {
        return null;
    }

    let response = await axios.get(API_URL_PREFIX + "payment/gocardless-bank-account-info", {
        params: { bankAccountId: bankAccountId },
        headers: {
            'Authorization': "Bearer " + apiSessionId
        }
    })

    if (typeof response.data != "undefined") {
        return response.data;
    } else {
        return null;
    }
}


/**
 * List GoCardless Bank Accounts.
 * @param params
 * @return {}
 */
PaymentService.goCardlessListBankAccounts = async function(params) {
    // Get the api session cookie.
    const cookies = new Cookies();
    let apiSessionId = cookies.get('apiSessionId')


    // Cookie doesn't exist.
    if (typeof apiSessionId == "undefined") {
        return null;
    }

    let response = await axios.get(API_URL_PREFIX + "payment/gocardless-list-bank-accounts", {
        params: params,
        headers: {
            'Authorization': "Bearer " + apiSessionId
        }
    })

    if (typeof response.data != "undefined") {
        return response.data;
    } else {
        return null;
    }
}

/**
 * Create new customer in GoCardless and save it on the individual.
 * @param contactId
 * 
 * @return {}
 */
PaymentService.createNewGoCardlessCustomerReference = async function(contactId) {
    // Get the api session cookie.
    const cookies = new Cookies();
    let apiSessionId = cookies.get('apiSessionId')


    // Cookie doesn't exist.
    if (typeof apiSessionId == "undefined") {
        return null;
    }

    let response = await axios.post(API_URL_PREFIX + "payment/gocardless-create-new-customer", {
        contactId: contactId
    }, {
        headers: {
            'Authorization': "Bearer " + apiSessionId
        }
    })

    if (typeof response.data != "undefined") {
        return response.data;
    } else {
        return null;
    }
}


/**
 * List GoCardless Mandates.
 * @param params
 * @return {}
 */
PaymentService.goCardlessListMandates = async function(params) {
    // Get the api session cookie.
    const cookies = new Cookies();
    let apiSessionId = cookies.get('apiSessionId')


    // Cookie doesn't exist.
    if (typeof apiSessionId == "undefined") {
        return null;
    }

    let response = await axios.get(API_URL_PREFIX + "payment/gocardless-list-mandates", {
        params: params,
        headers: {
            'Authorization': "Bearer " + apiSessionId
        }
    })

    if (typeof response.data != "undefined") {
        return response.data;
    } else {
        return null;
    }
}

/**
 * List GoCardless Payments.
 * @param params
 * @return {}
 */
PaymentService.goCardlessListPayments = async function(params) {
    // Get the api session cookie.
    const cookies = new Cookies();
    let apiSessionId = cookies.get('apiSessionId')


    // Cookie doesn't exist.
    if (typeof apiSessionId == "undefined") {
        return null;
    }

    let response = await axios.get(API_URL_PREFIX + "payment/gocardless-list-payments", {
        params: params,
        headers: {
            'Authorization': "Bearer " + apiSessionId
        }
    })

    if (typeof response.data != "undefined") {
        return response.data;
    } else {
        return null;
    }
}


/**
 * Handle the payment for invoices.
 * @deprecated
 * @param token
 * @param memberId
 * @param invoices
 * @return {}
 */
PaymentService.handleInvoicePayment = async function(token, memberId, invoices, billingAddress) {
    
    // Get the api session cookie.
    const cookies = new Cookies();
    let apiSessionId = cookies.get('apiSessionId')


    // Cookie doesn't exist.
    if (typeof apiSessionId == "undefined") {
        return null;
    }
    
    let response = await axios.post(API_URL_PREFIX + "payment/handle-invoice-payment", 
        {
            memberId: memberId,
            token: token,
            invoices: invoices,
            billingAddress: billingAddress
        }, 
        { headers: { 'Authorization': "Bearer " + apiSessionId } }
    )
        console.log(response);
    if (typeof response.data != "undefined") {
        return response.data;
    } else {
        return null;
    }
}

PaymentService.handleCreateInvoicePaymentIntent = async (memberId, invoiceNumber, addressType, paymentMethodId) => {
    // Get the api session cookie.
    const cookies = new Cookies();
    let apiSessionId = cookies.get('apiSessionId')


    // Cookie doesn't exist.
    if (typeof apiSessionId == "undefined") {
        return null;
    }
    
    let response = await axios.post(API_URL_PREFIX + "payment/create-invoice-payment-intent", 
        {
            memberId,
            invoice: invoiceNumber,
            addressType,
            paymentMethodId
        }, 
        { headers: { 'Authorization': "Bearer " + apiSessionId } }
    )
    if (typeof response.data != "undefined") {
        return response.data;
    } else {
        return null;
    }
}


/**
 * Handle the payment for invoices through direct debit.
 * 
 * @param mandateId
 * @param memberId
 * @param invoices
 * @return {}
 */
PaymentService.handleInvoicePaymentDirectDebit = async function(currentUserId, mandateId, memberId, invoices, billingAddress) {
    
    console.log('handleInvoicePaymentDirectDebit');
    // Get the api session cookie.
    const cookies = new Cookies();
    let apiSessionId = cookies.get('apiSessionId')


    // Cookie doesn't exist.
    if (typeof apiSessionId == "undefined") {
        return null;
    }

    let response = await axios.post(API_URL_PREFIX + "payment/handle-invoice-payment-direct-debit", 
        {
            currentUserId: currentUserId,
            memberId: memberId,
            mandateId: mandateId,
            invoices: invoices,
            billingAddress: billingAddress
        }, 
        { headers: { 'Authorization': "Bearer " + apiSessionId } }
    )

    console.log('response2',response);

    if (typeof response.data != "undefined") {
        return response.data;
    } else {
        return null;
    }
}

PaymentService.checkForPaymentStatus = async function(invoiceNumber) {
    // Get the api session cookie.
    const cookies = new Cookies();
    let apiSessionId = cookies.get('apiSessionId')


    // Cookie doesn't exist.
    if (typeof apiSessionId == "undefined") {
        return null;
    }

    let response = await axios.post(API_URL_PREFIX + "payment/check-invoice-payment-status", 
        {
            invoiceNumber
        }, 
        { headers: { 'Authorization': "Bearer " + apiSessionId } }
    )

    if (typeof response.data != "undefined") {
        return response.data;
    } else {
        return null;
    }
}


/**
 * Create a GoCardless Bank Account.
 * 
 * @param bankAccountInfo
 * @return {}
 */
PaymentService.createGoCardlessBankAccount = async function(bankAccountInfo) {
   
    // Get the api session cookie.
    const cookies = new Cookies();
    let apiSessionId = cookies.get('apiSessionId')

    // Cookie doesn't exist.
    if (typeof apiSessionId == "undefined") {
        return null;
    }

    let response = await axios.post(API_URL_PREFIX + "payment/create-gocardless-bank-account", 
        { bankAccountInfo: bankAccountInfo }, 
        { headers: { 'Authorization': "Bearer " + apiSessionId } }
    )

    if (typeof response.data != "undefined") {
        return response.data;
    } else {
        return null;
    }
}


/**
 * Direct debits - daily script
 * Get any invoices due in 10 days and pay using GoCardless
 * 
 * @return {}
 */
PaymentService.processDirectDebitInvoices = async function() {
   
    // Get the api session cookie.
    const cookies = new Cookies();
    let apiSessionId = cookies.get('apiSessionId')

    // Cookie doesn't exist.
    if (typeof apiSessionId == "undefined") {
        return null;
    }

    let response = await axios.post(API_URL_PREFIX + "payment/process-direct-debit-invoices", 
        {  }, 
        { headers: { 'Authorization': "Bearer " + apiSessionId } }
    )

    if (typeof response.data != "undefined") {
        return response.data;
    } else {
        return null;
    }
}



/**
 * Get all the payments from the API database.
 * @return array
 */
const CancelToken = axios.CancelToken;
let cancelSearch = null;
PaymentService.getAllPayments = async function(params) {

    
    // Get the api session cookie.
    const cookies = new Cookies();
    let apiSessionId = cookies.get('apiSessionId')

    // Cookie doesn't exist.
    if (typeof apiSessionId == "undefined") {
       return false; 
    }

    // If we've searched before, cancel that request.
    if (cancelSearch != null) {
        cancelSearch('Operation canceled by the user.');
    }

    try {
        // Check if the cookie is valid and call the service.
        let response = await axios(API_URL_PREFIX + "payment/all-payments", {
            method: 'GET',
            params: params,
            cancelToken: new CancelToken(function executor(c) {
                cancelSearch = c; // Resave var.
            }),
            headers: {
                'Authorization': "Bearer " + apiSessionId
            },
        });
        // Get the result.
        let result = await response;
    
        if (typeof result.data.results != "undefined") {
            return result.data.results
        } else {
            return[];
        }
    } catch (error) {
        return []; 
    }

}




/**
 * Get all the BACs payments from the API database.
 * @return array
 */
PaymentService.getAllBacsPayments = async function(params) {

    
    // Get the api session cookie.
    const cookies = new Cookies();
    let apiSessionId = cookies.get('apiSessionId')

    // Cookie doesn't exist.
    if (typeof apiSessionId == "undefined") {
       return false; 
    }

    // If we've searched before, cancel that request.
    if (cancelSearch != null) {
        cancelSearch('Operation canceled by the user.');
    }

    try {
        // Check if the cookie is valid and call the service.
        let response = await axios(API_URL_PREFIX + "payment/all-bacs-payments", {
            method: 'GET',
            params: params,
            cancelToken: new CancelToken(function executor(c) {
                cancelSearch = c; // Resave var.
            }),
            headers: {
                'Authorization': "Bearer " + apiSessionId
            },
        });
        // Get the result.
        let result = await response;
    
        if (typeof result.data.results != "undefined") {
            return result.data.results
        } else {
            return[];
        }
    } catch (error) {
        return []; 
    }

}



/**
 * Export all the payments from the API database.
 * @return array
 */
PaymentService.exportAllPayments = async function(params) {

    // Get the api session cookie.
    const cookies = new Cookies();
    let apiSessionId = cookies.get('apiSessionId')

    // Cookie doesn't exist.
    if (typeof apiSessionId == "undefined") {
       return false; 
    }

    try {
        // Check if the cookie is valid and call the service.
        let response = await axios(API_URL_PREFIX + "payment/export-all-payments", {
            method: 'GET',
            params: params,
            headers: {
                'Authorization': "Bearer " + apiSessionId
            },
        });
        
        // Get the result.
        let result = await response;
    
        if (typeof result.data.results != "undefined") {
            return result.data.results
        } else {
            return[];
        }
    } catch (error) {
        return []; 
    }

}



/**
 * Export all the BACs payments from Dynamics.
 * @return array
 */
PaymentService.exportAllBacsPayments = async function(params) {

    // Get the api session cookie.
    const cookies = new Cookies();
    let apiSessionId = cookies.get('apiSessionId')

    // Cookie doesn't exist.
    if (typeof apiSessionId == "undefined") {
       return false; 
    }

    try {
        // Check if the cookie is valid and call the service.
        let response = await axios(API_URL_PREFIX + "payment/export-all-bacs-payments", {
            method: 'GET',
            params: params,
            headers: {
                'Authorization': "Bearer " + apiSessionId
            },
        });
        
        // Get the result.
        let result = await response;
    
        if (typeof result.data.results != "undefined") {
            return result.data.results
        } else {
            return[];
        }
    } catch (error) {
        return []; 
    }

}



/**
 * Email BACs receipts.
 * 
 * @return {}
 */
PaymentService.emailBacsReceipts = async function() {
    
    // Get the api session cookie.
    const cookies = new Cookies();
    let apiSessionId = cookies.get('apiSessionId')


    // Cookie doesn't exist.
    if (typeof apiSessionId == "undefined") {
        return null;
    }

    let response = await axios.post(API_URL_PREFIX + "payment/email-bacs-receipts", 
        {},
        { headers: { 'Authorization': "Bearer " + apiSessionId } }
    )

    if (typeof response.data != "undefined") {
        return response.data;
    } else {
        return null;
    }
}



/**
 * Link all members to GoCardless.
 * 
 * @return {}
 */
PaymentService.goCardlessCreateCustomerReference = async function() {
    
    // Get the api session cookie.
    const cookies = new Cookies();
    let apiSessionId = cookies.get('apiSessionId')


    // Cookie doesn't exist.
    if (typeof apiSessionId == "undefined") {
        return null;
    }

    let response = await axios.post(API_URL_PREFIX + "payment/gocardless-create-customer-reference", 
        {}, 
        { headers: { 'Authorization': "Bearer " + apiSessionId } }
    )

    if (typeof response.data != "undefined") {
        return response.data;
    } else {
        return null;
    }
}


export default PaymentService