import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

// import AppliedRoute from './components/AppliedRoute'

// Hub pages.
import Home from './pages/Home'
import PageNotFound from './pages/PageNotFound'

// Members
import Members from './pages/members/Members'
import MembersView from './pages/members/view/ViewContext'

// Applications
// import Applications from './pages/applications/Applications'
import Applications from './pages/applications/Applications'
import ApplicationsCompleted from './pages/applications/CompletedApplications'
import DuplicateApplications from './pages/applications/DuplicateApplications'
import ApplicationsConfiguration from './pages/applications/Configuration'
import DiscountCodes from './pages/applications/DiscountCodes'
import EditDiscountCodes from './pages/applications/EditDiscountCodes'
import AddDiscountCodes from './pages/applications/AddDiscountCodes'
import IncompleteApplications from './pages/applications/IncompleteApplications'
import CompletedApplications from './pages/applications/CompletedApplications'
import ProcessApplications from "./pages/applications/ProcessApplications";
import CountryDiscounts from "./pages/applications/CountryDiscounts";
import EditCountryDiscountsLmi from "./pages/applications/EditCountryDiscountsLmi";
import EditCountryDiscountsOverseasNonVat from "./pages/applications/EditCountryDiscountsOverseasNonVat";
import EditStickerbookDiscounts from "./pages/applications/EditStickerbookDiscounts";
import StickerBookDiscounts from "./pages/applications/StickerbookDiscounts";


// Invoices
import Invoices from './pages/invoices/Invoices'
import InvoicesDetails from './pages/invoices/Details'

// Payments
import Payments from './pages/payments/Payments'
import ExportStripeToSage from './pages/payments/ExportStripeToSage'
import ExportGoCardlessToSage from './pages/payments/ExportGoCardlessToSage'
import BACSPayments from './pages/payments/BACSPayments'

// Email Log
import Emails from './pages/email-log/EmailLog'
import EmailsView from './pages/email-log/ViewEmail'

// DirectDebits
import DirectDebits from './pages/direct-debits/DirectDebits'
import BankAccountsMain from './pages/direct-debits/BankAccountsMain'
import BankAccountsView from './pages/direct-debits/BankAccountsView'
import MandatesMain from './pages/direct-debits/MandatesMain'
import PaymentsMain from './pages/direct-debits/PaymentsMain'
import PaymentsView from './pages/direct-debits/PaymentView'
import MandateView from './pages/direct-debits/MandateView'

// Users
import Users from './pages/users/Users'
import UserAdd from './pages/users/AddUser'
import UserEdit from './pages/users/EditUser'
import MyAccount from './pages/users/MyAccount'

// Settings
import SettingsEdit from './pages/settings/Edit'
import ExportApplications from "./pages/applications/ExportApplications";

export default function Routes({ appProps }) {
    return (
        <BrowserRouter>
            <Switch>
                <AppliedRoute path="/" exact component={Home} appProps={appProps} />

                {/* Users */}
                <AppliedRoute path="/users" exact component={Users} appProps={appProps} />
                <AppliedRoute path="/users/edit-user/:userId" exact component={UserEdit} appProps={appProps} />
                <AppliedRoute path="/users/add-user" exact component={UserAdd} appProps={appProps} />
                <AppliedRoute path="/my-account/:userId" exact component={MyAccount} appProps={appProps} />

                {/* Members */}
                <AppliedRoute path="/members" exact component={Members} appProps={appProps} />
                <AppliedRoute path="/members/:memberId" exact component={MembersView} appProps={appProps} />
                <AppliedRoute path="/members/:memberId/:tabSlug" exact component={MembersView} appProps={appProps} />
                
                {/* Applications */}
                <AppliedRoute path="/applications" exact component={Applications} appProps={appProps} />
                <AppliedRoute path="/applications/completed-applications" exact component={CompletedApplications} appProps={appProps} />
                <AppliedRoute path="/applications/view/:viewApplicationId" exact component={ApplicationsCompleted} appProps={appProps} />
                <AppliedRoute path="/applications/export-applications" exact component={ExportApplications} appProps={appProps} />
                <AppliedRoute path="/applications/export-applications/view/:viewApplicationId" exact component={ExportApplications} appProps={appProps} />
                <AppliedRoute path="/applications/duplicate-applications" exact component={DuplicateApplications} appProps={appProps} />
                <AppliedRoute path="/applications/duplicate-applications/view/:viewApplicationId" exact component={DuplicateApplications} appProps={appProps} />
                <AppliedRoute path="/applications/incomplete-applications" exact component={IncompleteApplications} appProps={appProps} />
                <AppliedRoute path="/applications/process-applications" exact component={ProcessApplications} appProps={appProps} />
                <AppliedRoute path="/applications/process-applications/view/:viewApplicationId" exact component={ProcessApplications} appProps={appProps} />
                <AppliedRoute path="/applications/configuration" exact component={ApplicationsConfiguration} appProps={appProps} />
                
                {/* Discount Codes */}
                <AppliedRoute path="/applications/discount-codes" exact component={DiscountCodes} appProps={appProps} />
                <AppliedRoute path="/applications/discount-codes/discount" exact component={DiscountCodes} appProps={appProps} />
                <AppliedRoute path="/applications/discount-codes/access" exact component={DiscountCodes} appProps={appProps} />
                <AppliedRoute path="/applications/discount-codes/tracker" exact component={DiscountCodes} appProps={appProps} />
                <AppliedRoute path="/applications/discount-codes/archived" exact component={DiscountCodes} appProps={appProps} />
                <AppliedRoute path="/applications/discount-codes/add" exact component={AddDiscountCodes} appProps={appProps} />

                <AppliedRoute path="/applications/discount-codes/edit/:discountId" exact component={EditDiscountCodes} appProps={appProps} />
                <AppliedRoute path="/applications/discount-codes/edit/:discountId/:tabSlug" exact component={EditDiscountCodes} appProps={appProps} />

                {/* Country Discounts */}
                <AppliedRoute path="/applications/country-discounts" exact component={CountryDiscounts} appProps={appProps} />
                <AppliedRoute path="/applications/country-discounts-lmi/edit" exact component={EditCountryDiscountsLmi} appProps={appProps} />
                <AppliedRoute path="/applications/country-discounts-overseas-non-vat/edit" exact component={EditCountryDiscountsOverseasNonVat} appProps={appProps} />
                

                {/* Stickerbook Discounts */}
                <AppliedRoute path="/applications/stickerbook-discounts" exact component={StickerBookDiscounts} appProps={appProps} />
                <AppliedRoute path="/applications/stickerbook-discounts/edit/:discountId" exact component={EditStickerbookDiscounts} appProps={appProps} />

                {/* Invoices */}
                <AppliedRoute path="/invoices" exact component={Invoices} appProps={appProps} />
                <AppliedRoute path="/invoices/fully-paid" exact component={Invoices} appProps={appProps} />
                <AppliedRoute path="/invoices/partial" exact component={Invoices} appProps={appProps} />
                <AppliedRoute path="/invoices/due" exact component={Invoices} appProps={appProps} />
                <AppliedRoute path="/invoices/cancelled" exact component={Invoices} appProps={appProps} />
                <AppliedRoute path="/invoices/:invoiceId" exact component={InvoicesDetails} appProps={appProps} />
                
                {/* Payments */}
                <AppliedRoute path="/payments" exact component={Payments} appProps={appProps} />
                <AppliedRoute path="/payments/export-stripe-sage" exact component={ExportStripeToSage} appProps={appProps} />
                <AppliedRoute path="/payments/export-gocardless-sage" exact component={ExportGoCardlessToSage} appProps={appProps} />
                <AppliedRoute path="/payments/export-bacs-payments" exact component={BACSPayments} appProps={appProps} />

                {/* Email Log */}
                <AppliedRoute path="/email-log" exact component={Emails} appProps={appProps} />
                <AppliedRoute path="/email-log/view/:emailId" exact component={EmailsView} appProps={appProps} />

                {/* Direct Debits. */}
                <AppliedRoute path="/direct-debits" exact component={DirectDebits} appProps={appProps} />
                <AppliedRoute path="/direct-debits/mandates/:mandateId" exact component={MandateView} appProps={appProps} />
                <AppliedRoute path="/direct-debits/bank-accounts" exact component={BankAccountsMain} appProps={appProps} />
                <AppliedRoute path="/direct-debits/bank-accounts/:bankAccountId" exact component={BankAccountsView} appProps={appProps} />
                <AppliedRoute path="/direct-debits/mandates" exact component={MandatesMain} appProps={appProps} />
                <AppliedRoute path="/direct-debits/payments" exact component={PaymentsMain} appProps={appProps} />
                <AppliedRoute path="/direct-debits/payments/:paymentId" exact component={PaymentsView} appProps={appProps} />

                {/* Settings */}
                <AppliedRoute path="/settings" exact component={SettingsEdit} appProps={appProps} />
                

                <AppliedRoute component={PageNotFound} />
            </Switch>
        </BrowserRouter>
    );
}

function AppliedRoute({ component: C, appProps, ...rest }) {
    return (
        <Route {...rest} render={props => <C {...props} {...appProps} />} />
    );
}