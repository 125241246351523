import React, { Component } from 'react';
import Layout from '../../bricks/Layout'
import { useTable, usePagination } from 'react-table'
import { AuthContext } from '../../components/AuthContext';
import AccessDenied from '../../components/AccessDenied';
import ApplicationService from '../../services/ApplicationService';
import { Link, withRouter } from 'react-router-dom'
var moment = require('moment');

class Applications extends Component {
	state = {
		allDiscounts: [],
		discountTable: {
			query: '',
			type: '',
			pageIndex: 0,
			pageCount: 0,
			perPage: 30,
			isLoading: true,
			sort: {
				field: null,
				descending: false
			}
		},
		allTypes: [
			{ key: "", label: "All" },
			{ key: "discount", label: "Discount" },
			{ key: "access", label: "Access" },
			{ key: "tracker", label: "Tracker" },
			{ key: "archived", label: "Archived" },
		],
	};
	
	tabButtons = { "": null, "discount": null, "access": null, "tracker": null, "archived": null }
    
    // Breadcrumbs.
    breadcrumbs = [
        { title: 'Home', link: '/' }, 
        { title: 'Applications', link: '/applications'},
        { title: 'Discount Codes', link: '/applications/discount-codes', active: true }
	];
	

	// Set the table columns.
	columns = [
		{ Header: 'Code', accessor: 'code', sortable: true },
		{ Header: 'Type', accessor: 'type', sortable: true },
		{ Header: 'Value', accessor: 'discountValue', sortable: true },
		{ Header: 'From', accessor: 'fromDate', sortable: true },
		{ Header: 'Expires at', accessor: 'expiryDate', sortable: true },
		{ Header: 'Enabled', accessor: 'enabled', sortable: true },
		{ Header: '', accessor: 'view', sortable: false }
	];
    
	componentDidMount() {
		let { discountTable } = this.state

		// Load all Members.
		ApplicationService.getAllDiscounts(discountTable).then((response) => {
			this.setState({ 
				allDiscounts: response.discounts,
				discountTable: {
					...discountTable,
					isLoading: false,
					pageCount: response.totalPages,
					pageIndex: response.pageIndex,
					perPage: response.perPage
				}
			})
		});
	}
	
	currentlUrl = ""
	componentDidUpdate (prevProps, prevState) {
		let activeTab = window.location.pathname.split("/").pop();

		if (this.currentlUrl !== activeTab && (activeTab === "" || activeTab === "discount" || activeTab === "access" || activeTab === "tracker")) {
			this.currentlUrl = activeTab
			// this.setState({ openedTab: activeTab })
			this.tabButtons[activeTab].click();
		}
	}


	// On the sort change get new data.
	handleSortData = (sort) => {
		let { discountTable } = this.state


		// Set loading to true and update the page number.
		discountTable.isLoading = true;
		discountTable.sort = sort;
		this.setState({ discountTable: discountTable })

		// Load all Members.
		ApplicationService.getAllDiscounts(discountTable).then((response) => {
			this.setState({ 
				allDiscounts: response.discounts,
				discountTable: {
					...discountTable,
					isLoading: false,
					pageCount: response.totalPages,
					pageIndex: response.pageIndex,
					perPage: response.perPage
				}
			})
		});
	}


	// On page change for the table.
	handlePageChange = (pageIndex) => {
		let { discountTable } = this.state

		// Set loading to true and update the page number.
		discountTable.isLoading = true;
		discountTable.pageIndex = pageIndex;
		this.setState({ discountTable: discountTable })

		// Load all Members.
		ApplicationService.getAllDiscounts(discountTable).then((response) => {
			this.setState({ 
				allDiscounts: response.discounts,
				discountTable: {
					...discountTable,
					isLoading: false,
					pageCount: response.totalPages,
					pageIndex: response.pageIndex,
					perPage: response.perPage
				}
			})
		});
	}

	/**
	 * Filter the status of the invoices
	 */
	handleFilterType = (e) => {
		let { discountTable } = this.state
		let tabSlug = e.target.getAttribute('tab-slug');

		// Set loading to true and update the page number.
		discountTable.isLoading = true;
		discountTable.type = tabSlug;

		// Set the url.
		this.props.history.push("/applications/discount-codes/" + tabSlug);

		this.setState({ discountTable: discountTable })

		// Load all Invoices.
		ApplicationService.getAllDiscounts(discountTable).then((response) => {
			this.setState({ 
				allDiscounts: response.discounts,
				discountTable: {
					...discountTable,
					isLoading: false,
					pageCount: response.totalPages,
					pageIndex: response.pageIndex,
					perPage: response.perPage
				}
			})
		});
	}


	render() {
		let { allTypes, discountTable } = this.state;
		
		return (
			<Layout breadcrumbs={this.breadcrumbs} match={this.props.match}>
				<div className="page-title mb-4 clearfix">
					<div className="float-left">
						<h1 className="page-title">Discount Codes</h1>
					</div>

					<div className="float-right">
						<Link to={"/applications/discount-codes/add"} className={"btn btn-primary"}>Add Discount Code</Link>
					</div>
				</div>

				<ul className="nav nav-tabs mb-4">
					{Object.keys(allTypes).map((key, i) => {
						return (
							<li className="nav-item" key={i}>
								<button
									tab={allTypes[key].label}
									tab-slug={allTypes[key].key}
									className={"nav-link" + ((discountTable.type === allTypes[key].key) ? ' active' : '')}
									onClick={this.handleFilterType}
									ref={button => this.tabButtons[allTypes[key].key] = button}
								>{allTypes[key].label}</button>
							</li>
						)
					})}
				</ul>

				<div className="table-responsive">
					<DiscountsTable 
						tableData={this.state.allDiscounts} 
						tableColumns={this.columns} 
						searchQuery={this.state.discountTable.searchQuery}
						gotoPage={this.handlePageChange} 
						pageIndex={this.state.discountTable.pageIndex} 
						pageCount={this.state.discountTable.pageCount}
						isLoading={this.state.discountTable.isLoading}
						sort={this.state.discountTable.sort}
						sortData={this.handleSortData}
					/>
				</div>
			</Layout>
		);
	}
}


/**
 * Table to display the members with pagination and filtering.
 * @param {}
 */
function DiscountsTable({ tableColumns, tableData, gotoPage, pageIndex, pageCount, isLoading, sort, sortData, handleTdClick }) {

	// Define the table columns.
	const columns = React.useMemo(() => tableColumns, [tableColumns])
	
	// Define the data.
	const data = React.useMemo(() => tableData, [tableData])
	
	// Use the state and functions returned from useTable to build your UI
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		page
	} = useTable({
		columns,
		data,
		manualPagination: true,
		initialState: { pageIndex: pageIndex, pageSize: 20 },
	}, usePagination)


	/**
	 * Calculate and show the correct pages.
	 * @param {*} pIndex 
	 * @param {*} pCount 
	 */
	const numberedPages = (pIndex, pCount) => {
		let gCount = 3;
		let showPagesArr = [];

		// Loop through all pages and calculate which ones to show.
		for (let i = 0; i < pCount; i++) {
			if ((pIndex - gCount) < i && (gCount + pIndex) > i) {
				showPagesArr.push(i);
			}            
		}
		return showPagesArr;
	}

	/**
	 * On click of the sort header.
	 * @param {*} e 
	 */
	const sortHeader = (e) => {
		let sortField = e.target.getAttribute("sortfield");

		if (sort.field === sortField && !sort.descending) {
			sort.descending = true;
		} else if (sort.field === sortField && sort.descending) {
			sort.field = null;
			sort.descending = false;
		} else {
			sort.field = sortField;
			sort.descending = false;
		}

		sortData(sort);
	}

    
	return (
		<div>
			<div className="table-responsive table-data position-relative">
				<table className={"table table-striped table-hover"} {...getTableProps()}>
					<thead>
						{headerGroups.map(headerGroup => (
							<tr {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map(column => (
									// Add the sorting props to control sorting. For this example
									// we can add them into the header props
									<th {...column.getHeaderProps()} onClick={sortHeader} sortfield={column.id}>
										{column.Header}
										{(column.sortable) ? 
											(<span className={"sort " + (sort.field === column.id ? (sort.descending ? 'sort-desc' : 'sort-asc') : 'sort-none')}></span>) 
										: ''}
									</th>
								))}
							</tr>
						))}
					</thead>
					<tbody {...getTableBodyProps()}>
						{page.map((row, i) => {
							prepareRow(row);

							return (
								<tr {...row.getRowProps()} to={"/applications/discount-codes/edit/" + row.original.id}>
									{row.cells.map(cell => {
										if (cell.column.id === 'view') {
											return (<td {...cell.getCellProps()}>
												<Link to={"/applications/discount-codes/edit/" + cell.row.original.id} className={"btn btn-primary btn-sm btn-view float-right"}><span>Edit</span></Link>
											</td>)
										} else if (cell.column.id === 'discountValue') {
											if (cell.row.original.type === "access") {
												return (<td {...cell.getCellProps()}></td>)
											}
											if (cell.row.original.discountType === "fixed") {
												return (<td {...cell.getCellProps()}>&pound;{cell.row.values.discountValue.toFixed(2)}</td>)
											} else {
												return (<td {...cell.getCellProps()}>{cell.row.values.discountValue}%</td>)
											}

										} else if (cell.column.id === 'type') {
											return (<td {...cell.getCellProps()}>{cell.row.original.type.charAt(0).toUpperCase() + cell.row.original.type.slice(1)}</td>)

										} else if (cell.column.id === 'enabled') {
											return (<td {...cell.getCellProps()}>
												<span className={"badge" + (cell.row.original.enabled === 1 ? " badge-success" : " badge-danger")}>{cell.row.original.enabled === 1 ? "Enabled" : "Disabled"}</span>
											</td>)
										} else if (cell.column.id === 'fromDate') {
											return (<td {...cell.getCellProps()}>{moment(cell.row.original.fromDate).format('DD/MM/YYYY hh:mmA')}</td>)
										} else if (cell.column.id === 'expiryDate') {
											if (cell.row.original.expiryDate === null) {
												return (<td {...cell.getCellProps()}>Never</td>)
											} else {
												return (<td {...cell.getCellProps()}>{moment(cell.row.original.expiryDate).format('DD/MM/YYYY hh:mmA')}</td>)
											}
										} else {
											return (<td {...cell.getCellProps()}>{cell.render('Cell')}</td>)
										}
									})}
								</tr>
							)
						})}
					</tbody>
				</table>
				<div className={"loading-table-data" + ((isLoading) ? " active" : "")}><div className="loading-inner">Loading...</div></div>
			</div>

			<nav className="d-flex mt-4" aria-label="Page navigation">
				<ul className="pagination mx-auto">
					<li className="page-item">
						<button className="page-link" onClick={() => gotoPage(0)} disabled={(pageIndex === 0)}>&lt;&lt; First</button>
					</li>
					<li className="page-item">
						<button className="page-link" onClick={() => gotoPage(pageIndex-1)} disabled={(pageIndex === 0)}>Previous</button>
					</li>
					{numberedPages(pageIndex, pageCount).map((pageNum) => {
						return (
							<li className={"page-item " + (pageIndex === pageNum ? 'active' : '')} key={pageNum+1}>
								<button className="page-link" onClick={() => gotoPage(pageNum)}>{pageNum+1}</button>
							</li>
						)
					})}
					<li className="page-item">
						<button className="page-link" onClick={() => gotoPage(pageIndex+1)} disabled={(pageIndex+1 === pageCount)}>Next &gt;</button>
					</li>
					<li className="page-item">
						<button className="page-link" onClick={() => gotoPage(pageCount-1)} disabled={(pageIndex+1 === pageCount)}>Last &gt;&gt;</button>
					</li>
				</ul>
			</nav>
		</div>
	)
}

export default withRouter((props, ref) => (
	<AuthContext.Consumer>{context => {
		if (context.currentUser.permissions.applications) {
			return (<Applications {...props} authContext={context} />)
		} else {
			return (<AccessDenied {...props}/>)
		}
	}}</AuthContext.Consumer>
));