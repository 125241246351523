import React, { Component } from 'react';
import Layout from '../../bricks/Layout'
import { useTable, usePagination } from 'react-table'
import PaymentService from '../../services/PaymentService'
import { AuthContext } from '../../components/AuthContext';
import AccessDenied from '../../components/AccessDenied';
import { Link } from 'react-router-dom'


class BankAccountsMain extends Component {
	state = {
		response: '',
		allBankAccounts: [],
		bankAccountsTable: {
			perPage: 25,
			isLoading: true,
			firstId: '',
			lastId: ''
		}
	};
    

	// Breadcrumbs.
	breadcrumbs = [
		{ title: 'Home', link: '/' }, 
		{ title: 'Direct Debits', link: '/direct-debits' },
		{ title: 'Bank Accounts', link: '/direct-debits/bank-accounts', active: true }
	];

	componentDidMount() {
		let { bankAccountsTable } = this.state

        this.updateData({ limit: bankAccountsTable.perPage })
	}

	// Set the table columns.
	columns = [
		{ Header: 'ID', accessor: 'id', sortable: false },
		{ Header: 'Account Holder', accessor: 'account_holder_name', sortable: false },
		{ Header: 'Bank Name', accessor: 'bank_name', sortable: false },
		{ Header: 'Account Number', accessor: 'account_number_ending', sortable: false },
		{ Header: '', accessor: 'view', sortable: false }
	];
	
	// On page change for the table.
	updateData = (params) => {
		let { bankAccountsTable } = this.state
		this.setState({ bankAccountsTable: { ...bankAccountsTable, isLoading: true } })

		PaymentService.goCardlessListBankAccounts(params).then((response) => {
			bankAccountsTable.isLoading = false
			
			if (response.bankAccounts.length > 0) {
				bankAccountsTable.firstId = response.bankAccounts[0].id
				bankAccountsTable.lastId = response.bankAccounts[response.bankAccounts.length-1].id
			}

			this.setState({
				allBankAccounts: response.bankAccounts,
				bankAccountsTable: bankAccountsTable
			})
		})
	}
	

	render() {
		return (
			<Layout breadcrumbs={this.breadcrumbs} match={this.props.match}>
				<h1 className="page-title">Bank Accounts</h1>

				<div className="table-responsive">
					<BankAccountsTable 
						tableData={this.state.allBankAccounts} 
						tableColumns={this.columns} 
						isLoading={this.state.bankAccountsTable.isLoading}
						perPage={this.state.bankAccountsTable.perPage}
						firstId={this.state.bankAccountsTable.firstId}
						lastId={this.state.bankAccountsTable.lastId}
						updateData={this.updateData}
					/>
				</div>
			</Layout>
		);
	}
}


/**
 * Table to display the members with pagination and filtering.
 * @param {}
 */
function BankAccountsTable({ tableColumns, tableData, isLoading, perPage, updateData, firstId, lastId }) {

	// Define the table columns.
	const columns = React.useMemo(() => tableColumns, [tableColumns])
	
	// Define the data.
	const data = React.useMemo(() => tableData, [tableData])
	
	// Use the state and functions returned from useTable to build your UI
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		page
	} = useTable({
		columns,
		data,
		manualPagination: true,
	}, usePagination)

    
	return (
		<div>
			<div className="table-responsive table-data position-relative">
				<table className={"table table-striped"} {...getTableProps()}>
					<thead>
						{headerGroups.map(headerGroup => (
							<tr {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map(column => (
									// Add the sorting props to control sorting. For this example
									// we can add them into the header props
									<th {...column.getHeaderProps()}>
										{column.Header}
									</th>
								))}
							</tr>
						))}
					</thead>
					<tbody {...getTableBodyProps()}>
						{page.map((row, i) => {
							prepareRow(row);

							return (
								<tr {...row.getRowProps()}>
									{row.cells.map(cell => {
										if (cell.column.id === 'view') {
											return (<td {...cell.getCellProps()}>
												<Link to={"/direct-debits/bank-accounts/" + cell.row.original.id} className={"btn btn-primary btn-sm float-right"}>View</Link>
											</td>)
										} else if (cell.column.id === 'account_number_ending') {
											return (<td {...cell.getCellProps()}>****{cell.render('Cell')}</td>)
										} else {
											return (<td {...cell.getCellProps()}>{cell.render('Cell')}</td>)
										}
									})}
								</tr>
							)
						})}
					</tbody>
				</table>
				<div className={"loading-table-data" + ((isLoading) ? " active" : "")}><div className="loading-inner">Loading...</div></div>
			</div>

			<nav className="d-flex mt-4" aria-label="Page navigation">
				<ul className="pagination mx-auto">
					<li className="page-item">
						<button className="page-link" onClick={() => updateData({ limit: perPage, before: firstId })}>&lt; Previous</button>
					</li>
					<li className="page-item">
						<button className="page-link" onClick={() => updateData({ limit: perPage, after: lastId })}>Next &gt;</button>
					</li>
				</ul>
			</nav>
		</div>
	)
}

export default ((props, ref) => (
	<AuthContext.Consumer>{context => {
		if (context.currentUser.permissions.directDebits) {
			return (<BankAccountsMain {...props} authContext={context} />)
		} else {
			return (<AccessDenied {...props}/>)
		}
	}}</AuthContext.Consumer>
));