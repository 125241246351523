import React, { Component } from 'react';

class Home extends Component {
	state = {
		response: ''
	};

	componentDidMount() {
    }
	render() {
		// 
		return (
            <div id="page-not-found">
                <div>
                    <h1>404</h1>
                    <h3>Page Not Found</h3>
                    
                </div>
            </div>
		);
	}
}
export default Home;