import React, { Component } from 'react';
import Layout from '../../bricks/Layout'
import { store } from 'react-notifications-component';
import { Modal } from 'react-bootstrap';
import  { withRouter } from 'react-router-dom'
import { AuthContext } from './../../components/AuthContext';
import UserService from '../../services/UserService'
import AccessDenied from '../../components/AccessDenied';

class EditUser extends Component {
	state = {
		response: ''
    };
    
    // Breadcrumbs.
	breadcrumbs = [
		{ title: 'Home', link: '/' }, 
		{ title: 'Users', link: '/users', active: false },
		{ title: 'Edit User', active: true }
	];

	// Setting default tab state
    state = {
        user: {
			firstName: "",
			lastName: "",
			email: "",
			suspended: 0,
			permissions: {
				members: true,
				applications: true, 
				invoices: true,
				payments: true, 
				directDebits: true, 
				emailLog: true,
				admin: true,
				settings: true,
			}
		},
        currentPassword: "",
        newPassword: "",
		confirmPassword: "",
		deleteUserModal: false,
        formStatus: {
            errors: [], success: ""
        }
    };

	componentDidMount() {				
		let userId = this.props.match.params.userId
		// Load all Members.
		UserService.getUserById(userId).then((user) => {
	
		// Show the data.
			this.setState({ user: user, userId: userId })
		});
    }

	

    /**
	 * Handle details form submit.
	 */
	handleResetPasswordFormSubmit = e => {
		e.preventDefault();

		let { newPassword, confirmPassword } = this.state;
		let memberId = this.props.match.params.userId

		// This is an admin updating the email, we don't need to check the current password. 
		let currentPassword = true;

		
		// Save the data.
		UserService.resetPassword(currentPassword, newPassword, confirmPassword, memberId).then((response) => {
			if (typeof response.errors != "undefined" && response.errors.length === 0 ) {
				store.addNotification({
					title: "Success!",
					message: "Password has been updated.",
					type: "success",
					container: "top-right",
					animationIn: ["animated", "slideInRight"],
					animationOut: ["animated", "slideOutRight"],
					dismiss: { duration: 3000 }
				});
			} else {
				response.errors.map(error => {
					store.addNotification({
						title: "Error!",
						message: error,
						type: "danger",
						container: "top-right",
						animationIn: ["animated", "slideInRight"],
						animationOut: ["animated", "slideOutRight"],
						dismiss: { duration: 10000 }
					});
					return true;
				})
			}


			// Clear the state and inputs
			this.setState({ newPassword: '', confirmPassword: '' });

		});

	}

	    /**
     * Show and hide the modal.
     */
    handleDeleteUserModal = (show) => {
        this.setState({ deleteUserModal: (typeof show === "boolean") ? show : false })
	}
	
	handleDeleteUser = e => {
		e.preventDefault()
		// let currentUser = context.currentUser.id
		let userId = this.props.match.params.userId

		// Delete a user
		UserService.deleteUser(userId).then((response) => {
			
			if (typeof response.errors === "undefined" && response) {
				store.addNotification({
					title: "Success!",
					message: "User has been deleted.",
					type: "success",
					container: "top-right",
					animationIn: ["animated", "slideInRight"],
					animationOut: ["animated", "slideOutRight"],
					dismiss: { duration: 3000 }
				});

				this.props.history.push('/users')

				
			} else {
				response.errors.map(error => {
					store.addNotification({
						title: "Error!",
						message: error,
						type: "danger",
						container: "top-right",
						animationIn: ["animated", "slideInRight"],
						animationOut: ["animated", "slideOutRight"],
						dismiss: { duration: 10000 }
					});
					return true;
				})
			}
		});
	}


	/**
	 * On input change for the reset password.
	 */
	handlePasswordDetailsChange = e => {
        let currentState = this.state;
        currentState[e.target.name] = e.target.value
		this.setState(currentState)
	};

	/**
	 * On input change for the details.
	 */
	handleDetailsChange = e => {
		let { user } = this.state;
		// Check if checkbox
		if (e.target.type === "checkbox" ) {
			// Are we checking suspended?
			if (e.target.name === "suspended") {
				let checkedValue;
				// Yes, convert bool to 1 and 0 for TINYINT
				if (e.target.checked === true) {
					checkedValue = 1
				} else {
					checkedValue = 0
				}
				
				// Update the suspended state
				user.suspended = checkedValue
			} else {
				// No, it must be checking permissions
				user.permissions[e.target.name] = e.target.checked	
			}
		} else {	
			user[e.target.name] = e.target.value
		}
		this.setState({ user: user })
	};

	/**
	 * Handle details form submit.
	 */
	handleDetailsFormSubmit = e => {
		e.preventDefault();

		let { user } = this.state;
		// Save the data.
		UserService.updateUser(user).then((response) => {
			if (typeof response.errors === "undefined" && response) {
				store.addNotification({
					title: "Success!",
					message: "User details have been updated.",
					type: "success",
					container: "top-right",
					animationIn: ["animated", "slideInRight"],
					animationOut: ["animated", "slideOutRight"],
					dismiss: { duration: 3000 }
				});

				this.props.history.push('/users')

				
			} else {
				response.errors.map(error => {
					store.addNotification({
						title: "Error!",
						message: error,
						type: "danger",
						container: "top-right",
						animationIn: ["animated", "slideInRight"],
						animationOut: ["animated", "slideOutRight"],
						dismiss: { duration: 10000 }
					});
					return true;
				})
			}
		});

	}

	render() {
		let { formStatus, user, deleteUserModal } = this.state;
		return (
			<AuthContext.Consumer>	
				{context => (			
					<Layout breadcrumbs={this.breadcrumbs} match={this.props.match}>
						<h1 className="page-title">Edit {user.firstName + ' ' + user.lastName}</h1>
						<form onSubmit={this.handleDetailsFormSubmit} className={(formStatus.success === "" ? "" : " d-none")}>
							<div className="row">

								<div className="col-3">
									<div className="form-group">
										<label>First Name</label>
										<input className="form-control" name="firstName" value={user.firstName} required type="text" onChange={this.handleDetailsChange} />
									</div>
								</div>

								<div className="col-3">
									<div className="form-group">
										<label>Last Name</label>
										<input className="form-control" name="lastName" value={user.lastName} required type="text" onChange={this.handleDetailsChange} />
									</div>
								</div>

								<div className="col-6">
									<div className="form-group">
										<label>Email</label>
										<input className="form-control" name="email" value={user.email} required type="email" onChange={this.handleDetailsChange} />
									</div>
								</div>						
							</div>

							{context.currentUser.permissions.admin ? <h4>User Permissions</h4> : ""}
							
							{context.currentUser.permissions.admin && 	
							<div>
								<div className="form-check">
									<label className="form-check-label"><input className="form-check-input" checked={user.permissions.members} onChange={this.handleDetailsChange} name="members" type="checkbox"/>Members</label>
								</div>

								<div className="form-check">
									<label className="form-check-label"><input className="form-check-input" checked={user.permissions.applications} onChange={this.handleDetailsChange} name="applications" type="checkbox"/>Applications</label>
								</div>

								<div className="form-check">
									<label className="form-check-label"><input className="form-check-input" checked={user.permissions.invoices} onChange={this.handleDetailsChange} name="invoices" type="checkbox"/>Invoices</label>
								</div>

								<div className="form-check">
									<label className="form-check-label"><input className="form-check-input" checked={user.permissions.payments} onChange={this.handleDetailsChange} name="payments" type="checkbox"/>Payments</label>

								</div>
								<div className="form-check">
									<label className="form-check-label"><input className="form-check-input" checked={user.permissions.directDebits} onChange={this.handleDetailsChange} name="directDebits" type="checkbox"/>Direct Debits</label>
								</div>

								<div className="form-check">
									<label className="form-check-label"><input className="form-check-input" checked={user.permissions.emailLog} onChange={this.handleDetailsChange} name="emailLog" type="checkbox"/>Email Log</label>
								</div>

								<div className="form-check">
									<label className="form-check-label"><input className="form-check-input" checked={user.permissions.admin} onChange={this.handleDetailsChange} name="admin" type="checkbox"/>Admin (Add/Edit Users)</label>
								</div>

								<div className="form-check">
									<label className="form-check-label"><input className="form-check-input" checked={user.permissions.settings} onChange={this.handleDetailsChange} name="settings" type="checkbox"/>Settings</label>
								</div>

								<br/>

								<div className="form-check">
									<label className="form-check-label"><input className="form-check-input" 
									checked={ user.suspended === 1 ? true : false }
									onChange={this.handleDetailsChange} name="suspended" type="checkbox"/>Suspend Account</label>
								</div>
							</div>						

							}
							
							<button type="submit" className="float-right btn-outline-primary btn px-5">Save</button>
							<div className="clearfix"></div>
						</form> <hr className="my-5" />

						<form onSubmit={this.handleResetPasswordFormSubmit} className={(formStatus.success === "" ? "" : " d-none")}>
							<div className="row">
								<div className="col-12">
									<h3>Reset Password</h3>
								</div>
								<div className="col-6">
									<div className="form-group">
										<label>Password</label>
										<input className="form-control" name="newPassword" value={this.state.newPassword} required type="password" onChange={this.handlePasswordDetailsChange} />
									</div>
								</div>
								<div className="col-6">
									<div className="form-group">
										<label>Confirm Password</label>
										<input className="form-control" name="confirmPassword" value={this.state.confirmPassword} required type="password" onChange={this.handlePasswordDetailsChange} />
									</div>
								</div>
							</div>
							<button type="submit" className="float-right btn-outline-primary btn px-5">Reset Password</button>
							
						</form> 
			
						<br/>
						<button onClick={(e) => { this.handleDeleteUserModal(true) }} className={"px-5 py-2 btn btn-secondary " + (user.id !== context.currentUser.id ? '' : 'd-none')}>Remove Account</button>

						<Modal show={deleteUserModal} onHide={this.handleDeleteUserModal}>
							<form onSubmit={this.handleDeleteUser}>
								<Modal.Header>
									<Modal.Title>Delete User</Modal.Title>
										<button type="button" className="close" onClick={this.handleDeleteUserModal}><span aria-hidden="true">×</span><span className="sr-only">Close</span></button>
								</Modal.Header>
								<Modal.Body>
										<h4>Are you sure you would like to delete this user?</h4>
										<p>This action is irreversible.</p>
								</Modal.Body>
								<Modal.Footer>
									<button className="px-5 py-2 btn btn-secondary">Yes, remove Account</button>
								</Modal.Footer>
							</form>
						</Modal>
					</Layout>
				)}
			</AuthContext.Consumer>
		);
	}
}
export default withRouter( ((props, ref) => (
	<AuthContext.Consumer>{context => {
		if (context.currentUser.permissions.admin) {
			return (<EditUser {...props} authContext={context} />)
		} else if (parseInt(props.match.params.userId) === parseInt(context.currentUser.id)) {
			return (<EditUser {...props} authContext={context} />)
		}
		else {
			return (<AccessDenied {...props}/>)
		}
	}}</AuthContext.Consumer>
)));