/**
 * Request Helper
 * =====================
 * 
 * This will have several methods to aid the missing ones on the fetch command.
 * 
 */


 
/**
 * Encode to Base64
 * 
 * @param stringToEncode 
 * @return {string}
 */
export function base64Encode(stringToEncode) {
    return new Buffer(stringToEncode).toString('base64');
}


/**
 * Encode the form data for application/x-www-form-url-encoded types
 *  
 * @param {} data
 */
export function encodeFormData(data) {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
        .join('&');
}