/**
 * User Service
 * =================
 * 
 * @author    José Veríssimo
 * @since     1.0.2
 */

// Module dependencies.
import { base64Encode, encodeFormData } from '../helpers/RequestHelper'
import Cookies from 'universal-cookie';
const axios = require('axios').default;
const UserService = {};

// Required configuration.
// const API_URL = process.env.REACT_APP_API_URL;
const API_URL_PREFIX = (typeof process.env.REACT_APP_API_URL_PREFIX_OVERRIDE != "undefined") ? 
    process.env.REACT_APP_API_URL_PREFIX_OVERRIDE : process.env.REACT_APP_API_URL_PREFIX;
const API_CLIENT_ID = process.env.REACT_APP_API_CLIENT_ID;
const API_CLIENT_SECRET = process.env.REACT_APP_API_CLIENT_SECRET;

/**
 * Handle the login via username and password.
 * 
 * @return {} result
 */

UserService.login = async function(email, password) {

    let response = await fetch(API_URL_PREFIX + "oauth/token", {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': "Basic " + base64Encode(API_CLIENT_ID + ":" + API_CLIENT_SECRET)
        },
        method: 'POST',
        body: encodeFormData({
            grant_type: "password",
            username: email,
            password: password
        })
    });

    // Get the result.
    let result = await response.json();

    // Do we have an access token?
    if (typeof result['accessToken'] != "undefined") {

        // Set cookie.
        const cookies = new Cookies();
        cookies.set('apiSessionId', result['accessToken'], { 
            path: '/', 
            expires: new Date(result['accessTokenExpiresAt'])
        });

        // Check if this user has been suspended.
        if (await this.checkIfSuspended(email) === true) {
            // For safety, remove the cookie.
            const cookies = new Cookies();
            cookies.remove('apiSessionId');

            return {
                success: false,
                error: 'Your account is suspended, contact an administrator.'
            }
        } else {
            // Redirect to the homepage.
            return { success: true, expireTime: new Date(result['accessTokenExpiresAt']) - new Date().getTime() }
        }



    } else {
        // For safety, remove the cookie.
        const cookies = new Cookies();
        cookies.remove('apiSessionId');

        return {
            success: false,
            error: 'Invalid login credentials, please try again.'
        }
    }
}

UserService.checkIfSuspended = async function(email) {

    // Get the api session cookie.
    const cookies = new Cookies();
    let apiSessionId = cookies.get('apiSessionId')


    // Cookie doesn't exist.
    if (typeof apiSessionId == "undefined") {
        return null;
    }

    let response = await axios({
        url: API_URL_PREFIX + "user/check-if-suspended",
        method: 'POST',
        data: { 
            email: email,
        },
        headers: {
            'Authorization': "Bearer " + apiSessionId
        }
    })

    // Return if the user is suspended
    if (typeof response.data.suspended != "undefined") {
        return response.data.suspended;
    } else {
        return {};
    }
} 

/**
 * Create new user.
 * 
 * @return {} result
 */
UserService.addUser = async function(user) {

    // Get the api session cookie.
    const cookies = new Cookies();
    let apiSessionId = cookies.get('apiSessionId')


    // Cookie doesn't exist.
    if (typeof apiSessionId == "undefined") {
        return null;
    }

    let response = await axios({
        url: API_URL_PREFIX + "user/add-user",
        method: 'POST',
        data: { 
            user: user,
        },
        headers: {
            'Authorization': "Bearer " + apiSessionId
        }
    })

    if (typeof response.data != "undefined") {
        return response.data;
    } else {
        return {};
    }
}   


/**
 * Update new user.
 * 
 * @return {} result
 */
UserService.updateUser = async function(user) {
    // Get the api session cookie.
    const cookies = new Cookies();
    let apiSessionId = cookies.get('apiSessionId')

    // Cookie doesn't exist.
    if (typeof apiSessionId == "undefined") {
        return null;
    }

    let response = await axios({
        url: API_URL_PREFIX + "user/update-user",
        method: 'POST',
        data: { 
            user: user,
        },
        headers: {
            'Authorization': "Bearer " + apiSessionId
        }
    })


    if (typeof response.data != "undefined") {
        return response.data;
    } else {
        return {};
    }
}   



/**
 * Handle the logout.
 * 
 * @return {} result
 */

UserService.logout = function() {
    // For safety, remove the cookie.
    const cookies = new Cookies();
    cookies.remove('apiSessionId');


    // Set cookie.
    cookies.set('apiSessionId', null, { 
        path: '/', 
        expires: new Date()
    });

    return {
        info: 'Successfully logged out.'
    }
}


/**
 * Check if the user is logged and correctly authenticated.
 *
 * @return boolean
 */
UserService.checkLogin = async function() {
    // Get the api session cookie.
    const cookies = new Cookies();
    let apiSessionId = cookies.get('apiSessionId')

    // Cookie doesn't exist.
    if (typeof apiSessionId == "undefined") {
       return { authenticated: false, tokenExpiresIn: null }; 
    }

    // Check if the cookie is valid.
    let response = await fetch(API_URL_PREFIX + "oauth/auth", {
        headers: {
            'Authorization': "Bearer " + apiSessionId
        },
        method: 'GET'
    });

    // Get the result.
    let result = await response.json();
    
    if (typeof result.token !== "undefined" && typeof result.token.accessTokenExpiresAt !== "undefined") {
        return { ...result, tokenExpiresIn: (new Date(result.token.accessTokenExpiresAt) - (new Date().getTime())) };
    } else {
        return result;
    }
}


/**
 * Get a list of all of the users to display on the users page.
 * 
 * @return []
 */
UserService.getAllUsers = async function() {
    // Get the api session cookie.
    const cookies = new Cookies();
    let apiSessionId = cookies.get('apiSessionId')


    // Cookie doesn't exist.
    if (typeof apiSessionId == "undefined") {
        return null;
    }

    // Check if the cookie is valid.
    let response = await fetch(API_URL_PREFIX + "user/all-users", {
        headers: {
            'Authorization': "Bearer " + apiSessionId
        },
        method: 'GET'
    });

    // Get the result.
    let result = await response.json();


    if (typeof result.users != "undefined" && result.users.length > 0) {
        return result.users;
    } else {
        return null;
    }
}
/**
 * Get a list of all of the users to display on the users page.
 * 
 * @return []
 */
UserService.getUserById = async function(userId) {
    // Get the api session cookie.
    const cookies = new Cookies();
    let apiSessionId = cookies.get('apiSessionId')

    // Cookie doesn't exist.
    if (typeof apiSessionId == "undefined") {
        return null;
    }

    // Check if the cookie is valid.
    let response = await axios(API_URL_PREFIX + "user/get-user-id", {
        method: 'GET',
        params: {
            userId: userId
        }, 
        headers: {
            'Authorization': "Bearer " + apiSessionId
        },
    });

    // Get the result.
    let result = await response;

    // Check if we were able to get a user
    if (typeof result.data.user != "undefined") {
        return result.data.user;
    } else {
        return null;
    }
}

UserService.resetPassword = async function(currentPassword, newPassword, confirmPassword, memberId) {

    // Get the cookie and check if we are logged in
    const cookies = new Cookies();
    let apiSessionId = cookies.get('apiSessionId')

    // Cookie doesn't exist.
    if (typeof apiSessionId == "undefined") {
        return null;
    }

    // Get the data with the API Session ID
    let response = await axios({
        url: API_URL_PREFIX + "user/reset-password",
        method: 'POST',
        data: { 
            newPassword: newPassword,
            currentPassword: currentPassword,
            confirmPassword: confirmPassword,
            memberId: memberId,
        },
        headers: {
            'Authorization': "Bearer " + apiSessionId
        }
    })

    if (response.status === 200 ) {
        return response.data
    } else {
        return {
            errors: ["Response invalid, please try again."]
        }
    }
}


/**
 * Remove a user from the database.
 *
 * @return boolean
 */
UserService.deleteUser  = async function(userId) {
    // Get the api session cookie.
    const cookies = new Cookies();
    let apiSessionId = cookies.get('apiSessionId')

    // Cookie doesn't exist.
    if (typeof apiSessionId == "undefined") {
       return false; 
    }

    // Check if the cookie is valid.
    let response = await axios(API_URL_PREFIX + "user/delete-user", {
        method: 'POST',
        data: {
            userId: userId
        },
        headers: {
            'Authorization': "Bearer " + apiSessionId
        },
    });

    // Get the result.
    let result = await response;

    if (result.data.deleted === true) {
        return true
        
    }
    
    return false;

}




export default UserService