import React, { Component, createContext } from 'react'
import User from '../services/UserService'
import { FullPageSpinner } from './FullPageSpinner'

export const AuthContext = createContext()


class AuthContextProvider extends Component {
    // Setting the default setting
    state = {
        authenticating: true,
        isAuthenticated: false,
        currentUser: null,
        email: '',
        password: '',
        infoMessage: '',
        errorMessage: ''
    }

    loginTimeout = null

    // Once the component has mounted
    componentDidMount = async () => {
        await this.authenticateLogin();
    }

    /**
     * Check login.
     * 
     */
    authenticateLogin = async () => {
        // Check if apiSessionId cookies exists.
        let response = await User.checkLogin();
        
        if (typeof response.authenticated != "undefined" && response.authenticated === true) {
            this.setState({ 
                isAuthenticated: true, 
                authenticating: false,
                currentUser: response.token.user
            })

            if (this.loginTimeout === null) {

                // Once it expires check login again.
                this.loginTimeout = setTimeout(async () => {
                    let authenticateRes = await this.authenticateLogin();

                    if (authenticateRes === false) {
                        this.setState({ errorMessage: "Your session has expired, please login again." })
                    }
        
                }, response.tokenExpiresIn)
            }

            return true;
        } else {
            this.setState({ 
                isAuthenticated: false, 
                authenticating: false,
                currentUser: null 
            })
            return false;
        }
    }

    /**
     * Logged in will be triggered when the user has sucesssfully logged in.
     * 
     */
    setAuthenticated = (bool) => {
        this.setState({ isAuthenticated: bool })
    }


    /**
     * Handle the form submit.
     */
    handleLoginSubmit = async e => {
        e.preventDefault(); // Prevent form from submitting.

        // Get needed variables.
        const { email, password } = this.state;

        // Attempt the login using email and password.
        let loginResult = await User.login(email, password);

        // Are there any errors?
        if (typeof loginResult.error !== "undefined" && loginResult.error !== "") {
            this.setState({ errorMessage: loginResult.error, infoMessage: '' })
            
        } else {
            // Double-check login.
            await this.authenticateLogin();

            // Redirect to the homepage.
            this.setState({ isAuthenticated: true, authenticating: false, infoMessage: '', errorMessage: '' })
        }
    }

    /**
     * Handle a change in an input.
     */
    handleLoginChange = e => {
        this.setState({ [e.target.name]: e.target.value })
    }

    /**
     * Handle a logout button click.
     */
    handleLogoutClick = e => {
        let logoutResults = User.logout();

        // Redirect to the homepage.
        this.setState({ 
            isAuthenticated: false, 
            authenticating: false,
            infoMessage: logoutResults.info
        })        
    }


    render() {
		const {
            errorMessage,
            infoMessage,
            isAuthenticated,
            authenticating
        } = this.state;

        // Check if the user is logged in.
        let renderedPage = (
            <div className="login-wrapper">
                <div className="login-section">
                    <form action="" className="login-form" onSubmit={this.handleLoginSubmit}>
                        <div className="company-logo mb-4">
                            <img src={'/assets/logo.svg'} alt="IEMA Logo"/>
                        </div>

                        <div className={"alert alert-info " + (infoMessage === '' ? 'd-none' : '')}>{infoMessage}</div>
                        <div className={"alert alert-danger " + (errorMessage === '' ? 'd-none' : '')}>{errorMessage}</div>

                        <div className="form-group">
                            {/* Email field */}
                            <input 
                                type="email"
                                className="form-control email mb-3"
                                name="email"
                                value={this.state.email}
                                onChange={this.handleLoginChange}
                                placeholder="Email" 
                                required="required"
                            />
                        </div>

                        <div className="form-group">
                            {/* Password field */}
                            <input
                                type="password"
                                className="form-control password"
                                name="password"
                                value={this.state.password}
                                onChange={this.handleLoginChange}
                                placeholder="Password"
                                required="required"
                            />
                        </div>
                        <div className="d-flex justify-content-end">
                            <button className="btn btn-primary">Login</button>
                        </div>
                      
                    </form>
                </div>
            </div>
        )

        // If the user is logged in show the actual content.
        if (isAuthenticated) {
            renderedPage = (
                <div>
                    {this.props.children}
                </div>
            );
        }

        // When authenticating, show the loading screen.
        if (authenticating) {
            renderedPage = (<FullPageSpinner/>)
        }

        // When authenticating, show the loading screen.
        if (authenticating) {
            renderedPage = (<FullPageSpinner/>)
        }

        if (window.location.pathname !== "/" && !isAuthenticated && !authenticating) {
            window.location.href = "/"
            renderedPage = (<FullPageSpinner/>)
        }
        
        return (
            <AuthContext.Provider value={{ 
                ...this.state, 
                setAuthenticated: this.setAuthenticated,
                handleLogoutClick: this.handleLogoutClick

            }}>
                {renderedPage}
            </AuthContext.Provider>
        )
    }
}

export default AuthContextProvider