import React, { Component } from 'react';
import { ViewContext } from "./ViewContext";
import { useTable } from 'react-table'
import { Modal } from 'react-bootstrap';
// import { Link } from 'react-router-dom'
import EmailLogService from '../../../services/EmailLogService';
import "react-datepicker/dist/react-datepicker.css";

class EmailLogsTab extends Component {
	state = {
		allMemberEmails: [],
		emailDisplayModal: false,
		emailDisplayed: {},
		emailLogTable: {
			query: {
				searchQuery: "",
				startDate: null,
				endDate: null,
			},
			pageIndex: 0,
			pageCount: 0,
			perPage: 20,
			isLoading: true,
			sort: {
				field: null,
				descending: false
			}
		}
	}

	componentDidMount() {
		// Get the membership number
		let { memberId } = this.props
		let { emailLogTable } = this.state
		
		
		EmailLogService.getMemberEmails(memberId, emailLogTable).then((response) => {
			this.setState({ 
				allMemberEmails: response.allEmails,
				emailLogTable: {
					...emailLogTable, 
					isLoading: false,
					pageCount: response.totalPages,
					pageIndex: response.pageIndex, 
					perPage: response.perPage
				}
			 })
		})
	} 

	// Set the table columns.
	columns = [
		{ Header: 'ID', accessor: 'id' },
		{ Header: 'From', accessor: 'senderEmail' },
		{ Header: 'To', accessor: 'recipientEmail' },
		{ Header: 'Subject', accessor: 'subject' },
		{ Header: 'Date / Time', accessor: 'emailDateSent' },
		{ Header: 'Status', accessor: 'statusName' },
		{ Header: '', accessor: 'view' },
	];
		
	/**
     * Show the modal and fetch email information.
     */
    handleEmailDisplayModal = (e) => {
		let emailId = e.target.getAttribute('email-id')
		this.setState({ emailDisplayModal: true })

        // Get this email
        EmailLogService.getEmailById(emailId).then((email) => {
			this.setState({ emailDisplayed: email })
		});
		
	}


	/**
     * Hide the modal.
     */
	closeEmailDisplayModal = (e) => {
		this.setState({ emailDisplayModal: false })
	}



	// On page change for the table.
	handlePageChange = (pageIndex) => {
		let { memberId } = this.props
		let { emailLogTable } = this.state
		// Set loading to true and update the page number.
		emailLogTable.isLoading = true;
		emailLogTable.pageIndex = pageIndex;

		this.setState({ emailLogTable: emailLogTable })

		// Load all Members.
		EmailLogService.getMemberEmails(memberId, emailLogTable).then((response) => {
			
			this.setState({ 
				allMemberEmails: response.allEmails,
				emailLogTable: {
					...emailLogTable,
					isLoading: false,
					pageCount: response.totalPages,
					pageIndex: response.pageIndex,
					perPage: response.perPage
				}
			})
		});
	}



	render() {		
		return (
			<ViewContext.Consumer>
				{context => { 
					let { allMemberEmails, emailLogTable } = this.state;
					let { memberId } = this.props
					let { statusName } = this.state.emailDisplayed
					// Message Status Colours
					let className;
					switch (statusName) {
						case 'Sent': className = 'success'; break;
						case 'Loaded': className = 'success'; break;
						case 'Link Clicked': className = 'success'; break;
						case 'Held': className = 'warning'; break;
						case 'Delayed': className = 'warning'; break;
						case 'Cancelled': className = 'danger'; break;
						default: className = 'warning'; break;
					}

					
					if (allMemberEmails.length > 0) {
						return (
							<div>
								<EmailLogTable memberId={memberId} gotoPage={this.handlePageChange} handleEmailDisplayModal={this.handleEmailDisplayModal} pageIndex={emailLogTable.pageIndex} pageCount={emailLogTable.pageCount} propData={allMemberEmails}  propColumns={this.columns} />
								<Modal centered size="lg" show={this.state.emailDisplayModal} onHide={this.closeEmailDisplayModal}>	
									<Modal.Header>
										<Modal.Title>{this.state.emailDisplayed.subject}</Modal.Title>
											<button type="button" className="close" onClick={this.closeEmailDisplayModal}><span aria-hidden="true">×</span><span className="sr-only">Close</span></button>
									</Modal.Header>
									<Modal.Body>
										<div className="container">
											<div className="row">
												<div className="col-sm-6">
													<p>To: {this.state.emailDisplayed.recipientEmail}<br />
													{this.state.emailDisplayed.dateSent}<br/></p>
												</div>	
												<div className="col-sm-6">
													<p className={"float-right badge badge-" + className}>Status: {this.state.emailDisplayed.statusName}<br /></p>
												</div>
											</div>
											<div className="row">
												<div className="col-sm-12">
													<hr />
													<div className="email-log-message" dangerouslySetInnerHTML={{__html: this.state.emailDisplayed.body}} />
												</div>
											</div>
										</div>
											
									</Modal.Body>
									<Modal.Footer></Modal.Footer>

							</Modal>
							</div>
						)

					} else {
						return (
							<div>
								<h2>No Emails Found</h2>
							</div>
						)
					}
				}}
			</ViewContext.Consumer>
		)
	}
}


function EmailLogTable({ handleEmailDisplayModal, propColumns, propData, isLoading, handleTdClick }) {
	// Define the table columns.
	const columns = React.useMemo(() => propColumns, [propColumns])
	
	// Define the data.
	const data = React.useMemo(() => propData, [propData])

	// Use the state and functions returned from useTable to build your UI
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow,
	} = useTable({
		columns,
		data,
	})


	return (
		
		<div className="table-responsive">
			<table className={"table table-striped"} {...getTableProps()}>
				<thead>
					{headerGroups.map(headerGroup => (
					<tr {...headerGroup.getHeaderGroupProps()}>
						{headerGroup.headers.map(column => (
							<th {...column.getHeaderProps()}>{column.render("Header")}</th>
						))}
					</tr>
					))}
				</thead>
				<tbody {...getTableBodyProps()}>
					{rows.map((row, i) => {
						prepareRow(row);
						return (
							<tr {...row.getRowProps()}>
								{row.cells.map(cell => {
									if (cell.column.id === 'name') {
										return <td key={i} {...cell.getCellProps()}>{cell.row.original.firstname + ' ' + cell.row.original.lastname}</td>;
									} else if (cell.column.id === 'view') {
											return (<td key={i}><button onClick={handleEmailDisplayModal} email-id={cell.row.original.id} className={"btn btn-primary btn-sm btn-view"}><span>View</span></button></td>)
                                    } else if (cell.column.id === 'statusName') {
										let label = cell.row.original.statusName;
										let className;
										switch (label) {
											case 'Sent': className = 'success'; break;
											case 'Loaded': className = 'success'; break;
											case 'Link Clicked': className = 'success'; break;
											case 'Held': className = 'warning'; break;
											case 'Delayed': className = 'warning'; break;
											case 'Cancelled': className = 'danger'; break;
											default: className = 'warning'; break;
										}
										return (<td {...cell.getCellProps()}>
											<span className={"badge badge-" + className}>{cell.row.original.statusName}</span>
										</td>)
									} else {
										return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
									}
								})}
							</tr>
						);
					})}
				</tbody>
			</table>
			<div className={"loading-table-data" + ((isLoading) ? " active" : "")}><div className="loading-inner">Loading...</div></div>
		</div>
	)
}
	


export default EmailLogsTab;