/**
 * Application Service
 * =================
 *
 * @author    Jamie Hunt
 * @since     1.0.2
 */

// Module dependencies.
import Cookies from 'universal-cookie';
import { encodeFormData } from '../helpers/RequestHelper'
const axios = require('axios').default;
const ApplicationService = {};
let cancelSearch = null;

// Required configuration.
// const API_URL = process.env.REACT_APP_API_URL;
const API_URL_PREFIX = (typeof process.env.REACT_APP_API_URL_PREFIX_OVERRIDE != "undefined") ?
    process.env.REACT_APP_API_URL_PREFIX_OVERRIDE : process.env.REACT_APP_API_URL_PREFIX;


/**
 * Get all the applications forms from the API database.
 * @return array
 */
ApplicationService.getConfigFile = async function() {
    // Get the api session cookie.
    const cookies = new Cookies();
    let apiSessionId = cookies.get('apiSessionId')

    // Cookie doesn't exist.
    if (typeof apiSessionId == "undefined") {
        return false;
    }

    try {
        // Check if the cookie is valid and call the service.
        let response = await axios(API_URL_PREFIX + "applications/get-application-config", {
            method: 'GET',
            headers: {
                'Authorization': "Bearer " + apiSessionId
            },
        });

        // Get the result.
        let result = await response;

        return result.data

    } catch (error) {
        return {
            errors: ["Error. There was an issue updating the form."]
        };
    }

}


/**
 * Toggles an application type configurartion enabled/disabled
 */
ApplicationService.toggleApplicationTypeConfiguration = async function(configurationId) {

    // Get the api session cookie.
    const cookies = new Cookies();
    let apiSessionId = cookies.get('apiSessionId')

    // Cookie doesn't exist.
    if (typeof apiSessionId == "undefined") {
        return false;
    }

    try {

            // Check if the cookie is valid and call the service.
            let response = await axios(API_URL_PREFIX + "applications/toggle-application-type-configuration", {
                method: 'POST',
                data: {
                    configurationId: configurationId,
                },
                headers: {
                    'Authorization': "Bearer " + apiSessionId
                },
            });

        // Get the result.
        let result = await response;

        return result.data

    } catch (error) {
        return {
            errors: ["Error. There was an issue toggling the application type configuration."]
        };
    }


}

/**
 * Get all application type configurations
 */
ApplicationService.getApplicationTypeConfigurations = async function() {

    // Get the api session cookie.
    const cookies = new Cookies();
    let apiSessionId = cookies.get('apiSessionId')

    // Cookie doesn't exist.
    if (typeof apiSessionId == "undefined") {
        return false;
    }

    try {

        // Check if the cookie is valid and call the service.
        let response = await axios(API_URL_PREFIX + "applications/get-application-type-configurations", {
            method: 'GET',
            headers: {
                'Authorization': "Bearer " + apiSessionId
            },
        });

        // Get the result.
        let result = await response;

        return result.data

    } catch (error) {
        return {
            errors: ["Error. There was an issue retrieving the application type configurations."]
        };
    }

}


/**
 * Get all form types.
 */
ApplicationService.getAllFormTypes = async function() {

    // Get the api session cookie.
    const cookies = new Cookies();
    let apiSessionId = cookies.get('apiSessionId')

    // Cookie doesn't exist.
    if (typeof apiSessionId == "undefined") {
        return false;
    }

    try {
        // Check if the cookie is valid and call the service.
        let response = await axios(API_URL_PREFIX + "applications/all-form-types", {
            method: 'GET',
            headers: {
                'Authorization': "Bearer " + apiSessionId
            },
        });

        // Get the result.
        let result = await response;

        return result.data

    } catch (error) {
        return {
            errors: ["Error. There was an issue updating the form."]
        };
    }

}


/**
 * Get all the completed applications
 * @return array
 */
ApplicationService.getCompletedApplications = async function(params) {

    //console.log(API_URL_PREFIX + "applications/get-completed-applications");

        // Get the api session cookie.
        const cookies = new Cookies();
        let apiSessionId = cookies.get('apiSessionId')

        // Cookie doesn't exist.
        if (typeof apiSessionId == "undefined") {
           return false;
        }

        // If we've searched before, cancel that request.
        if (cancelSearch != null) {
            cancelSearch('Operation canceled by the user.');
        }

        try {
            // Check if the cookie is valid and call the service.
            let response = await axios(API_URL_PREFIX + "applications/get-completed-applications", {
                method: 'GET',
                params: {
                    data: params,
                },
                headers: {
                    'Authorization': "Bearer " + apiSessionId
                },
            });

            // Get the result.
            let result = await response;

            return result.data

        } catch (error) {
            return {
                errors: ["Error. There was an issue updating the form."]
            };
        }
}

/**
 * Get all of the incomplete applications
 * @return array
 */
ApplicationService.getIncompleteApplications = async function(params) {

        // Get the api session cookie.
        const cookies = new Cookies();
        let apiSessionId = cookies.get('apiSessionId')

        // Cookie doesn't exist.
        if (typeof apiSessionId == "undefined") {
           return false;
        }

        // If we've searched before, cancel that request.
        if (cancelSearch != null) {
            cancelSearch('Operation canceled by the user.');
        }

        try {
            // Check if the cookie is valid and call the service.
            let response = await axios(API_URL_PREFIX + "applications/get-incomplete-applications", {
                method: 'GET',
                params: {
                    data: params,
                },
                headers: {
                    'Authorization': "Bearer " + apiSessionId
                },
            });

            // Get the result.
            let result = await response;

            return result.data

        } catch (error) {
            return {
                errors: ["Error. There was an issue updating the form."]
            };
        }
}


/**
 * Get one application using an ID.
 * @param {int} ID
 * @return {object} Application Data
 */

ApplicationService.getApplicationById = async function(params) {

    // Get the api session cookie.
    const cookies = new Cookies();
    let apiSessionId = cookies.get('apiSessionId')

    // Cookie doesn't exist.
    if (typeof apiSessionId == "undefined") {
        return false;
    }

    try {
        // Check if the cookie is valid and call the service.
        let response = await axios(API_URL_PREFIX + "applications/get-applications-by-id", {
            method: 'GET',
            params: {
                applicationId: params,
            },
            headers: {
                'Authorization': "Bearer " + apiSessionId
            },
        });

        return { ...response.data.application, apiUrl: API_URL_PREFIX }

    } catch (error) {
        return {
            errors: ["Error. There was an issue fetching application data"]
        };
    }

}

/**
 * Get all the applications using a list of IDs.
 * @return array
 */
ApplicationService.exportSelectedApplications = async function(params) {

    // Get the api session cookie.
    const cookies = new Cookies();
    let apiSessionId = cookies.get('apiSessionId')

    // Cookie doesn't exist.
    if (typeof apiSessionId == "undefined") {
        return false;
    }

    try {
        // Check if the cookie is valid and call the service.
        let response = await axios(API_URL_PREFIX + "applications/export-selected-applications", {
            method: 'GET',
            params: {
                applicationId: params,
            },
            headers: {
                'Authorization': "Bearer " + apiSessionId
            },
        });

        return response.data.results

    } catch (error) {
        return {
            errors: ["Error. There was an issue fetching application data"]
        };
    }
}

/**
 * Manually mark applications as processed in Dynamics.
 *
 * @return array
 */
ApplicationService.manuallyProcessApplications = async function(applicationIds) {

    // Get the api session cookie.
    const cookies = new Cookies();
    let apiSessionId = cookies.get('apiSessionId')

    // Cookie doesn't exist.
    if (typeof apiSessionId == "undefined") {
        return false;
    }

    try {
        // Check if the cookie is valid and call the service.
        let response = await axios(API_URL_PREFIX + "applications/manually-process-applications", {
            method: 'POST',
            data: {
                applicationIds: applicationIds,
            },
            headers: { 'Authorization': "Bearer " + apiSessionId },
        });
        if(response.status === 200){
            return response.data
        }else {
           return {
               errors: [`There was an issue processing the application`]
           }
        }
    } catch (error) {
        return {
            errors: ["There was an issue processing the application"]
        };
    }
}


/**
 * Check to see if an application needs to have it's documents checked
 *
 * @return array
 */
ApplicationService.documentsCheck = async function(application) {

    console.log('documentsCheck');
    console.log(application);

    let returnData = {};
    returnData.pass = true;

    console.log(application.files.eligibility_doc);

    // Do we have documents?
    if (typeof application.files.eligibility_doc != "undefined" && application.files.eligibility_doc.length > 0)
    {
        console.log('we have files');
        // We have documnets
        // Have they been marked as checked?
        if (typeof application.docsChecked != "undefined" && application.docsChecked == 1)
        {

        }
        else
        {
            returnData.pass = false;
        }
    }
    else
    {
        console.log('we dont have files');
    }

    return returnData;
}

/**
 * Check to see if an application needs to have it's documents checked
 *
 * @return array
 */
ApplicationService.markDocsAsChecked = async function(applicationId) {

    // Get the api session cookie.
    const cookies = new Cookies();
    let apiSessionId = cookies.get('apiSessionId')

    // Cookie doesn't exist.
    if (typeof apiSessionId == "undefined") {
        return false;
    }

    try {

        // Check if the cookie is valid and call the service.
        let response = await axios(API_URL_PREFIX + "applications/mark-docs-as-checked", {
            method: 'POST',
            data: {
                applicationId: applicationId,
            },
            headers: { 'Authorization': "Bearer " + apiSessionId },
        });

        if(response.status === 200){
            return response.data
        }else {
           return {
               errors: [`There was an issue marking the docs as checked`]
           }
        }
    } catch (error) {
        return {
            errors: ["There was an issue marking the docs as checked"]
        };
    }
}

/**
 * Process applications in Dynamics.
 *
 * @return array
 */
ApplicationService.processApplications = async function(applicationIds) {

    // Get the api session cookie.
    const cookies = new Cookies();
    let apiSessionId = cookies.get('apiSessionId')

    // Cookie doesn't exist.
    if (typeof apiSessionId == "undefined") {
        return false;
    }

    try {
        // Check if the cookie is valid and call the service.
        let response = await axios(API_URL_PREFIX + "applications/process-applications", {
            method: 'POST',
            data: {
                applicationIds: applicationIds,
            },
            headers: { 'Authorization': "Bearer " + apiSessionId },
        });
        if(response.status === 200){
            return response.data
        }else {
           return {
               errors: [`There was an issue processing the application`]
           }
        }
    } catch (error) {
        return {
            errors: ["There was an issue processing the application"]
        };
    }
}


/**
 * Call the service to get all the applications that have not been already exported
 * @return array
 */
ApplicationService.exportRemainingApplications = async function() {
    // Get the api session cookie.
    const cookies = new Cookies();
    let apiSessionId = cookies.get('apiSessionId')

    // Cookie doesn't exist.
    if (typeof apiSessionId == "undefined") {
        return false;
    }

    try {
        // Check if the cookie is valid and call the service.
        let response = await axios(API_URL_PREFIX + "applications/export-remaining-applications", {
            method: 'GET',
            headers: {
                'Authorization': "Bearer " + apiSessionId
            },
        });

    // Are there any applications to export?
    if (response.data.results.applicationsToExport === "undefined" || response.data.results.applicationsToExport.length === 0 ) {
        // No, send an error back.
        return {
            errors: ["Error. There are no remaining applications to export."]
        };
    } else {
        // Yes, send the data.
        return response.data.results
    }

    } catch (error) {
        return {
            errors: ["Error. There was an issue fetching application config data"]
        };
    }
}


/**
 * Call the service to get all the incomplete applications that have not been already exported
 * @return array
 */
ApplicationService.exportRemainingIncompleteApplications = async function() {
    // Get the api session cookie.
    const cookies = new Cookies();
    let apiSessionId = cookies.get('apiSessionId')

    // Cookie doesn't exist.
    if (typeof apiSessionId == "undefined") {
        return false;
    }

    try {
        // Check if the cookie is valid and call the service.
        let response = await axios(API_URL_PREFIX + "applications/export-remaining-incomplete-applications", {
            method: 'GET',
            headers: {
                'Authorization': "Bearer " + apiSessionId
            },
        });

    // Are there any applications to export?
    if (response.data.results.applicationsToExport === "undefined" || response.data.results.applicationsToExport.length === 0 ) {
        // No, send an error back.
        return {
            errors: ["Error. There are no remaining applications to export."]
        };
    } else {
        // Yes, send the data.
        return response.data.results
    }

    } catch (error) {
        return {
            errors: ["Error. There was an issue fetching application config data"]
        };
    }
}


/**
 * Download a file for an application.
 * @param {int} ID
 * @return {object} Application Data
 */

ApplicationService.downloadApplicationFile = async function(fileId, applicationRef) {

    // Get the api session cookie.
    const cookies = new Cookies();
    let apiSessionId = cookies.get('apiSessionId')

    // Cookie doesn't exist.
    if (typeof apiSessionId == "undefined") {
        return false;
    }

    try {

        let queryParams = encodeFormData({
            fileId: fileId,
            applicationRef: applicationRef
        });

        // Check if the cookie is valid.
        let response = await fetch(API_URL_PREFIX + "applications/download-application-file?" + queryParams, {
            headers: {
                'Authorization': "Bearer " + apiSessionId
            },
            method: 'GET'
        });

        // Get the title of the file.
        let filename = "file.pdf";
        let disposition = response.headers.get('content-disposition');
        if (disposition && disposition.indexOf('attachment') !== -1) {
            let matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(disposition);
            filename = (matches != null && matches[1]) ? matches[1].replace(/['"]/g, '') : "file.pdf";
        }

        // Get the result.
        let blob = await response.blob();

        return { url: window.URL.createObjectURL(new Blob([blob])), filename: filename };
    } catch (error) {
        return null;
    }

}


/**
 * Get all discounts.
 */
// let cancelSearch = null;
ApplicationService.getAllDiscounts = async function(params) {
    // Get the api session cookie.
    const cookies = new Cookies();
    let apiSessionId = cookies.get('apiSessionId')

    // Cookie doesn't exist.
    if (typeof apiSessionId == "undefined") {
        return false;
    }

    try {
        // Check if the cookie is valid and call the service.
        let response = await axios.get(API_URL_PREFIX + "applications/all-discounts", {
            params: params,
            cancelToken: new axios.CancelToken(function executor(c) {
                cancelSearch = c; // Resave var.
            }),
            headers: {
                'Authorization': "Bearer " + apiSessionId
            }
        })

        // Get the result.
        return response.data.results;
    } catch (error) {
        return {
            errors: ["There was an issue fetching the discounts list."]
        };
    }
}


/**
 * Get all discounts.
 */
// let cancelSearch = null;
ApplicationService.getDiscountById = async function(discountId) {
    // Get the api session cookie.
    const cookies = new Cookies();
    let apiSessionId = cookies.get('apiSessionId')

    // Cookie doesn't exist.
    if (typeof apiSessionId == "undefined") {
        return false;
    }

    try {
        // Check if the cookie is valid and call the service.
        let response = await axios.get(API_URL_PREFIX + "applications/discount-by-id", {
            params: { discountId: discountId },
            headers: {
                'Authorization': "Bearer " + apiSessionId
            }
        })

        // Get the result.
        return response.data.discount;
    } catch (error) {
        return {
            errors: ["There was an issue fetching the discounts list."]
        };
    }
}

/**
 * Get country or stickerbook discount.
 */
// let cancelSearch = null;
ApplicationService.getSingleDiscountByType = async function(type) {
    // Get the api session cookie.
    const cookies = new Cookies();
    let apiSessionId = cookies.get('apiSessionId')

    // Cookie doesn't exist.
    if (typeof apiSessionId == "undefined") {
        return false;
    }
    
    try {
        // Check if the cookie is valid and call the service.
        let response = await axios.get(API_URL_PREFIX + "applications/single-discount-by-type", {
            params: {
                type
            },
            headers: {
                'Authorization': "Bearer " + apiSessionId
            }
        })

        // Get the result.
        return response.data.discount;
    } catch (error) {
        return {
            errors: ["There was an issue fetching the discount."]
        };
    }
}


/**
 * Get the discount analytics data.
 *
 */
ApplicationService.getDiscountAnalyticData = async function(discountId) {
    // Get the api session cookie.
    const cookies = new Cookies();
    let apiSessionId = cookies.get('apiSessionId')

    // Cookie doesn't exist.
    if (typeof apiSessionId == "undefined") {
        return false;
    }

    try {
        // Check if the cookie is valid and call the service.
        let response = await axios.get(API_URL_PREFIX + "applications/discount-analytic-data-by-id", {
            params: { discountId: discountId },
            headers: {
                'Authorization': "Bearer " + apiSessionId
            }
        })

        // Get the result.
        return response.data;
    } catch (error) {
        return {
            errors: ["There was an issue fetching the discounts list."]
        };
    }
}


/**
 * Download the discount analytics data.
 *
 */
ApplicationService.downloadAllAnalyticDataById = async function(discountId) {
    // Get the api session cookie.
    const cookies = new Cookies();
    let apiSessionId = cookies.get('apiSessionId')

    // Cookie doesn't exist.
    if (typeof apiSessionId == "undefined") {
        return false;
    }

    try {
        // Check if the cookie is valid and call the service.
        let response = await axios.get(API_URL_PREFIX + "applications/download-all-analytic-data-by-id", {
            params: { discountId: discountId },
            headers: {
                'Authorization': "Bearer " + apiSessionId
            }
        })

        // Get the result.
        return response.data;
    } catch (error) {
        return {
            errors: ["There was an issue fetching the discounts list."]
        };
    }
}


/**
 * Call the service to get all the applications that have not been already exported
 * @return array
 */
ApplicationService.createDiscount = async function(discountData, applicationProducts) {
    // Get the api session cookie.
    const cookies = new Cookies();
    let apiSessionId = cookies.get('apiSessionId')

    // Cookie doesn't exist.
    if (typeof apiSessionId == "undefined") {
        return false;
    }

    try {
        // Check if the cookie is valid and call the service.
        let response = await axios(API_URL_PREFIX + "applications/create-discount", {
            method: 'POST',
            data: {
                discountData: discountData,
                applicationProducts: applicationProducts
            },
            headers: {
                'Authorization': "Bearer " + apiSessionId
            }
        });

        return response.data
    } catch (error) {
        return {
            errors: ["Error. There was an issue fetching application config data"]
        };
    }
}


/**
 * Call the service to get all the applications that have not been already exported
 * @return array
 */
ApplicationService.saveDiscount = async function(discountId, discountData, applicationProducts) {
    // Get the api session cookie.
    const cookies = new Cookies();
    let apiSessionId = cookies.get('apiSessionId')

    // Cookie doesn't exist.
    if (typeof apiSessionId == "undefined") {
        return false;
    }

    try {
        // Check if the cookie is valid and call the service.
        let response = await axios(API_URL_PREFIX + "applications/save-discount", {
            method: 'POST',
            data: {
                discountId: discountId,
                applicationProducts: applicationProducts,
                discountData: discountData
            },
            headers: {
                'Authorization': "Bearer " + apiSessionId
            }
        });

        return response.data
    } catch (error) {
        return {
            errors: ["Error. There was an issue fetching application config data"]
        };
    }
}


/**
 * Get all the applications forms from the API database.
 * @param {string} keyword
 * @return array
 */
ApplicationService.productSearch = async function(keyword) {
    // Get the api session cookie.
    const cookies = new Cookies();
    let apiSessionId = cookies.get('apiSessionId')

    // Cookie doesn't exist.
    if (typeof apiSessionId == "undefined") {
        return false;
    }


    try {
        let queryParams = encodeFormData({
            keyword: keyword
        });

        // Check if the cookie is valid and call the service.
        let response = await axios(API_URL_PREFIX + "applications/product-search?" + queryParams, {
            method: 'GET',
            headers: { 'Authorization': "Bearer " + apiSessionId },
        });

        // Get the result.
        let result = await response.data.products;

        return result

    } catch (error) {
        return {
            errors: ["Error with fetching data."]
        };
    }

}


/**
 * Get all the applications forms from the API database.
 * @param {string} keyword
 * @return array
 */
ApplicationService.getPriceLists = async function() {
    // Get the api session cookie.
    const cookies = new Cookies();
    let apiSessionId = cookies.get('apiSessionId')

    // Cookie doesn't exist.
    if (typeof apiSessionId == "undefined") {
        return false;
    }

    try {

        // Check if the cookie is valid and call the service.
        let response = await axios(API_URL_PREFIX + "applications/get-price-lists", {
            method: 'GET',
            headers: {'Authorization': "Bearer " + apiSessionId},
        });

        // Get the result.
        let result = await response.data.priceLists;

        return result

    } catch (error) {
        return {
            errors: ["Error with fetching data."]
        };

    }
}

/**
 * Get all countries.
 */
// let cancelSearch = null;
ApplicationService.getAllCountries = async function(params) {
    // Get the api session cookie.
    const cookies = new Cookies();
    let apiSessionId = cookies.get('apiSessionId')

    // Cookie doesn't exist.
    if (typeof apiSessionId == "undefined") {
        return false;
    }
    
    try {
        // Check if the cookie is valid and call the service.
        let response = await axios.get(API_URL_PREFIX + "applications/all-countries", {
            params: params,
            cancelToken: new axios.CancelToken(function executor(c) {
                cancelSearch = c; // Resave var.
            }),
            headers: {
                'Authorization': "Bearer " + apiSessionId
            }
        })

        // Get the result.
        return response.data.results;
    } catch (error) {
        return {
            errors: ["There was an issue fetching the countries list."]
        };
    }
}

export default ApplicationService
