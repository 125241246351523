/**
 * Settings Service
 * =================
 * 
 * @author    José Veríssimo
 * @since     1.0.2
 */

// Module dependencies.
import Cookies from 'universal-cookie';
const axios = require('axios').default;

const SettingsService = {};

// Required configuration.
const API_URL_PREFIX = (typeof process.env.REACT_APP_API_URL_PREFIX_OVERRIDE != "undefined") ? 
    process.env.REACT_APP_API_URL_PREFIX_OVERRIDE : process.env.REACT_APP_API_URL_PREFIX;

/**
 * Get all the settings.
 * @return {}
 */
SettingsService.getInfo = async function() {
    // Get the api session cookie.
    const cookies = new Cookies();
    let apiSessionId = cookies.get('apiSessionId') 


    // Cookie doesn't exist.
    if (typeof apiSessionId == "undefined") {
        return null;
    }

    let response = await axios.get(API_URL_PREFIX + "settings/get-settings", {
        // params: { memberId: memberId },
        headers: {
            'Authorization': "Bearer " + apiSessionId
        }
    })

    if (typeof response.data != "undefined") {
        return response.data;
    } else {
        return {};
    }
}


/**
 * Update the settings.
 * 
 * @return {}
 */
SettingsService.updateSettings = async function(settings) {
    // Get the api session cookie.
    const cookies = new Cookies();
    let apiSessionId = cookies.get('apiSessionId')

    // Cookie doesn't exist.
    if (typeof apiSessionId == "undefined") {
        return null;
    }

    let response = await axios({
        url: API_URL_PREFIX + "settings/update-settings",
        method: 'POST',
        data: { settings: settings },
        headers: { 'Authorization': "Bearer " + apiSessionId }
    })

    if (typeof response.data != "undefined") {
        return response.data;
    } else {
        return {};
    }
}


export default SettingsService