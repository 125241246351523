/**
 * User Service
 * =================
 * 
 * @author    José Veríssimo
 * @since     1.0.2
 */

// Module dependencies.
import Cookies from 'universal-cookie';
const axios = require('axios').default;

const MemberService = {};

// Required configuration.
const API_URL_PREFIX = (typeof process.env.REACT_APP_API_URL_PREFIX_OVERRIDE != "undefined") ? 
    process.env.REACT_APP_API_URL_PREFIX_OVERRIDE : process.env.REACT_APP_API_URL_PREFIX;

/**
 * Get a list of all of the users to display on the users page.
 * 
 * @return []
 */
const CancelToken = axios.CancelToken;
let cancelSearch = null;
MemberService.getAllMembers = async function(params) {
    // Get the api session cookie.
    const cookies = new Cookies();
    let apiSessionId = cookies.get('apiSessionId')
    
    // Cookie doesn't exist.
    if (typeof apiSessionId == "undefined") {
        return null;
    }


    // If we've searched before, cancel that request.
    if (cancelSearch != null) {
        cancelSearch('Operation canceled by the user.');
    }

    // Check if the cookie is valid.
    try {
        let response = await axios.get(API_URL_PREFIX + "member/all-members", {
            params: params,
            cancelToken: new CancelToken(function executor(c) {
                cancelSearch = c; // Resave var.
            }),
            headers: {
                'Authorization': "Bearer " + apiSessionId
            }
        })
    
        // Get the result.
        if (typeof response.data.members != "undefined") {
            return response.data;
        } else {
            return [];
        }
    } catch (error) {
        return [];
    }
}


/**
 * Get the basic info for a member.
 * 
 * @return {}
 */
MemberService.getInfo = async function(memberId) {
    // Get the api session cookie.
    const cookies = new Cookies();
    let apiSessionId = cookies.get('apiSessionId')


    // Cookie doesn't exist.
    if (typeof apiSessionId == "undefined") {
        return null;
    }

    let response = await axios.get(API_URL_PREFIX + "member/info", {
        params: { memberId: memberId },
        headers: {
            'Authorization': "Bearer " + apiSessionId
        }
    })

    if (typeof response.data.memberInfo != "undefined") {
        return response.data.memberInfo;
    } else {
        return {};
    }
}


/**
 * Update the info/details for a member.
 * 
 * @return {}
 */
MemberService.updateInfo = async function(memberId, memberDetails) {
    // Get the api session cookie.
    const cookies = new Cookies();
    let apiSessionId = cookies.get('apiSessionId')


    // Cookie doesn't exist.
    if (typeof apiSessionId == "undefined") {
        return null;
    }

    let response = await axios({
        url: API_URL_PREFIX + "member/info",
        method: 'POST',
        data: { 
            memberId: memberId,
            memberDetails: memberDetails
        },
        headers: {
            'Authorization': "Bearer " + apiSessionId
        }
    })

    if (typeof response.data != "undefined") {
        return response.data;
    } else {
        return {};
    }
}


/**
 * Update the membership info/details for a member.
 * 
 * @return {}
 */
MemberService.updateMembershipInfo = async function(memberId, membershipDetails) {
    // Get the api session cookie.
    const cookies = new Cookies();
    let apiSessionId = cookies.get('apiSessionId')


    // Cookie doesn't exist.
    if (typeof apiSessionId == "undefined") {
        return null;
    }

    let response = await axios({
        url: API_URL_PREFIX + "member/membership-info",
        method: 'POST',
        data: { 
            memberId: memberId,
            membershipDetails: membershipDetails
        },
        headers: {
            'Authorization': "Bearer " + apiSessionId
        }
    })

    if (typeof response.data != "undefined") {
        return response.data;
    } else {
        return {};
    }
}



/**
 * Send invoice via email.
 * 
 * @param {string} memberId 
 * @param {string} invoiceId 
 */
MemberService.emailInvoice = async function(memberId, invoiceId, emailAddress) {
    // Get the api session cookie.
    const cookies = new Cookies();
    let apiSessionId = cookies.get('apiSessionId')

    // Cookie doesn't exist.
    if (typeof apiSessionId == "undefined") {
        return null;
    }

    let response = await axios.get(API_URL_PREFIX + "member/email-invoice", {
        params: { memberId: memberId, invoiceId: invoiceId, emailAddress: emailAddress },
        headers: {
            'Authorization': "Bearer " + apiSessionId
        }
    })

    return response;
}


/**
 * Send reset password email
 * 
 * @return {}
 */
MemberService.sendResetPasswordEmail = async function(memberId, email) {
    // Get the api session cookie.
    const cookies = new Cookies();
    let apiSessionId = cookies.get('apiSessionId')


    // Cookie doesn't exist.
    if (typeof apiSessionId == "undefined") {
        return null;
    }

    let response = await axios({
        url: API_URL_PREFIX + "member/reset-password-email",
        method: 'POST',
        data: { 
            memberId: memberId,
            email: email
        },
        headers: {
            'Authorization': "Bearer " + apiSessionId
        }
    })

    if (typeof response.data != "undefined") {
        return response.data;
    } else {
        return {};
    }
}

/**
 * Get the list of countries.
 */
MemberService.getAllCountries = async function() {
    // Get the api session cookie.
    const cookies = new Cookies();
    let apiSessionId = cookies.get('apiSessionId')
    
    // Cookie doesn't exist.
    if (typeof apiSessionId == "undefined") {
        return null;
    }
    
    let response = await axios.get(API_URL_PREFIX + "member/get-countries", {
        headers: {
            'Authorization': "Bearer " + apiSessionId
        }
    })

    return response.data;
}

export default MemberService