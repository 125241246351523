import React, { Component } from 'react';
import Layout from '../../bricks/Layout'
import { store } from 'react-notifications-component';
import UserService from '../../services/UserService'
import { AuthContext } from '../../components/AuthContext';
import AccessDenied from '../../components/AccessDenied';

class MyAccount extends Component {
	state = {
		response: ''
    };
    
    // Breadcrumbs.
	breadcrumbs = [
		{ title: 'Home', link: '/' }, 
		{ title: 'My Account', active: true }
	];

    // Setting default tab state
    state = {
        user: {},
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
        formStatus: {
            errors: [], success: ""
        }
    };

	componentDidMount() {				
		let userId = this.props.match.params.userId

		// Load all Members.
		UserService.getUserById(userId).then((user) => {
	
		// Show the data.
			this.setState({ user: user, userId: userId })
		});
    }

    /**
	 * Handle details form submit.
	 */
	handleDetailsFormSubmit = e => {
		e.preventDefault();

		let { newPassword, currentPassword, confirmPassword } = this.state;
        let memberId = this.props.match.params.userId

		// Save the data.
		UserService.resetPassword(currentPassword, newPassword, confirmPassword, memberId).then((response) => {
			if (typeof response.errors != "undefined" && response.errors.length === 0 ) {
				store.addNotification({
					title: "Success!",
					message: "Password has been updated.",
					type: "success",
					container: "top-right",
					animationIn: ["animated", "slideInRight"],
					animationOut: ["animated", "slideOutRight"],
					dismiss: { duration: 3000 }
				});
			} else {
				response.errors.map(error => {
					store.addNotification({
						title: "Error!",
						message: error,
						type: "danger",
						container: "top-right",
						animationIn: ["animated", "slideInRight"],
						animationOut: ["animated", "slideOutRight"],
						dismiss: { duration: 10000 }
					});
					return true;
				})
			}
		});

	}


	/**
	 * On input change for the details tab.
	 */
	handleDetailsChange = e => {
        let currentState = this.state;
        currentState[e.target.name] = e.target.value
        this.setState(currentState)
    };
    
	render() {
		let { formStatus, user } = this.state;


		return (
			<Layout breadcrumbs={this.breadcrumbs} match={this.props.match}>
				<div className="row">
					<div className="offset-3 col-6">
						<div className="card">
							<div className="card-header">
								<h3>Edit - {user.firstName + ' ' + user.lastName}</h3>
							</div>
							<div className="card-body">
								<form onSubmit={this.handleDetailsFormSubmit} className={(formStatus.success === "" ? "" : " d-none")}>
									<div className="form-group">
										<label>Current Password</label>
										<input className="form-control" name="currentPassword" required type="password" onChange={this.handleDetailsChange} />
									</div>
									<div className="form-group">
										<label>Password</label>
										<input className="form-control" name="newPassword" required type="password" onChange={this.handleDetailsChange} />
									</div>
									
									<div className="form-group">
										<label>Confirm Password</label>
										<input className="form-control" name="confirmPassword" required type="password" onChange={this.handleDetailsChange} />
									</div>
									
									<button type="submit" className="float-right btn-outline-primary btn px-5">Save</button>
								</form> 
							</div>
						</div>
					</div>
				</div>
			</Layout>
		);
	}
}
export default ((props, ref) => (
	<AuthContext.Consumer>{context => {
		if (context.currentUser.permissions.users) {
			return (<MyAccount {...props} authContext={context} />)
		} else {
			return (<AccessDenied {...props}/>)
		}
	}}</AuthContext.Consumer>
));