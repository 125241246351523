import React, { Component } from 'react';
import Layout from './../../bricks/Layout'
import { Link } from 'react-router-dom'
import { AuthContext } from '../../components/AuthContext';
import AccessDenied from '../../components/AccessDenied';


class DirectDebits extends Component {
	state = {
		response: ''
	};

	componentDidMount() {
	}


	// Breadcrumbs.
	breadcrumbs = [
		{ title: 'Home', link: '/' }, 
		{ title: 'Payments', link: '/payments', active: true },
	];
	render() {
		return (
			<Layout match={this.props.match} breadcrumbs={this.breadcrumbs}>
				<h1 className="page-title">Payments</h1>

				<div className="row">
					<div className="col-12 col-sm-6 col-md-4 col-lg-4">
						<div className="card">
							<div className="card-body bg-light">
								<h4 className="mb-4">Export Stripe Transactions to Sage</h4>
								<Link className="btn btn-outline-secondary" to={"/payments/export-stripe-sage"}>View Page</Link>
							</div>
						</div>
					</div>

					<div className="col-12 col-sm-6 col-md-4 col-lg-4">
						<div className="card">
							<div className="card-body bg-light">
								<h4 className="mb-4">Export GoCardless Transactions to Sage</h4>
								<Link className="btn btn-outline-secondary" to={"/payments/export-gocardless-sage"}>View Page</Link>
							</div>
						</div>
					</div>

					<div className="col-12 col-sm-6 col-md-4 col-lg-4">
						<div className="card">
							<div className="card-body bg-light">
								<h4 className="mb-4">Export BACs transactions to Sage</h4>
								<Link className="btn btn-outline-secondary" to={"/payments/export-bacs-payments"}>View Page</Link>
							</div>
						</div>
					</div>

				</div>
			</Layout>
		);
	}
}

export default ((props, ref) => (
	<AuthContext.Consumer>{context => {
		if (context.currentUser.permissions.directDebits) {
			return (<DirectDebits {...props} authContext={context} />)
		} else {
			return (<AccessDenied {...props}/>)
		}
	}}</AuthContext.Consumer>
));