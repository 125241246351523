import React, { Component } from 'react';
import Layout from './../bricks/Layout'
import { Link } from 'react-router-dom'
import { AuthContext } from '../components/AuthContext';
import AccessDenied from '../components/AccessDenied';

class Home extends Component {
	state = {
		response: ''
	};
	
	render() {

		/* 
		*  We need access to the current user data
		*  this is so we can display content that
		*  the user has the correct permission to.
		*/
		let { authContext } = this.props;
		let permissions = (typeof authContext.currentUser.permissions !== "undefined") ? authContext.currentUser.permissions : {};
		// 
		return (
			<Layout match={this.props.match}>
				<div className="row">
					{permissions.members && 
					<div className="col-12 col-sm-6 col-md-4 col-lg-4 mb-3">
						<div className="card">
							<div className="card-body bg-light test-blah-class">
								<h4 className="mb-4">Members</h4>
								<Link to="/members" className="btn btn-outline-secondary">View Members</Link>
							</div>
						</div>
					</div>
					}

					{permissions.applications && 
					<div className="col-12 col-sm-6 col-md-4 col-lg-4 mb-3">
						<div className="card">
							<div className="card-body bg-light">
								<h4 className="mb-4">Applications</h4>
								<Link to="/applications" className="btn btn-outline-secondary">View Applications</Link>
							</div>
						</div>
					</div>
					}

					{permissions.invoices && 
					<div className="col-12 col-sm-6 col-md-4 col-lg-4 mb-3">
						<div className="card">
							<div className="card-body bg-light">
								<h4 className="mb-4">Invoices</h4>
								<Link to="/invoices" className="btn btn-outline-secondary">View Invoices</Link>
							</div>
						</div>
					</div>
					}

					{permissions.payments && 
					<div className="col-12 col-sm-6 col-md-4 col-lg-4 mb-3">
						<div className="card">
							<div className="card-body bg-light">
								<h4 className="mb-4">Payments</h4>
								<Link to="/payments" className="btn btn-outline-secondary">View Payments</Link>
							</div>
						</div>
					</div>
					}

					{permissions.directDebits && 
					<div className="col-12 col-sm-6 col-md-4 col-lg-4 mb-3">
						<div className="card">
							<div className="card-body bg-light">
								<h4 className="mb-4">Direct Debits</h4>
								<Link to="/direct-debits" className="btn btn-outline-secondary">View Direct Debits</Link>
							</div>
						</div>
					</div>
					}

					{permissions.emailLog && 
					<div className="col-12 col-sm-6 col-md-4 col-lg-4 mb-3">
						<div className="card">
							<div className="card-body bg-light">
								<h4 className="mb-4">Email Log</h4>
								<Link to="/email-log" className="btn btn-outline-secondary">View Email Log</Link>
							</div>
						</div>
					</div>
					}

					{permissions.emailLog && 
					<div className="col-12 col-sm-6 col-md-4 col-lg-4 mb-3">
						<div className="card">
							<div className="card-body bg-light">
								<h4 className="mb-4">Users</h4>
								<Link to="/users" className="btn btn-outline-secondary">Manage Hub Users</Link>
							</div>
						</div>
					</div>
					}

				</div>
			</Layout>
		);
	}
}



export default ((props, ref) => (
	<AuthContext.Consumer>{context => {
		// Check if we have any permissions for the hub
		if (context.currentUser.permissions.members === false
			&& context.currentUser.permissions.applications === false
			&& context.currentUser.permissions.directDebits === false
			&& context.currentUser.permissions.emailLog === false
			&& context.currentUser.permissions.invoices === false
			&& context.currentUser.permissions.payments === false
			&& context.currentUser.permissions.admin === false) {
			// They don't have any permissions
			return (<AccessDenied {...props}/>)
			} else {
			return (<Home {...props} authContext={context} />)
		}
	}}</AuthContext.Consumer>
));