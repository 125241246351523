import React, { Component } from 'react';
import PaymentService from '../../../services/PaymentService'
import { useTable, useSortBy, usePagination } from 'react-table'
import { Link } from 'react-router-dom'

class PaymentsTab extends Component {
    state = {
        payments: [],
        searchQuery: ""
    };

    // Set the table columns.
    columns = [
        { Header: 'Number', accessor: 'paymentNumber', sortable: true },
        { Header: 'Amount', accessor: 'amount', sortable: true },
        { Header: '', accessor: 'view', sortable: false }
    ];

	componentDidMount() {
        let memberId = this.props.memberId;

        // Load all Members.
        PaymentService.getByMemberId(memberId).then((payments) => {
            this.setState({ payments: payments })
        });
    }
    render() {
        
        return (
            <div>
                <PaymentsTable tableData={this.state.payments} tableColumns={this.columns} searchQuery={this.state.searchQuery} />
            </div>
        );
    }
}



/**
 * Table to display the members with pagination and filtering.
 * @param {}
 */
function PaymentsTable({ tableColumns, tableData }) {

    // Define the table columns.
    const columns = React.useMemo(() => tableColumns, [tableColumns])
    
    // Define the data.
    const data = React.useMemo(() => tableData, [tableData])
    
    // Use the state and functions returned from useTable to build your UI
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page, 
        // Instead of using 'rows', we'll use page,
        // which has only the rows for the active page

        canPreviousPage,
        canNextPage,
        // pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
    } = useTable({
        columns,
        data,
        initialState: { pageIndex: 0, pageSize: 20 },
    }, useSortBy, usePagination)


    /**
     * Calculate and show the correct pages.
     * @param {*} pIndex 
     * @param {*} pCount 
     */
    const numberedPages = (pIndex, pCount) => {
        let gCount = 3;
        let showPagesArr = [];

        // Loop through all pages and calculate which ones to show.
        for (let i = 0; i < pCount; i++) {
            if ((pIndex - gCount) < i && (gCount + pIndex) > i) {
                showPagesArr.push(i);
            }            
        }
        return showPagesArr;
    }
    
    return (
        <div>
            <div className="table-responsive">
                <table className={"table table-striped"} {...getTableProps()}>
                    <thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                // Add the sorting props to control sorting. For this example
                                // we can add them into the header props
                                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                    {column.render('Header')}

                                    {/* Add a sort direction indicator */}
                                    {(column.sortable) ? (
                                        <span className={"sort " + (column.isSorted ? (column.isSortedDesc ? 'sort-desc' : 'sort-asc') : 'sort-none')}>
                                        </span>
                                    ) : ''}
                                </th>
                            ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map((row, i) => {
                            prepareRow(row);

                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map(cell => {
                                        if (cell.column.id === 'view') {
                                            return (<td {...cell.getCellProps()}>
                                                    <Link to={"/payments/" + cell.row.original.id} className={"btn btn-primary btn-sm btn-view"}><span>View</span></Link>
                                                </td>)
                                        } else if (cell.column.id === 'amount') {
                                            return (<td {...cell.getCellProps()}>£{cell.render('Cell')}</td>)
                                        } else {
                                            return (<td {...cell.getCellProps()}>{cell.render('Cell')}</td>)
                                        }
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>

            {/* 
                Pagination can be built however you'd like. 
                This is just a very basic UI implementation:
            */}
            <div className="pagination">
                <select
                value={pageSize}
                onChange={e => {
                    setPageSize(Number(e.target.value))
                }}
                >
                {[10, 20, 30, 40, 50].map(pageSize => (
                    <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                    </option>
                ))}
                </select>
            </div>

            <nav className="d-flex" aria-label="Page navigation">
                <ul className="pagination mx-auto">
                    <li className="page-item">
                        <button className="page-link" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>&lt;&lt; First</button>
                    </li>
                    <li className="page-item">
                        <button className="page-link" onClick={() => previousPage()} disabled={!canPreviousPage}>Previous</button>
                    </li>
                    {numberedPages(pageIndex, pageCount).map((pageNum) => {
                        return (
                            <li className={"page-item " + (pageIndex === pageNum ? 'active' : '')} key={pageNum+1}>
                                <button className="page-link" onClick={() => gotoPage(pageNum)}>{pageNum+1}</button>
                            </li>
                        )
                    })}
                    <li className="page-item">
                        <button className="page-link" onClick={() => nextPage()} disabled={!canNextPage}>Next &gt;</button>
                    </li>
                    <li className="page-item">
                        <button className="page-link" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>Last &gt;&gt;</button>
                    </li>
                </ul>
            </nav>
        </div>
    )
}

export default PaymentsTab;