import React, { Component } from 'react';
import Layout from './../../bricks/Layout'
import PaymentService from '../../services/PaymentService'
import { AuthContext } from '../../components/AuthContext';
import AccessDenied from '../../components/AccessDenied';
var moment = require('moment');

class MandateView extends Component {
	state = {
		response: '',
		mandate: {},
		customer: {},
		bankAccount: {},
		mandateEvents: [],
		loading: true
	};

	// Breadcrumbs.
	breadcrumbs = [
		{ title: 'Home', link: '/' }, 
		{ title: 'Direct Debits', link: '/direct-debits' },
		{ title: 'Mandates', link: '/direct-debits/mandates' },
		{ title: this.props.match.params.mandateId, link: '/direct-debits/mandates' + this.props.match.params.mandateId, active: true }
	];

	componentDidMount() {
		let mandateId = this.props.match.params.mandateId
		
		// Load mandate.
        PaymentService.getMandateInfo(mandateId).then((mandateInfo) => {
            this.setState({ 
				mandate: mandateInfo.mandate, 
				mandateEvents: mandateInfo.events, 
				bankAccount: mandateInfo.bankAccount, 
				customer: mandateInfo.customer, 
				loading: false
			})
		});

    }
	
	
	render() {
		let { mandate, mandateEvents, customer, bankAccount, loading } = this.state;

		return (
		<AuthContext.Consumer>
			{context => { return (
				<div>
					<Layout breadcrumbs={this.breadcrumbs} match={this.props.match}>
						<h1 className="page-title">{this.props.match.params.mandateId}</h1>

						{!loading && <>
							<h4 className="mb-3">Details</h4>
							<table className="table table-sm mb-5">
								<tbody>
									<tr>	
										<td><strong>GoCardless Reference</strong></td>
										<td>{mandate.id}</td>
									</tr>
									<tr>	
										<td><strong>Customer</strong></td>
										<td>{customer.given_name} {customer.family_name}</td>
									</tr>
									<tr>	
										<td><strong>Bank</strong></td>
										<td>{bankAccount.bank_name}</td>
									</tr>
									<tr>	
										<td><strong>Bank Account Holder</strong></td>
										<td>{bankAccount.account_holder_name}</td>
									</tr>
									<tr>	
										<td><strong>Bank Account Number</strong></td>
										<td>******{bankAccount.account_number_ending}</td>
									</tr>
									<tr>	
										<td><strong>Status</strong></td>
										<td>
											<span 
												className={"badge text-uppercase badge-secondary badge-" + 
													(mandate.status === "pending_submission" ? "secondary":"") + 
													(mandate.status === "submitted" ? "success":"") + 
													(mandate.status === "cancelled" ? "danger":"")
												}
											>{(typeof mandate.status != "undefined") ? mandate.status.replace(/_/gi, " ") : ''}</span>	
										</td>
									</tr>

								</tbody>
							</table>
							<br />
							<h4>Events</h4>
							<p>Mandate events recorded by GoCardless</p>

							<table className="table table-condensed">
								<thead>
									<tr>
										<th>Action</th>
										<th>Origin</th>
										<th>Description</th>
										<th>Date</th>
									</tr>
								</thead>
								<tbody>
									{mandateEvents.map(event => (
										<tr>
											<td>{event.action}</td>
											<td>{event.details.origin}</td>
											<td>{event.details.description}</td>
											<td>{moment(event.created_at).format('DD/MM/YYYY')} {moment(event.created_at).format('LTS')}</td>
										</tr>
									))}
								</tbody>
							</table>
						</> }

						{loading && <>
							<p>Loading...</p>
						</>}
					</Layout>
				</div>
			)}}
		</AuthContext.Consumer>
		);
	}
}

export default ((props, ref) => (
	<AuthContext.Consumer>{context => {
		if (context.currentUser.permissions.directDebits) {
			return (<MandateView {...props} authContext={context} />)
		} else {
			return (<AccessDenied {...props}/>)
		}
	}}</AuthContext.Consumer>
));