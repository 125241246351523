import React, { Component } from 'react';
import Layout from '../../bricks/Layout'
import PaymentService from '../../services/PaymentService'
import { AuthContext } from '../../components/AuthContext';
import AccessDenied from '../../components/AccessDenied';
import { Link } from 'react-router-dom'
var moment = require('moment');

class BankAccountsView extends Component {
	state = {
		response: '',
		bankAccount: {},
		customer: {},
		mandates: [],
		loading: true,

		// Breadcrumbs.
		breadcrumbs: [
			{ title: 'Home', link: '/' }, 
			{ title: 'Direct Debits', link: '/direct-debits' },
			{ title: 'Bank Accounts', link: '/direct-debits/bank-accounts' }
		]
	};


	componentDidMount() {
		let bankAccountId = this.props.match.params.bankAccountId
		let { breadcrumbs } = this.state
		
		// Load bankAccount.
        PaymentService.getBankAccountInfo(bankAccountId).then((bankAccountInfo) => {
			breadcrumbs.push({ title: bankAccountInfo.bankAccount.account_holder_name + " ("+ bankAccountInfo.bankAccount.bank_name + ")", link: '/direct-debits/bank-accounts' + this.props.match.params.bankAccountId, active: true })

            this.setState({ 
				bankAccount: bankAccountInfo.bankAccount, 
				customer: bankAccountInfo.customer, 
				mandates: bankAccountInfo.mandates, 
				loading: false,
				breadcrumbs: breadcrumbs
			})
		});

    }
	
	
	render() {
		let { bankAccount, mandates, loading } = this.state;

		return (
		<AuthContext.Consumer>
			{context => { return (
				<div>
					<Layout breadcrumbs={this.state.breadcrumbs} match={this.props.match}>
						<h1 className="page-title">{bankAccount.account_holder_name} ({bankAccount.bank_name})</h1>

						{!loading && <>
							<h4 className="mb-3">Details</h4>
							<table className="table table-sm mb-5">
								<tbody>
									<tr>	
										<td><strong>Bank</strong></td>
										<td>{bankAccount.bank_name}</td>
									</tr>
									<tr>	
										<td><strong>Bank Account Holder</strong></td>
										<td>{bankAccount.account_holder_name}</td>
									</tr>
									<tr>	
										<td><strong>Bank Account Number</strong></td>
										<td>******{bankAccount.account_number_ending}</td>
									</tr>
									<tr>	
										<td><strong>Currency</strong></td>
										<td>{bankAccount.currency}</td>
									</tr>
									<tr>	
										<td><strong>Enabled</strong></td>
										<td><span className={bankAccount.enabled ? "icon-enabled" : "icon-disabled"}></span></td>
									</tr>
								</tbody>
							</table>

							{mandates.length > 0 && <>
								<h4 className="mb-4">Mandates</h4>
								<div className="table-responsive table-data position-relative">
									<table className="table table-striped">
										<thead>
											<tr>
												<th colSpan="1">ID</th>
												<th colSpan="1">Status</th>
												<th colSpan="1">Creation Date</th>
												<th colSpan="1"></th>
											</tr>
										</thead>
										<tbody>
											{mandates.map((mandate, i) => {
												return (
													<tr key={"mandates-" + i}>
														<td>{mandate.id}</td>
														<td>
															<span 
															className={"badge text-uppercase badge-secondary badge-" + 
																(mandate.status === "pending_submission" ? "secondary":"") + 
																(mandate.status === "submitted" ? "success":"") + 
																(mandate.status === "active" ? "success":"") + 
																(mandate.status === "cancelled" ? "danger":"")
															}
															>{mandate.status.replace(/_/gi, " ")}</span>
														</td>
														<td>{moment(mandate.created_at).format('DD/MM/YYYY')}</td>
														<td><Link className="btn btn-primary btn-sm float-right" to={"/direct-debits/mandates/" + mandate.id}>View</Link>
														</td>
													</tr>
												)
											})}
										</tbody>
									</table>
								</div>
							</>}
						</> }

						{loading && <>
							<p>Loading...</p>
						</>}
					</Layout>
				</div>
			)}}
		</AuthContext.Consumer>
		);
	}
}

export default ((props, ref) => (
	<AuthContext.Consumer>{context => {
		if (context.currentUser.permissions.directDebits) {
			return (<BankAccountsView {...props} authContext={context} />)
		} else {
			return (<AccessDenied {...props}/>)
		}
	}}</AuthContext.Consumer>
));