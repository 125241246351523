import React, { Component } from 'react';
import UserService from '../../services/UserService';
import { store } from 'react-notifications-component';
import Layout from './../../bricks/Layout'
import { AuthContext } from '../../components/AuthContext'
 

class AddUser extends Component {

	// Breadcrumbs.
	breadcrumbs = [
		{ title: 'Home', link: '/' }, 
		{ title: 'Users', link: '/users' },
		{ title: 'Add User', link: '/add-user', active: true }
	];

	// Setting default tab state
	state = {
		user: {
			permissions: {
				members: false,
				applications: false, 
				invoices: false,
				payments: false, 
				directDebits: false, 
				emailLog: false,
				admin: false,
				settings: false,
			}
		},
		formStatus: {
			errors: [], success: ""
		}
	};


	/**
	 * Handle details form submit.
	 */
	handleDetailsFormSubmit = e => {
		e.preventDefault();

		let { user } = this.state;
		// Check if user beinf created will have any permissions.
		if (user.permissions.members === false
			&& user.permissions.applications === false
			&& user.permissions.directDebits === false
			&& user.permissions.emailLog === false
			&& user.permissions.invoices === false
			&& user.permissions.payments === false
			&& user.permissions.settings === false
			&& user.permissions.admin === false) {
			// User has no permissions, tell the user. 
			store.addNotification({
				title: "Error!",
				message: "Please select at least 1 permission for this user.",
				type: "danger",
				container: "top-right",
				animationIn: ["animated", "slideInRight"],
				animationOut: ["animated", "slideOutRight"],
				dismiss: { duration: 10000 }
			});

		} else {
			// Save the data.
			UserService.addUser(user).then((response) => {
				if (typeof response.errors != "undefined" && response.errors.length === 0 ) {
					store.addNotification({
						title: "Success!",
						message: "User has been created.",
						type: "success",
						container: "top-right",
						animationIn: ["animated", "slideInRight"],
						animationOut: ["animated", "slideOutRight"],
						dismiss: { duration: 3000 }
					});
					// Only Redirect if there are no errors
					this.props.history.push("/users");
				} else {
					response.errors.map(error => {
						store.addNotification({
							title: "Error!",
							message: error,
							type: "danger",
							container: "top-right",
							animationIn: ["animated", "slideInRight"],
							animationOut: ["animated", "slideOutRight"],
							dismiss: { duration: 10000 }
						});
						return true;
					})
				}
						
				
			});
			}
	}


	/**
	 * On input change for the details tab.
	 */
	handleDetailsChange = e => {
		let { user } = this.state;

		// Normal input.
		if (e.target.type === "checkbox") {
			user.permissions[e.target.name] = e.target.checked
		} else {	
			user[e.target.name] = e.target.value
		}

		// Normal input.
		user[e.target.name] = e.target.value
		this.setState({ user: user })
	};


	render() {
		let { formStatus, user } = this.state;

		return (
			<Layout breadcrumbs={this.breadcrumbs} match={this.props.match}>

				<h2>Add User</h2>

				<div className={"alert alert-success" + (formStatus.success !== "" ? "" : " d-none")}>
					{formStatus.success}
				</div>
				<div className={"alert alert-danger" + (formStatus.errors.length > 0 ? "" : " d-none")}>
					{formStatus.errors.map(error => (
						<span>{error}<br /></span>
					))}
				</div>

				<form onSubmit={this.handleDetailsFormSubmit}>
					<div className="row">

						<div className="col-3">
							<div className="form-group">
								<label>First Name</label>
								<input className="form-control" name="firstname" required type="text" onChange={this.handleDetailsChange} />
							</div>
						</div>

						<div className="col-3">
							<div className="form-group">
								<label>Last Name</label>
								<input className="form-control" name="lastname" required type="text" onChange={this.handleDetailsChange} />
							</div>
						</div>

						<div className="col-6">
							<div className="form-group">
								<label>Email</label>
								<input className="form-control" name="email" required type="email" onChange={this.handleDetailsChange} />
							</div>
						</div>

						<div className="col-6">
							<div className="form-group">
								<label>Password</label>
								<input className="form-control" name="password" required type="password" onChange={this.handleDetailsChange} />
							</div>
						</div>

						<div className="col-6">
							<div className="form-group">
								<label>Confirm Password</label>
								<input className="form-control" name="confirmPassword" required type="password" onChange={this.handleDetailsChange} />
							</div>
						</div>
					</div>
						<h4>User Permissions</h4>
							<div className="form-check">
								<label className="form-check-label"><input className="form-check-input" checked={user.permissions.members} onChange={this.handleDetailsChange} name="members" type="checkbox"/>Members</label>
							</div>

							<div className="form-check">
								<label className="form-check-label"><input className="form-check-input" checked={user.permissions.applications} onChange={this.handleDetailsChange} name="applications" type="checkbox"/>Applications</label>
							</div>

							<div className="form-check">
								<label className="form-check-label"><input className="form-check-input" checked={user.permissions.invoices} onChange={this.handleDetailsChange} name="invoices" type="checkbox"/>Invoices</label>
							</div>

							<div className="form-check">
								<label className="form-check-label"><input className="form-check-input" checked={user.permissions.payments} onChange={this.handleDetailsChange} name="payments" type="checkbox"/>Payments</label>

							</div>
							<div className="form-check">
								<label className="form-check-label"><input className="form-check-input" checked={user.permissions.directDebits} onChange={this.handleDetailsChange} name="directDebits" type="checkbox"/>Direct Debits</label>
							</div>

							<div className="form-check">
								<label className="form-check-label"><input className="form-check-input" checked={user.permissions.emailLog} onChange={this.handleDetailsChange} name="emailLog" type="checkbox"/>Email Log</label>
							</div>

							<div className="form-check">
								<label className="form-check-label"><input className="form-check-input" checked={user.permissions.admin} onChange={this.handleDetailsChange} name="admin" type="checkbox"/>Admin (Add/Edit Users)</label>
							</div>

							<div className="form-check">
								<label className="form-check-label"><input className="form-check-input" checked={user.permissions.settings} onChange={this.handleDetailsChange} name="settings" type="checkbox"/>Settings</label>
							</div>
					
					<button type="submit" className="float-right btn-outline-primary btn px-5">Save</button>
				</form> 
			</Layout>
		)
	}
}

export default ((props, ref) => (
	<AuthContext.Consumer>{context => {
		if (context.currentUser.permissions.admin) {
			return (<AddUser {...props} authContext={context} />)
		} else {
			return (<div><h1>Access denied</h1></div>)
		}
	}}</AuthContext.Consumer>
));