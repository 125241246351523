import React, { Component } from 'react';
// import { useTable } from 'react-table'
import EmailLogService from '../../services/EmailLogService'
import Layout from './../../bricks/Layout'
import { AuthContext } from '../../components/AuthContext';
import AccessDenied from '../../components/AccessDenied';
// var moment = require('moment');


class EmailsView extends Component {
    state = {
		response: '',
		email: []
	};

    componentDidMount() {
        let emailId = this.props.match.params.emailId
        // Get this email
        EmailLogService.getEmailById(emailId).then((email) => {
			this.setState({ email: email })
		});
    }

	// Breadcrumbs.
	breadcrumbs = [
		{ title: 'Home', link: '/' }, 
		{ title: 'Email Log', link: '/email-log' },
		{ title: 'Details', link: null, active: true }
    ];


    // Set the table columns.
	columns = [
		{ Header: 'Action', accessor: 'action' },
		{ Header: 'Message', accessor: 'message' },
		{ Header: 'Logged At', accessor: 'dateTime' },
		{ Header: '', accessor: 'view' },
	];


	render() {
        // const dateTime = moment().format('MMMM Do YYYY, h:mm:ss a');
        let { email } = this.state;
            return (
                <Layout breadcrumbs={this.breadcrumbs} match={this.props.match}>
                
                    <h1 className="page-title float-left">Email Details</h1>
                    <table className="table table-sm mb-5">
                        <tbody>
                            <tr>
                                <td width="20%"><strong>Email ID</strong></td>
                                <td>{ email.id }</td>
                            </tr>
                            <tr>
                                <td width="20%"><strong>Email Address</strong></td>
                                <td>{ email.recipientEmail }</td>
                            </tr>
                            <tr>
                                <td width="20%"><strong>From</strong></td>
                                <td>{ email.senderEmail }</td>
                            </tr>
                            <tr>
                                <td width="20%"><strong>Subject</strong></td>
                                <td>{ email.subject }</td>
                            </tr>
                            <tr>
                                <td width="20%"><strong>State</strong></td>
                                <td>{ email.statusName }</td>
                            </tr>
                            <tr>
                                <td width="20%"><strong>Sent Date / Time</strong></td>
                                <td>{ email.dateSent }</td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="row">
                        <div className="container">
                            <h3>Message Content</h3>
                            <div className="email-log-message" dangerouslySetInnerHTML={{__html: email.body}} />
                        </div>
                    </div>
                </Layout>
            );

        }
	}


    // function UsersTable({ propColumns, propData }) {
    //     // Define the table columns.
    //     const columns = React.useMemo(() => propColumns, [propColumns])
        
    //     // Define the data.
    //     const data = React.useMemo(() => propData, [propData])
        
    //     // Use the state and functions returned from useTable to build your UI
    //     const {
    //         getTableProps,
    //         getTableBodyProps,
    //         headerGroups,
    //         rows,
    //         prepareRow,
    //     } = useTable({
    //         columns,
    //         data,
    //     })
        
    //     const dateTime = moment().format('MMMM Do YYYY, h:mm:ss a');
    
    //     return (
            
    //         <div className="table-responsive">
    //             <table className={"table table-striped"} {...getTableProps()}>
    //                 <thead>
    //                     {headerGroups.map(headerGroup => (
    //                     <tr {...headerGroup.getHeaderGroupProps()}>
    //                         {headerGroup.headers.map(column => (
    //                             <th {...column.getHeaderProps()}>{column.render("Header")}</th>
    //                         ))}
    //                     </tr>
    //                     ))}
    //                 </thead>
    //                 <tbody {...getTableBodyProps()}>
    //                     {rows.map((row, i) => {
    //                         prepareRow(row);
    //                         return (
    //                             <tr {...row.getRowProps()}>
    //                                 {row.cells.map(cell => {
    //                                     if (cell.column.id === 'action') {
    //                                         return <td {...cell.getCellProps()}>
    //                                             <span className={"badge badge-success"}>Message Sent</span></td>;
    //                                     } else if (cell.column.id === 'message') {
    //                                         return (<td {...cell.getCellProps()}>
    //                                             <span>Message for helpdesk@cursor.co.uk accepted by mailhop-smtp-in.l.mailhop.co.uk (2a00:1450:400c:c07::1a)</span></td>)
    //                                     } else if (cell.column.id === "dateTime") {
    //                                         return (<td {...cell.getCellProps()}>
    //                                             <span>{dateTime}</span></td>)
    //                                     } else if (cell.column.id === "view") {
    //                                         return (<td {...cell.getCellProps()}>
    //                                                 <button class={"btn btn-primary"}>View</button></td>)
    //                                     }
    //                                     else {
    //                                         return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
    //                                     }
    //                                 })}
    //                             </tr>
    //                         );
    //                     })}
    //                 </tbody>
    //             </table>
    //         </div>
    //     )
    // }
    export default ((props, ref) => (
        <AuthContext.Consumer>{context => {
            if (context.currentUser.permissions.emailLog) {
                return (<EmailsView {...props} authContext={context} />)
            } else {
                return (<AccessDenied {...props}/>)
            }
        }}</AuthContext.Consumer>
    ));