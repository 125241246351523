import React, { Component } from 'react';
import Layout from './../../bricks/Layout'
import PaymentService from '../../services/PaymentService'
import { AuthContext } from '../../components/AuthContext';
import AccessDenied from '../../components/AccessDenied';
var moment = require('moment');

class PaymentView extends Component {
	state = {
		response: '',
		payment: {},
		customer: {},
		bankAccount: {},
		mandate: {},
		invoice: {},
		paymentEvents: [],
		loading: true
	};

	// Breadcrumbs.
	breadcrumbs = [
		{ title: 'Home', link: '/' }, 
		{ title: 'Direct Debits', link: '/direct-debits' },
		{ title: 'Payments', link: '/direct-debits/payments' },
		{ title: this.props.match.params.paymentId, link: '/direct-debits/payments' + this.props.match.params.paymentId, active: true }
	];

	componentDidMount() {
		let paymentId = this.props.match.params.paymentId
		
		// Load payment.
        PaymentService.getPaymentInfo(paymentId).then((paymentInfo) => {
            this.setState({ 
				payment: paymentInfo.payment, 
				invoice: paymentInfo.invoice,
				paymentEvents: paymentInfo.events, 
				bankAccount: paymentInfo.bankAccount, 
				customer: paymentInfo.customer,
				mandate: paymentInfo.mandate,
				loading: false
			})
		});

    }
	
	
	render() {
		let { payment, paymentEvents, customer, bankAccount, loading } = this.state;
		return (
		<AuthContext.Consumer>
			{context => { return (
				<div>
					<Layout breadcrumbs={this.breadcrumbs} match={this.props.match}>
						<h1 className="page-title">{this.props.match.params.paymentId}</h1>


						{!loading && <>
							<h4 className="mb-3">Details</h4>
							<table className="table table-sm mb-5">
								<tbody>
									<tr>	
										<td><strong>Description</strong></td>
										<td>{payment.description}</td>
									</tr>
									<tr>	
										<td><strong>Amount</strong></td>
										<td>&pound;{(payment.amount/100).toFixed(2)}</td>
									</tr>
									<tr>	
										<td><strong>Status</strong></td>
										<td>
											<span 
												className={"badge text-uppercase badge-secondary badge-" + 
													(payment.status === "pending_submission" ? "secondary":"") + 
													(payment.status === "submitted" ? "success":"") + 
													(payment.status === "cancelled" ? "danger":"")
												}
											>{(typeof payment.status != "undefined") ? payment.status.replace(/_/gi, " ") : ''}</span>	
										</td>
									</tr>
									<tr>	
										<td><strong>Created</strong></td>
										<td>{moment(payment.created_at).format('DD/MM/YYYY')} {moment(payment.created_at).format('LTS')}</td>
									</tr>
									<tr>	
										<td><strong>Charge Date</strong></td>
										<td>{moment(payment.charge_date).format('DD/MM/YYYY')}</td>
									</tr>
								</tbody>
							</table>
							
							<h4 className="mb-3">Bank Account</h4>
							<table className="table table-sm mb-5">
								<tbody>
									<tr>	
										<td><strong>Customer</strong></td>
										<td>{customer.given_name} {customer.family_name}</td>
									</tr>
									<tr>	
										<td><strong>Bank</strong></td>
										<td>{bankAccount.bank_name}</td>
									</tr>
									<tr>	
										<td><strong>Bank Account Holder</strong></td>
										<td>{bankAccount.account_holder_name}</td>
									</tr>
									<tr>	
										<td><strong>Bank Account Number</strong></td>
										<td>******{bankAccount.account_number_ending}</td>
									</tr>

								</tbody>
							</table>

							<h4>Events</h4>
							<p>payment events recorded by GoCardless</p>

							<table className="table table-condensed">
								<thead>
									<tr>
										<th>Action</th>
										<th>Origin</th>
										<th>Description</th>
										<th>Date</th>
									</tr>
								</thead>
								<tbody>
									{paymentEvents.map((event, i) => (
										<tr key={"payment-events-" + i}>
											<td>{event.action}</td>
											<td>{event.details.origin}</td>
											<td>{event.details.description}</td>
											<td>{moment(event.created_at).format('DD/MM/YYYY')} {moment(event.created_at).format('LTS')}</td>
										</tr>
									))}
								</tbody>
							</table>
						</> }

						{loading && <>
							<p>Loading...</p>
						</>}
					</Layout>
				</div>
			)}}
		</AuthContext.Consumer>
		);
	}
}

export default ((props, ref) => (
	<AuthContext.Consumer>{context => {
		if (context.currentUser.permissions.directDebits) {
			return (<PaymentView {...props} authContext={context} />)
		} else {
			return (<AccessDenied {...props}/>)
		}
	}}</AuthContext.Consumer>
));