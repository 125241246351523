import React, { Component } from 'react';
import Layout from '../../bricks/Layout'
import ApplicationService from '../../services/ApplicationService';
import { withRouter } from 'react-router-dom'
import { AuthContext } from '../../components/AuthContext';
import AccessDenied from '../../components/AccessDenied';
import Tabs from "../../components/Tabs"; 
import { Check, Close } from '@material-ui/icons';
import { green, red } from '@material-ui/core/colors';

class ApplicationsConfiguration extends Component {
	state = {
		applicationConfigFields: {},
		applicationConfigPrices: {},
		applicationConfigPrices_2022: {},
		applicationConfigPrices_2023: {},
		applicationConfigPrices_2024: {},
		applicationTypeConfigurations: []
	};
    // Breadcrumbs.
    breadcrumbs = [
        { title: 'Home', link: '/' }, 
        { title: 'Applications', link: '/applications', active: false },
        { title: 'Configuration', link: '/applications/configuration', active: true }
    ];

	async componentDidMount() {
		let response = await ApplicationService.getConfigFile();
		this.setState({ 
			applicationConfigFields: response.applicationConfigFields, 
			applicationConfigPrices: response.applicationConfigPrices, 
			applicationConfigPrices_2022: response.applicationConfigPrices_2022, 
			applicationConfigPrices_2023: response.applicationConfigPrices_2023,
			applicationConfigPrices_2024: response.applicationConfigPrices_2024
		});
			
		await this.fetchApplicationSettings();
	}
	
	async fetchApplicationSettings() {
		let response = await ApplicationService.getApplicationTypeConfigurations();
		this.setState({applicationTypeConfigurations: response.applicationTypeConfigurations})
	}

	async toggleApplicationTypeStp(configurationId){
		let result = await ApplicationService.toggleApplicationTypeConfiguration(configurationId);
		if(result) {
			await this.fetchApplicationSettings();
		}
	}

	render() {
		let { applicationConfigPrices } = this.state
		let { applicationConfigPrices_2022 } = this.state
		let { applicationConfigPrices_2023 } = this.state
		let { applicationConfigPrices_2024 } = this.state

		return (
			<Layout breadcrumbs={this.breadcrumbs} match={this.props.match}>
                <h1 className="page-title">Application Forms Configuration</h1>
				<div className="row">
					<div className="col-md-12">
						<p>To change application configuration please contact Cursor.</p>

						<Tabs> 
							{/* <div label="Application Prices"> 
								<h3>Application Prices</h3>	<br />
								{Object.keys(applicationConfigPrices).map(applicationType => {
									let applicationGroup = applicationConfigPrices[applicationType]
									
									return (
										<div key={applicationType}>
											{Object.keys(applicationGroup).map(applicationSubType => {
												let subType = applicationGroup[applicationSubType]
												return (
													<div key={applicationSubType}>
														<h4>{subType.name}</h4>
														<p><strong>Price:</strong> &pound;{subType.price}<br />
														<strong>Products:</strong></p>
														<table className="table table-sm mb-4 mt-2" style={{ width: "450px" }}>
															<thead>
																<tr>
																	<th>Number</th>
																	<th>NET</th>
																	<th>VAT</th>
																	<th>Total</th>
																</tr>
															</thead>
															<tbody>
																{Object.keys(subType.products).map(number => (
																	<tr key={number}>
																		<td>{number}</td>
																		<td>&pound;{subType.products[number].total - subType.products[number].vat}</td>
																		<td>&pound;{subType.products[number].vat}</td>
																		<td>&pound;{subType.products[number].total}</td>
																	</tr>
																))}
															</tbody>
														</table>
													</div>
												)
											})}
										</div>
									)
								})}
							</div>  */}
							{/* <div label="Application Prices (2022)"> 
								<h3>Application Prices (2022)</h3>	<br />
								{Object.keys(applicationConfigPrices_2022).map(applicationType => {
									let applicationGroup = applicationConfigPrices_2022[applicationType]
									
									return (
										<div key={applicationType}>
											{Object.keys(applicationGroup).map(applicationSubType => {
												let subType = applicationGroup[applicationSubType]
												return (
													<div key={applicationSubType}>
														<h4>{subType.name}</h4>
														<p><strong>Price:</strong> &pound;{subType.price}<br />
														<strong>Products:</strong></p>
														<table className="table table-sm mb-4 mt-2" style={{ width: "450px" }}>
															<thead>
																<tr>
																	<th>Number</th>
																	<th>NET</th>
																	<th>VAT</th>
																	<th>Total</th>
																</tr>
															</thead>
															<tbody>
																{Object.keys(subType.products).map(number => (
																	<tr key={number}>
																		<td>{number}</td>
																		<td>&pound;{subType.products[number].total - subType.products[number].vat}</td>
																		<td>&pound;{subType.products[number].vat}</td>
																		<td>&pound;{subType.products[number].total}</td>
																	</tr>
																))}
															</tbody>
														</table>
													</div>
												)
											})}
										</div>
									)
								})}
							</div>  */}
							<div label="Application Prices (2023)"> 
								<h3>Application Prices (2023)</h3>	<br />
								{Object.keys(applicationConfigPrices_2023).map(applicationType => {
									let applicationGroup = applicationConfigPrices_2023[applicationType]
									
									return (
										<div key={applicationType}>
											{Object.keys(applicationGroup).map(applicationSubType => {
												let subType = applicationGroup[applicationSubType]
												return (
													<div key={applicationSubType}>
														<h4>{subType.name}</h4>
														<p><strong>Price:</strong> &pound;{subType.price}<br />
														<strong>Products:</strong></p>
														<table className="table table-sm mb-4 mt-2" style={{ width: "450px" }}>
															<thead>
																<tr>
																	<th>Number</th>
																	<th>NET</th>
																	<th>VAT</th>
																	<th>Total</th>
																</tr>
															</thead>
															<tbody>
																{Object.keys(subType.products).map(number => (
																	<tr key={number}>
																		<td>{number}</td>
																		<td>&pound;{subType.products[number].total - subType.products[number].vat}</td>
																		<td>&pound;{subType.products[number].vat}</td>
																		<td>&pound;{subType.products[number].total}</td>
																	</tr>
																))}
															</tbody>
														</table>
													</div>
												)
											})}
										</div>
									)
								})}
							</div> 
							<div label="Application Prices (2024)"> 
								<h3>Application Prices (2024)</h3>	<br />
								{Object.keys(applicationConfigPrices_2024).map(applicationType => {
									let applicationGroup = applicationConfigPrices_2024[applicationType]
									
									return (
										<div key={applicationType}>
											{Object.keys(applicationGroup).map(applicationSubType => {
												let subType = applicationGroup[applicationSubType]
												return (
													<div key={applicationSubType}>
														<h4>{subType.name}</h4>
														<p><strong>Price:</strong> &pound;{subType.price}<br />
														<strong>Products:</strong></p>
														<table className="table table-sm mb-4 mt-2" style={{ width: "450px" }}>
															<thead>
																<tr>
																	<th>Number</th>
																	<th>NET</th>
																	<th>VAT</th>
																	<th>Total</th>
																</tr>
															</thead>
															<tbody>
																{Object.keys(subType.products).map(number => (
																	<tr key={number}>
																		<td>{number}</td>
																		<td>&pound;{subType.products[number].total - subType.products[number].vat}</td>
																		<td>&pound;{subType.products[number].vat}</td>
																		<td>&pound;{subType.products[number].total}</td>
																	</tr>
																))}
															</tbody>
														</table>
													</div>
												)
											})}
										</div>
									)
								})}
							</div> 
							<div label="Application Fields"> 
								<h3>Application Fields</h3>	
								<pre className="json-string">
									{JSON.stringify(this.state.applicationConfigFields, null, 2)}	
								</pre>
							</div> 
							<div label="Application Type Configurations"> 
								<h3>Application Type Configurations</h3>	<br />
								
								<table className="table table-sm mb-4 mt-2" style={{ width: "750px" }}>
									<thead>
										<tr>
											<th>Name</th>
											<th>With support</th>
											<th>With exemption</th>
											<th>Upgrade</th>
											<th>STP</th>
											<th>Action</th>
										</tr>
									</thead>
									<tbody>
										{this.state.applicationTypeConfigurations && this.state.applicationTypeConfigurations.map(configuration => (
											<tr key={configuration.id}>
												<td>{configuration.name} {configuration.withExemption ? 'with exemption' : ''} {configuration.withSupport ? 'with support' : ''} {configuration.isUpgrade ? '(upgrade)' : '(new application)'}</td>
												<td>{configuration.withSupport ? 'yes' : 'no'}</td>
												<td>{configuration.withExemption ? 'yes' : 'no'}</td>
												<td>{configuration.isUpgrade ? 'yes' : 'no'}</td>
												<td>{configuration.stpEnabled ? <Check style={{ color: green[500] }} /> : <Close style={{ color: red[500] }} />}</td>
												<td><button type="button" className="btn btn-primary btn-sm btn-view float-right" onClick={() => this.toggleApplicationTypeStp(configuration.id)}>{configuration.stpEnabled ? 'Disable' : 'Enable'}</button></td>
											</tr>
										))}
									</tbody>
								</table>
							</div> 
						</Tabs> 

				
						

						{/* <pre>
							<JSONPretty id="JSONPretty" data={ this.state.applicationConfigPrices }></JSONPretty>
						</pre>
						
						
						<pre>
							<JSONPretty id="JSONPretty" data={ this.state.applicationConfigFields }></JSONPretty>
						</pre> */}
						
					</div>
				</div>
			</Layout>
		);
	}
}



export default withRouter((props, ref) => (
	<AuthContext.Consumer>{context => {
		if (context.currentUser.permissions.applications) {
			return (<ApplicationsConfiguration {...props} authContext={context} />)
		} else {
			return (<AccessDenied {...props}/>)
		}
	}}</AuthContext.Consumer>
));