import React, { Component } from 'react';
import Layout from '../../bricks/Layout'
import { useTable, usePagination } from 'react-table'
import { AuthContext } from '../../components/AuthContext';
import AccessDenied from '../../components/AccessDenied';
import ApplicationService from '../../services/ApplicationService';
import { Link, withRouter } from 'react-router-dom'

const moment = require('moment');

class CountryDiscounts extends Component {
	state = {
		allCountries: [],
		countriesTable: {
			pageIndex: 0,
			pageCount: 0,
			perPage: 30,
			isLoading: true,
			sort: {
				field: null,
				descending: false
			}
		},
	};

    // Breadcrumbs.
    breadcrumbs = [
			{ title: 'Home', link: '/' },
			{ title: 'Applications', link: '/applications'},
			{ title: 'Country Discounts', link: '/applications/country-discounts', active: true }
	];


	// Set the table columns.
	columns = [
		{ Header: 'Country', accessor: 'name', sortable: true },
		{ Header: 'LMI Discount', accessor: 'countryDiscountInScope', sortable: true },
		{ Header: 'Non-UK VAT Discount', accessor: 'overseasVatDiscountInScope', sortable: true },
	];

	componentDidMount() {
		let { countriesTable } = this.state

		// Load all Members.
		ApplicationService.getAllCountries(countriesTable).then((response) => {
			this.setState({
				allCountries: response.countries,
				countriesTable: {
					...countriesTable,
					isLoading: false,
					pageCount: response.totalPages,
					pageIndex: response.pageIndex,
					perPage: response.perPage
				}
			})
		});
	}

	// On the sort change get new data.
	handleSortData = (sort) => {

		let { countriesTable } = this.state

		// Set loading to true and update the page number.
		countriesTable.isLoading = true;
		countriesTable.sort = sort;
		this.setState({ countriesTable: countriesTable })

		// Load all Members.
		ApplicationService.getAllCountries(countriesTable).then((response) => {
			this.setState({
				allCountries: response.countries,
				countriesTable: {
					...countriesTable,
					isLoading: false,
					pageCount: response.totalPages,
					pageIndex: response.pageIndex,
					perPage: response.perPage
				}
			})
		});
	}


	// On page change for the table.
	handlePageChange = (pageIndex) => {
		let { countriesTable } = this.state

		// Set loading to true and update the page number.
		countriesTable.isLoading = true;
		countriesTable.pageIndex = pageIndex;
		this.setState({ countriesTable: countriesTable })

		// Load all Members.
		ApplicationService.getAllCountries(countriesTable).then((response) => {
			this.setState({
				allCountries: response.countries,
				countriesTable: {
					...countriesTable,
					isLoading: false,
					pageCount: response.totalPages,
					pageIndex: response.pageIndex,
					perPage: response.perPage
				}
			})
		});
	}


	render() {
		return (
			<Layout breadcrumbs={this.breadcrumbs} match={this.props.match}>
				<div className="page-title mb-4 clearfix">
					<div className="float-left">
						<h1 className="page-title">Country Discounts</h1>
					</div>

					<div className="float-right">
						<Link to={"/applications/country-discounts-lmi/edit"} className={"btn btn-primary"}>Edit Product Overrides (LMI)</Link>
						<br/>
						<br/>
						<Link to={"/applications/country-discounts-overseas-non-vat/edit"} className={"btn btn-primary"}>Edit Product Overrides (Overseas Non-VAT)</Link>
					</div>
				</div>

				<div className="table-responsive">
					<CountriesTable
						tableData={this.state.allCountries}
						tableColumns={this.columns}
						searchQuery={this.state.countriesTable.searchQuery}
						gotoPage={this.handlePageChange}
						pageIndex={this.state.countriesTable.pageIndex}
						pageCount={this.state.countriesTable.pageCount}
						isLoading={this.state.countriesTable.isLoading}
						sort={this.state.countriesTable.sort}
						sortData={this.handleSortData}
					/>
				</div>
			</Layout>
		);
	}
}


/**
 * Table to display the countries with pagination and filtering.
 * @param {}
 */
function CountriesTable({ tableColumns, tableData, gotoPage, pageIndex, pageCount, isLoading, sort, sortData }) {

	// Define the table columns.
	const columns = React.useMemo(() => tableColumns, [tableColumns])

	// Define the data.
	const data = React.useMemo(() => tableData, [tableData])

	// Use the state and functions returned from useTable to build your UI
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		page
	} = useTable({
		columns,
		data,
		manualPagination: true,
		initialState: { pageIndex: pageIndex, pageSize: 20 },
	}, usePagination)


	/**
	 * Calculate and show the correct pages.
	 * @param {*} pIndex
	 * @param {*} pCount
	 */
	const numberedPages = (pIndex, pCount) => {
		let gCount = 3;
		let showPagesArr = [];

		// Loop through all pages and calculate which ones to show.
		for (let i = 0; i < pCount; i++) {
			if ((pIndex - gCount) < i && (gCount + pIndex) > i) {
				showPagesArr.push(i);
			}
		}
		return showPagesArr;
	}

	/**
	 * On click of the sort header.
	 * @param {*} e
	 */
	const sortHeader = (e) => {
		let sortField = e.target.getAttribute("sortfield");

		if (sort.field === sortField && !sort.descending) {
			sort.descending = true;
		} else if (sort.field === sortField && sort.descending) {
			sort.field = null;
			sort.descending = false;
		} else {
			sort.field = sortField;
			sort.descending = false;
		}

		sortData(sort);
	}

	return (
		<div>
			<div className="table-responsive table-data position-relative">
				<table className={"table table-striped table-hover"} {...getTableProps()}>
					<thead>
						{headerGroups.map(headerGroup => (
							<tr {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map(column => (
									// Add the sorting props to control sorting. For this example
									// we can add them into the header props
									<th {...column.getHeaderProps()} onClick={sortHeader} sortfield={column.id}>
										{column.Header}
										{(column.sortable) ?
											(<span className={"sort " + (sort.field === column.id ? (sort.descending ? 'sort-desc' : 'sort-asc') : 'sort-none')}></span>)
										: ''}
									</th>
								))}
							</tr>
						))}
					</thead>
					<tbody {...getTableBodyProps()}>
						{page.map((row, i) => {
							prepareRow(row);
							return (
								<tr {...row.getRowProps()} to={"/applications/country-discounts/edit/" + row.original.id}>
									{row.cells.map(cell => {
										if (cell.column.id === 'countryDiscountInScope') {
											return (<td {...cell.getCellProps()}>
												<span className={"badge" + (cell.row.original.countryDiscountInScope === 1 ? " badge-success" : " badge-danger")}>{cell.row.original.countryDiscountInScope === 1 ? "Enabled" : "Disabled"}</span>
											</td>)
										}
										else if (cell.column.id === 'overseasVatDiscountInScope') {
											return (<td {...cell.getCellProps()}>
												<span className={"badge" + (cell.row.original.overseasVatDiscountInScope === 1 ? " badge-success" : " badge-danger")}>{cell.row.original.overseasVatDiscountInScope === 1 ? "Enabled" : "Disabled"}</span>
											</td>)
										} else {
											return (<td {...cell.getCellProps()}>{cell.render('Cell')}</td>)
										}
									})}
								</tr>
							)
						})}
					</tbody>
				</table>
				<div className={"loading-table-data" + ((isLoading) ? " active" : "")}><div className="loading-inner">Loading...</div></div>
			</div>

			<nav className="d-flex mt-4" aria-label="Page navigation">
				<ul className="pagination mx-auto">
					<li className="page-item">
						<button className="page-link" onClick={() => gotoPage(0)} disabled={(pageIndex === 0)}>&lt;&lt; First</button>
					</li>
					<li className="page-item">
						<button className="page-link" onClick={() => gotoPage(pageIndex-1)} disabled={(pageIndex === 0)}>Previous</button>
					</li>
					{numberedPages(pageIndex, pageCount).map((pageNum) => {
						return (
							<li className={"page-item " + (pageIndex === pageNum ? 'active' : '')} key={pageNum+1}>
								<button className="page-link" onClick={() => gotoPage(pageNum)}>{pageNum+1}</button>
							</li>
						)
					})}
					<li className="page-item">
						<button className="page-link" onClick={() => gotoPage(pageIndex+1)} disabled={(pageIndex+1 === pageCount)}>Next &gt;</button>
					</li>
					<li className="page-item">
						<button className="page-link" onClick={() => gotoPage(pageCount-1)} disabled={(pageIndex+1 === pageCount)}>Last &gt;&gt;</button>
					</li>
				</ul>
			</nav>
		</div>
	)
}

export default withRouter((props, ref) => (
	<AuthContext.Consumer>{context => {
		if (context.currentUser.permissions.applications) {
			return (<CountryDiscounts {...props} authContext={context} />)
		} else {
			return (<AccessDenied {...props}/>)
		}
	}}</AuthContext.Consumer>
));
