/**
 * User Service
 * =================
 * 
 * @author    José Veríssimo
 * @since     1.0.2
 */

// Module dependencies.
import Cookies from 'universal-cookie';
import { encodeFormData } from '../helpers/RequestHelper'
const axios = require('axios').default;

const InvoiceService = {};

// Required configuration.
const API_URL_PREFIX = (typeof process.env.REACT_APP_API_URL_PREFIX_OVERRIDE != "undefined") ? 
    process.env.REACT_APP_API_URL_PREFIX_OVERRIDE : process.env.REACT_APP_API_URL_PREFIX;

/**
 * Get the basic info for a member.
 * 
 * @return {}
 */
InvoiceService.getByMemberId = async function(memberId) {
    // Get the api session cookie.
    const cookies = new Cookies();
    let apiSessionId = cookies.get('apiSessionId')


    // Cookie doesn't exist.
    if (typeof apiSessionId == "undefined") {
        return null;
    }
    
    let response = await axios.get(API_URL_PREFIX + "invoice/by-member-id", {
        params: { memberId: memberId },
        headers: {
            'Authorization': "Bearer " + apiSessionId
        }
    })

    if (typeof response.data.invoices != "undefined") {
        return response.data.invoices;
    } else {
        return null;
    }
}


/**
 * Get the basic info for a member.
 * 
 * @return {}
 */
InvoiceService.getById = async function(invoiceId) {
    // Get the api session cookie.
    const cookies = new Cookies();
    let apiSessionId = cookies.get('apiSessionId')


    // Cookie doesn't exist.
    if (typeof apiSessionId == "undefined") {
        return null;
    }
    
    let response = await axios.get(API_URL_PREFIX + "invoice/by-id", {
        params: { invoiceId: invoiceId },
        headers: {
            'Authorization': "Bearer " + apiSessionId
        }
    })

    if (typeof response.data != "undefined") {
        return response.data;
    } else {
        return null;
    }
}

/**
 * Get all invoices.
 * 
 * @return {}
 */
const CancelToken = axios.CancelToken;
let cancelSearch = null;
InvoiceService.getAll = async function(params) {
    // Get the api session cookie.
    const cookies = new Cookies();
    let apiSessionId = cookies.get('apiSessionId')


    // If we've searched before, cancel that request.
    if (cancelSearch != null) {
        cancelSearch('Operation canceled by the user.');
    }

    // Cookie doesn't exist.
    if (typeof apiSessionId == "undefined") {
        return null;
    }
    
    let response = await axios.get(API_URL_PREFIX + "invoice/all-invoices", {
        params: params,
        cancelToken: new CancelToken(function executor(c) {
            cancelSearch = c; // Resave var.
        }),
        headers: {
            'Authorization': "Bearer " + apiSessionId
        }
    })

    if (typeof response.data.invoices != "undefined") {
        return response.data;
    } else {
        return null;
    }
}


/**
 * Download invoice as a PDF file.
 * 
 * @param {Request} req 
 * @param {Response} res 
 */
InvoiceService.downloadInvoicePdf = async function(memberId, invoiceId) {
    // Get the api session cookie.
    const cookies = new Cookies();
    let apiSessionId = cookies.get('apiSessionId')

    // Cookie doesn't exist.
    if (typeof apiSessionId == "undefined") {

        return null;
    }

    let queryParams = encodeFormData({ memberId: memberId, invoiceId: invoiceId });

    // Check if the cookie is valid.
    let response = await fetch(API_URL_PREFIX + "invoice/download-pdf-invoice?" + queryParams, {
        headers: {
            'Authorization': "Bearer " + apiSessionId
        },
        method: 'GET'
    });

    // Get the result.
    let blob = await response.blob();

    return window.URL.createObjectURL(new Blob([blob]));
}


export default InvoiceService