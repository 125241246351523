import React, { Component } from 'react';
import InvoiceService from '../../../services/InvoiceService'
import MemberService from '../../../services/MemberService'
import PaymentService from '../../../services/PaymentService'
import { store } from 'react-notifications-component';
import { AuthContext } from '../../../components/AuthContext';

// Stripe elements
import {loadStripe} from '@stripe/stripe-js';
import {CardElement, Elements, ElementsConsumer } from '@stripe/react-stripe-js';


const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_3DS_PUBLIC_KEY);
let closeModalButton = null;
let closePaymentModalButton = null;
let closeDirectDebitModalButton = null;

let tryCounter = 30;
class InvoicesTab extends Component {
	state = {
		currentUserId: '',
		selectedInvoiceId: "",
		downloading: [],
		loading: true,
		totalAmount: 0,
		invoiceDetail: {},
		emailInvoice: {},
		billingAddress: "correspondence",

		//Payment Modal Items
		formDisabled: false,
		submitButtonText: "",
		cardElement: null,
		paymentStatus: "",
		stripePaymentError: ""
	};

	componentDidMount() {
		let memberId = this.props.memberId;
		let hubUserId = this.props.currentUserId;
		this.setState({ currentUserId: hubUserId })

		// Load all Members.
		InvoiceService.getByMemberId(memberId).then((invoices) => {
			this.setState({ invoices: invoices, loading: false })
		});
	}

	/**
	 * Handle the click of the pay button in the modal.
	 */
	handlePaymentInteraction = async (cardElement) => {

		// console.log('handlePaymentInteraction');
		// console.log('cardElement',cardElement);

		// create payment method
		let stripe = await stripePromise;

		let paymentResult = await stripe.createPaymentMethod({
    		type: 'card',
    		card: cardElement
  		});

		this.setState({stripePaymentError: ""});
		let addressType = this.state.billingAddress;

		//disable submit button
		this.setState({submitButtonText : "Processing invoice(s)", formDisabled: true});

		/**
		 * This is where things deviate from the regular flow. We create an intent and attempt to take payment instantly using MOTO.
		 * THis means we get a result back straight away and technically do not need to poll for a result.
		 */

		let intentResult = await PaymentService.handleCreateInvoicePaymentIntent(this.props.memberId, this.state.selectedInvoiceNumber,
			addressType, paymentResult.paymentMethod.id);

		//console.log("Intent Result", intentResult);
		
		
		if(intentResult.errors) {
			//console.log('we have intent errors');
			this.setState({stripePaymentError: intentResult.errors.raw.message });
			store.addNotification({
				title: "Error!",
				message: intentResult.errors.raw.message,
				type: "danger",
				container: "top-right", animationIn: ["animated", "slideInRight"], animationOut: ["animated", "slideOutRight"],
				dismiss: { duration: 3000 }
			});

			this.setState({formDisabled: false});
			return;
		}
		else if(intentResult.status == false)
		{
			store.addNotification({
				title: "Error!",
				message: 'unable to retrieve invoice data',
				type: "danger",
				container: "top-right", animationIn: ["animated", "slideInRight"], animationOut: ["animated", "slideOutRight"],
				dismiss: { duration: 3000 }
			});

			this.setState({formDisabled: false});
			return;
		}

		// payment was successful
		this.setState({stripePaymentError: ""});
		this.setState({submitButtonText : "Paid.", formDisabled: true});
		store.addNotification({
			title: "Paid!",
			message: "Payment was taken successfully. Changes will be reflected on this page in around a minute",
			type: "success",
			container: "top-right", animationIn: ["animated", "slideInRight"], animationOut: ["animated", "slideOutRight"],
			dismiss: { duration: 6000 }
		});

		closePaymentModalButton.click();

		// refresh the invoice list, with a small delay as we are waiting for the webhook
		setTimeout((async () => {
			let invoices = await InvoiceService.getByMemberId(this.props.memberId);
			this.setState({invoices: invoices});
		}).bind(this), 6000);

	}


	/**
	 * Handle the click of the pay button in the modal.
	 */
	handleDirectDebitPayment = mandateId => {

		console.log('handleDirectDebitPayment');

		let memberDetails = this.props.memberDetails;
		let chosenAddress = this.state.billingAddress;

		let billingAddress = {
			fullname: memberDetails.fullname,
			emailAddress: memberDetails.emailAddress,
			line1: memberDetails[chosenAddress].line1,
			line2: memberDetails[chosenAddress].line2,
			city: memberDetails[chosenAddress].city,
			county: memberDetails[chosenAddress].county,
			country: memberDetails[chosenAddress].country,
			postcode: memberDetails[chosenAddress].postcode
		}


		if (typeof mandateId != "undefined") {

			let invoiceArray = Array(this.state.selectedInvoiceId);
			console.log('invoiceArray', invoiceArray);

			// Call the API with our token.
			PaymentService.handleInvoicePaymentDirectDebit(
				this.state.currentUserId,
				mandateId,
				this.props.memberId,
				invoiceArray,
				billingAddress
			).then(response => {

				console.log('response', response);

				// Close the modal.
				closeDirectDebitModalButton.click();

				if (response.success) {
					store.addNotification({
						title: "Payment was successful",
						message: "Payment has been recorded and an email confirmation has been sent.",
						type: "success",
						container: "top-right", animationIn: ["animated", "slideInRight"], animationOut: ["animated", "slideOutRight"],
						dismiss: { duration: 3000 }
					});

					// Loading invoices
					let notificationId = store.addNotification({
						title: "Loading...",
						message: "Refreshing the most up-to-date invoices.",
						type: "default",
						container: "top-right", animationIn: ["animated", "slideInRight"], animationOut: ["animated", "slideOutRight"],
						dismiss: { duration: 0 }
					});

					// Load all Members.
					InvoiceService.getByMemberId(this.props.memberId).then((invoices) => {
						store.removeNotification(notificationId)

						this.setState({invoices: invoices})
					});
				} else {

					store.addNotification({
						title: "Error!",
						message: "An error occured during payment please refresh your browser and try again.",
						type: "danger",
						container: "top-right", animationIn: ["animated", "slideInRight"], animationOut: ["animated", "slideOutRight"],
						dismiss: { duration: 3000 }
					});
				}


			});
		}
	}

	/**
	 * On invoice checkbox checked and unchecked.
	 */
	invoiceRadioHandler = e => {
		let invoiceNumber = e.target.value;

		let invoice = this.state.invoices.Due[invoiceNumber];
		this.setState({
			selectedInvoiceId: invoice.id,
			selectedInvoiceNumber: invoice.invoiceNumber,
			totalAmount: invoice.totalAmount,
			paymentButtonText: "Pay £" + invoice.totalAmount.toFixed(2)
		});
	}




	/**
	 * On address change
	 */
	handleAddressChange = e => {
		this.setState({ billingAddress: e.target.value })
	}


	render() {
		let { invoices, totalAmount, loading } = this.state;
		let { memberDetails } = this.props;

		if (loading) {
			return (
				<div>
					<div className="alert alert-info" role="alert">
						<h3>Loading</h3>
						<span>Loading invoices, please wait....</span>
					</div>
				</div>
			)
		}
			// Do we have any due invoices?
		let dueInvoices = invoices?.Due ?? [];
		let fullyPaid =  invoices['Fully Paid'] ?? [];

			// Make addresses availables.
			let addresses = {
				correspondence: (typeof memberDetails.correspondence != "undefined" && memberDetails.correspondence != null) ?
				(
					<div>
						<input type="radio" name="address" id="correspondence" value="correspondence" defaultChecked
							onChange={this.handleAddressChange} />
						<div>
							<label className="card address-card" htmlFor="correspondence">{memberDetails.correspondence.name}, {memberDetails.correspondence.line}</label>
						</div>
					</div>
				) : (<div/>),
				invoice: (typeof memberDetails.invoice != "undefined" && memberDetails.invoice != null && memberDetails.sameAsCorrespondence === false) ?
				(
					<div>
						<input type="radio" name="address" id="invoice" value="invoice"
							onChange={this.handleAddressChange} />
						<div>
							<label className="card address-card" htmlFor="invoice">{memberDetails.invoice.name}, {memberDetails.invoice.line}</label>
						</div>
					</div>
				) : (<div/>)
			}

			return (
				<div>
					<h3>Due invoices</h3>

					<div className={Object.keys(dueInvoices).length <= 0 ? "d-none" : ""}>
						<p>Please select the invoice(s) to pay.</p>

						{/* List of invoices. */}
						<table className="table table-invoices">
							<thead>
								<tr>
									<th></th>
									<th>Invoice Number</th>
									<th>Due Date</th>
									<th>Payment Method</th>
									<th>Total</th>
									{/* <th></th> needed for spacing  */}
									<th></th>
								</tr>
							</thead>
							<tbody>
								{Object.keys(dueInvoices).map((key, index) => {
									let invoice = dueInvoices[key];
									return (
										<React.Fragment key={key}>
											<tr>
												<td>
													{ invoice?.stripePaymentStatus == "Complete" ? <small title="Waiting for the queue to finish processing payment" style={{color: 'green'}}>Finalising Payment</small> :
													(invoice.paymentMethod === "Direct Debit" ? "" : <input type="radio"
														onChange={this.invoiceRadioHandler}
														value={invoice.invoiceNumber}
														data-total={invoice.totalAmount}
														checked={(this.state.selectedInvoiceId == invoice.id)}
														data-invoice-number={invoice.invoiceNumber} />) }

												</td>
												<td>{invoice.invoiceNumber}</td>
												<td>{invoice.dueDate}</td>
												<td>{invoice.paymentMethod}</td>
												<td>&pound;{invoice.totalAmount.toFixed(2)}</td>
												<td>
													<div className="float-right">
														<button
															className="btn btn-outline-secondary view-invoice text-center"
															onClick={() => { this.setState({ emailInvoice: invoice }) }}
															data-toggle="modal"
															data-target="#email-invoice-modal"
															>Email invoice</button>
														<button
															className="btn btn-primary text-center"
															onClick={() => { this.setState({ invoiceDetail: invoice }) }}
															data-toggle="modal"
															data-target="#invoice-detail-modal"
															>View</button>
													</div>
												</td>
											</tr>
										</React.Fragment>
									)
								})}
							</tbody>
						</table>


						{/* Addresses. */}
						<h4 className="mt-5">Select billing address</h4>
						<p>You can change/add a billing address on the
							<button tab="details" className="details-link btn" onClick={(e) => {
								this.props.onClickTabButton(e);
								setTimeout(() => {
									let billingAddress = document.getElementById("address-form-invoice");
									billingAddress.scrollIntoView();
								}, 100);
							}}>Details Tab</button>
						.</p>
						<div className="select-address">
							{addresses.correspondence}

							{addresses.invoice}
						</div>

						<p className="text-right mt-5">To pay: <strong>&pound;{totalAmount.toFixed(2)}</strong></p>

						<button
							className="btn btn-primary text-center float-right"
							data-toggle="modal"
							data-target="#payment-modal"
							disabled={(totalAmount === 0) ? true : false}
							target="new">Take card payment</button>

						{memberDetails.goCardlessId !== null &&
							<button
								className="btn btn-secondary text-center float-right mr-3"
								data-toggle="modal"
								data-target="#direct-debit-payment-modal"
								disabled={(totalAmount === 0) ? true : false}
								target="new">Collect via Direct Debit</button>
						}

						<br /><br /><br />
					</div>
					<div className={Object.keys(dueInvoices).length > 0 ? "d-none" : ""}>
						<p>No due invoices as of yet.</p>
					</div>

					{/* Show payment modal. */}
					<Elements stripe={stripePromise}>
						<ElementsConsumer>
							{({stripe, elements}) => (
								<PaymentModal
									formDisabled={this.state.formDisabled}
									paymentButtonText={this.state.paymentButtonText}
									paymentStatus={this.state.paymentStatus}
									stripePaymentError={this.state.stripePaymentError}
									handleResult={this.handlePaymentInteraction}
									totalAmount={totalAmount}
									closeButton={this.closeModalButton}
									stripe={stripe}
									elements={elements} />
							)}
						</ElementsConsumer>
					</Elements>

					{/* Show Direct Debit modal. */}
					<DirectDebitModal handleResult={this.handleDirectDebitPayment} totalAmount={totalAmount} closeButton={this.closeModalButton} directDebitDetails={this.props.directDebitDetails} />

					{/* Show Invoice detail modal. */}
					<InvoiceDetailModal closeButton={this.closeModalButton} memberDetails={memberDetails} invoiceDetail={this.state.invoiceDetail} />

					{/* Show Email invoice modal. */}
					<EmailInvoiceModal closeButton={this.closeModalButton} emailInvoice={this.state.emailInvoice} defaultEmail={memberDetails.emailAddress} />

					{/*
						* Display paid invoices in the page.
					*/}
					<div className={Object.keys(fullyPaid).length <= 0 ? "d-none" : ""}>
						<hr /><br />

						<h3>Paid invoices</h3>

						{/* List of invoices. */}
						<table className="table table-invoices mt-4">
							<thead>
								<tr>
									<th>Invoice Number</th>
									<th>Transaction Date</th>
									<th>Total</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								{Object.keys(fullyPaid).map((key, index) => {
									let invoice = fullyPaid[key];

									return (
										<React.Fragment key={key}>
											<tr>
												<td>{invoice.invoiceNumber}</td>
												<td>{invoice.transactionDate}</td>
												<td>&pound;{invoice.totalAmount.toFixed(2)}</td>
												<td>
													<div className="float-right">
														<button
															className="btn btn-outline-secondary view-invoice text-center"
															onClick={() => { this.setState({ emailInvoice: invoice }) }}
															data-toggle="modal"
															data-target="#email-invoice-modal"
															>Email invoice</button>
														<button
															className="btn btn-primary text-center"
															onClick={() => { this.setState({ invoiceDetail: invoice }) }}
															data-toggle="modal"
															data-target="#invoice-detail-modal"
															>View</button>
													</div>
												</td>
											</tr>

										</React.Fragment>
									)
								})}
							</tbody>
						</table>

					</div>
				</div>)

	}
}


/**
 * Payment modal.
 *
 */
class PaymentModal extends Component {

	handleChange = (e) => {

	};

	handleSubmit = (e) => {
		e.preventDefault();
		const cardElement = this.props.elements.getElement(CardElement);
		this.props.handleResult(cardElement);
	};


  	render() {
		return (
	  		<form onSubmit={this.handleSubmit.bind(this)}>
				<div className="modal" role="dialog" id="payment-modal">
					<div className="modal-dialog" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">Secure Payment</h5>
								<img src="/assets/icons/card-logos.svg" alt="card logos - amex, visa, mastercard" />
							</div>
							<div className="modal-body">
								{this.props.paymentStatus != "" &&
								(<div className="row">
									<div className="col-12 alert alert-warning">
										{this.props.paymentStatus}
									</div>
								</div>)
								}
								<div className="row">
									<div className="col-12">
										<CardElement ref={this.myRef} options={{
											hidePostalCode: true
										}} onChange={this.handleChange} />
									</div>
								</div>

								{/* Errors */}
								{this.props.stripePaymentError != "" &&
								(<div className="text-danger" role="alert">
									{this.props.stripePaymentError}
								</div>) }
							</div>
							<div className="modal-footer">
								<button type="button" ref={button => closePaymentModalButton = button} id="payment-cancel-modal-button" className="btn btn-default float-left" data-dismiss="modal">Cancel</button>
								<button type="submit" className="btn btn-primary float-right px-5" disabled={this.props.formDisabled} id="stripe-payment-submit-button">{this.props.paymentButtonText}</button>
							</div>
						</div>
					</div>
				</div>
			</form>
		);
  }
}


/**
 * Direct Debit modal.
 *
 */
class DirectDebitModal extends Component {
	state = {
		errorMessage: '',
		mandateId: "none"
  	};


	handleMandateChange(e) {
		this.setState({mandateId: e.target.value});
	}

	handleSubmit = (e) => {
		e.preventDefault();
		let { mandateId } = this.state

		if (mandateId === "none") {
			// Show error
			store.addNotification({
				title: "Error!",
				message: "Please select a mandate before submitting the form.",
				type: "danger",
				container: "top-right", animationIn: ["animated", "slideInRight"], animationOut: ["animated", "slideOutRight"],
				dismiss: { duration: 3000 }
			});

		} else {
			this.props.handleResult(mandateId);
		}
	};

  	render() {
		let { directDebitDetails } = this.props;
		let mandates = (directDebitDetails !== null) ? directDebitDetails.mandates : [];
		let bankAccounts = (directDebitDetails !== null) ? directDebitDetails.bankAccounts : [];

		return (
	  		<form onSubmit={this.handleSubmit.bind(this)}>
				<div className="modal" role="dialog" id="direct-debit-payment-modal">
					<div className="modal-dialog" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">Direct Debits</h5>
							</div>
							<div className="modal-body">
								<div className="form-group">
									{/* List Mandates */}
									<label htmlFor="mandateSelect">Select an active mandate</label>
									<select className="form-control" id="mandateSelect" defaultValue={this.state.mandateId} name="mandateId" onChange={this.handleMandateChange.bind(this)}>
										<option disabled={true} value="none">Please select a mandate</option>
										{mandates.map((mandate, key) => {
											if (mandate.status === "active") {
												let bankAccount = "";
												if (typeof mandate.links.customer_bank_account != "undefined") {
													bankAccounts.map((account) => {
														if (account.id === mandate.links.customer_bank_account) {
															bankAccount = "(" + account.account_holder_name + ")"
														}
														return true;
													})
												}
												return (<option key={key} value={mandate.id}>{mandate.id} {bankAccount}</option>)
											} else {
												return true;
											}
	  									})}
									</select>
								</div>
							</div>
							<div className="modal-footer">
								<button type="button" ref={button => closeDirectDebitModalButton = button} className="btn btn-default float-left" data-dismiss="modal">Cancel</button>
								<button type="submit" className="btn btn-primary float-right px-5">Collect &pound;{this.props.totalAmount.toFixed(2)}</button>
							</div>
						</div>
					</div>
				</div>
			</form>
		);
  	}
}

/**
 * Invoice detail modal.
 *
 */
class InvoiceDetailModal extends Component {


	render() {
	let { invoiceDetail, memberDetails } = this.props;
	// Add the VAT percentage
	invoiceDetail.vatPercentage = (invoiceDetail.totalTax * 100) / invoiceDetail.lineItemAmount;

	if (invoiceDetail != null) {
		let labelClass = "secondary";
		if (invoiceDetail.statusCode === "Paid" || invoiceDetail.statusCode === "Fully Paid") { labelClass = "success"; }
		else if (invoiceDetail.statusCode === "Due") { labelClass = "warning"; }
		else if (invoiceDetail.statusCode === "Cancelled") { labelClass = "danger"; }
		return (
				<div className="modal" role="dialog" id="invoice-detail-modal">
					<div className="modal-dialog modal-lg modal-dialog-centered" role="document">
						<div className="modal-content">
							<div className="modal-header"><h5>#{invoiceDetail.invoiceNumber} Invoice Details</h5>
								<button type="button" className="close" data-dismiss="modal" aria-label="Close">
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div className="modal-body">
								<table className="table table-borderless table-sm mb-5">
									<tbody>{memberDetails.fullname ?
										<tr>
											<td width="25%" className="pl-0"><strong>Name</strong></td>
											<td>{memberDetails.fullname}</td>
										</tr> : <tr></tr>}
										{memberDetails.membershipType ?
										<tr>
											<td width="25%" className="pl-0"><strong>Membership Type</strong></td>
											<td>{memberDetails.membershipType}</td>
										</tr> : <tr></tr>}
										{memberDetails.membershipNumber ?
										<tr>
											<td width="25%" className="pl-0"><strong>Membership Number</strong></td>
											<td>{memberDetails.membershipNumber}</td>
										</tr> : <tr></tr>}
										{memberDetails.paymentMethod ?
										<tr>
											<td width="25%" className="pl-0"><strong>Payment Method</strong></td>
											<td>{memberDetails.paymentMethod}</td>
										</tr> : <tr></tr>}
										{invoiceDetail.totalAmount ?
										<tr>
											<td width="25%" className="pl-0"><strong>Total Amount</strong></td>
											<td>&pound;{invoiceDetail.totalAmount.toFixed(2)}</td>
										</tr> : <tr></tr>}
										{invoiceDetail.transactionDate ?
										<tr>
											<td width="25%" className="pl-0"><strong>Transaction Date</strong></td>
											<td>{invoiceDetail.transactionDate}</td>
										</tr> : <tr></tr>}
										{invoiceDetail.dueDate ?
										<tr>
											<td width="25%" className="pl-0"><strong>Due Date</strong></td>
											<td>{invoiceDetail.dueDate}</td>
										</tr> : <tr></tr>}
										{typeof invoiceDetail.poNumber !== "undefined" && invoiceDetail.poNumber !== "" ? (
											<tr>
												<td width="25%" className="pl-0"><strong>PO Number</strong></td>
												<td>{invoiceDetail.poNumber}</td>
											</tr>
										) : <tr></tr>}
										{invoiceDetail.statusCode ?
										<tr>
											<td width="25%" className="pl-0"><strong>Status</strong></td>
											<td><span className={"badge badge-"+labelClass}>{invoiceDetail.statusCode}</span></td>
										</tr> : <tr></tr>}
									</tbody>
							</table>
							{invoiceDetail.products ?
							<table className="table table-borderless table-condensed">
								<thead>
									<tr>
										<th>Description</th>
										<th>Quantity</th>
										<th>Unit Price</th>
										<th>VAT</th>
										<th>Amount</th>
									</tr>
								</thead>
								<tbody>
									{invoiceDetail.products.map((product, index) => (
										<tr className="table-row-border" key={"product"+index}>
											<td>{product.description}</td>
											<td>{product.qty}</td>
											<td>&pound;{product.itemCost.toFixed(2)}</td>
											<td>&pound;{product.taxAmount.toFixed(2)}</td>
											<td>&pound;{(product.total - product.taxAmount).toFixed(2)}</td>
										</tr>
									))}
									<tr>
										<td>&nbsp;</td>
										<td>&nbsp;</td>
										<td>&nbsp;</td>
										<td>Subtotal (Excl VAT): </td>
										<td>&pound;{invoiceDetail.lineItemAmount.toFixed(2)}</td>
									</tr>

									<tr className="no-border">
										<td>&nbsp;</td>
										<td>&nbsp;</td>
										<td>&nbsp;</td>
										<td>Total VAT: </td>
										<td>&pound;{invoiceDetail.totalTax.toFixed(2)}</td>
									</tr>

									<tr className="table-row-border-top">
										<td>&nbsp;</td>
										<td>&nbsp;</td>
										<td>&nbsp;</td>
										<td><strong>Total: </strong></td>
										<td><strong>&pound;{invoiceDetail.totalAmount.toFixed(2)}</strong></td>
									</tr>
								</tbody>
							</table>

							: <div></div>}

						</div>
						</div>
					</div>
				</div>
		  );
	} else {
		return(<div></div>)
		}
	}
}


/**
 * Email Invoice modal.
 *
 */
class EmailInvoiceModal extends Component {
	state = { errorMessage: '' };

	handleSubmit = (e) => {
		e.preventDefault();

		// Close the modal.
		closeModalButton.click();

		let { emailInvoice } = this.props;
		let emailVal = e.target.querySelector('[name="email-invoice"]').value

		if (typeof emailInvoice.invoiceNumber === "undefined") {
			// Show error
			store.addNotification({
				title: "Error!",
				message: "Invalid invoice, please try again.",
				type: "danger",
				container: "top-right", animationIn: ["animated", "slideInRight"], animationOut: ["animated", "slideOutRight"],
				dismiss: { duration: 3000 }
			});

		} else {

			// Show some feedback to the user and store the notification ID.
			const notificationId = store.addNotification({
				title: "Sending email",
				message: "Sending invoice #" + emailInvoice.invoiceNumber + " to " + emailVal + ".",
				type: "default",
				container: "top-right", animationIn: ["animated", "slideInRight"], animationOut: ["animated", "slideOutRight"],
				dismiss: { duration: 0, showIcon: true }
			});


			// Send email.
			MemberService.emailInvoice(emailInvoice.contactId, emailInvoice.id, emailVal).then(url => {

				// Remove previous notification and show new one.
				store.removeNotification(notificationId)
				store.addNotification({
					title: "Email has been sent",
					message: "Sending invoice #" + emailInvoice.invoiceNumber + " to " + emailVal + ".",
					type: "success",
					container: "top-right", animationIn: ["animated", "slideInRight"], animationOut: ["animated", "slideOutRight"],
					dismiss: { duration: 3000, showIcon: true }
				});
			})
		}
	};


  	render() {
		let { emailInvoice, defaultEmail } = this.props;
		return (
	  		<form onSubmit={this.handleSubmit.bind(this)}>
				<div className="modal" role="dialog" id="email-invoice-modal">
					<div className="modal-dialog" role="document">
						<div className="modal-content">
							<div className="modal-header">
								{typeof emailInvoice.invoiceNumber != "undefined" ?
								(<h5 className="modal-title">Email #{emailInvoice.invoiceNumber} invoice</h5>) :
								(<h5 className="modal-title">Email invoice</h5>)}

							</div>
							<div className="modal-body">
								<div className="form-group">
									{/* List Mandates */}
									<label htmlFor="mandateSelect">Email</label>
									<input type="email" name="email-invoice" defaultValue={defaultEmail} className="form-control" />
								</div>
							</div>
							<div className="modal-footer">
								<button type="button" ref={button => closeModalButton = button} className="btn btn-default float-left" data-dismiss="modal">Cancel</button>
								<button type="submit" className="btn btn-primary float-right px-5">Email invoice</button>
							</div>
						</div>
					</div>
				</div>
			</form>
		);
  	}
}




export default InvoicesTab;
