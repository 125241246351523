import React, { Component } from 'react';
import Layout from '../../bricks/Layout'
import ApplicationService from '../../services/ApplicationService';
import { Link, withRouter } from 'react-router-dom'
import { useTable, usePagination } from 'react-table'
import { Modal } from 'react-bootstrap';
import { store } from 'react-notifications-component';
import { AuthContext } from '../../components/AuthContext';
import AccessDenied from '../../components/AccessDenied';
import { buildURI } from '../../components/CSVCore';
import { AlertHeading } from 'react-bootstrap/Alert';
var moment = require('moment');

class DuplicateApplications extends Component {
	csvLink = React.createRef()
	csvLinkCheckedApplications = React.createRef()

	state = {
		response: '',
		applicationDisplayedModal: false,
		applicationDisplayed: {},
		completedApplications: [],
		checkedApplications: [],
		csvHeaderData: [],
		applicationsToExport: [],
		exportToCsvText: 'Export Applications',
		loadingModal: true,
		manuallyProcessingApplication: false,
        completedApplicationsTable: {
			query: {
				searchQuery: "",
				startDate: null,
                endDate: null
            },
            duplicates: true,
			pageIndex: 0,
			pageCount: 0,
			perPage: 20,
			isLoading: true,
			sort: {
				field: null,
				descending: false
			}
		}

	};
	
    // Breadcrumbs.
    breadcrumbs = [
        { title: 'Home', link: '/' }, 
        { title: 'Applications', link: '/applications', active: true },
    ];


    // Set the table columns.
	columns = [
		{ Header: x => {
			return (
				<input
					type="checkbox"
					className="checkbox"
					onChange={this.allApplicationsCheckboxHandler}
				/>
			);
		}, accessor: 'checkbox'},
		{ Header: 'ID', accessor: 'id' },
		{ Header: 'Name', accessor: 'name' },
		{ Header: 'Email', accessor: 'email' },
		{ Header: 'Application Type', accessor: 'applicationType' },
		{ Header: 'Total Amount', accessor: 'totalAmount' },
		{ Header: 'Date Submitted', accessor: 'dateSubmitted' },
		{ Header: 'Exported', accessor: 'exported' },
		{ Header: 'Exported by', accessor: 'exportedBy' },
		{ Header: 'Processed', accessor: 'processed' },
		{ Header: '', accessor: 'view' },
	];


	componentDidMount() {
        let { completedApplicationsTable } = this.state

        // Get all the types of Application Forms 
        ApplicationService.getCompletedApplications(completedApplicationsTable).then((response) => { 
            this.setState({ 
				completedApplications: response.results.completedApplications,
				completedApplicationsTable: {
					...completedApplicationsTable,
					isLoading: false,
					pageCount: response.results.totalPages,
					pageIndex: response.results.pageIndex,
					perPage: response.results.perPage
				}
			})
		});
		
		
		// Do we need to display an invoice by default?
		if (typeof this.props.match.params.viewApplicationId !== "undefined") {
			let applicationId = this.props.match.params.viewApplicationId

			this.setState({ applicationDisplayedModal: true, loadingModal: true })

			// Get this application
			ApplicationService.getApplicationById(applicationId).then((application) => {
				this.props.history.push("/applications/duplicate-applications/view/" + applicationId);

				this.setState({ applicationDisplayed: application, loadingModal: false })
			});
		}
        
	}


	/**
	 * On the search input change, update the state.
	 * @param {*} e 
	 * @param {*} fieldName 
	 */
	handleSearchInput = (e, fieldName) => {
		let { completedApplicationsTable } = this.state

		// Get the changed field info.
		let value = (e !== null && typeof e.target !== "undefined") ? e.target.value.toString() : e;
		let name = (typeof fieldName !== "undefined") ? fieldName : e.target.getAttribute('name');

		// Set loading to true and update the page number.
		completedApplicationsTable.isLoading = true;
		completedApplicationsTable.query[name] = value;
		completedApplicationsTable.pageIndex = 0;
		this.setState({ completedApplicationsTable: completedApplicationsTable })

		ApplicationService.getCompletedApplications(completedApplicationsTable).then((response) => {
			if (typeof response.results.query !== "undefined") {
				if (this.state.completedApplicationsTable.query.searchQuery === response.results.query.searchQuery || (this.state.completedApplicationsTable.query.searchQuery === "" && response.results.query.searchQuery === null)) {
					completedApplicationsTable.isLoading = false;
					this.setState({ completedApplications: response.results.completedApplications, completedApplicationsTable: completedApplicationsTable })
				}

			}
		});
	}

	/**
	 * On the sort change get new data.
	 * @param {*} sort 
	 */
	handleSortData = (sort) => {
		let { completedApplicationsTable } = this.state


		// Set loading to true and update the page number.
		completedApplicationsTable.isLoading = true;
		completedApplicationsTable.sort = sort;
		this.setState({ completedApplicationsTable: completedApplicationsTable })

		// Load all applications.
        ApplicationService.getCompletedApplications(completedApplicationsTable).then((response) => { 
            this.setState({ 
				completedApplications: response.results.completedApplications,
				completedApplicationsTable: {
					...completedApplicationsTable,
					isLoading: false,
					pageCount: response.totalPages,
					pageIndex: response.pageIndex,
					perPage: response.perPage
				}
			})
        });
	}

	/**
	 * On page change for the table.
	 * @param {*} pageIndex 
	 */
	handlePageChange = (pageIndex) => {
		
		let { completedApplicationsTable } = this.state
		// Set loading to true and update the page number.
		completedApplicationsTable.isLoading = true;
		completedApplicationsTable.pageIndex = pageIndex;
		this.setState({ completedApplicationsTable: completedApplicationsTable })

		// Load all Members.
		ApplicationService.getCompletedApplications(completedApplicationsTable).then((response) => {
			this.setState({ 
				completedApplications: response.results.completedApplications,
				completedApplicationsTable: {
					...completedApplicationsTable,
					isLoading: false,
					pageCount: response.results.totalPages,
					pageIndex: response.results.pageIndex,
					perPage: response.results.perPage
				}
			})
		});
	}

	/**
     * Show the modal and fetch application information.
	 * @param {*} e 
     */
    handleApplicationDisplayModal = (e) => {
		let applicationId = e.target.getAttribute('application-id')
		this.setState({ applicationDisplayedModal: true, loadingModal: true })

		this.props.history.push("/applications/duplicate-applications/view/" + applicationId);

        // Get this application
        ApplicationService.getApplicationById(applicationId).then((application) => {
			this.setState({ applicationDisplayed: application, loadingModal: false })
		});
		
	}
		
	/**
     * Hide the modal.
	 * @param {*} e 
     */
	closeApplicationDisplayModal = (e) => {
		this.props.history.push("/applications/duplicate-applications");
		this.setState({ applicationDisplayedModal: false })
	}


	/**
	 * On applications checkbox checked and unchecked. 
	 * @param {*} e 
	 */
	allApplicationsCheckboxHandler = e => {

		let { completedApplications, exportToCsvText } = this.state;
		let checkedApplications = [];
		if (e.target.checked) {
			Object.keys(completedApplications).map((key, index) => {
				checkedApplications.push(completedApplications[key].id)
				exportToCsvText = "Export Selected Applications" // Update the text
				return true

			})
		}
		
		// Change back to default message if array is empty
		if (checkedApplications.length === 0) {
			exportToCsvText = "Export Applications"
		}

		this.setState({ checkedApplications: checkedApplications, exportToCsvText: exportToCsvText })
	}

	/**
	 * On invoice checkbox checked and unchecked. 
	 * @param {*} e 
	 */
	applicationsCheckboxHandler = e => {
		let { checkedApplications, exportToCsvText } = this.state;
		let applicationId = parseInt(e.target.value);

		if (e.target.checked) {
			checkedApplications.push(applicationId) // Add to array.
			exportToCsvText = "Export Selected Applications" // Update the text
		} else {
			let i = checkedApplications.indexOf(applicationId); // Remove from array.
			if (i !== -1) { checkedApplications.splice(i, 1); }
		}

		// Change back to default message if array is empty
		if (checkedApplications.length === 0) {
			exportToCsvText = "Export Applications"
		}

		this.setState({ checkedApplications: checkedApplications, exportToCsvText: exportToCsvText })
	}

	/**
	 * Call the service to get all the applications that have not been exported already
	 * @param {*} e 
	 */
	exportRemainingToCsv = async (e) => {
		// Stop the click propagation
		e.preventDefault();

		let response = await ApplicationService.exportRemainingApplications(); 

		// Check if we need to display an error message
		if(typeof response.errors !== "undefined" && response.errors.length !== 0) {
			// Yes, loop through and display all the errors.
			for (let i = 0; i < response.errors.length; i++) {
				const message = response.errors[i];
				store.addNotification({
					title: "Error!",
					message: message,
					type: "danger",
					container: "top-right",
					animationIn: ["animated", "slideInRight"],
					animationOut: ["animated", "slideOutRight"],
					dismiss: { duration: 3000 }
				});
			}
		} else {
			// Assign our variables with data
			let csvHeaderData = response.headerData
			let applicationsToExport = response.applicationsToExport
	
			// Export to CSV
			buildURI(applicationsToExport, false, csvHeaderData, ",", "", `Remaining-Applications-${moment().format('YYYY-MM-DD')}.csv`);
		}

		
		return true;
	}

	/**
	 * Call the service to get all the application information using ID as a param
	 * @param {*} e 
	 */
	exportSelectedToCsv = async (e) => {
		// Stop the click propagation
		e.preventDefault();

		// Call the service
		let response = await ApplicationService.exportSelectedApplications(this.state.checkedApplications); 

		// Assign our variables with data
		let csvHeaderData = response.headerData
		let applicationsToExport = response.applicationsToExport

		// Export to CSV
		buildURI(applicationsToExport, false, csvHeaderData, ",", "", `Selected-Applications-${moment().format('YYYY-MM-DD')}.csv`);
		
	};


	/**
	 * 
	 * Call the service to get all the application information using ID as a param
	 * @param {*} e 
	 */
	exportModalApplicationCsv = async (e) => {
		e.preventDefault();

		let applicationArray = []; 

		applicationArray.push(this.state.applicationDisplayed.id)

		// Call the service
		let response = await ApplicationService.exportSelectedApplications(applicationArray); 

		// Assign our variables with data
		let csvHeaderData = response.headerData
		let applicationsToExport = response.applicationsToExport

		// Export to CSV
		buildURI(applicationsToExport, false, csvHeaderData, ",", "", `Selected-Application-${moment().format('YYYY-MM-DD')}.csv`);
		
	};

	/**
	 * Process applications in Dynamics.
	 *
	 * @param {*} e
	 */
	manuallyProcessApplication = async (e) => {
		console.log('test111');

		// prevent call if we are already processing the application
		if (!this.state.manuallyProcessingApplication) {

			console.log('test222');

			//set state to processing application
			this.state.manuallyProcessingApplication = true;
			this.setState({manuallyProcessingApplication: this.state.manuallyProcessingApplication})

			e.preventDefault();
			let applicationIds = [];
			applicationIds.push(this.state.applicationDisplayed.id)
			// Call the service
			let result = await ApplicationService.manuallyProcessApplications(applicationIds);

			console.log('processApplications');
			console.log(result);

			//set state to finish processing application
			this.state.manuallyProcessingApplication = false;
			this.setState({manuallyProcessingApplication: this.state.manuallyProcessingApplication})
			// if we have errors, we should notify the user
			if (typeof(result.errors) != 'undefined') {

				console.log('we have received errors')

				result.errors.map((error) => {
					store.addNotification({
						title: "Error!",
						//message: error,
						content: (
							<div style={{ backgroundColor: "#dc3545",borderLeft: "8px solid #bd1120" }} class="notification-content">
								<p class="notification-title">Error!</p>
								<p class="notification-message">{error}</p>
								<p class="notification-message">
									<button class="btn" onClick={function() {
										alert('test');
									}}>Mark as manually completed</button>
									{/* <button class="btn btn-primary btn-sm btn-view" application-id="2808">View</button> */}
								</p>
								
								<button style={{ position:"absolute", top:"0", right:"0" }} class="close">
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
						),
						type: "danger",
						container: "top-right",
						animationIn: ["animated", "slideInRight"],
						animationOut: ["animated", "slideOutRight"]
					});
					return true;
				});
			} else {
				console.log('we have not received errors')
				// All went well, notify the user
				store.addNotification({
					title: "Success!",
					message: "Application processed with success",
					type: "success",
					container: "top-right",
					animationIn: ["animated", "slideInRight"],
					animationOut: ["animated", "slideOutRight"],
					dismiss: {duration: 3000}
				});
			}

			
		}
	};


	/**
	 * Convert field name to title.
	 * 
	 * @param {string} fieldName 
	 */
	fileFieldToLabel = (fieldName) => {
		switch (fieldName) {
			case "eligibility_doc": return "Eligibility Document";
			default: return "File Field";
		}
	}


	/**
	 * Download application file.
	 * @param {*} e 
	 */
	downloadApplicationFile = (e) => {
		e.preventDefault();
		
		let applicationRef = e.target.getAttribute('data-application-ref')
		let fileId = e.target.getAttribute('data-id')


        // Get this application
        ApplicationService.downloadApplicationFile(fileId, applicationRef).then((data) => {

			if (data !== null) {
				const link = document.createElement('a');
				link.href = data.url;
				link.setAttribute('download', data.filename);
				
				// Append to html page
				document.body.appendChild(link);
				
				// Click the link automatically then remove it.
				link.click();
				link.parentNode.removeChild(link);
			} else {

			}
		});
	}

	pad = function(n, width, z) {
		z = z || '0';
		n = n + '';
		return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
	}


	render() {
		let { applicationDisplayed, applicationDisplayedModal, loadingModal,  manuallyProcessingApplication} = this.state

		return (
			<Layout breadcrumbs={this.breadcrumbs} match={this.props.match}>
				<div>
					<h1 className="page-title float-left">Duplicate Applications</h1>
					<div className="form-group float-right">

						{/* Use the same link depending if any applications are checked */}
						{ this.state.checkedApplications.length ?
						<button 
							onClick={this.exportSelectedToCsv}
							ref={this.csvLinkCheckedApplications} 
							className={"btn btn-primary"}>{this.state.exportToCsvText}</button> :
						<button 
							onClick={this.exportRemainingToCsv}
							ref={this.csvLink} 
							className={"btn btn-primary px-4"}>{this.state.exportToCsvText}</button> }

						<input name="searchQuery" className="form-control form-control-sm mt-2" placeholder="Search Applications" onChange={this.handleSearchInput} />

					</div>
				</div>

                {/* Display the table with all the applications */}
                <div className="table-responsive">
					<ApplicationTable
						propData={this.state.completedApplications} 
						propColumns={this.columns}
						tableColumns={this.columns} 
						searchQuery={this.state.completedApplicationsTable.searchQuery} 
						gotoPage={this.handlePageChange} 
						handleTdClick={this.handleTdClick}
						pageIndex={this.state.completedApplicationsTable.pageIndex} 
						pageCount={this.state.completedApplicationsTable.pageCount}
						isLoading={this.state.completedApplicationsTable.isLoading}
						sort={this.state.completedApplicationsTable.sort}
						sortData={this.handleSortData}
						checkedApplications={this.state.checkedApplications} 
						applicationsCheckboxHandler={this.applicationsCheckboxHandler}
						handleApplicationDisplayModal={this.handleApplicationDisplayModal}/>
				</div>
				
				<Modal centered size="lg" show={applicationDisplayedModal} onHide={this.closeApplicationDisplayModal}>	
					<Modal.Header>
						{!loadingModal ? (<Modal.Title>{applicationDisplayed.answers.first_name + " " + applicationDisplayed.answers.last_name} - { applicationDisplayed.priceObj.name }</Modal.Title>) : (<Modal.Title>Loading...</Modal.Title>)}
							<button type="button" className="close" onClick={this.closeApplicationDisplayModal}><span aria-hidden="true">×</span><span className="sr-only">Close</span></button>
					</Modal.Header>

					<Modal.Body>
						<div className="container">
							{manuallyProcessingApplication ?(
								<div>
									<div className="alert alert-info" role="alert">
										<span>Processing application, please wait...</span>
									</div>
								</div>
							):(<div/>)}
							{!loadingModal ? (
								<div className="row">

									<div className="col-md-6">
										{/* Application Type */}
										<p><strong>Application Type: </strong>{ applicationDisplayed.priceObj.name }</p>

										{/* Payment Type */}
										<p><strong>Payment Method: </strong>
											{applicationDisplayed.paymentMethod === "cardPayment" ? <span>Card Payment ({applicationDisplayed.stripeChargeId})</span> : <></>}
											{applicationDisplayed.paymentMethod === "directDebit" ? 
												<span>Direct Debit (<Link to={"/direct-debits/payments/"+applicationDisplayed.gcPaymentId}>View Payment</Link>)</span> : <></>
											}
											{applicationDisplayed.paymentMethod === null ? <span>No Payment Method</span> : <></>}

											{applicationDisplayed.gcCustomerId !== null ? <span><br /><br /><strong>GoCardless ID:</strong> {applicationDisplayed.gcCustomerId}</span> : <></>}
										</p>
										
										{/* Date Submitted */}
										<p><strong>Date Submitted: </strong>{ moment(applicationDisplayed.dateSubmitted).format("DD/MM/YYYY h:mma") }</p>

										{/* Employer */}
										{applicationDisplayed.answers.employer ? <><p><strong>Employer: </strong>{applicationDisplayed.answers.employer}</p></> : <p><strong>Employer: </strong>No employer</p>}

										{/* Application Support */}
										{applicationDisplayed.answers.application_support === "true" ? <p><strong>Application Support: </strong>Yes</p> : <p><strong>Application Support: </strong>No</p> }

										{/* Email address */}
										{applicationDisplayed.answers.contact_email ? <><p><strong>Email address: </strong>
											<a href={"mailto:"+applicationDisplayed.answers.contact_email}>{applicationDisplayed.answers.contact_email}</a>
										</p></> : <></>}

										{/* Landline Telephone */}
										{applicationDisplayed.answers.contact_primary_number ? <><p><strong>Landline Telephone: </strong>{applicationDisplayed.answers.contact_primary_number}</p></> : <></>}

										{/* Mobile Telephone */}
										{applicationDisplayed.answers.contact_secondary_number ? <><p><strong>Mobile Telephone: </strong>{applicationDisplayed.answers.contact_secondary_number}</p></> : <></>}

										{/* Discount Code */}
										{applicationDisplayed.discountCode ? <><p><strong>Discount Code: </strong>{applicationDisplayed.discountCode}</p></> : <></>}

										{/* Discount Code */}
										{applicationDisplayed.discountCode ? <><p><strong>Discount Code: </strong>{applicationDisplayed.discountCode}</p></> : <></>}

										{/* Access codes */}
										{applicationDisplayed.accessIEMACode ? <><p><strong>Access Code: </strong>{applicationDisplayed.accessIEMACode}</p></> : <></>}
									</div>

									<div className="col-md-6">
										{/* Exempt from Exam? */}
										{applicationDisplayed.answers.exam_exempt === true || applicationDisplayed.answers.exam_exempt === "true" ? <p><strong>Exam Exempt:</strong> Exempt</p> : "" }

										{/* Eligibility Awarding Body */}
										{applicationDisplayed.answers.eligibility_awardingBody ? <p><strong>Eligibility Awarding Body:</strong> { applicationDisplayed.answers.eligibility_awardingBody }</p> : ""}

										{/* Course */}
										{applicationDisplayed.answers.eligibility_course ? <p><strong>Eligibility Course:</strong> { applicationDisplayed.answers.eligibility_course }</p> : ""}
										
										{/* Are they taking an exam? */}
										{applicationDisplayed.answers.exam ? <>
										<p><strong>Exam Type: </strong>{ applicationDisplayed.answers.exam }<br />
										<strong>Exam Month: </strong>{ applicationDisplayed.answers.exam_month }<br />
										<strong>Exam Year: </strong>{ applicationDisplayed.answers.exam_year }</p>
										{applicationDisplayed.answers.apply_resonable_adjustment == 'true' ? <p><strong>Apply Reasonable Adjustment: </strong>Yes</p> : <p><strong>Apply Reasonable Adjustment: </strong>No</p>}
										</> : ""} 
										
										{/* Are they taking an exam? */}
										{typeof applicationDisplayed.answers.achievement_date !== "undefined" && applicationDisplayed.answers.achievement_date !== null ? 
											<p><strong>Achievement Date: </strong>{this.pad(applicationDisplayed.answers.achievement_date, 2)}/{this.pad(applicationDisplayed.answers.achievement_month, 2)}/{applicationDisplayed.answers.achievement_year}
										</p> : ""}
										
										{/* Show Graduation date for applications. */}
										{typeof applicationDisplayed.answers.graduation_date !== "undefined" && applicationDisplayed.answers.graduation_date !== null ? 
											<p><strong>Graduation Date: </strong>{this.pad(applicationDisplayed.answers.graduation_date, 2)}/{this.pad(applicationDisplayed.answers.graduation_month, 2)}/{applicationDisplayed.answers.graduation_year}
										</p> : ""}
									</div>

									<div className="col-md-12">
										{/* List subjects covered for Graduate applications */}
										<SubjectsCoveredList answers={applicationDisplayed.answers} />

										{/* List all files */}
										{Object.keys(applicationDisplayed.files).map((key) => {
											let field = applicationDisplayed.files[key];

											return (<div key={key}>
												<p style={{marginBottom: 0}}><strong>{this.fileFieldToLabel(key)}:</strong></p>
												<ul>
													{field.map((file) => (
														<li key={key+"-"+file.id}><a
															href="/" 
															onClick={this.downloadApplicationFile} 
															data-application-ref={file.application_ref}
															data-id={file.id}
														>{file.filename}</a></li>
													))}
												</ul>
											</div>)
										})}

										{/* Total Amount */}
										{ applicationDisplayed.lineItemAmount ? <span><strong>Line Item Total: </strong>&pound;{applicationDisplayed.lineItemAmount.toFixed(2)}<br /></span> : <></>}
										{ applicationDisplayed.discountAmount ? <span><strong>Discount Amount: </strong>-&pound;{applicationDisplayed.discountAmount.toFixed(2)}<br /></span> : <></>}
										{ applicationDisplayed.lowerIncomeCountryDiscount ? <span><strong>Country Discount: </strong>{applicationDisplayed.lowerIncomeCountryDiscount ? 'Yes' : 'No'}<br /></span> : <></>}
										{ applicationDisplayed.totalAmount ? <span><strong>Total Amount: </strong>&pound;{applicationDisplayed.totalAmount.toFixed(2)}<br /></span> : <p><strong>Total Amount: </strong>N/A</p>}
									</div>
								</div>
							) : (
								
								<div>
									<div className="alert alert-info" role="alert">
										<h3>Loading</h3>
										<span>Loading application information, please wait...</span>
									</div>
								</div>
							)}
						</div>
					</Modal.Body>
							
					<Modal.Footer>
						{ applicationDisplayed.processed == 0 ? 
						<button className="btn btn-secondary" onClick={this.manuallyProcessApplication}>Manually Process Application</button>
						: ""} 
						<button className="btn btn-primary" onClick={this.exportModalApplicationCsv}>Export This Application</button>
					</Modal.Footer>
				</Modal>

			</Layout>
		);
	}
}


/**
 * List all subjects covered.
 * 
 * @param {*} answers 
 */
function SubjectsCoveredList({ answers }) {
	if (typeof answers !== "undefined") {
		let getLabel = (key) => {
			let label = null;
			switch (key) {
				case "subject_covered_global_megatrends": label = "Global megatrends and their impact on sustainability"; break;				case "subject_covered_sustainable_development": label = "Sustainable Development"; break;
				case "subject_covered_corporate_responsibility": label = "Corporate Responsibility"; break;
				case "subject_covered_natural_cycles": label = "Natural cycles, ecosystems, biodiversity and the impact of pollution."; break;
				case "subject_covered_tools_and_techniques": label = "Tools and techniques for assessing and managing environment, social, economic issues and improving sustainability performance of organisations, products and services, or new developments."; break;
				case "subject_covered_environment_and_sustainability": label = "Environment and sustainability principles and issues and associated Policy and Legislation (international, regional or national) suitably relevant."; break;
				default: label = null; break;
			}
			return label;
		}

		// Regexp to get all correct answers.
		let matches = Object.keys(answers).filter(function(pattern) {
			return (pattern.match(/^subject_/s) && answers[pattern] === "true") ? pattern : null;
		})

		// Check if have any valid subjects.
		if (matches.length > 0) {
			return (
				<div>
					<strong>Sustainability Criteria Selections:</strong><br />
					<ul className="pl-4">
						{matches.map(subject => (
							<li key={subject}>{getLabel(subject)}</li>
						))}
					</ul>
				</div>
			)
		}
	}

	return (<></>)
}


/**
 * Application table to display all applications.
 * 
 * @param {*}
 */
function ApplicationTable({ handleApplicationDisplayModal, propColumns, propData, checkedApplications, applicationsCheckboxHandler, gotoPage, pageIndex, pageCount, isLoading, sort, sortData, }) {

	// Define the table columns.
	const columns = React.useMemo(() => propColumns, [propColumns])
	
	// Define the data.
	const data = React.useMemo(() => propData, [propData])

	// Use the state and functions returned from useTable to build your UI
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow,
	} = useTable({
		columns,
		data,
		initialState: { pageIndex: pageIndex, pageSize: 20 },
	}, usePagination)
	

	/**
	 * Calculate and show the correct pages.
	 * @param {*} pIndex 
	 * @param {*} pCount 
	 */
	const numberedPages = (pIndex, pCount) => {
		let gCount = 3;
		let showPagesArr = [];

		// Loop through all pages and calculate which ones to show.
		for (let i = 0; i < pCount; i++) {
			if ((pIndex - gCount) < i && (gCount + pIndex) > i) {
				showPagesArr.push(i);
			}            
		}
		return showPagesArr;
	}
	
    
	return (
		<div className="table-responsive">
			<table className={"table table-striped"} {...getTableProps()}>
				<thead>
					{headerGroups.map(headerGroup => (
						<tr {...headerGroup.getHeaderGroupProps()}>
						{headerGroup.headers.map(column => (
							<th {...column.getHeaderProps()}>{column.render("Header")}</th>
						))}
					</tr>
					))}
				</thead>

				<tbody {...getTableBodyProps()}>
					{rows.map((row, i) => {
						prepareRow(row);
						return (
							<tr {...row.getRowProps()}>
								{row.cells.map(cell => {
									if (cell.column.id === 'price') {
										return (<td {...cell.getCellProps()}>
											&pound;{cell.render("Cell")}</td>)
									} else if (cell.column.id === "name") {
										return <td key={i} {...cell.getCellProps()}>{cell.row.original.firstName + ' ' + cell.row.original.lastName}</td>;
									} else if (cell.column.id === "exportedBy") {
										return <td key={i} {...cell.getCellProps()}>
											<Link to={"/users/edit-user/"+cell.row.original.exportedById}>{cell.row.original.exportedBy}</Link>
										</td>;
									} else if (cell.column.id === 'checkbox') {
										return (<td {...cell.getCellProps()}>
											<input type="checkbox" className="check-one" value={cell.row.original.id} checked={checkedApplications.includes(cell.row.original.id) ? true : false} onChange={applicationsCheckboxHandler}/></td>)
										} else if (cell.column.id === "view") {
										return (<td {...cell.getCellProps()}>
										<button key={i} onClick={handleApplicationDisplayModal} className="btn btn-primary btn-sm btn-view" application-id={cell.row.original.id}>View</button></td>)
									} else if (cell.column.id === "totalAmount") {
										return (<td {...cell.getCellProps()}>
										&pound;{cell.row.original.totalAmount.toFixed(2)}</td>)
									} else if (cell.column.id === 'exported') {
										let label = cell.row.original.exported;
										if (label === 1) {
										return (<td {...cell.getCellProps()}>
											<span className={"badge badge-success"}>Exported</span></td>)
										}
										return (<td {...cell.getCellProps()}>
											<span className={"badge badge-warning"}>Not Exported</span></td>)
									}
									else if (cell.column.id === 'processed') {
										let label = cell.row.original.processed;
										if (label === 1) {
										return (<td {...cell.getCellProps()}>
											<span className={"badge badge-success"}>Processed</span></td>)
										}
										return (<td {...cell.getCellProps()}>
											<span className={"badge badge-warning"}>Not Processed</span></td>)
									} else {
										return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
									}
								})}
							</tr>
						);
					})}
				</tbody>
			</table>
			<nav className="d-flex mt-4" aria-label="Page navigation">
				<ul className="pagination mx-auto">
					<li className="page-item">
						<button className="page-link" onClick={() => gotoPage(0)} disabled={(pageIndex === 0)}>&lt;&lt; First</button>
					</li>
					<li className="page-item">
						<button className="page-link" onClick={() => gotoPage(pageIndex-1)} disabled={(pageIndex === 0)}>&lt; Previous</button>
					</li>
					{numberedPages(pageIndex, pageCount).map((pageNum) => {
						return (
							<li className={"page-item " + (pageIndex === pageNum ? 'active' : '')} key={pageNum+1}>
								<button className="page-link" onClick={() => gotoPage(pageNum)}>{pageNum+1}</button>
							</li>
						)
					})}
					<li className="page-item">
						<button className="page-link" onClick={() => gotoPage(pageIndex+1)} disabled={(pageIndex+1 === pageCount)}>Next &gt;</button>
					</li>
					<li className="page-item">
						<button className="page-link" onClick={() => gotoPage(pageCount-1)} disabled={(pageIndex+1 === pageCount)}>Last &gt;&gt;</button>
					</li>
				</ul>
			</nav>
		</div>
	)
}

export default withRouter((props, ref) => (
	<AuthContext.Consumer>{context => {
		if (context.currentUser.permissions.applications) {
			return (<DuplicateApplications {...props} authContext={context} />)
		} else {
			return (<AccessDenied {...props}/>)
		}
	}}</AuthContext.Consumer>
));